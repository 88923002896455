import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Span, Flex } from '../../ui';
//////////////////////////////////////////////////

const getHighIcon = (smallIcons: any) => {
  if (G.isTrue(smallIcons)) return I.temperatureHot(null, 10, 15);

  return I.temperatureHot();
};

const getLowIcon = (smallIcons: any) => {
  if (G.isTrue(smallIcons)) return I.temperatureCold(null, 10, 15);

  return I.temperatureCold();
};

const TemperatureHigh = ({ value, showText, smallIcons }: Object) => (
  <Flex px='4px' title={`${G.getWindowLocale('titles:temperature-high', 'Temperature High')}: ${value}`}>
    {getHighIcon(smallIcons)}
    {
      showText &&
      <Span ml='6px'>{value}</Span>
    }
  </Flex>
);

const TemperatureLow = ({ showText, smallIcons, temperatureLowDisplayValue, temperatureHighDisplayValue }: Object) => {
  let title = `${G.getWindowLocale('titles:temperature-low', 'Temperature Low')}: ${temperatureLowDisplayValue}`;

  if (G.isNotNil(temperatureHighDisplayValue)) {
    title = `${title}; ${
      G.getWindowLocale('titles:temperature-high', 'Temperature High')}: ${temperatureHighDisplayValue}`;
  }

  return (
    <Flex px='4px' title={title}>
      {getLowIcon(smallIcons)}
      {
        showText &&
        <Span ml='6px'>{temperatureLowDisplayValue}</Span>
      }
    </Flex>
  );
};

export const ItemIcons = ({ width, showText, smallIcons, anyHazardous, temperatureLow, temperatureHigh }: Object) => {
  let temperatureLowValue;
  let temperatureLowDisplayValue;
  let temperatureHighValue;
  let temperatureHighDisplayValue;

  if (G.isImperialUomSystem()) {
    temperatureLowValue = G.toFixed(G.fromCelsiusToFahrenheit(temperatureLow), 0);
    temperatureLowDisplayValue = `${temperatureLowValue}F`;
    temperatureHighValue = G.toFixed(G.fromCelsiusToFahrenheit(temperatureHigh), 0);
    temperatureHighDisplayValue = `${temperatureHighValue}F`;
  } else {
    temperatureLowValue = G.toFixed(temperatureLow, 0);
    temperatureLowDisplayValue = `${temperatureLowValue}C`;
    temperatureHighValue = G.toFixed(temperatureHigh, 0);
    temperatureHighDisplayValue = `${temperatureHighValue}C`;
  }

  return (
    <Flex width={R.or(width, '100%')} justifyContent='space-around'>
      {
        G.isNotNil(temperatureLow) &&
        <TemperatureLow
          showText={showText}
          smallIcons={smallIcons}
          temperatureLowDisplayValue={temperatureLowDisplayValue}
          temperatureHighDisplayValue={G.ifElse(R.isNil(temperatureHigh), null, temperatureHighDisplayValue)}
        />
      }
      {
        R.and(R.isNil(temperatureLow), G.isNotNil(temperatureHigh)) &&
        <TemperatureHigh
          showText={showText}
          smallIcons={smallIcons}
          value={temperatureHighDisplayValue}
        />
      }
      {G.isTrue(anyHazardous) && I.renderHazardousIcon()}
    </Flex>
  );
};
