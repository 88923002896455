import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const deleteAllResizeData = createAction('deleteAllResizeData');
export const getReportTableResize = createAction('getReportTableResize');
export const setReportTableResize = createAction('setReportTableResize');
export const updateTableResizeByGuid = createAction('updateTableResizeByGuid');
export const deleteTableResizeByGuid = createAction('deleteTableResizeByGuid');
export const deleteTableResizeByType = createAction('deleteTableResizeByType');
export const initTableResizeIDBSuccess = createAction('initTableResizeIDBSuccess');
export const reportTableResizeIDBReconnect = createAction('reportTableResizeIDBReconnect');
