import styled from 'styled-components';
// helpers
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const StyledCard = styled.div`
  width: 380px;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;

  color: ${() => G.getTheme('colors.textDark')};
  background-color: ${() => G.getTheme('colors.white')};
  box-shadow: ${() => G.getTheme('shadows.cardBoxShadow')};
  border: 1px solid ${() => G.getTheme('colors.borderGray')};

  &:hover {
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

