import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../form-footer';
// forms
import { Fieldset2 } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const fieldSettings = [
  {
    type: 'multiEmail',
    label: ['titles:mail-to'],
    shouldOverrideEmails: true,
    fieldName: GC.FIELD_MAIL_TO,
    inputWrapperStyles: { mt: 10, mb: 25, width: 270 },
  },
];

const SendDocuments = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2 {...G.getFormikProps(props)} fields={fieldSettings} />
    <FormFooter2 />
  </form>
);

const enhance = compose(
  withFormik({
    handleSubmit: ({ mailTo }: Object, { props }: Object) => props.submitAction(mailTo),
    mapPropsToValues: () => ({
      [GC.FIELD_MAIL_TO]: [],
    }),
    validationSchema: Yup.object().shape({
      [GC.FIELD_MAIL_TO]: G.yupArrayRequired,
    }),
  }),
  withHandlers({
    handleGetEmailFromProfile: (props: Object) => async () => {
      const { isDriver, setFieldValue, payrollTypeGuid } = props;

      const endpoint = R.prop(
        G.ifElse(isDriver, 'getFleetDriverEmailByGuid', 'getFleetVendorEmailByGuid'),
        endpointsMap,
      )(payrollTypeGuid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (R.and(G.isNotNilAndNotEmpty(data), G.isResponseSuccess(status))) {
        setFieldValue(GC.FIELD_MAIL_TO, R.of(Array, data));
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetEmailFromProfile();
    },
  }),
  pure,
);

export default enhance(SendDocuments);
