import * as R from 'ramda';
import { compose, withState, withHandlers, lifecycle } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetConfigsByNames } from '../../../helpers/api-async';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// components charge
import { getAccessorialsForState } from '../helpers';
import { setAccessorialsFromResponseToChargeUse, getRateFromSharedAccessAndFuelPrices } from './helpers';
//////////////////////////////////////////////////

const mapDefaultAccessorial = (type: string, configValue: string, accessorials: Object) => {
  const sharedAccessorial = R.find(
    R.propEq(configValue, GC.FIELD_GUID),
    R.values(accessorials),
  );

  const accessorialConfig = R.find(
    R.propEq(configValue, GC.FIELD_ORIGINAL_CONFIG_GUID),
    R.values(accessorials),
  );

  const data = R.or(sharedAccessorial, accessorialConfig);

  if (G.isNilOrEmpty(data)) return null;

  const {
    rate,
    guid,
    currency,
    rateType,
    rateUnit,
    nonTaxable,
    fuelRelated,
    storedValue,
    fuelIndexInfo,
    advancePayment,
    displayedValue,
    internalExpense,
    originalConfigGuid,
  } = data;

  return {
    guid,
    type,
    rate,
    total: 0,
    currency,
    rateType,
    rateUnit,
    nonTaxable,
    fuelRelated,
    storedValue,
    fuelIndexInfo,
    displayedValue,
    advancePayment,
    internalExpense,
    [GC.FIELD_ID]: G.genShortId(),
    [GC.FIELD_ACCESSORIAL_CONFIG_GUID]: originalConfigGuid,
  };
};

export const mapDefaultAccessorials = (
  defaultAccessorials: Array,
  accessorials: Array,
  autoAssessorial: boolean,
) => R.map(
  (item: Object) => {
    const { guid, currency, rateType, minStops, maxStops, rateUnit, useFuelIndex, assessorialGuid } = item;

    const { rate, fuelIndexInfo } = getRateFromSharedAccessAndFuelPrices(item);

    const accessorialConfig = R.find(R.propEq(assessorialGuid, GC.FIELD_ORIGINAL_CONFIG_GUID), accessorials);

    const {
      nonTaxable,
      fuelRelated,
      storedValue,
      advancePayment,
      displayedValue,
      internalExpense,
    } = accessorialConfig;

    const type = G.ifElse(
      R.or(useFuelIndex, fuelRelated),
      GC.CHARGE_TYPE_FUEL,
      GC.CHARGE_TYPE_ADDITIONAL,
    );

    return {
      guid,
      type,
      rate,
      total: 0,
      currency,
      rateType,
      rateUnit,
      minStops,
      maxStops,
      nonTaxable,
      fuelRelated,
      storedValue,
      fuelIndexInfo,
      displayedValue,
      advancePayment,
      internalExpense,
      autoAssessorial,
      [GC.FIELD_ID]: G.genShortId(),
      defaultAccessorial: G.isNilOrEmpty(autoAssessorial),
      [GC.FIELD_ACCESSORIAL_CONFIG_GUID]: assessorialGuid,
    };
  },
  defaultAccessorials,
);

const getDefaultCarrierAccessorials = async (branchGuid: string, accessorials: any, callback: Function) => {
  try {
    const defaultCarrierAccessorials = R.path(['amousDefaultCarrierAccessorials', branchGuid], window);

    if (G.isNilOrEmpty(defaultCarrierAccessorials)) {
      const res = await sendRequest(
        'get',
        endpointsMap.defaultCarrierAccessorialList,
        { params: { [GC.BRANCH_GUID]: branchGuid } },
      );

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const newAmousDefaultCarrierAccessorials = R.assoc(
          branchGuid,
          data,
          G.getItemFromWindow('amousDefaultCarrierAccessorials'),
        );

        const mapped = mapDefaultAccessorials(data, accessorials);

        G.callFunctionWithArgs(callback, mapped);

        G.setItemToWindow('amousDefaultCarrierAccessorials', newAmousDefaultCarrierAccessorials);
      } else {
        G.handleException('getDefaultCarrierAccessorials exception');
      }
    } else {
      const mapped = mapDefaultAccessorials(defaultCarrierAccessorials, accessorials);

      G.callFunctionWithArgs(callback, mapped);
    }
  } catch (error) {
    G.handleException('error', 'getDefaultCarrierAccessorials exception');
  }
};

const getDefaultOrderAccessorials = async (branchGuid: string, accessorials: any, callback: Function) => {
  try {
    const defaultAccessorials = R.path(['amousDefaultOrderAccessorials', branchGuid], window);

    if (G.isNilOrEmpty(defaultAccessorials)) {
      const res = await sendRequest(
        'get',
        endpointsMap.defaultOrderAccessorialList,
        { params: { [GC.BRANCH_GUID]: branchGuid } },
      );

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const newAccessorials = R.assoc(
          branchGuid,
          data,
          G.getItemFromWindow('amousDefaultOrderAccessorials'),
        );

        const mapped = mapDefaultAccessorials(data, accessorials);

        G.callFunctionWithArgs(callback, mapped);

        G.setItemToWindow('amousDefaultOrderAccessorials', newAccessorials);
      } else {
        G.handleException('getDefaultOrderAccessorials exception');
      }
    } else {
      const mapped = mapDefaultAccessorials(defaultAccessorials, accessorials);

      G.callFunctionWithArgs(callback, mapped);
    }
  } catch (error) {
    G.handleException('error', 'getDefaultOrderAccessorials exception');
  }
};

const getDefaultOrderFuelAccessorial = async (branchGuid: string, accessorials: any, callback: Function) => {
  try {
    const data = await asyncGetConfigsByNames({ branchGuid, names: [GC.CLO_RATE_DEFAULT_FUEL_ASSESSORIAL] });

    const configValue = G.getConfigValueFromStore(GC.CLO_RATE_DEFAULT_FUEL_ASSESSORIAL, data);
    const mapped = mapDefaultAccessorial('fuel', configValue, accessorials);

    G.callFunctionWithArgs(callback, mapped);
  } catch (error) {
    G.handleException('error', 'getDefaultOrderFuelAccessorial exception');
  }
};

export const getAutoAccessorials = ({ stopCount, accessorials, autoAccessorials }: Object) => {
  const filtered = R.compose(
    R.filter(({ rateType, minStops }: Object) => {
      if (R.and(R.equals(rateType, GC.CHARGE_RATE_TYPE_STOP), G.isNotNilAndNotEmpty(minStops))) {
        return R.gt(stopCount, minStops);
      }

      return true;
    }),
  )(autoAccessorials);

  return mapDefaultAccessorials(filtered, accessorials, true);
};

export const getTelSharedAccessorialsApplyTo = async (props: Object) => {
  try {
    const {
      telGuid,
      loadData,
      stopCount,
      branchGuid,
      applyToType,
      accessorials,
    } = props;

    if (G.isNotNilAndNotEmpty(telGuid)) {
      const options = { params: { applyToType } };

      const endpoint = endpointsMap.getTelAutoApplySharedAssessorials(telGuid);

      const { data, status } = await sendRequest('get', endpoint, options);

      if (G.isResponseSuccess(status)) {
        return getAutoAccessorials({ stopCount, accessorials, autoAccessorials: R.or(data, []) });
      }
    }

    if (R.or(G.isNilOrEmpty(loadData), G.isNilOrEmpty(branchGuid))) return [];

    const origin = R.path(['events', 0, 'location'], loadData);
    const destination = R.path(['location'], R.last(R.pathOr([], ['events'], loadData)));

    if (R.or(G.isNilOrEmpty(origin), G.isNilOrEmpty(destination))) return [];

    const options = {
      data: {
        origin,
        destination,
        applyToType,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const endpoint = endpointsMap.sharedAccessorialListApplyTo;

    const { data, status } = await sendRequest('post', endpoint, options);

    if (G.isResponseSuccess(status)) {
      return getAutoAccessorials({ stopCount, accessorials, autoAccessorials: R.or(data, []) });
    }

    return [];
  } catch (error) {
    G.handleException('error', 'getTelSharedAccessorialsApplyTo exception');
  }
};

const getSharedAccessorialsApplyTo = async (props: Object) => {
  try {
    const {
      cloGuid,
      telGuid,
      loadData,
      callback,
      stopCount,
      branchGuid,
      applyToType,
      accessorials,
    } = props;

    if (G.isNotNilAndNotEmpty(telGuid)) {
      const options = { params: { applyToType } };

      const endpoint = endpointsMap.getTelAutoApplySharedAssessorials(telGuid);

      const { data, status } = await sendRequest('get', endpoint, options);

      if (G.isResponseSuccess(status)) {
        const autoAccessorials = getAutoAccessorials({ stopCount, accessorials, autoAccessorials: data });

        return G.callFunctionWithArgs(callback, autoAccessorials);
      }
    }

    if (G.isNotNilAndNotEmpty(cloGuid)) {
      const options = { params: { applyToType } };

      const endpoint = endpointsMap.getCloAutoApplySharedAssessorials(cloGuid);

      const { data, status } = await sendRequest('get', endpoint, options);

      if (G.isResponseSuccess(status)) {
        const autoAccessorials = getAutoAccessorials({ stopCount, accessorials, autoAccessorials: data });

        return G.callFunctionWithArgs(callback, autoAccessorials);
      }
    }

    if (R.or(G.isNilOrEmpty(loadData), G.isNilOrEmpty(branchGuid))) return [];

    const origin = R.path(['events', 0, 'location'], loadData);
    const destination = R.path(['location'], R.last(R.pathOr([], ['events'], loadData)));

    if (R.or(G.isNilOrEmpty(origin), G.isNilOrEmpty(destination))) return [];

    const options = {
      data: {
        origin,
        destination,
        applyToType,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const endpoint = endpointsMap.sharedAccessorialListApplyTo;

    const { data, status } = await sendRequest('post', endpoint, options);

    if (G.isResponseSuccess(status)) {
      const autoAccessorials = getAutoAccessorials({ stopCount, accessorials, autoAccessorials: data });

      G.callFunctionWithArgs(callback, autoAccessorials);
    }
  } catch (error) {
    G.handleException('error', 'getSharedAccessorialsApplyTo exception');
  }
};

export const withAsyncChargeAccessorials = (args: any) => compose(
  withState('accessorials', 'setAccessorials', null),
  withState('fuelSelectOptions', 'setFuelSelectOptions', []),
  withState('noFuelSelectOptions', 'setNoFuelSelectOptions', []),
  withState('defaultOrderAccessorials', 'setDefaultOrderAccessorials', null),
  withState('sharedAccessorialsApplyTo', 'setSharedAccessorialsApplyTo', null),
  withState('defaultCarrierAccessorials', 'setDefaultCarrierAccessorials', null),
  withState('defaultOrderFuelAccessorial', 'setDefaultOrderFuelAccessorial', null),
  withHandlers({
    handleGetAccessorials: (props: Object) => async () => {
      const {
        cloGuid,
        telGuid,
        loadData,
        loadType,
        isInvoice,
        telEvents,
        stopCount,
        branchGuid,
        isFromPlanner,
        setAccessorials,
        setFuelSelectOptions,
        setNoFuelSelectOptions,
        setDefaultOrderAccessorials,
        setSharedAccessorialsApplyTo,
        setDefaultCarrierAccessorials,
        setDefaultOrderFuelAccessorial,
      } = props;

      const currentUserBranchGuid = G.getAmousCurrentUserBranchGuidFromWindow();
      const branchGuidToUse = R.or(branchGuid, currentUserBranchGuid);

      const options = {
        params: {
          [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
        },
      };

      const res = await sendRequest('get', endpointsMap.accessorialsListEndpoint, options);
      const sharedRes = await sendRequest('get', endpointsMap.sharedAccessorialList, options);

      const { data, status } = res;

      if (R.and(G.isResponseSuccess(status), G.isNotNilAndNotEmpty(data))) {
        let accessorilasToUse = data;

        if (G.isTrue(isInvoice)) {
          accessorilasToUse = R.reject(R.propEq(true, GC.FIELD_CHARGE_INTERNAL_EXPENSE), accessorilasToUse);
        }

        const dataToUse = setAccessorialsFromResponseToChargeUse(
          accessorilasToUse,
          G.getDataFromObject(sharedRes),
        );

        const { accessorials, fuelSelectOptions, noFuelSelectOptions } = getAccessorialsForState(dataToUse, loadType);

        setAccessorials(accessorials);
        setFuelSelectOptions(fuelSelectOptions);
        setNoFuelSelectOptions(noFuelSelectOptions);

        const isDriver = R.path(['isDriver'], args);
        const isCarrier = R.path(['isCarrier'], args);
        const isCustomer = R.path(['isCustomer'], args);
        const isCreateMode = R.path(['isCreateMode'], args);

        if (R.and(G.isTrue(isCustomer), G.isTrue(isCreateMode))) {
          await getDefaultOrderFuelAccessorial(branchGuidToUse, accessorials, setDefaultOrderFuelAccessorial);

          getDefaultOrderAccessorials(branchGuidToUse, data, setDefaultOrderAccessorials);

          getSharedAccessorialsApplyTo({
            cloGuid,
            loadData,
            stopCount,
            accessorials: data,
            branchGuid: branchGuidToUse,
            callback: setSharedAccessorialsApplyTo,
            applyToType: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_CUSTOMER,
          });
        }

        if (R.and(G.isTrue(isCarrier), G.isTrue(isCreateMode))) {
          getDefaultCarrierAccessorials(branchGuidToUse, data, setDefaultCarrierAccessorials);

          getSharedAccessorialsApplyTo({
            telGuid,
            loadData,
            stopCount,
            accessorials: data,
            branchGuid: branchGuidToUse,
            callback: setSharedAccessorialsApplyTo,
            applyToType: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_CARRIER,
          });
        }

        if (R.and(G.isTrue(isDriver), G.isTrue(isCreateMode))) {
          const loadDataToUse = G.ifElse(
            R.and(G.isTrue(isFromPlanner), G.isNilOrEmpty(loadData)),
            R.objOf('events', telEvents),
            loadData,
          );

          getSharedAccessorialsApplyTo({
            telGuid,
            stopCount,
            accessorials: data,
            loadData: loadDataToUse,
            branchGuid: branchGuidToUse,
            callback: setSharedAccessorialsApplyTo,
            applyToType: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_FLEET,
          });
        }
      }
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.handleGetAccessorials();
    },
  }),
);
