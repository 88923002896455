import React from 'react';
import * as R from 'ramda';
import { createPortal } from 'react-dom';
import { OuterClick } from 'react-outer-click';
import { Manager, Target, Popper } from 'react-popper';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../text';
// helpers/constants
import * as G from '../../helpers';
// svgs
import * as I from '../../svgs';
// ui
import { Box, Flex, RelativeBox, PageTitleCount } from '../../ui';
// features routes
import getSettings from './settings';
import { Wrapper, PopperComponentWrapper } from './ui';
//////////////////////////////////////////////////

const popperAction = (props: Object) => () => {
  const { isOpened, actionOnHoverOrClick, handleSetPopperIsOpened } = props;

  if (isOpened) return;

  if (G.isNotNil(actionOnHoverOrClick)) actionOnHoverOrClick();

  handleSetPopperIsOpened(true);
};

const enhance = compose(
  withState('isOpened', 'setOpen', false),
  withHandlers(() => {
    let timeout;

    return {
      handleSetPopperIsOpened: (props: Object) => (value: boolean, clicked: boolean) => {
        clearTimeout(timeout);

        const { setOpen } = props;

        if (R.isNil(value)) return setOpen((prev: boolean) => R.not(prev));

        if (R.and(G.isFalse(value), G.isFalse(clicked))) {
          timeout = setTimeout(() => setOpen(value), 300);

          return;
        }

        setOpen(value);
      },
      handleTogglePopper: ({ setOpen }: Object) => (value: Object) => setOpen(R.not(value)),
      handleHoverPopper: (props: Object) => () => {
        const { setOpen, isOpened, actionOnHoverOrClick } = props;

        clearTimeout(timeout);

        if (isOpened) return;

        if (G.isNotNil(actionOnHoverOrClick)) actionOnHoverOrClick();

        setOpen(true);
      },
    };
  }),
);

export const DropDownOnHover = (props: Object) => (
  <PopperComponentWrapper
    maxHeight={props.maxHeight}
    activeStyles={props.activeStyles}
    wrapperWidth={props.wrapperWidth}
    popperZIndex={props.popperZIndex}
    onMouseEnter={props.handleHoverPopper}
    wrapperMaxWidth={props.wrapperMaxWidth}
    onMouseLeave={() => props.handleSetPopperIsOpened(false, false)}
  >
    <Manager className='popper-container'>
      <Target className='popper-target'>
        <Box
          display='flex'
          transform={G.ifElse(
            R.and(props.isOpened, G.isTrue(props.rotate)),
            R.or(props.rotateDeg, 'rotate(90deg)'),
            'rotate(0deg)',
          )}
          transition='transform .3s linear'
        >
          {props.children}
        </Box>
      </Target>
      {
        props.isOpened &&
        <Wrapper {...props}>
          <Popper className='popper' placement={props.position}>
            {props.content}
          </Popper>
        </Wrapper>
      }
    </Manager>
  </PopperComponentWrapper>
);

const renderContent = ({ setOpen, content }: Object) => {
  if (G.isFunction(content)) {
    const closePopper = () => setOpen(false);

    return content({ closePopper });
  }

  return content;
};

const PopperContent = (props: Object) => {
  const { position, usePortal, withoutOuterClick, handleSetPopperIsOpened } = props;

  const content = (
    <Popper className='popper' placement={position}>
      {renderContent(props)}
    </Popper>
  );

  const popperContent = (
    <Wrapper {...props}>
      {
        withoutOuterClick
          ? content
          : (
            <OuterClick onOuterClick={() => handleSetPopperIsOpened(false, true)}>
              {content}
            </OuterClick>
          )
      }
    </Wrapper>
  );

  if (G.isTrue(usePortal)) {
    return createPortal(popperContent, document.body);
  }

  return popperContent;
};

export const DropDownOnClick = (props: Object) => {
  const {
    zi,
    rotate,
    children,
    isOpened,
    rotateDeg,
    maxHeight,
    wrapperWidth,
    activeStyles,
    popperZIndex,
    wrapperMaxWidth,
    popperComponentWrapperMargin,
  } = props;

  return (
    <PopperComponentWrapper
      zi={zi}
      cursor='pointer'
      maxHeight={maxHeight}
      wrapperWidth={wrapperWidth}
      activeStyles={activeStyles}
      popperZIndex={popperZIndex}
      onClick={popperAction(props)}
      m={popperComponentWrapperMargin}
      wrapperMaxWidth={wrapperMaxWidth}
    >
      <Manager className='popper-container'>
        <Target className='popper-target'>
          <Box
            display='flex'
            transition='transform .3s linear'
            transform={G.ifElse(
              R.and(isOpened, G.isTrue(rotate)),
              R.or(rotateDeg, 'rotate(90deg)'),
              'rotate(0deg)',
            )}
          >
            {children}
          </Box>
        </Target>
        {isOpened && <PopperContent {...props} />}
      </Manager>
    </PopperComponentWrapper>
  );
};

export const PopperComponent = enhance((props: Object) => {
  const dropDownType = {
    hover: DropDownOnHover,
    click: DropDownOnClick,
  };

  return dropDownType[props.type](props);
});

export const FilterWithCounter = (props: Object) => (
  <RelativeBox
    ml='5px'
    mt='5px'
    title={props.title}
  >
    {I.filter(props.iconColor)}
    <PageTitleCount
      r='-3px'
      t='-3px'
      width='14px'
      height='14px'
      fontSize='11px'
      border='1px solid'
      version={props.version}
    >
      {props.filterCount}
    </PageTitleCount>
  </RelativeBox>
);

export const PopperWrapper = (props: Object) => {
  const {
    type,
    icon,
    text,
    filterCount,
    popperContent,
    popperWithCount,
    additionalStyles,
    withoutOuterClick,
    actionOnHoverOrClick,
  } = props;

  const mainBlue = G.getTheme('colors.dark.blue');
  const mainLight = G.getTheme('colors.light.mainLight');
  const settings = getSettings({ mainBlue, mainLight });

  return (
    <PopperComponent
      {...R.path(['popper', type], settings)}
      {...additionalStyles}
      content={popperContent}
      withoutOuterClick={withoutOuterClick}
      actionOnHoverOrClick={actionOnHoverOrClick}
    >
      <Flex {...R.path(['popperContainer', type], settings)}>
        <Flex {...R.path(['text', type], settings)}>
          <TextComponent>
            {text}
          </TextComponent>
          {
            G.isNotNilAndNotEmpty(icon) &&
            <Box {...R.path(['icon', type], settings)}>{icon}</Box>
          }
        </Flex>
        {
          R.and(G.isTrue(popperWithCount), G.isNotNilAndNotZero(filterCount)) &&
          <FilterWithCounter
            {...R.path(['filterIcon', type], settings)}
            title={filterCount}
            filterCount={filterCount}
          />
        }
      </Flex>
    </PopperComponent>
  );
};
