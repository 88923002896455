import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../text';
// features
import PC from '../../features/permission/role-permission';
import { AuthWrapper } from '../../features/permission/index';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Flex } from '../../ui';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const renderRow = ({ load, actions, fromDetails, actionNames, withoutActions, handleActionClick }: Object) => (
  actionNames.map((actionName: string, i: number) => {
    const data = actions[actionName];

    if (R.isNil(data)) return null;

    let displayedValue = G.getDisplayedValueFromObject(data);
    let permissions = G.getPropFromObject('permissions', data);

    if (G.isAllTrue(
      G.isNotNilAndNotEmpty(load),
      G.isNilOrEmpty(R.path([GC.FIELD_ROUTE_GUID], load)),
      R.equals(actionName, GC.OPEN_IN_ROUTE_BUILDER_ACTION),
    )) {
      displayedValue = G.getWindowLocale('titles:go-to-load-planner', 'Go To Load Planner');
    } else if (G.isAllTrue(
      G.isNotNilAndNotEmpty(load),
      G.isFalse(G.getPropFromObject(GC.FIELD_MARGIN_VIOLATION_APPROVED, load)),
      R.or(R.equals(actionName, GC.DISPATCH_LOAD_ACTION), R.equals(actionName, GC.RE_DISPATCH_LOAD_ACTION)),
      G.isAnyTrue(
        G.isTrue(G.getPropFromObject(GC.FIELD_MAX_PAY_VIOLATED, load)),
        G.isTrue(G.getPropFromObject(GC.FIELD_MIN_MARGIN_VIOLATED, load)),
        G.isTrue(G.getPropFromObject(GC.FIELD_CRITICAL_MARGIN_VIOLATED, load)),
      ),
    )) {
      permissions = [PC.MARGIN_VIOLATION_APPROVAL_EXECUTE];
      displayedValue = G.getWindowLocale('titles:approve', 'Approve');
    }

    const componentProps = {
      mr: '8px',
      p: '4px 8px',
      fontSize: 11,
      height: '100%',
      display: 'block',
      withEllipsis: true,
      textAlign: 'center',
      borderRadius: '5px',
      color: darkBlueColor,
      title: displayedValue,
      border: `1px solid ${darkBlueColor}`,
      width: G.ifElse(fromDetails, 150, 100),
    };

    if (withoutActions) {
      return (
        <TextComponent key={i} {...componentProps}>
          {displayedValue}
        </TextComponent>
      );
    }

    return (
      <AuthWrapper key={i} has={permissions} notHas={data.notHasPermissions}>
        <TextComponent
          {...componentProps}
          onClick={() => handleActionClick(actionName)}
          cursor={G.ifElse(R.not(withoutActions), 'pointer')}
        >
          {displayedValue}
        </TextComponent>
      </AuthWrapper>
    );
  })
);

export const LoadStatusActions = (props: Object) => {
  const { data, load, loadType, fromDetails, withoutActions, handleActionClick } = props;

  if (G.isNilOrEmpty(data)) return null;

  const { firstRow, secondRow } = data;
  const actions = G.getLoadStatusActions(loadType, data[GC.FIELD_STATUS]);

  return (
    <Flex flexDirection='column'>
      {
        G.isNotNilAndNotEmpty(firstRow) &&
        <Flex width='100%' height={20} alignItems='flex-start'>
          {
            renderRow({
              load,
              actions,
              fromDetails,
              withoutActions,
              handleActionClick,
              actionNames: firstRow,
            })
          }
        </Flex>
      }
      {
        G.isNotNilAndNotEmpty(secondRow) &&
        <Flex mt='8px' height={20} width='100%' alignItems='flex-start'>
          {
            renderRow({
              load,
              actions,
              fromDetails,
              withoutActions,
              handleActionClick,
              actionNames: secondRow,
            })
          }
        </Flex>
      }
    </Flex>
  );
};
