import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../text';
// helpers/constants
import * as G from '../../helpers';
// ui
import { AbsoluteBox } from '../../ui';
// component page-title
import { Wrapper, StatusLabel } from './ui';
//////////////////////////////////////////////////

export const PageTitle = (props: Object) => {
  const {
    mr,
    count,
    title,
    label,
    zIndex,
    status,
    content,
    display,
    withCount,
    showStatus,
    textTransform,
    pageTitleMarginTop,
    borderTopLeftRadius,
    borderTopRightRadius,
  } = props;

  return (
    <Wrapper
      mr={mr}
      p='0 15px'
      height={45}
      fontSize={20}
      zIndex={zIndex}
      fontWeight={500}
      lineHeight='48px'
      display={display}
      position='relative'
      mt={pageTitleMarginTop}
      bg={G.getTheme('colors.dark.blue')}
      color={G.getTheme('pages.listTitle.color')}
      borderTopLeftRadius={R.or(borderTopLeftRadius, '2px')}
      borderTopRightRadius={R.or(borderTopRightRadius, '2px')}
    >
      {
        withCount &&
        <AbsoluteBox
          top={-15}
          width={30}
          height={30}
          right={-15}
          fontSize={11}
          borderRadius={15}
          border='2px solid'
          justifyContent='center'
          bg={G.getTheme('colors.dark.blue')}
          color={G.getTheme('colors.light.mainLight')}
          borderColor={G.getTheme('pages.listTitle.countBorderColor')}
        >
          {R.or(count, 0)}
        </AbsoluteBox>
      }
      <TextComponent
        title={title}
        display='block'
        maxWidth='300px'
        withEllipsis={true}
        textTransform={textTransform}
      >
        {title}
      </TextComponent>
      {G.isNotNil(content) && content}
      {label}
      {showStatus && <StatusLabel status={status}><div /></StatusLabel>}
    </Wrapper>);
};
