import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex } from '../../ui';
// component filter
import LabelWrapper from './label-wrapper';
import { compileExpression } from './helpers';
//////////////////////////////////////////////////

export const withDeleteFilter = compose(
  withHandlers({
    handleDeleteFilter: (props: Object) => (i: number, e: Event) => {
      G.stopPropagation(e);

      const draftReport = R.assoc(
        'searchCriteria',
        R.remove(i, 1, props.selectedReport.searchCriteria),
        props.selectedReport,
      );

      props.setUsedReport(draftReport);
      props.handleListRequest(true);
    },
  }),
  pure,
);

export const FilterLabel = withDeleteFilter((props: Object) => {
  const condition = R.and(
    G.isNotNilAndNotEmpty(props.selectedReport),
    G.isNotEmpty(G.getOrElse(props.selectedReport, 'searchCriteria', [])),
  );
  if (R.equals(condition, false)) return null;
  return (
    <Flex
      alignItem='center'
      margin='2px 0 2px 8px'
    >
      <Box minWidth={90} width='18%'>{G.getWindowLocale('titles:report-filters', 'Report Filters')}: </Box>
      {
        props.selectedReport.searchCriteria.map((filteredParams: Object, i: number) => {
          const params = {
            filteredParams,
            columnSettings: props.columnSettings,
            filterBy: G.setFilterByParams(props.refList, props.filterProps),
          };

          return (
            <LabelWrapper
              key={i}
              displayText={compileExpression(params)}
              handleClick={(e: Object) => props.handleDeleteFilter(i, e)} />
          );
        })
      }
    </Flex>
  );
});

export default FilterLabel;
