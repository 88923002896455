import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// ui
import TextComponent from '../text';
import { BoxHovered } from '../../ui';
//////////////////////////////////////////////////

export const ActionBox = ({ text, color, action, boxStyles, hoverColor }: Object) => (
  <BoxHovered
    cursor='pointer'
    onClick={action}
    hoverFontWeight='bold'
    hoverTextDecoration='underline'
    color={color || G.getTheme('colors.light.blue')}
    hoverColor={hoverColor || G.getTheme('colors.dark.blue')}
    {...G.spreadUiStyles(boxStyles)}
  >
    <TextComponent display='block' withEllipsis={true}>
      {text}
    </TextComponent>
  </BoxHovered>
);
