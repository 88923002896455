import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// component charge
import { getChargeGlCodeByCurrency } from '../hocs';
import { accessorialFieldsToPick, accessorialFieldsToPick2 } from '../settings';
import {
  calcSimpleChargeTotal,
  isLineHaulWithPercent,
  isDiscountWithPercent,
  isInvalidChargeRateField,
  isTaxableTotalWithPercent,
  isCustomerRateWithPercent,
  isCustomerLineHaulWithPercent,
  getDefaultChargeUnitFromChargeRateType,
} from '../helpers';
//////////////////////////////////////////////////

// TODO: check if customerTotal the same value as totalCustomersRate remove totalCustomersRate logic:
// const customerRate = G.ifElse(G.isNotNilAndNotEmpty(customerTotal), customerTotal, totalCustomersRate);

// calc charges total
export const calcMainSubtotal = (charges: Array, options: any) => {
  const { mainChargesTotal, discountChargesTotal } = G.calcAllChargesArrayTotal(charges, options);

  return G.mathRoundNumber(R.subtract(mainChargesTotal, discountChargesTotal));
};

const calcMainTotal = (charge: Object, options: Object) => {
  const { rate, quantity, rateType, rateUnit } = charge;

  const customerLineHaul = G.getPropFromObject('customerLineHaul', options);
  const totalCustomersRate = G.getPropFromObject('totalCustomersRate', options);

  if (isCustomerRateWithPercent(rateType, rateUnit)) {
    if (R.and(G.isNotNilAndNotEmpty(quantity), G.notEquals(totalCustomersRate, quantity))) {
      return G.setPercentage(rate, quantity);
    }

    return G.setPercentage(rate, totalCustomersRate);
  }

  if (isCustomerLineHaulWithPercent(rateType, rateUnit)) {
    if (R.and(G.isNotNilAndNotEmpty(quantity), G.notEquals(customerLineHaul, quantity))) {
      return G.setPercentage(rate, quantity);
    }

    return G.setPercentage(rate, customerLineHaul);
  }

  return calcSimpleChargeTotal(charge);
};

const calcDiscountTotal = (charge: Object, charges: Object) => {
  const { rate, rateType, rateUnit, quantity, holdQuantity } = charge;

  if (R.and(G.isTrue(holdQuantity), isDiscountWithPercent(rateType, rateUnit))) {
    return G.setPercentage(rate, quantity);
  }

  if (isDiscountWithPercent(rateType, rateUnit)) {
    const { mainChargesTotal } = G.calcAllChargesArrayTotal(charges);

    return G.setPercentage(rate, mainChargesTotal);
  }

  return G.ifElse(G.isEmptyString(rate), 0, rate);
};

const calcFuelTotal = (charge: Object, charges: Object, options: Object) => {
  const { rate, rateType, rateUnit } = charge;

  if (isLineHaulWithPercent(rateType, rateUnit)) {
    const mainSubtotal = calcMainSubtotal(charges, options);

    return G.setPercentage(rate, mainSubtotal);
  }

  return calcSimpleChargeTotal(charge);
};

const calcAdditionalTotal = (charge: Object, charges: Array, options: Object) => {
  const { rate, quantity, rateType, rateUnit } = charge;

  const customerLineHaul = G.getPropFromObject('customerLineHaul', options);
  const totalCustomersRate = G.getPropFromObject('totalCustomersRate', options);

  if (isLineHaulWithPercent(rateType, rateUnit)) {
    const mainSubtotal = calcMainSubtotal(charges, options);

    return G.setPercentage(rate, mainSubtotal);
  }

  if (isTaxableTotalWithPercent(rateType, rateUnit)) {
    return G.setPercentage(rate, quantity);
  }

  if (isCustomerRateWithPercent(rateType, rateUnit)) {
    if (R.and(G.isNotNilAndNotEmpty(quantity), G.notEquals(totalCustomersRate, quantity))) {
      return G.setPercentage(rate, quantity);
    }

    return G.setPercentage(rate, totalCustomersRate);
  }

  if (isCustomerLineHaulWithPercent(rateType, rateUnit)) {
    if (R.and(G.isNotNilAndNotEmpty(quantity), G.notEquals(customerLineHaul, quantity))) {
      return G.setPercentage(rate, quantity);
    }

    return G.setPercentage(rate, customerLineHaul);
  }

  return calcSimpleChargeTotal(charge);
};

const calcChargeTotal = (charge: Object, charges: Array, props: Object) => {
  const rateCurrency = G.getCurrencyFromRate(props);
  const customerLineHaul = R.pathOr(0, ['customerLineHaul'], props);

  const totalCustomersRate = R.pathOr(
    R.pathOr(0, ['totalCustomersRate'], props),
    ['customerTotal'],
    props,
  );

  if (G.isChargeTypeMain(charge)) {
    return calcMainTotal(charge, { customerLineHaul, totalCustomersRate });
  }

  if (G.isChargeTypeDiscount(charge)) {
    return calcDiscountTotal(charge, charges, { rateCurrency });
  }

  if (G.isChargeTypeFuel(charge)) {
    return calcFuelTotal(charge, charges, { rateCurrency });
  }

  if (G.isChargeTypeAdditional(charge)) {
    return calcAdditionalTotal(charge, charges, { rateCurrency, customerLineHaul, totalCustomersRate });
  }

  return calcSimpleChargeTotal(charge);
};
// calc charges total

// calc charge quantity
const calcDiscountQty = (options: Object) => {
  const { charge, charges } = options;
  const { rateType, rateUnit } = charge;

  if (isDiscountWithPercent(rateType, rateUnit)) {
    const { mainChargesTotal } = G.calcAllChargesArrayTotal(charges);

    return G.mathRoundNumber(mainChargesTotal);
  }

  return 1;
};

const calcDOQtyFromTime = (rateUnit: string, serviceDays: any, chargeQty: any) => {
  if (R.and(
    G.isNotNilAndNotEmpty(serviceDays),
    R.equals(rateUnit, GC.CHARGE_RATE_UNIT_TYPE_HOUR),
  )) {
    return R.multiply(serviceDays, 24);
  }

  if (G.isNotNilAndNotEmpty(serviceDays)) return serviceDays;

  return chargeQty;
};

const calcDMQtyFromTime = (rateUnit: string, serviceDays: any, chargeQty: any, payedHours: any) => {
  if (R.and(
    G.isNotNilAndNotEmpty(payedHours),
    R.equals(rateUnit, GC.CHARGE_RATE_UNIT_TYPE_HOUR),
  )) {
    return payedHours;
  }

  return calcDOQtyFromTime(rateUnit, serviceDays, chargeQty);
};

const calcQtyFromTime = (rateUnit: string, options: Object) => {
  const { loadType, payedHours, serviceDays, chargeQty } = options;

  if (G.isLoadTypeClo(loadType)) {
    return calcDOQtyFromTime(rateUnit, serviceDays, chargeQty);
  }

  return calcDMQtyFromTime(rateUnit, serviceDays, chargeQty, payedHours);
};

const calcQtyFromDistance = (rateUnit: string, distance: string, distanceUom: string) => {
  if (R.and(R.equals(rateUnit, GC.UOM_MILE), R.equals(distanceUom, GC.UOM_KILOMETER))) {
    return G.mathRoundNumber(G.fromKmsToMiles(distance));
  } else if (R.and(R.equals(rateUnit, GC.UOM_KILOMETER), R.equals(distanceUom, GC.UOM_MILE))) {
    return G.mathRoundNumber(G.fromMilesToKms(distance));
  }

  return R.or(distance, 0);
};

const calcQtyFromWeight = (rateUnit: string, weight: string, weightUom: string) => G.calcWeightFromTo({
  to: rateUnit,
  value: weight,
  from: weightUom,
  toFixedNumber: 2,
});

const calcQtyFromWeight100 = (rateUnit: string, weight: string, weightUom: string) => G.calcWeightFromTo100({
  to: rateUnit,
  value: weight,
  from: weightUom,
  toFixedNumber: 2,
});

const calcQtyFromVolume = (rateUnit: string, volume: string) => R.or(volume, 0);

const calcQtyFromLineHaul = (options: Object) => {
  const { props, charge, charges } = options;
  const { rateType, rateUnit } = charge;

  const rateCurrency = G.getCurrencyByPath(props);

  if (isLineHaulWithPercent(rateType, rateUnit)) {
    return calcMainSubtotal(charges, { rateCurrency });
  }

  return 1;
};

const calcTotalTaxableAdditionalCharges = (additionalCharges: Object) => R.compose(
  R.reduce((acc: Array, item: Object) => {
    if (G.isTrue(R.prop(GC.FIELD_CHARGE_NON_TAXABLE, item))) return acc;

    return R.add(acc, G.getTotalFromCharge(item));
  }, 0),
  R.reject((item: Object) => {
    const { rateType, rateUnit } = item;

    return isTaxableTotalWithPercent(rateType, rateUnit);
  }),
)(additionalCharges);

const calcQtyFromTaxableTotal = (options: Object) => {
  const { charge, charges } = options;
  const { rateType, rateUnit } = charge;

  if (isTaxableTotalWithPercent(rateType, rateUnit)) {
    const { additionalCharges } = G.getFilteredByTypeCharges(charges);
    const additionalTaxable = calcTotalTaxableAdditionalCharges(additionalCharges);
    const mainSubtotal = calcMainSubtotal(charges);

    return G.mathRoundNumber(R.add(mainSubtotal, additionalTaxable));
  }

  return 1;
};

const calcChargeQty = (options: Object) => {
  const { props, charge } = options;

  const rateType = G.getRateTypeFromCharge(charge);
  const rateUnit = G.getRateUnitFromCharge(charge);
  const chargeQty = G.getQuantityFromCharge(charge);
  const holdQuantity = G.getHoldQuantityFromCharge(charge);

  if (G.isTrue(holdQuantity)) return chargeQty;

  switch (rateType) {
    case GC.CHARGE_RATE_TYPE_STOP:
      const stopsQuantity = R.path(['stopsQuantity'], props);

      if (G.isEmptyString(chargeQty)) return chargeQty;

      if (G.isZero(chargeQty)) return chargeQty;

      if (GC.NUMBER_REGEXP.test(chargeQty)) return chargeQty;

      const minStops = G.getPropFromObject(GC.FIELD_MIN_STOPS, charge);
      const maxStops = G.getPropFromObject(GC.FIELD_MAX_STOPS, charge);

      if (G.isOneNotNilOrNotEmpty([minStops, maxStops])) {
        if (R.gte(minStops, stopsQuantity)) return 0;

        if (R.isNil(maxStops)) return R.subtract(stopsQuantity, minStops);

        if (R.isNil(minStops)) return G.ifElse(R.gt(maxStops, stopsQuantity), stopsQuantity, maxStops);

        const diff1 = R.subtract(stopsQuantity, minStops);
        const diff2 = R.subtract(maxStops, minStops);

        return G.ifElse(R.gt(stopsQuantity, maxStops), diff2, diff1);
      }

      return stopsQuantity;

    case GC.CHARGE_RATE_TYPE_CUSTOMER_RATE:
      const { customerTotal, totalCustomersRate } = props;

      const customerRate = G.ifElse(G.isNotNilAndNotEmpty(customerTotal), customerTotal, totalCustomersRate);

      // TODO: check this condition logic with hold quantity
      if (R.and(G.isNotNilAndNotEmpty(chargeQty), G.notEquals(customerRate, chargeQty))) {
        return chargeQty;
      }

      return G.mathRoundNumber(customerRate);

    case GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL:
      const customerLineHaul = R.path(['customerLineHaul'], props);

      if (R.and(G.isNotNilAndNotEmpty(chargeQty), G.notEquals(customerLineHaul, chargeQty))) {
        return chargeQty;
      }

      return G.mathRoundNumber(customerLineHaul);

    case GC.CHARGE_RATE_TYPE_DISTANCE:
      const totalTripDistance = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE], props);

      if (G.isNilOrEmpty(totalTripDistance)) return 1;

      return calcQtyFromDistance(
        rateUnit,
        totalTripDistance,
        R.path([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], props),
      );

    case GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE:
      const deadhead = R.path([GC.FIELD_DEADHEAD_DISTANCE], props);

      if (G.isNilOrEmpty(deadhead)) return 1;

      const deadheadUom = R.path([GC.FIELD_DEADHEAD_DISTANCE_UOM], props);

      if (G.isNotNilAndNotEmpty(deadhead)) {
        return calcQtyFromDistance(
          rateUnit,
          deadhead,
          deadheadUom,
        );
      }

      return calcQtyFromDistance(
        rateUnit,
        R.path(['distanceToStart'], props),
        GC.UOM_KILOMETER,
      );

    case GC.CHARGE_RATE_TYPE_WEIGHT:
      const weight = R.path([GC.FIELD_TOTAL_TRIP_WEIGHT], props);

      if (G.isNilOrEmpty(weight)) return 1;

      const weightUom = R.path([GC.FIELD_TOTAL_TRIP_WEIGHT_UOM], props);
      const weight100 = R.or(R.equals(GC.UOM_POUND_100, rateUnit), R.equals(GC.UOM_KILOGRAM_100, rateUnit));

      if (weight100) return calcQtyFromWeight100(rateUnit, weight, weightUom);

      return calcQtyFromWeight(rateUnit, weight, weightUom);

    case GC.CHARGE_RATE_TYPE_VOLUME:
      const volume = R.path([GC.FIELD_TOTAL_TRIP_VOLUME], props);

      if (G.isNilOrEmpty(volume)) return chargeQty;

      return calcQtyFromVolume(
        rateUnit,
        volume,
        R.path([GC.FIELD_TOTAL_TRIP_VOLUME_UOM], props),
      );

    case GC.CHARGE_RATE_TYPE_QUANTITY:
      const quantities = R.path([GC.FIELD_TOTAL_QUANTITIES], props);

      if (G.isNilOrEmpty(quantities)) {
        return R.pathOr(0, [GC.FIELD_CHARGE_QUANTITY], charge);
      }

      return R.pathOr(R.or(chargeQty, 0), [rateUnit], quantities);

    case GC.CHARGE_RATE_TYPE_TIME:
      const { loadType, payedHours, serviceDays } = props;

      return calcQtyFromTime(
        rateUnit,
        { loadType, payedHours, serviceDays, chargeQty },
      );

    case GC.CHARGE_RATE_TYPE_DISCOUNT:
      return calcDiscountQty(options);

    case GC.CHARGE_RATE_TYPE_LINE_HAUL:
      return calcQtyFromLineHaul(options);

    case GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL:
      return calcQtyFromTaxableTotal(options);

    default:
      return 1;
  }
};
// calc charge quantity

// set charge quantity and total
const getChargesFromRateProps = (chargesArrayName: string, rateProps: Object) => R.path(
  ['values', chargesArrayName],
  rateProps,
);

export const setChargeQtyAndTotal = (
  charge: Object,
  props: Object,
  fieldName: string,
  fieldValue: number,
) => {
  const { rateProps, chargesArrayName } = props;

  const charges = getChargesFromRateProps(chargesArrayName, rateProps);
  let chargeQty = calcChargeQty({ props, charge, charges });

  if (R.and(G.isNotNil(fieldName), R.equals(fieldName, GC.FIELD_CHARGE_QUANTITY))) {
    chargeQty = fieldValue;
  }

  const chargeWithQty = R.assoc(GC.FIELD_CHARGE_QUANTITY, chargeQty, charge);
  const total = calcChargeTotal(chargeWithQty, charges, props);
  const chargeWithTotal = R.assoc(GC.FIELD_CHARGE_TOTAL, total, chargeWithQty);

  return chargeWithTotal;
};

const setChargeQtyAndTotalOnRecalc = (
  charge: Object,
  charges: Object,
  props: Object,
  options: Object,
) => {
  const omitRecalcChargeId = R.path(['omitRecalcChargeId'], options);

  if (
    R.and(
      G.isString(omitRecalcChargeId),
      R.equals(G.getIdOrGuidFromObject(charge), omitRecalcChargeId),
    )
  ) {
    return charge;
  }

  const chargeQty = calcChargeQty({ props, charge, charges });
  const chargeWithQty = R.assoc(GC.FIELD_CHARGE_QUANTITY, chargeQty, charge);
  const total = calcChargeTotal(chargeWithQty, charges, props);
  const chargeWithTotal = R.assoc(GC.FIELD_CHARGE_TOTAL, total, chargeWithQty);

  return chargeWithTotal;
};
// set charge quantity and total

// charges array input handlers
export const handleChangeCharge = (event: Object, charge: Object, props: Object) => {
  if (isInvalidChargeRateField(event.target)) return;

  const { name, value } = event.target;

  let newCharge = R.assoc(name, value, charge);

  if (R.equals(name, GC.FIELD_CHARGE_RATE_TYPE)) {
    const rateUnit = getDefaultChargeUnitFromChargeRateType(value, props);
    newCharge = {
      ...newCharge,
      [GC.FIELD_CHARGE_QUANTITY]: null,
      [GC.FIELD_CHARGE_RATE_UNIT]: rateUnit,
      [GC.FIELD_CHARGE_HOLD_QUANTITY]: false,
    };
  }

  if (R.equals(name, GC.FIELD_CURRENCY)) {
    const { glCodeMappings } = props;

    const glCode = getChargeGlCodeByCurrency(newCharge, null, glCodeMappings);

    newCharge = R.assoc(GC.FIELD_GL_CODE, glCode, newCharge);
  }

  newCharge = setChargeQtyAndTotal(newCharge, props, name, value);

  return newCharge;
};

export const handleChangeChargeSelect = (event: Object, charge: Object, props: Object) => {
  const { value } = event.target;

  const { isInvoice, rateProps, glCodeMappings } = props;

  const { values, accessorials } = rateProps;

  const accessorial = R.pathOr({}, [value], accessorials);
  const originalConfigGuid = G.getPropFromObject(GC.FIELD_ORIGINAL_CONFIG_GUID, accessorial);

  let picked = {
    ...R.pick(accessorialFieldsToPick2, accessorial),
    [GC.FIELD_ORIGINAL_CONFIG_GUID]: originalConfigGuid,
    [GC.FIELD_ACCESSORIAL_CONFIG_GUID]: originalConfigGuid,
    [GC.FIELD_SHARED_ASSESSORIAL_GUID]: G.getPropFromObject(GC.FIELD_SHARED_ASSESSORIAL_GUID, accessorial),
  };

  if (G.isTrue(isInvoice)) {
    const currency = G.getCurrencyFromObject2(values);
    const mappings = R.pathOr(glCodeMappings, [currency], glCodeMappings);

    const glCode = R.pathOr('', [GC.INVOICE_MAPPING_TYPE_ASSESSORIALS, originalConfigGuid], mappings);
    picked = R.assoc(GC.FIELD_GL_CODE, glCode, picked);
  }

  if (G.isNotNilAndNotEmpty(picked)) {
    const merged = R.mergeDeepRight(charge, picked);
    const newCharge = setChargeQtyAndTotal(merged, props);

    return newCharge;
  }

  return charge;
};
// charges array input handlers

// recalculate charges
export const recalculateAllCharges = (charges: Array, props: Object, options: Object) => {
  if (G.isNilOrEmpty(charges)) return charges;

  const { mainCharges, fuelCharges, discountCharges, additionalCharges } = G.getFilteredByTypeCharges(charges);

  const newMainCharges = R.map(
    (charge: Object) => setChargeQtyAndTotalOnRecalc(charge, charges, props, options),
    mainCharges,
  );

  const newDiscountCharges = R.map(
    (charge: Object) => setChargeQtyAndTotalOnRecalc(charge, newMainCharges, props, options),
    discountCharges,
  );

  const newMainAndDiscountCharges = R.concat(newMainCharges, newDiscountCharges);

  const newFuelCharges = R.map(
    (charge: Object) => setChargeQtyAndTotalOnRecalc(charge, newMainAndDiscountCharges, props, options),
    fuelCharges,
  );

  const newMainAndDiscountFuelCharges = R.concat(newMainAndDiscountCharges, newFuelCharges);

  const newMainDiscountFuelWithOldAdditionalCharges = R.concat(newMainAndDiscountFuelCharges, additionalCharges);

  const newAdditionalCharges = R.map(
    (charge: Object) => setChargeQtyAndTotalOnRecalc(
      charge,
      newMainDiscountFuelWithOldAdditionalCharges,
      props,
      options,
    ),
    additionalCharges,
  );

  return R.reduce(R.concat, newMainCharges, [newDiscountCharges, newFuelCharges, newAdditionalCharges]);
};
// recalculate charges
