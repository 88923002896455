import styled from 'styled-components';
import { space, width, height, maxHeight } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const ChatWrapper = styled.div`
  ${width}
  ${height}
  ${maxHeight}

  display: flex;
  font-size: 15px;
  position: relative;
  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${() => G.getTheme('chat.chatWrapperBgColor')};
`;

ChatWrapper.defaultProps = {
  height: '100%',
};

export const ChatHeader = styled.div`
  padding: 6px 12px;
  font-weight: bold;  
  color: ${({ color }: Object) => color || G.getTheme('chat.cloHeaderColor')};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('chat.cloHeaderBgColor')};
`;

export const ChatContent = styled.div`
  display: flex;
  height: max-content;
  min-height: calc(100% - 95px);
  background-color: ${() => G.getTheme('chat.contentBgColor')};
`;

export const ScrollableWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const ChatAction = styled.div`
  bottom: 2px;
  position: absolute;
  right: ${({ right }: Object) => right || '10px'};
`;

export const ChatMessageWrapper = styled.div`
  ${space}

  width: 100%;
  height: 50px;
  position: relative;
  background-color: ${() => G.getTheme('chat.inputBgColor')};
  border-top: 1px solid ${() => G.getTheme('chat.messageBorderColor')};

  & textarea {
    width: 100%;
    padding: 2px;
    border: none;
    height: 100%;
    font-size: 12px;
    line-height: 12px;
    background-color: transparent;
    color: ${() => G.getTheme('chat.inputColor')};
    &:focus {
      box-shadow: none;
    }
  }
`;

export const MessageBoxWrapper = styled.div`
  ${space}

  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  position: relative;
  justify-content: ${({ jc }: Object) => jc};
  & audio {
    max-width: 100%;
  }
`;

export const NameSection = styled.div`
  ${space}

  width: 100%;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  position: relative;
  margin-bottom: 5px;
  justify-content: ${({ jc }: Object) => jc};
`;

export const ChatUserBox = styled.div`
  ${space}

  top: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  border-radius: 50%;
  ${({ positionSide }: Object) => positionSide}: 5px;
`;

export const ChatUserBoxPhoto = styled.div`
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background: url(${({ bgImage }: Object) => bgImage}) no-repeat center center;
`;

export const ChatUserBoxInitials = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${({ color }: Object) => color};
  border: 1px solid ${({ borderColor }: Object) => borderColor};
`;

export const MessageBox = styled.div`
  padding: 6px 12px;
  border-radius: 10px;
  word-break: break-word;
  color: ${({ color }: Object) => color};
  background-color: ${({ bgColor }: Object) => bgColor};
`;

export const IconWrapper = styled.div`
  ${space}
  ${width}
  ${height}
`;
