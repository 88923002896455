import styled from 'styled-components';
import { renderToString } from 'react-dom/server';
// svgs
import { zoomPlus, zoomMinus } from '../../svgs';
//////////////////////////////////////////////////

const encodePlusIcon = encodeURIComponent(renderToString(zoomPlus()));
const encodeMinusIcon = encodeURIComponent(renderToString(zoomMinus()));

export const FileViewerWrapper = styled.div`
  & .pdf-controlls-container {
    width: 80px;
    height: 40px;
    margin: auto;
    display: flex;
    font-size: 30px;
    align-items: center;
    justify-content: space-between;
  }
  & .view-control:nth-child(odd) {
    width: 36px;
    height: 36px;
    cursor: pointer;
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
  }
  & .view-control:nth-child(1) {
    background-image: url('data:image/svg+xml;utf8,${encodePlusIcon}');
  }
  & .view-control:nth-child(3) {
    background-image: url('data:image/svg+xml;utf8,${encodeMinusIcon}');
  }
  & .photo-viewer-container {
    width: auto!important;
    height: auto!important;
    & img {
      width: 100%!important;
      height: auto!important;
    }
  }
`;
