import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, ArrowUp, ArrowDown, AbsoluteBox, RelativeFlex } from '../../../ui';
//////////////////////////////////////////////////

const TitleSort = (props: Object) => {
  const { fieldProps, titleSortValue, handleTableTitleFilter } = props;

  const { type, value } = fieldProps;

  const { order, sequence } = titleSortValue;

  const activeColor = 'colors.dark.blue';
  const inactiveColor = 'colors.light.middleGrey';
  const reference = R.equals(type, 'reference');

  return (
    <AbsoluteBox left='100%'>
      <RelativeFlex width={22} height={36} flexDirection='column'>
        {
          G.isNotNil(sequence) &&
          <AbsoluteBox
            top={11}
            left='0'
            width={22}
            fontSize={11}
            fontWeight='bold'
            flexDirection='column'
            color={G.getTheme(activeColor)}
          >
            {sequence}
          </AbsoluteBox>
        }
        <Box
          p='6px'
          title='ASC'
          cursor='pointer'
          onClick={() => handleTableTitleFilter({
            isSorting: true,
            sortData: {
              reference,
              name: value,
              sequence: 1,
              order: 'ASC',
            },
          })}
        >
          <ArrowUp size={6} color={G.ifElse(R.equals(order, 'ASC'), activeColor, inactiveColor)} />
        </Box>
        <Box
          p='6px'
          title='DESC'
          cursor='pointer'
          onClick={() => handleTableTitleFilter({
            isSorting: true,
            sortData: {
              reference,
              name: value,
              sequence: 1,
              order: 'DESC',
            },
          })}
        >
          <ArrowDown size={6} color={G.ifElse(R.equals(order, 'DESC'), activeColor, inactiveColor)} />
        </Box>
      </RelativeFlex>
    </AbsoluteBox>
  );
};

export default TitleSort;
