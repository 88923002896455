import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../actions-element';
// features
import PC from '../../../features/permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const enhance = compose(
  withHandlers({
    handleResetByReportGuid: (props: Object) => () => {
      const {
        closeFixedPopup,
        handleClickResetByGuid,
      } = props;

      handleClickResetByGuid();

      closeFixedPopup();
    },
    handleResetByReportType: (props: Object) => () => {
      const {
        closeFixedPopup,
        handleClickResetByType,
      } = props;

      handleClickResetByType();

      closeFixedPopup();
    },
  }),
  pure,
);

const getOptions = ({
  reportType,
  newColumnOptions,
  handleAddNewColumn,
  handleResetByReportGuid,
  handleResetByReportType,
}: Object) => ([
  {
    permissions: [PC.ANY],
    action: handleResetByReportGuid,
    frontIcon: I.refresh(iconColor, 15, 15),
    text: G.getWindowLocale(
      'actions:reset-current-columns-width',
      'Reset current report columns width',
    ),
  },
  {
    permissions: [PC.ANY],
    action: handleResetByReportType,
    frontIcon: I.refresh(iconColor, 15, 15),
    // eslint-disable-next-line max-len
    text: `${G.getWindowLocale('actions:reset-all', 'Reset all')} ${G.getEnumLocale(reportType)} ${G.getWindowLocale('titles:report-column-width', 'reports columns width')}`.trim(),
  },
  ...(R.isNotNil(newColumnOptions) ? [{
    permissions: [PC.ANY],
    action: handleAddNewColumn,
    frontIcon: I.plusRound(iconColor, 15, 15),
    text: G.getWindowLocale(
      'actions:add-new-column',
      'Add new column',
    ),
  }] : []),
]);

export const ResizeActions = (props: Object) => <ActionsElement options={getOptions(props)} />;

export default enhance(ResizeActions);
