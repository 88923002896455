import styled from 'styled-components';
// ui
import { RelativeBox } from '../../ui';
//////////////////////////////////////////////////

export const CalendarWrapper = styled(RelativeBox)`
  & .react-datepicker__triangle {
    display: none;
  }
  & .react-datepicker-popper {
    top: 20px !important;
    left: -180px !important;
    transform: none !important;
  }
  & .react-datepicker__time-container {
    right: -82px !important;
  }
  & .react-datepicker__time-box {
    width: 80px !important;
  }
  & .react-datepicker__time-list {
    padding: 0 !important;
  }
`;
