import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// ui
import { SimpleInput } from './ui';
//////////////////////////////////////////////////

const getBorderColor = ({
  value,
  error,
  validate,
  inputBorderColor,
  submitValidation,
}: Object) => {
  const borderColor = G.isNilOrEmpty(inputBorderColor) ? G.getTheme('forms.inputs.borderColor') : inputBorderColor;

  if (G.isNilOrEmpty(validate)) {
    return borderColor;
  }

  const isError = R.or(error, R.and(submitValidation, R.not(validate(value))));

  return G.ifElse(
    G.isTrue(isError),
    G.getTheme('forms.inputs.borderColorErr'),
    borderColor,
  );
};

const enhance = compose(
  withState('error', 'setError', false),
  withHandlers({
    handleBlur: (props: Object) => (e: Object) => {
      const { validate, setError } = props;

      const value = G.getEventTargetValue(e);

      if (G.isNilOrEmpty(validate)) return;

      if (R.not(validate(value))) setError(true);
    },
    handleFocus: (props: Object) => () => {
      const { error, validate, setError } = props;

      if (G.isNilOrEmpty(validate)) return;

      if (error) setError(false);
    },
    handleChange: ({ onChange }: Object) => (e: Object) => onChange(G.getEventTargetValue(e)),
  }),
  pure,
);

const InputWithHandlers = enhance((props: Object) => {
  const {
    value,
    styles,
    handleBlur,
    placeholder,
    handleFocus,
    handleChange,
  } = props;

  const borderColor = getBorderColor(props);

  return (
    <SimpleInput
      {...styles}
      value={value}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      placeholder={placeholder}
      borderColor={borderColor}
    />
  );
});

export {
  InputWithHandlers,
};
