import * as R from 'ramda';
import React from 'react';
import {
  pure,
  compose,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import PreviewSlide from './slide-box';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Bottom, SliderWrap, BottomWrap } from './ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('timerId', 'setTimerId', null),
  withState('activeSlide', 'setActiveSlide', 0),
  withHandlers({
    handleSetSlide: ({ slides, setActiveSlide }: Object) => (index: number) => {
      if (R.gt(index, R.dec(R.length(slides)))) {
        return setActiveSlide(0);
      }

      setActiveSlide(index);
    },
  }),
  withHandlers({
    handleSetSlideOnClick: ({ timerId, handleSetSlide }: Object) => (index: number) => {
      clearTimeout(timerId);
      handleSetSlide(index);
    },
  }),
  withPropsOnChange(['activeSlide', 'slides'], (props: Object) => {
    const { slides, activeSlide, setTimerId, handleSetSlide } = props;

    if (R.and(G.isNotNilAndNotEmpty(slides), G.notEquals(R.length(slides), 1))) {
      const timerId = setTimeout(() => {
        const index = R.inc(activeSlide);
        setTimeout(() => handleSetSlide(index), 2000);
      }, 3000);

      setTimerId(timerId);
    }
  }),
  pure,
);

export const ImageSlider = ({
  color,
  slides = [],
  activeSlide,
  splashScreen,
  splashScreenSetting,
  handleGetInfoPanelRef,
  handleSetSlideOnClick,
}: Object) => (
  <SliderWrap bgColor={G.getTheme('colors.white')}>
    {
      slides.map((slide: Object, i: number) => (
        <PreviewSlide
          i={i}
          key={i}
          slide={slide}
          color={color}
          splashScreen={splashScreen}
          active={R.equals(activeSlide, i)}
          splashScreenSetting={splashScreenSetting}
          handleGetInfoPanelRef={handleGetInfoPanelRef}
        />
      ))
    }
    {
      G.notEquals(R.length(slides), 1) &&
      <BottomWrap>
        {
          slides.map((slide: Object, i: number) => (
            <Bottom
              key={i}
              active={R.equals(activeSlide, i)}
              onClick={() => handleSetSlideOnClick(i)}
            />
            ),
          )
        }
      </BottomWrap>
    }
  </SliderWrap>
);

export default enhance(ImageSlider);
