import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../modal/actions';
import { openLoader, closeLoader } from '../loader/actions';
import MultipleStatusMessageForm from '../multiple-status-message';
// features
import LoadStopsEdit from '../../features/new-do/outside-use/load-stops-edit';
import { getBranchConfigsWithLoadStopsRequest } from '../../features/new-do/actions';
import { setExpandedContainerOptions } from '../../features/expanded-container/actions';
import { makeSelectExpandedContainerOptions } from '../../features/expanded-container/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import routesMap from '../../utilities/routes';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
});

export const withStopsEditAndDnD = compose(
  connect(
    mapStateToProps,
    {
      openModal,
      openLoader,
      closeModal,
      closeLoader,
      setExpandedContainerOptions,
      getBranchConfigsWithLoadStopsRequest,
    },
  ),
  withHandlers({
    handleClickEditStops: (props: Object) => (events: Object) => {
      const { loadGuid, loadType, openModal, fromPage, branchGuid, getBranchConfigsWithLoadStopsRequest } = props;

      const refScopeName = G.ifElse(
        G.isLoadTypeClo({ loadType }),
        GC.REF_SCOPE_NAME_CLO,
        GC.REF_SCOPE_NAME_TEL,
      );

      getBranchConfigsWithLoadStopsRequest({ events, loadType, loadGuid, fromPage, branchGuid, refScopeName });

      const modalContent = (
        <LoadStopsEdit {...props} events={events} />
      );

      const modal = {
        p: '0px',
        component: modalContent,
        options: {
          title: '',
          height: '90vh',
        },
      };

      openModal(modal);
    },
    handleClickOnPrimaryReference: (props: Object) => (guid: string, fromClo: boolean = false) => {
      const { expandedContainerOptions, setExpandedContainerOptions } = props;

      const { opened } = expandedContainerOptions;

      const componentType = G.ifElse(fromClo, GC.PAGE_DISPATCH_DETAILS_NEW_LOAD, GC.PAGE_DISPATCH_DETAILS_NEW_ORDER);

      if (G.isTrue(opened)) {
        setExpandedContainerOptions({ opened, componentType, visitPageGuid: guid });
      } else {
        const route = G.ifElse(fromClo, routesMap.dispatchDetailsLoad, routesMap.dispatchDetailsOrder)(guid);

        G.goToRoute(route);
      }
    },
    handleAddMultipleStatusMessage: (props: Object) => (event: Object, arrived: boolean = false) => {
      const {
        loadType,
        openModal,
        closeModal,
        loadConfigs,
        asyncConfigs,
        statusMessagesConfigs,
        createMultipleStatusMessage,
      } = props;

      const component = (
        <MultipleStatusMessageForm
          arrived={arrived}
          loadType={loadType}
          closeModal={closeModal}
          asyncConfigs={asyncConfigs}
          events={G.mapTelEventsForMap(R.of(Array, event))}
          createMultipleStatusMessage={createMultipleStatusMessage}
          communicationsConfigs={R.path(['configGroups', GC.COMMUNICATION_CONFIG_GROUP], loadConfigs)}
          statusMessagesConfigs={R.pathOr(statusMessagesConfigs, ['statusMessageConfigs'], loadConfigs)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: null,
          height: 'auto',
          width: 'max-content',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
