import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { color, fontSize } from 'styled-system';
import { compose, withState, withHandlers } from 'react-recompose';
// icons
import * as I from '../../svgs';
// features
import { renderBorderColor } from '../../features/new-do/ui';
// forms
import { Error } from '../../forms/formik/fieldset2/ui';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Flex, AbsoluteBox, RelativeBox, PlacesAutocomplete } from '../../ui';
// places-autocomplete-input
import { enhance, getValue } from './helpers';
//////////////////////////////////////////////////

const Label = styled.label`
  ${color}
  ${fontSize}

  z-index: -1;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;

  transform: translate(3px, 0) scale(1);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

  &.required::after {
    content: '*';
    color: ${() => G.getTheme('colors.light.mainRed')};
  }

  ${({ focusedOrHasValue }: Object) => focusedOrHasValue && css`
    z-index: 1;
    color: ${G.getTheme('colors.azure')};
    transform: translate(0, -16px) scale(0.75);
    background-color: ${G.getTheme('colors.white')};
  `}
`;

Label.defaultProps = {
  fontSize: 12,
  color: G.getTheme('colors.darkGrey'),
};

const fullEnhance = compose(
  enhance,
  withState('focused', 'setFocused', false),
  withHandlers({
    handleFocus: ({ setFocused }: Object) => () => setFocused(true),
    handleBlur: ({ setFocused }: Object) => () => setFocused(false),
  }),
);

export const PlacesAutocompleteInputMui = fullEnhance((props: Object) => {
  const {
    label,
    width,
    error,
    focused,
    hasError,
    errorTop,
    isRequired,
    handleBlur,
    handleFocus,
    endIconName,
    placeholder,
    handleChange,
    handleSelect,
    withCloseIcon,
    onCloseCallback,
    inputWrapperStyles,
    useMenuPortalTarget,
  } = props;

  const value = getValue(props);

  let labelToUse = label;

  const endIcon = R.path([endIconName], I);

  if (G.isFunction(label)) labelToUse = label(props);

  const focusedOrHasValue = R.or(focused, G.isNotNilAndNotEmpty(value));

  const additionalStyles = {
    valueContainer: (baseStyles: Object) => ({
      ...baseStyles,
      paddingRight: 15,
    }),
    control: (baseStyles: Object) => {
      const borderColor = renderBorderColor({
        hasError,
        additionalColor: G.ifElse(focusedOrHasValue, G.getTheme('colors.azure'))
      });

      return ({
        ...baseStyles,
        minHeight: 32,
        borderRadius: 4,
        boxShadow: 'none',
        height: 'max-content',
        background: 'transparent',
        ':hover': { borderColor },
        border: `${G.ifElse(focusedOrHasValue, '1.5px', '1px')} solid ${borderColor}`,
      });
    },
  };

  return (
    <RelativeBox {...G.spreadUiStyles(inputWrapperStyles)}>
      {
        R.and(withCloseIcon, G.isNotNilAndNotEmpty(value)) &&
        <AbsoluteBox
          top={12}
          zIndex={2}
          cursor='pointer'
          left={R.subtract(width, 14)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {I.closeIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      { endIcon &&
        <AbsoluteBox
          top={12}
          zIndex={2}
          cursor='pointer'
          left={R.subtract(width, 18)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {endIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      <Flex width={width}>
        <PlacesAutocomplete
          width={width}
          value={value}
          handleBlur={handleBlur}
          placeholder={placeholder}
          handleFocus={handleFocus}
          handleSelect={handleSelect}
          additionalStyles={additionalStyles}
          useMenuPortalTarget={useMenuPortalTarget}
          setFieldValue={(_: any, value: string) => handleChange(value)}
        />
        <Label
          focusedOrHasValue={focusedOrHasValue}
          className={G.ifElse(isRequired, 'required', 'not-required')}
        >
          {labelToUse}
        </Label>
      </Flex>
      {hasError && <Error title={error} width={width} top={errorTop}>{error}</Error>}
    </RelativeBox>
  );
});
