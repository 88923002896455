import styled, { css } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const FilterLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0 2px 8px;
  & span {
    padding-right: 10px;
  }
  & > div {
    display: flex;
    position: relative;
    border-radius: 20px;
    padding: 2px 10px 2px 5px;
    border: 1px solid ${() => G.getTheme('colors.light.darkGrey')};
    & svg {
      top: 2px;
      right: 5px;
      border-radius: 50%;
      position: absolute;
      background-color: ${() => G.getTheme('colors.light.darkGrey')};
    }
    & span {
      margin-left: 5px;
    }
  }
`;

export const FilterDate = styled.div`
  display: flex;
  z-index: auto;
  flex-direction: ${({ flexDirection }: Object) => flexDirection || 'column'};
  & .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
      padding: 0;
      height: 183px !important;
    }
`;

export const FilterWrapper = styled.div`
  z-index: 12;
  cursor: pointer;
  & div[type='right'] {
    height: auto;
  }
  & div.Select-input {
    padding-left: 15px;
  }
  & form {
    background-color: ${() => G.getTheme('colors.white')};
    border: 2px solid ${() => G.getTheme('listActions.newBorderColor')};
  }
`;

export const DateWrapper = styled.div`
  display: inline-flex;
  z-index: auto;
  & > div {
    z-index: auto;
  }
  & > div > div {
    z-index: auto;
  }
  & .react-datepicker__input-container {
    z-index: auto;
  }
  & div.react-datepicker {
  }
  & div.react-datepicker-popper[data-placement^="bottom"] {
    z-index: 13;
    width: 285px;
    transform: translate(20px, 40px) !important;
  }
`;

export const LabelWrapper = styled.div`
  height: 22px;
  display: flex;
  overflow: hidden;
  margin: 0 10px 0 3px;
  max-width: 'max-content';
  color:${() => G.getTheme('downFilter.textColor')};
  & > span {
    cursor: ${({cursor}: Object) => cursor || 'default'};
  }
`;

export const LabelTextWrapper = styled.div`
  max-width: calc(100% - 12px);
`;

export const inputGroup = css`
  & label {
    width: 55px;
    padding-left: 15px;
  }
  &:first-of-type {
    margin-top: 5px;
  }
`;

export const FilterInfoWrapper = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const errorClass = css`
  bottom: 0;
  right: 10px;
  position: absolute;
`;

export const formGroupPosition = css`
  position: relative;
`;
