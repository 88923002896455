import * as R from 'ramda';
import React from 'react';
import { withState } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex, Span, RelativeFlex, AbsoluteBox } from '../../ui';
//////////////////////////////////////////////////

export const Icon = withState('hovered', 'setHovered', false)(({
  icon,
  label,
  hovered,
  iconColor,
  frontLabel,
  setHovered,
  onClickAction,
  iconWrapperWidth,
  onHoverFrontIconColor,
}: Object) => {
  const iconToUse = G.ifElse(
    R.is(Function, icon),
    () => icon(G.ifElse(hovered, onHoverFrontIconColor, iconColor)),
    () => icon,
  )();

  if (R.and(G.isNotNilAndNotEmpty(label), G.isNotNilAndNotEmpty(iconToUse))) {
    return (
      <RelativeFlex
        onMouseOut={() => setHovered(false)}
        onMouseOver={() => setHovered(true)}
      >
        <AbsoluteBox
          pr='15px'
          zIndex={11}
          right='130%'
          width='max-content'
          opacity={G.ifElse(hovered, 1, 0)}
          visibility={G.ifElse(hovered, 'visible', 'hidden')}
          transform={G.ifElse(hovered, 'scale(1)', 'scale(0.8)')}
          transition='scale, 0.2s ease-out, opacity 0.2s ease-out'
        >
          <Box
            p='5px'
            cursor='pointer'
            borderRadius={10}
            border='1px solid'
            onClick={onClickAction}
            bg={G.getTheme('colors.light.lightGrey')}
            borderColor={G.getTheme('colors.lightGrey')}
          >
            {label}
          </Box>
        </AbsoluteBox>
        <Flex
          onClick={onClickAction}
          width={R.or(iconWrapperWidth, 20)}
        >
          {iconToUse}
        </Flex>
      </RelativeFlex>
    );
  }

  if (R.and(G.isNilOrEmpty(frontLabel), G.isNotNilAndNotEmpty(iconToUse))) {
    return (
      <Flex
        onClick={onClickAction}
        width={R.or(iconWrapperWidth, 20)}
        onMouseOut={() => setHovered(false)}
        onMouseOver={() => setHovered(true)}
      >
        {iconToUse}
      </Flex>
    );
  }

  return null;
});

export const Label = (props: Object) => {
  const {
    jc,
    mr,
    mb,
    ml,
    width,
    label,
    cursor,
    height,
    labelP,
    onClick,
    endIcon,
    children,
    endLabel,
    fontSize,
    endAction,
    frontIcon,
    wordBreak,
    frontLabel,
    frontAction,
    endIconColor,
    frontIconColor,
    iconWrapperWidth,
    onHoverFrontIconColor,
  } = props;

  return (
    <Flex
      mr={mr}
      mb={mb}
      ml={ml}
      width={width}
      height={height}
      onClick={onClick}
      justifyContent={jc}
      display='inline-flex'
      cursor={R.or(cursor, 'pointer')}
    >
      {
        G.isNotNil(frontLabel) &&
        <Flex
          width={20}
          onClick={frontAction}
          justifyContent='center'
        >
          <img alt={frontLabel} src={G.composeImgLink('light', frontLabel)} />
        </Flex>
      }
      <Icon
        label={label}
        icon={frontIcon}
        frontLabel={frontLabel}
        iconColor={frontIconColor}
        onClickAction={frontAction}
        iconWrapperWidth={iconWrapperWidth}
        onHoverFrontIconColor={onHoverFrontIconColor}
      />
      <Span
        fontSize={fontSize}
        wordBreak={wordBreak}
        p={R.or(labelP, '0 5px')}
      >
        {R.or(children, '')}
      </Span>
      {
        G.isNotNil(endLabel) &&
        <Box>
          <img alt={endLabel} onClick={endAction} src={G.composeImgLink('light', endLabel)} />
        </Box>
      }
      <Icon
        label={label}
        icon={endIcon}
        frontLabel={frontLabel}
        iconColor={endIconColor}
        onClickAction={endAction}
        onHoverFrontIconColor={onHoverFrontIconColor}
      />
    </Flex>
  );
};

export default Label;
