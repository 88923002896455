import { $set } from 'plow-js';
import { createReducer } from 'redux-act';
// common idb
import * as A from './actions.js';
//////////////////////////////////////////////////

const idbName = 'Amous-resize-report-table-data';
const idbStoreName = 'report-guids';
const idbKeyPath = 'reportGuid';
const idbIndexKey = 'reportType';

const initialState = {
  idb: null,
  reportTableResize: {},
  idbProps: {
    idbName,
    idbKeyPath,
    idbIndexKey,
    idbStoreName,
  },
};

const setReportTableResize = (state: Object, resizeData: Object = {}) => (
  $set('reportTableResize', resizeData, state)
);

const initTableResizeIDBSuccess = (state: Object, idb: Object = {}) => (
  $set('idb', idb, state)
);

export default createReducer({
  [A.setReportTableResize]: setReportTableResize,
  [A.initTableResizeIDBSuccess]: initTableResizeIDBSuccess,
}, initialState);
