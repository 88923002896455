import * as R from 'ramda';
import React from 'react';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../text';
// features
import { AuthWrapper } from '../../features/permission';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Flex, Span } from '../../ui';
//////////////////////////////////////////////////

export const withTabs = compose(
  withState('activeTab', 'setActiveTab', ({ defaultActiveTab }: Object) => R.or(defaultActiveTab, 0)),
  withHandlers({
    handleSetActiveTab: ({ setActiveTab }: Object) => (index: number) => setActiveTab(index),
  }),
);

const commonTabStyles = {
  p: '6px',
  height: 36,
  fontWeight: 'bold',
  maxWidth: 'fit-content',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  boxShadow: '1px 0px 2px grey',
};

const getDefaultTabStyles = () => ({
  ...commonTabStyles,
  cursor: 'pointer',
  color: G.getTheme('colors.light.grey'),
});

const getDefaultActiveTabStyles = () => ({
  ...commonTabStyles,
  color: G.getTheme('colors.black'),
  bg: G.getTheme('colors.lightLime'),
});

const getDefaultErrorTabStyles = () => ({
  border: '1px solid',
  borderColor: G.getTheme('colors.light.lightRed'),
});

const getStyles = (isActive: boolean, hasError: boolean = false, props: Object) => {
  const { tabStyles, errorTabStyles, activeTabStyles } = props;

  let styles;

  if (isActive) {
    styles = G.mergeAndSpreadUiStyles(getDefaultActiveTabStyles(), activeTabStyles);
  } else {
    styles = G.mergeAndSpreadUiStyles(getDefaultTabStyles(), tabStyles);
  }

  if (hasError) {
    const errorStyles = G.mergeAndSpreadUiStyles(getDefaultErrorTabStyles(), errorTabStyles);
    styles = G.mergeAndSpreadUiStyles(styles, errorStyles);
  }

  return styles;
};

export const Tabs = (props: Object) => {
  const {
    tabs,
    count,
    activeTab,
    textWidth,
    textDisplay,
    countStyles,
    wrapperStyles,
    handleClickTab,
  } = props;

  return (
    <Flex {...R.or(wrapperStyles, {})}>
      {
        tabs.map(({
          text,
          isActive,
          hasError,
          withCount,
          permissions,
          clickHandler,
          renderRightStickedComponent,
        }: Object, i: number) => {
          const isActiveTab = G.ifElse(G.isBoolean(isActive), isActive, R.equals(i, activeTab));

          return (
            <AuthWrapper key={i} has={permissions}>
              <Flex
                {...getStyles(isActiveTab, hasError, props)}
                onClick={() => {
                  if (isActiveTab) return;

                  if (G.isFunction(clickHandler)) {
                    return clickHandler();
                  }

                  handleClickTab(i);
                }}
              >
                <TextComponent
                  title={text}
                  withEllipsis={true}
                  width={R.or(textWidth, '100%')}
                  display={R.or(textDisplay, 'flex')}
                >
                  {text}
                  {G.isTrue(withCount) && <Span {...R.or(countStyles, {})}>({count})</Span>}
                  {
                    R.and(G.isTrue(isActiveTab), G.isNotNilAndNotEmpty(renderRightStickedComponent)) &&
                    renderRightStickedComponent
                  }
                </TextComponent>
              </Flex>
            </AuthWrapper>
          );
        })
      }
    </Flex>
  );
};
