import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
// components/toggle-btn
import { ToggleBtnUi } from './ui';
//////////////////////////////////////////////////

const ToggleBtn = (props: Object) => {
  const { title, checked, onToggleHandler } = props;

  const btnProps = R.omit(['title', 'checked', 'onToggleHandler'], props);

  return (
    <ToggleBtnUi
      {...G.spreadUiStyles(btnProps)}
      type='button'
      checked={checked}
      onClick={() => G.callFunctionWithArgs(onToggleHandler, R.not(checked))}
    >
      {title}
    </ToggleBtnUi>
  );
};

export default ToggleBtn;
