import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import ReactAudioPlayer from 'react-audio-player';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withPropsOnChange } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// component audio-player
import { setAudioPlaying } from './actions';
import { makeSelectSrc, makeSelectAudioPlaying } from './selectors';
//////////////////////////////////////////////////

const AMOUS_AUDIO_PLAYER_ID = 'amous-audio-player';

const enhance = compose(
  withPropsOnChange(['playing'], (props: Object) => {
    const audio = document.getElementById(AMOUS_AUDIO_PLAYER_ID);

    if (G.isNilOrEmpty(audio)) return;

    if (R.and(props.playing, R.not(audio.playing))) audio.play();
  }),
  pure,
);

const AudioPlayer = enhance(({ src, setAudioPlaying }: Object) => (
  <ReactAudioPlayer
    src={src}
    id={AMOUS_AUDIO_PLAYER_ID}
    onEnded={() => setAudioPlaying(false)}
    onError={() => setAudioPlaying(false)}
  />
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  src: makeSelectSrc(state),
  playing: makeSelectAudioPlaying(state),
});

export default connect(mapStateToProps, {
  setAudioPlaying,
})(AudioPlayer);
