import * as R from 'ramda';
import {
  pure,
  withState,
  withHandlers,
  withPropsOnChange } from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature edit-report
import { defaultSortFields } from './settings';
import {
  reorderFieldsOnDrugEnd,
  getAvailableForReportSort,
  mapFieldsWithSequenceFromIndex,
} from '../../helpers';
//////////////////////////////////////////////////

export const withReportSortList = R.compose(
  withState('reportSortList', 'setReportSortList', []),
  withState('availableForSort', 'setAvailableForSort', []),
  withPropsOnChange(['sortFields'], (props: Object) => {
    const {
      sortFields,
      reportSortList,
      setReportSortList,
      setAvailableForSort,
    } = props;

    if (G.isNilOrEmpty(sortFields)) return;

    if (G.deepEquals(sortFields, reportSortList)) return;

    setReportSortList(sortFields);

    setAvailableForSort(getAvailableForReportSort(props, sortFields));
  }),
  withHandlers({
    handleAddSortField: (props: Object) => () => {
      const {
        reportSortList,
        setReportSortList,
        setAvailableForSort,
      } = props;

      const newField = R.assoc('sequence', R.inc(R.length(reportSortList)), defaultSortFields);
      const fieldsUpdated = R.append(newField, reportSortList);

      setReportSortList(fieldsUpdated);

      setAvailableForSort(getAvailableForReportSort(props, fieldsUpdated));
    },
    handleRemoveSortField: (props: Object) => (filterIndex: string) => {
      const {
        reportSortList,
        setReportSortList,
        setAvailableForSort,
      } = props;

      const fieldsUpdated = mapFieldsWithSequenceFromIndex(R.dissocPath([filterIndex], reportSortList));

      setReportSortList(fieldsUpdated);

      setAvailableForSort(getAvailableForReportSort(props, fieldsUpdated));
    },
    handleSelectSortName: (props: Object) => (values: Object) => {
      const {
        reportSortList,
        setReportSortList,
        setAvailableForSort,
      } = props;

      const { fieldIndex, data: { type, value, referenceFieldName } } = values;

      let newField = {
        ...defaultSortFields,
        name: value,
        reference: R.equals(type, 'reference'),
      };

      if (G.isNotNil(referenceFieldName)) {
        newField = R.assoc(GC.FIELD_REFERENCE_FIELD_NAME, referenceFieldName, newField);
      }
      const fieldsUpdated = R.assocPath([fieldIndex], newField, reportSortList);

      setReportSortList(fieldsUpdated);

      setAvailableForSort(getAvailableForReportSort(props, fieldsUpdated));
    },
    handleChangeFieldOrder: (props: Object) => ({ order, fieldIndex }: Object) => {
      const {
        reportSortList,
        setReportSortList,
        setAvailableForSort,
      } = props;

      const fieldsUpdated = R.assocPath([fieldIndex, 'order'], order, reportSortList);

      setReportSortList(fieldsUpdated);

      setAvailableForSort(getAvailableForReportSort(props, fieldsUpdated));
    },
    handleSortDragEnd: (props: Object) => (result: Object) => {
      const {
        reportSortList,
        setReportSortList,
        setAvailableForSort,
      } = props;

      const reorderedFields = reorderFieldsOnDrugEnd(reportSortList, result);

      setReportSortList(reorderedFields);

      setAvailableForSort(getAvailableForReportSort(props, reorderedFields));
    },
  }),
  pure,
);
