export const initialTableSettings = {
  rowHeight: 30,
  maxHeight: 200,
  cellFontSize: 11,
  titleFontSize: 11,
  tableRowHeight: 30,
  titleRowHeight: 30,
  withTitleRow: true,
  asyncCallback: null,
  useMainColors: true,
  allowEditBtn: false,
  moreBtnCellWidth: 50,
  coloredHeader: false,
  allowSelectAll: false,
  checkBoxCellWidth: 50,
  allowSelectItems: true,
  expandableItems: false,
  coloredActiveRow: false,
  minWidth: 'fit-content',
  asyncChildComponent: false,
  fixLocalLoaderHeight: false,
  getAsyncEndpoint: () => null,
  expandedDetailsComponent: () => null,
  checkBoxCellJustifyContent: 'flex-end',
};
