import * as R from 'ramda';
import React, { useRef } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// components
import { TextComponent } from '../../text';
import TitleSort from '../../table/components/title-sort';
import TitleFilter from '../../table/components/title-filter';
import { getRelativeWidth, filterTypesWithMuiTitleInput } from '../../table/components/helpers';
// ui
import {
  Flex,
  RelativeFlex,
} from '../../../ui';
//////////////////////////////////////////////////

const HeaderCellComponent = (props: Object) => {
  const {
    column,
    fieldName,
    fieldProps,
    resizeByGuid,
    tableSettings,
    resizeObserver,
    titleSortValue,
    titleFilterValue,
    handleTableTitleFilter,
  } = props;

  const {
    type,
    width,
    resizable,
    clickableTitle,
  } = column;

  const name = G.getWindowLocale(R.prop(GC.FIELD_NAME, column), ' ');

  const { handleTitleCellClick } = tableSettings;
  const cellRef = useRef(null);

  let storedWidth;

  if (resizable) {
    const cellEl = cellRef.current;

    storedWidth = R.prop(fieldName, resizeByGuid);

    if (cellEl) {
      cellEl[GC.FIELD_NAME] = fieldName;
      resizeObserver?.observe(cellEl);
    }
  }

  const clickHandler = G.ifElse(
    R.and(clickableTitle, G.isFunction(handleTitleCellClick)),
    () => handleTitleCellClick(fieldName),
    () => {},
  );

  const resultWidth = R.or(storedWidth, width);
  const needCalcWidth = R.isNotNil(R.prop(GC.FIELD_OPTIONS, fieldProps));
  const relativeWidth = getRelativeWidth({ type, resizable, needCalcWidth });

  return (
    <Flex
      zIndex={0}
      p='4px 8px'
      height='100%'
      ref={cellRef}
      width={resultWidth}
      flexDirection='column'
      onClick={clickHandler}
      alignItems='flex-start'
      justifyContent='flex-end'
      style={{width: resultWidth}}
      minWidth={R.divide(width, 2)}
      maxWidth={R.multiply(width, 2)}
      overflow={G.ifElse(resizable, 'auto', 'inherit')}
      resize={G.ifElse(resizable, 'horizontal', 'none')}
    >
      {
        G.notContain(fieldProps.type, filterTypesWithMuiTitleInput) && (
        <Flex
          maxWidth='100%'
          overflow='hidden'
          alignItems='center'
          height='-webkit-fill-available'
        >
          <TextComponent title={name} fontSize={11} withEllipsis={true} color={G.getTheme('colors.textDark')}>
            {name}
          </TextComponent>
        </Flex>
      )}
      <RelativeFlex
        width={relativeWidth}
        height='-webkit-fill-available'
      >
        <TitleFilter
          title={name}
          width='100%'
          fieldProps={fieldProps}
          useNewTitleFilterInputs={true}
          titleFilterValue={titleFilterValue}
          maxWidth={R.subtract(resultWidth, 30)}
          fontSize={tableSettings.titleFontSize}
          handleTableTitleFilter={handleTableTitleFilter}
        />
        <TitleSort
          fieldProps={fieldProps}
          titleSortValue={titleSortValue}
          handleTableTitleFilter={handleTableTitleFilter}
        />
      </RelativeFlex>
    </Flex>
  );
};

export default HeaderCellComponent;
