import * as R from 'ramda';
import React from 'react';
import { Rnd } from 'react-rnd';
// helpers/constants
import * as G from '../../helpers';
// ui
import { DisableEventsBox } from '../../ui';
// component resizable
import { getInitialOptions, initialWrapperStyles } from './settings';
//////////////////////////////////////////////////

const Resizable = (props: Object) => {
  const { options, children, isResizable, wrapperStyles } = props;

  const resizableOptions = R.mergeDeepLeft(options, getInitialOptions(isResizable));
  const styles = R.mergeRight(initialWrapperStyles, wrapperStyles);

  if (G.isFalse(isResizable)) return children;

  return (
    <DisableEventsBox {...styles}>
      <Rnd {...resizableOptions}>
        {children}
      </Rnd>
    </DisableEventsBox>
  );
};

export default Resizable;
