import { space, color } from 'styled-system';
import styled, { keyframes } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const Wrapper = styled.div`
  ${space}
  ${color}

  display: flex;
  position: relative;
  align-items: center;
  width: ${({ width }: Object) => width || '35px'};
  height: ${({ height }: Object) => height || '35px'};
  border-radius: ${({ borderRadius }: Object) => borderRadius || '30px'};
  justify-content: ${({ justifyContent }: Object) => justifyContent || 'center'};
  box-shadow: ${() => `-2px 2px 7px 0px ${G.getTheme('colors.colors.darkGrey')}`};
  &:hover {
    & > div {
      display: flex;
    }
  }
`;

export const animation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(60px) scale(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
`;
