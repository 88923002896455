import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Box, RelativeFlex } from '../../ui';
//////////////////////////////////////////////////

export const HandResize = ({ right = 28 }: Object) => (
  <RelativeFlex
    p='5px'
    width={34}
    zIndex={1}
    height={36}
    right={right}
    top='calc(50% - 18px)'
    justifyContent='center'
    borderTopLeftRadius={15}
    borderBottomLeftRadius={15}
    bg={G.getTheme('colors.mainBlue')}
  >
    <Box transform='rotate(270deg) scaleX(-1)'>
      {I.moveIcon(G.getTheme('colors.white'), 22, 23)}
    </Box>
  </RelativeFlex>
);

export const getInitialOptions = (isResizable: boolean) => ({
  minWidth: 370,
  minHeight: 400,
  maxWidth: '94vw',
  maxHeight: '100%',
  disableDragging: true,
  enableResizing: { left: true },
  style: { pointerEvents: 'auto', cursor: null },
  default: { x: 0, y: 0, width: '40vw', height: '100%' },
  resizeHandleStyles: {
    left: { cursor: 'ew-resize' },
  },
  resizeHandleComponent: {
    left: (
      isResizable && <HandResize right={27} />
    ),
  },
});

export const initialWrapperStyles = {
  top: '0px',
  zIndex: 20,
  right: '0px',
  width: '40vw',
  height: '100vh',
  position: 'fixed',
  disableEvents: true,
};
