import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../text';
// ui
import { Box, ReactSelect } from '../../../ui';
// component edit-report
import { Option } from '../ui';
import { setOptions } from '../helpers';
//////////////////////////////////////////////////

const OptionComponent = (props: Object) => {
  const { data, action, selectOption } = props;

  return (
    <Option
      onClick={() => {
        selectOption(data);
        action(props);
      }}
    >
      {data.name}
    </Option>
  );
};

const ValueComponent = (props: Object) => {
  const value = R.path(['data', 'name'], props);

  return (
    <TextComponent
      my={6}
      ml='8px'
      title={value}
      position='absolute'
      withEllipsis={true}
      width='calc(100% - 8px)'
    >
      {value}
    </TextComponent>
  );
};

export const FieldSelect = (({
  id,
  name,
  value,
  action,
  options,
  provided,
  collection,
  fieldIndex,
}: Object) => (
  <Box
    mx={10}
    width={232}
    // NOTE: react select menu doesn't open when user clicked on Control component
    cursor='auto'
    data-rbd-drag-handle-draggable-id='gibberish'
    data-rbd-drag-handle-context-id={R.path(['dragHandleProps', 'data-rbd-drag-handle-context-id'], provided)}
  >
    <ReactSelect
      id={id}
      name={name}
      height={30}
      value={value}
      action={action}
      isClearable={false}
      onBlurResetsInput={false}
      useMenuPortalTarget={true}
      onSelectResetsInput={false}
      // TODO: rename field options inside option
      options={setOptions(options)}
      closeMenuOnScroll={R.pathEq('edit_report_wrapper', ['target', 'id'])}
      components={{
        SingleValue: ValueComponent,
        Option: (props: Object) => (
          <OptionComponent
            {...props}
            action={action}
            collection={collection}
            fieldIndex={fieldIndex}
          />
        ),
      }}
    />
  </Box>
));
