import styled from 'styled-components';
import { width, space, height, color, border } from 'styled-system';
//////////////////////////////////////////////////

const SimpleInput = styled.input`
  ${space}
  ${width}
  ${color}
  ${height}
  ${border}

  outline: none;
  z-index: unset;

  background: ${({ background }: Object) => background};
`;

SimpleInput.defaultProps = {
  height: 30,
  width: 100,
  p: '0 16px',
  borderRadius: 2,
};

export {
  SimpleInput,
};
