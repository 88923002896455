import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, FixedFlex } from '../../ui';
//////////////////////////////////////////////////

const zoomMap = {
  1: {
    next: 2,
    locale: G.getWindowLocale('titles:100%', '100%'),
  },
  2: {
    prev: 1,
    next: 3,
    locale: G.getWindowLocale('titles:200%', '200%'),
  },
  3: {
    prev: 2,
    locale: G.getWindowLocale('titles:300%', '300%'),
  },
};

const Zoom = (props: Object) => {
  const { zoom, border, zoomMap, valueStyles, wrapperStyles, operationStyles, onZoomSwitched } = props;

  const title = R.pathOr(G.getWindowLocale('titles:100%', '100%'), [zoom, 'locale'], zoomMap);

  const changeZoom = (operation: string) => {
    let newValue = null;

    if (R.equals(operation, 'plus')) {
      newValue = R.path([zoom, 'next'], zoomMap);
    }

    if (R.equals(operation, 'minus')) {
      newValue = R.path([zoom, 'prev'], zoomMap);
    }

    if (G.isNilOrEmpty(newValue)) return;

    return onZoomSwitched(newValue);
  };

  return (
    <FixedFlex {...wrapperStyles}>
      <Box {...operationStyles} onClick={() => changeZoom('minus')}>-</Box>
      <Box {...valueStyles} borderLeft={border} borderRight={border}>{title}</Box>
      <Box {...operationStyles} onClick={() => changeZoom('plus')}>+</Box>
    </FixedFlex>
  );
};

Zoom.defaultProps = {
  zoomMap,
  wrapperStyles: {
    right: 25,
    bottom: 30,
    height: 24,
    fontSize: 18,
    zIndex: 1299,
    borderRadius: '4px',
    justifyContent: 'space-around',
    color: G.getTheme('colors.#7D828C'),
    backgroundColor: G.getTheme('colors.#E4E4E4'),
    border: `1px solid ${G.getTheme('colors.#C4C4C4')}`,
  },
  valueStyles: {
    p: '3px',
    fontSize: 14,
  },
  operationStyles: {
    width: 20,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default Zoom;
