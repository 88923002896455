import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// components text
import { Text } from './ui';
//////////////////////////////////////////////////

export const TextComponent = (props: Object) => {
  const { title, children } = props;

  if (G.isNotNil(title)) return <Text {...props} title={title}>{children}</Text>;

  return <Text {...props}>{children}</Text>;
};

export default TextComponent;
