import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { space, width, height } from 'styled-system';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import TextComponent from '../text';
// helpers
import * as G from '../../helpers';
// ui
import { Box, Flex, Span, ModalActionButton, ModalCancelButton } from '../../ui';
//////////////////////////////////////////////////

const Textarea = styled.textarea`
  ${space}
  ${width}
  ${height}

  cursor: text;
  resize: none;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 20px;
  border-radius: 1px;

  border: 1px solid ${({ borderColor }: Object) => borderColor};

  background-color: ${({ disabled }: Object) =>
    G.getThemeByCond(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor')};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

Textarea.defaultProps = {
  height: 72,
  width: 540,
};

export const ConfirmComponent = (props: Object) => {
  const { name, textProps, textLocale } = props;

  return (
    <Flex fontSize={18} justifyContent='center'>
      <TextComponent {...G.spreadUiStyles(textProps)} whiteSpace='normal'>
        {textLocale}
        {
          G.isNotNilAndNotEmpty(name) &&
          <Span ml='8px' fontWeight={700}>{name}?</Span>
        }
      </TextComponent>
    </Flex>
  );
};

export const FooterComponent = (props: Object) => {
  const { submitText, cancelText, cancelAction, submitAction } = props;

  return (
    <Flex mt={15} width='100%' justifyContent='space-between'>
      <ModalCancelButton width={120} onClick={cancelAction}>
        {cancelText}
      </ModalCancelButton>
      <ModalActionButton width={120} onClick={() => submitAction(props)}>
        {submitText}
      </ModalActionButton>
    </Flex>
  );
};

const enhance = compose(
  withState('comment', 'setComment', null),
  withHandlers({
    handleChangeComment: (props: Object) => (e: Object) => {
      const { setComment } = props;

      const value = G.getEventTargetValue(e);

      setComment(value);
    },
  }),
);

export const ConfirmModal = enhance((props: Object) => {
  const {
    text,
    name,
    comment,
    withComment,
    handleChangeComment,
  } = props;

  return (
    <Flex fontSize={18} flexDirection='column'>
      <TextComponent whiteSpace='normal'>
        {text}
        {
          G.isNotNilAndNotEmpty(name) &&
          <Span ml='8px' fontWeight={700}>{name}?</Span>
        }
      </TextComponent>
      {
        withComment &&
        <Flex width='100%' alignItems='start' flexDirection='column'>
          <Box p='3px' fontSize={14}>{G.getWindowLocale('titles:comment', 'Comment')}</Box>
          <Textarea
            p={10}
            width='100%'
            value={comment}
            onChange={handleChangeComment}
            borderColor={G.getTheme('forms.inputs.borderColor')}
          />
        </Flex>
      }
      <FooterComponent {...props} />
    </Flex>
  );
});

export const getConfirmModal = (props: Object) => {
  const { options } = props;

  return ({
    p: 15,
    component: <ConfirmModal {...props} />,
    options: R.mergeRight({
      width: 'auto',
      minWidth: 350,
      maxWidth: 600,
      height: 'auto',
      overflow: 'auto',
      maxHeight: '80vh',
    }, options),
  });
};
