import styled from 'styled-components';
import { space, width, fontSize } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const Button = styled.button`
  ${space}
  ${width}
  ${fontSize}

  outline: 0;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: middle;
`;

export const CancelButton = styled(Button)`
  height: 40px;
  min-width: 100px;
  border-radius: 2px;
  color: ${() => G.getTheme('buttons.cancelBtn.textColor')};
  background-color: ${() => G.getTheme('buttons.cancelBtn.bgColor')};
`;

export const ResetButton = styled(Button)`
  height: 40px;
  min-width: 100px;
  border-radius: 2px;
  color: ${() => G.getTheme('buttons.cancelBtn.textColor')};
  background-color: ${() => G.getTheme('buttons.cancelBtn.bgColor')};
`;

export const ActionButton = styled(Button)`
  height: 40px;
  min-width: 100px;
  border-radius: 2px;
  color: ${() => G.getTheme('buttons.saveBtn.textColor')};
  background-color: ${() => G.getTheme('colors.dark.blue')};
`;
