import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { width, space, fontSize } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// edit report
import { renderReportBorderColor } from './helpers';
//////////////////////////////////////////////////

export const formGroupLabelClass = css`
  width: 150px;
`;

export const RowGroup = styled.div`
  display: flex;
  padding: 10px 15px;
  position: relative;
  align-items: center;
  z-index: ${({ zI }: Object) => R.add(11, zI)};
  justify-content: ${({ jc }: Object) => jc || 'center'};
  border: 1px solid ${({ freezed }: Object) => G.getTheme(G.ifElse(
    freezed,
    'colors.light.mainRed',
    'colors.lightGrey',
  ))};
  & input {
    margin: 0 10px;
  }
`;

export const InputIcons = styled.div`
  display: flex;
  & > div:nth-child(2) {
    margin-left: 10px;
  }
  & > div:last-child {
    flex-direction: column;
    & > img:first-child {
      margin: 0 0 6px;
    }
    & > img:last-child {
      margin: 6px 0 0;
    }
  }
`;

export const FormWrapper = styled.div`
  & form > div:nth-child(1) > div:nth-child(2) {
    margin: 10px auto;
  }
  & form > div:nth-child(2) > div:nth-child(2) {
    margin: 10px auto;
  }
`;

export const summaryLabel = css`
  font-weight: bold;
  color: ${() => G.getTheme('colors.light.mainRed')};
`;

export const ReportWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ jc }: Object) => jc || 'baseline'};
  & svg:hover {
    cursor: pointer;
  }
  & img {
    height: 20px;
  }
`;

export const RightTitlePanel = styled.div`
  z-index: 11;
  display: flex;
  align-items: center;
  & #dropdown-user-export {
    & > div > img {
      height: 20px;
    }
  }
`;

export const LinkText = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: ${() => G.getTheme('colors.light.blue')};
`;

export const Group = styled.div`
  ${space}
  ${fontSize}

  display: flex;
  align-items: center;
  z-index: ${({ zI }: Object) => R.or(zI, '11')};
`;

export const EmptyFilter = styled.div`
  font-size: 20px;
  text-align: center;
  color: ${() => G.getTheme('colors.light.darkGrey')};
`;

export const FormDivider = styled.div`
  width: 1px;
  z-index: 11;
  background-color: ${() => G.getTheme('colors.light.mainLight')};
  border-left: ${() => `1px solid ${G.getTheme('colors.lightGrey')}`};
`;

export const FilterDate = styled.div`
  ${width}
  ${space}

  display: flex;

  z-index: ${({ zI }: Object) => zI || 11};
  justify-content: ${({ justifyContent }: Object) => justifyContent};

  & > div:last-child {
    margin: 0;
  }
  & .react-datepicker {
    width: max-content;
  }
  & .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
      height: 183px !important;
    }
  & .react-datepicker-popper {
    top: 5px !important;
    left: -50px !important;
  }
`;

export const Text = styled.div`
  padding: 5px 0;
  min-width: 340px;
`;

export const SelectWrapper = styled.div`
  ${width}
  ${space}

  & .Select-arrow-zone {
    background-color: ${() => G.getTheme('colors.white')};
  }

  & .Select.is-open .Select-arrow, .Select .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: ${() => G.getTheme('colors.dark.blue')};
  }

  & .Select-arrow {
    border-color: ${() => G.getTheme('colors.dark.blue')} transparent transparent;

    &:hover {
      border-color: ${() => G.getTheme('colors.dark.blue')} transparent transparent;
    }
  }

  & .Select.is-open > .Select-control .Select-arrow {
    border-color: transparent transparent ${() => G.getTheme('colors.dark.blue')};
  }

  & .Select {
    & .Select-control {
      height: 30px;
      border-radius: 2px;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(129, 129, 129, 0.2);
      border-color: ${(props: Object) => renderReportBorderColor(props)};
      & .Select-value {
        line-height: 28px;
      }
    }

    &.is-focused:not(.is-open) .Select-control {
      border-color: ${() => G.getTheme('forms.inputs.borderColor')};
      box-shadow: 0 0 5px 0 ${({ version }: Object) => G.getThemeByCond(
        G.isThemeSecondVersion(version),
        'colors.boxShadowBlue',
        'boxShadowRed',
      )};
    }
  }

  & .Select-placeholder {
    line-height: 30px;
  }

  & .Select-input {
    height: 28px;
    padding: 0px 0px 0px 9px;
    & input {
      padding: 0px;
      height: 28px;
    }
  }

  & .Select-menu-outer {
    border-radius: 4px;
    margin-bottom: 60px;
    box-shadow: 0 2px 4px 0 rgba(129, 129, 129, 0.2);
    border: solid 1px ${() => G.getTheme('colors.dark.grey')};
  }
`;

SelectWrapper.defaultProps = {
  m: '0 10px',
};

export const MultiSelectWrapper = styled.div`
  ${space}
  ${width}

  z-index: ${({ zI }: Object) => zI || 10};

  & > div.Select {
    & .Select-control {
      height: 30px;
      border-radius: 2px;
      border-color: ${(props: Object) => renderReportBorderColor(props)};
    }

    &.is-focused:not(.is-open) .Select-control {
      box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
      border-color: ${() => G.getTheme('forms.inputs.borderColor')};
    }
  }

  & .Select-input {
    margin: 0px;
    padding: 0px;
    height: 28px;

    & input {
      height: 28px;
      padding: 0px;
    }
  }

  & .Select-value {
    margin: 2px;
  }

  & .Select-placeholder {
    z-index: 0;
    line-height: 28px;
  }
`;

export const Option = styled.div`
  display: flex;
  padding: 5px 15px;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    font-weight: bold;
    color: ${() => G.getTheme('colors.dark.blue')};
    background: ${() => G.getTheme('colors.white')};
  }
  & > span > span {
    padding: 0 3px;
    &:hover svg path {
      fill: ${() => G.getTheme('colors.light.darkRed')};
    }
  }
`;

export const ReportPanelOption = styled(Option)`
  color: ${() => G.getTheme('colors.greyMatterhorn')};
  & svg path {
    fill: ${({ isDefault }: Object) => R.and(isDefault, G.getTheme('colors.light.darkRed'))};
  }
`;

export const ReportPanelOptionWrapper = styled.div`
  display: flex;
  padding: 5px 10px;
  align-items: center;
  word-break: break-all;
  justify-content: space-between;
  color: ${() => G.getTheme('colors.greyMatterhorn')};
  &:hover {
    cursor: pointer;
    font-weight: bold;
    color: ${() => G.getTheme('colors.dark.blue')};
    background: ${() => G.getTheme('colors.white')};
  }
`;

export const GenerateFile = styled.div`
  display: flex;
  & span {
    padding: 0 3px;
  }
  &:hover {
    cursor: pointer;
    & span {
      color: ${() => G.getTheme('colors.light.darkRed')};
      & path {
        fill: ${() => G.getTheme('colors.light.darkRed')};
      }
    }
  }
`;

export const minWidth = css`
  min-width: 300px;
`;
