import * as R from 'ramda';
import React from 'react';
import { withState } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, RelativeFlex, AbsoluteBox } from '../../ui';
// //////////////////////////////////////////
export const HoveringTitle = withState('hovered', 'setHovered', false)(({
  title,
  hovered,
  children,
  setHovered,
  handleClick,
  positionConfig }: Object) => {
  const clickable = G.isFunction(handleClick);
  return (
    <RelativeFlex
      onMouseOut={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
    >
      <AbsoluteBox
        {...positionConfig}
        opacity={G.ifElse(hovered, 1, 0)}
        pr={R.pathOr('15px', ['pr'], positionConfig)}
        transform={G.ifElse(hovered, 'scale(1)', 'scale(0.8)')}
        transition='scale, 0.2s ease-out, opacity 0.2s ease-out'
        zIndex={G.ifElse(hovered, R.path(['zIndex'], positionConfig), -100)}
      >
        <Box
          p='5px'
          borderRadius={10}
          border='1px solid'
          cursor={R.and(clickable, 'pointer')}
          onClick={R.and(clickable, handleClick)}
          bg={G.getTheme('colors.light.lightGrey')}
          display={G.ifElse(hovered, 'block', 'none')}
          borderColor={G.getTheme('colors.lightGrey')}
        >
          {title}
        </Box>
      </AbsoluteBox>
      {children}
    </RelativeFlex>
  );
});

export default HoveringTitle;
