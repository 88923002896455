import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex, CancelButton, ActionButton } from '../../../ui';
//////////////////////////////////////////////////

const commonBtnStyles = {
  ml: 20,
  height: 32,
  width: 120,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const getCancelStyles = () => ({
  ...commonBtnStyles,
  textColor: G.getTheme('colors.greyMatterhorn'),
  borderColor: G.getTheme('colors.greyMatterhorn'),
});

const getSubmitStyles = () => ({
  ...commonBtnStyles,
  textColor: G.getTheme('colors.white'),
  bgColor: G.getTheme('colors.dark.blue'),
});

const getSaveAsNewStyles = () => ({
  height: 32,
  p: '4px 8px',
  fontSize: 14,
  borderRadius: '5px',
  textTransform: 'uppercase',
  bgColor: G.getTheme('colors.white'),
  textColor: G.getTheme('colors.dark.blue'),
  border: `1px solid ${G.getTheme('colors.dark.blue')}`,
});

const ButtonsBlock = ({
  buttons,
  saveAsNew,
  showSaveAsNew,
  saveAsNewBtnType,
}: Object) => (
  <Box>
    {
      G.isTrue(showSaveAsNew) &&
      <ActionButton
        {...getSaveAsNewStyles()}
        onClick={saveAsNew}
        type={R.or(saveAsNewBtnType, 'submit')}
      >
        {G.getWindowLocale('actions:save-as-new', 'Save As New')}
      </ActionButton>
    }
    {
      buttons.map((button: Object, i: number) => (
        <ActionButton
          {...getSubmitStyles()}
          key={i}
          onClick={button.action}
          width={R.or(button.width, 100)}
          type={R.or(button.type, 'submit')}
        >
          {button.btnText}
        </ActionButton>
      ))
    }
  </Box>
);

const SaveCancelBlock = ({
  save,
  width,
  formId,
  cancel,
  btnText,
  submitting,
  hideFormSaveBtn,
}: Object) => (
  <Box>
    <CancelButton {...getCancelStyles()} onClick={cancel}>
      {G.getWindowLocale('actions:cancel', 'Cancel')}
    </CancelButton>
    {
      R.not(G.isTrue(hideFormSaveBtn)) &&
      <ActionButton
        {...getSubmitStyles()}
        type='submit'
        form={formId}
        onClick={save}
        width={R.or(width, 150)}
        disabled={G.isTrue(submitting)}
        cursor={G.ifElse(G.isTrue(submitting), 'not-allowed', 'pointer')}
      >
        {R.or(btnText, G.getWindowLocale('actions:save', 'Save'))}
      </ActionButton>
    }
  </Box>
);

export const ReportFormButtons = (props: Object) => (
  <Flex
    left='0px'
    height={60}
    bottom='0px'
    width='100%'
    position='fixed'
    style={props.style}
    borderTop='1px solid'
    pl={R.or(props.pl, 60)}
    pr={R.or(props.pr, 15)}
    transition='all .2s linear'
    justifyContent='space-between'
    zIndex={R.or(props.zIndex, 12)}
    bg={G.getTheme('forms.actionsFooter.bgColor')}
    borderColor={G.getTheme('forms.actionsFooter.borderTopColor')}
  >
    <ButtonsBlock
      showSaveAsNew={props.showSaveAsNew}
      saveAsNew={props.handleClickSaveAsNew}
      saveAsNewBtnType={props.saveAsNewBtnType}
      buttons={R.pathOr([], ['buttons'], props)}
      additionBtns={R.pathOr([], ['additionBtns'], props)}
    />
    <SaveCancelBlock
      width={props.width}
      formId={props.formId}
      btnText={props.btnText}
      save={props.handleClickSave}
      submitting={props.submitting}
      cancel={props.handleClickCancel}
      hideFormSaveBtn={props.hideFormSaveBtn}
    />
  </Flex>
);
