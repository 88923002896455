import React from 'react';
import { pure } from 'react-recompose';
// ui
import { Flex } from '../../ui';
// utilities
import * as G from '../../helpers';
// ui
import { ResetButton, ActionButton, CancelButton } from './ui';
//////////////////////////////////////////////////

const commonBtnProps = {
  width: '25%',
  p: '8px 16px',
};

export const FooterBtns = pure((props: Object) => (
  <Flex width='100%' p='10px 15px' justifyContent='center'>
    {
      props.saveAndAddNew &&
      <ActionButton
        {...commonBtnProps}
        mr='15px'
        type='submit'
        fontSize={18}
        disabled={props.isSubmitting}
        onClick={() => {
          if (G.isFunction(props.handleSaveAndAddNew)) props.handleSaveAndAddNew();
        }}
      >
        {G.getWindowLocale('actions:save-and-add-new', 'Save and Add New')}
      </ActionButton>
    }
    <ActionButton
      {...commonBtnProps}
      mr='15px'
      type='submit'
      fontSize={18}
      disabled={props.isSubmitting}
      onClick={() => {
        if (G.isFunction(props.submitAction)) props.submitAction();
      }}
    >
      {G.getWindowLocale('actions:save', 'Save')}
    </ActionButton>
    {
      G.isNotNilAndNotEmpty(props.handleReset) &&
      <ResetButton
        {...commonBtnProps}
        mr='15px'
        type='button'
        fontSize={18}
        onClick={props.handleReset}
      >
        {G.getWindowLocale('actions:reset', 'Reset')}
      </ResetButton>
    }
    <CancelButton
      {...commonBtnProps}
      type='button'
      fontSize={18}
      onClick={props.closeModal}
    >
      {G.getWindowLocale('actions:cancel', 'Cancel')}
    </CancelButton>
  </Flex>
));
