import * as R from 'ramda';
import styled from 'styled-components';
import React, { useRef, useState, useEffect } from 'react';
// TODO: remove after testing
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { space, width, height, zIndex, fontSize } from 'styled-system';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// components
import { MuiTextInput } from '../mui-text-input';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

const Input = styled.input`
  ${space}
  ${width}
  ${height}
  ${zIndex}
  ${fontSize}

  cursor: text;
  outline: none;
  text-align: ${({ p }: Object) => R.equals(p, '0') && 'center'};
  line-height: ${({ lineHeight }: Object) => lineHeight || '30px'};
  border-radius: ${({ borderRadius }: Object) => borderRadius || '2px'};
  border: ${(props: Object) => G.ifElse(props.withoutBorder, 'none', '1px solid')};

  border-color: ${({ error, touched, borderColor }: Object) => G.ifElse(
    G.isNotNilAndNotEmpty(borderColor),
    borderColor,
    G.getTheme(G.ifElse(R.and(error, touched), 'forms.inputs.borderColorErr', 'forms.inputs.borderColor')),
  )};

  background-color: ${({ bgColor, disabled }: Object) => (
    bgColor ||
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: ${({ version, withoutBorder }: Object) => (
      R.not(withoutBorder) &&
      `0 0 5px 0 ${G.getThemeByCond(G.isThemeSecondVersion(version), 'colors.boxShadowBlue', 'colors.boxShadowRed')}`
    )};
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  & + .MuiInputAdornment-root {
    min-width: 31px;
    margin-left: 2px;
  }
`;

Input.defaultProps = {
  zIndex: 2,
  height: 30,
  p: '0 0 0 15px',
};

const iconColor = G.getTheme('colors.dark.blue');

const useDatePickerHandlers = ({ onOpen, onClose }: Object) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: Event) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);

    G.callFunction(onOpen);
  };

  const handleClose = () => {
    if (G.isFalse(isOpen)) return;

    setIsOpen(false);

    G.callFunction(onClose);
  };

  return {
    isOpen,
    anchorEl,
    setIsOpen,
    setAnchorEl,
    handleClose,
    handleClick,
  };
};

const PureDatePicker = (props: Object) => {
  const {
    m,
    value,
    label,
    width,
    isOpen,
    maxDate,
    minDate,
    anchorEl,
    handleClick,
    handleClose,
    mask = null,
    wrapperWidth,
    withoutBorder,
    placeholder = '',
    autoFocus = false,
    datePickerInputRef,
    inputSettings = {},
    onChange = () => {},
    onAccept = () => {},
    isClearable = false,
    autoComplete = 'off',
    inputReadOnly = false,
    disablePortal = false,
    withCalendarIcon = true,
    isDateDisabled = () => {},
    useNewMuiInputField = false,
    popperPlacement = 'bottom-end',
    inputFormat = GC.DEFAULT_DATE_FORMAT,
  } = props;

  const componentProps = G.ifElse(
    isClearable,
    {
      actionBar: {
        actions: ['clear'],
      },
    },
    {},
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box width={wrapperWidth}>
        <DesktopDatePicker
          mask={mask}
          value={value}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          onAccept={onAccept}
          autoFocus={autoFocus}
          onClose={handleClose}
          inputFormat={inputFormat}
          inputRef={datePickerInputRef}
          componentsProps={componentProps}
          shouldDisableDate={isDateDisabled}
          PopperProps={{
            anchorEl,
            open: isOpen,
            disablePortal,
            placement: popperPlacement,
          }}
          renderInput={({ inputRef, inputProps }: Object) => (
            <Flex m={m} width={width}>
              {
                useNewMuiInputField && (
                <MuiTextInput
                  {...inputProps}
                  {...inputSettings}
                  label={label}
                  inputRef={inputRef}
                  onClick={handleClick}
                  readOnly={inputReadOnly}
                  placeholder={placeholder}
                  withoutBorder={withoutBorder}
                />
              )}
              {
                R.not(useNewMuiInputField) && (
                <Input
                  {...inputProps}
                  {...inputSettings}
                  width='100%'
                  ref={inputRef}
                  onClick={handleClick}
                  readOnly={inputReadOnly}
                  placeholder={placeholder}
                  autoComplete={autoComplete}
                  bgColor={G.getTheme('inputs.bgColor')}
                />
              )}
              {
                withCalendarIcon &&
                <Flex
                  ml={10}
                  height={30}
                  cursor='pointer'
                  onClick={handleClick}
                >
                  {I.calendar(iconColor, 16, 15)}
                </Flex>
              }
            </Flex>
          )}
        />
      </Box>
    </ClickAwayListener>
  );
};

export const DatePickerMui = (props: Object) => {
  const { onOpen, onClose } = props;

  const {
    isOpen,
    anchorEl,
    handleClose,
    handleClick,
  } = useDatePickerHandlers({ onOpen, onClose });

  return (
    <PureDatePicker
      {...props}
      isOpen={isOpen}
      anchorEl={anchorEl}
      handleClose={handleClose}
      handleClick={handleClick}
    />
  );
};

export const DatePickerMuiWithAutoOpenPickerOnMount = (props: Object) => {
  const { onOpen, onClose } = props;

  const {
    isOpen,
    anchorEl,
    setIsOpen,
    setAnchorEl,
    handleClose,
    handleClick,
  } = useDatePickerHandlers({ onOpen, onClose });

  const datePickerInputRef = useRef();

  useEffect(() => {
    setIsOpen(true);
    setAnchorEl(datePickerInputRef.current);
  }, [datePickerInputRef.current]);

  return (
    <PureDatePicker
      {...props}
      isOpen={isOpen}
      anchorEl={anchorEl}
      handleClose={handleClose}
      handleClick={handleClick}
      datePickerInputRef={datePickerInputRef}
    />
  );
};
