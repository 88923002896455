import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle } from 'react-recompose';
// components
import { ItemIcons } from '../../item-icons';
import { LocalLoader } from '../../local-loader';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// features
import { getStopInfoRequest } from '../../../features/dispatch-planner-events/actions';
import { makeSelectStopsInfoList } from '../../../features/dispatch-planner-events/selectors';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  stopsInfoList: makeSelectStopsInfoList(state),
});

export const enhance = compose(
  connect(mapStateToProps, { getStopInfoRequest }),
  lifecycle({
    componentDidMount() {
      const { location, stopsInfoList, getStopInfoRequest } = this.props;

      const guid = G.getGuidFromObject(location);

      if (G.isNilOrEmpty(R.path([guid], stopsInfoList))) {
        getStopInfoRequest(guid);
      }
    },
  }),
  pure,
);

const locationFieldsToPick = [GC.FIELD_ADDRESS_1, GC.FIELD_CITY, GC.FIELD_STATE, GC.FIELD_ZIP, GC.FIELD_COUNTRY];

const StopInfo = ({ stopInfo, location, handleSelect }: Object) => {
  const {
    comments,
    packageType,
    storedWeight,
    locationName,
    totalQuantity,
    eventLateDate,
    eventEarlyDate,
    temperatureLow,
    temperatureHigh,
    appointmentLateDate,
    appointmentEarlyDate,
    lastTerminalDropName,
    primaryReferenceValue,
  } = stopInfo;

  const { selected } = location;

  const isPickup = G.isStopPickup(location);
  const icon = G.ifElse(isPickup, I.pickupStopIcon, I.dropStopIcon);
  const stopTitle = G.getWindowLocale(...G.ifElse(
    isPickup,
    ['titles:pickup', 'Pickup'],
    ['titles:drop', 'Drop'],
  ));

  return (
    <Flex alignItems='flex-start' flexDirection='column'>
      <Flex alignItems='center'>
        {icon()}
        <Box ml='5px'>{stopTitle}</Box>
        <Box ml='5px' fontWeight='bold'>
          ({G.getWindowLocale('titles:clo', 'Order')} - {primaryReferenceValue})
        </Box>
      </Flex>
      {
        R.not(selected) &&
        <Flex my='4px'>
          <Box
            cursor='pointer'
            color={G.getTheme('colors.dark.blue')}
            onClick={() => handleSelect({ results: R.of(Array, location) })}
          >
            {G.getWindowLocale('actions:add-to-planner', 'Add To Planner')}
          </Box>
        </Flex>
      }
      <Flex my='2px'>
        <Box mr='5px'>{G.getWindowLocale('titles:location-name', 'Location Name')}:</Box>
        {locationName}
      </Flex>
      {
        G.isNotNilAndNotEmpty(lastTerminalDropName) &&
        <Flex my='2px'>
          <Box mr='5px'>{G.getWindowLocale('titles:last-terminal-drop', 'Last TD')}:</Box>
          {lastTerminalDropName}
        </Flex>
      }
      <Flex my='2px'>
        <Box mr='5px'>{I.calendar()} {G.getWindowLocale('titles:early-date')}:</Box>
        {eventEarlyDate}
      </Flex>
      <Flex my='2px'>
        <Box mr='5px'>{I.calendar()} {G.getWindowLocale('titles:late-date')}:</Box>
        {eventLateDate}
      </Flex>
      {
        G.isNotNilAndNotEmpty(appointmentEarlyDate) &&
        <Flex my='2px'>
          <Box mr='5px'>{I.calendar()} {G.getWindowLocale('titles:appointment-date', 'Appointment Date')}:</Box>
          {appointmentEarlyDate} - {R.compose(
            R.join(' '),
            R.tail,
            R.split(' '),
        )(appointmentLateDate)}
        </Flex>
      }
      <Box my='2px'>{I.locationMarker()} {G.concatLocationFields(stopInfo, locationFieldsToPick)}</Box>
      {
        G.isNotNilAndNotEmpty(comments) &&
        <Flex my='4px'>
          <Box mr='5px'>{G.getWindowLocale('titles:comments', 'Comments')}:</Box>
          {comments}
        </Flex>
      }
      {
        G.isAllTrue(
          G.isNotNilAndNotEmpty(packageType),
          G.isNotNilAndNotEmpty(totalQuantity),
          G.isNotNilAndNotEmpty(storedWeight),
        ) &&
        <Flex my='4px'>
          <Box mr='5px'>{G.getWindowLocale('titles:items', 'Items')}:</Box>
          {totalQuantity} {packageType}, {G.getWeightWithUomByConfigUomSystem(storedWeight)}
        </Flex>
      }
      <ItemIcons
        width='auto'
        showText={true}
        temperatureLow={temperatureLow}
        temperatureHigh={temperatureHigh}
      />
    </Flex>
  );
};

export const PopupContent = enhance((props: Object) => {
  const { location, handleSelect, stopsInfoList } = props;

  const stopInfo = R.path([G.getGuidFromObject(location)], stopsInfoList);

  return (
    <LocalLoader
      width={300}
      localLoaderOpen={G.isNilOrEmpty(stopInfo)}
    >
      {
        G.isNotNilAndNotEmpty(stopInfo) &&
        <StopInfo stopInfo={stopInfo} location={location} handleSelect={handleSelect} />
      }
    </LocalLoader>
  );
});
