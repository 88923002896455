import React from 'react';
import * as R from 'ramda';
// components
import { LeafletMap } from '../leaflet-map';
import { Map, StopInfo, Directions, StopMarker, MarkerWithInfo } from '../map';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Box } from '../../ui';
// component tracking
import { StatusMessageInfoContent, StatusMessageMarkerContent } from './status-message-marker';
//////////////////////////////////////////////////

const arrayReductionWithFirstAndLastElement = (arr: Array, count: number) => {
  if (R.lte(arr.length, count)) return arr;

  const newArr = [];
  const maxVal = R.subtract(count, 2);
  const delta = Math.ceil(R.divide(arr.length, maxVal));

  newArr.push(R.prop(0, arr));

  for (let i = 0; i < arr.length; i += delta) {
    newArr.push(R.prop(i, arr));
  }

  newArr.push(R.prop(R.dec(arr.length), arr));

  return newArr;
};

const getWaypointsFromStatusMessages = (locations: Array) => {
  let usedLocations = [];

  const newLocations = R.compose(
    R.filter((item: Object | null) => (
      G.isAllTrue(
        G.isNotNilAndNotEmpty(R.path(['lat'], item)),
        G.isNotNilAndNotEmpty(R.path(['lng'], item)),
        G.notEquals(item, { lat: 0, lng: 0 }),
      )
    )),
    R.map((location: Object) => {
      if (R.includes(location.latLng, usedLocations)) {
        return null;
      }
      usedLocations = R.append(location.latLng, usedLocations);

      return location.latLng;
    }),
  )(R.or(locations, []));

  return newLocations;
};

const getStatusMessageLocations = (statusMessages: Array) => R.compose(
  R.values,
  G.mapIndexed((statusMessage: Object, index: number) => {
    const { latitude, longitude } = statusMessage;

    const title = R.inc(index);

    return R.mergeRight(statusMessage, {
      title,
      latLng: { lat: latitude, lng: longitude },
      markerContent: <StatusMessageMarkerContent title={title} />,
      infoContent: <StatusMessageInfoContent location={statusMessage} />,
    });
  }),
  R.filter(({ latitude, longitude }: Object = {}) => G.isAllNotNilOrNotEmpty([latitude, longitude])),
  R.reverse,
)(arrayReductionWithFirstAndLastElement(statusMessages, 25));

const getEventLocations = (mappedEventsForMap: Array) => R.map((event: Object) => {
  const { title } = event;

  return R.mergeRight(event, {
    useGreenColor: true,
    infoContent: <StopInfo {...event} />,
    markerContent: <StopMarker {...event} />,
    color: G.getTheme('map.completedMarkerColor'),
    markerTitle: `${R.head(title)}${G.getNumberFromString(title)}`,
  });
}, mappedEventsForMap);

export const MapWithStatusMessages = (props: Object) => {
  const { mapCenter, mappedEventsForMap, statusMessages = [] } = props;

  const useGoogleMap = G.isTrue(G.getAmousConfigByNameFromWindow(GC.UI_TRACKING_USE_GOOGLE_MAP));

  const eventLocations = getEventLocations(mappedEventsForMap);
  const statusMessageLocations = getStatusMessageLocations(statusMessages);
  const locations = R.concat(eventLocations, statusMessageLocations);

  const eventWaypoints = R.map(({ latLng }: Object) => latLng, mappedEventsForMap);
  const statusMessageWaypoints = getWaypointsFromStatusMessages(statusMessageLocations);

  const center = {
    lat: R.pathOr(38.755157, [GC.FIELD_LATITUDE], mapCenter),
    lng: R.pathOr(-98.269035, [GC.FIELD_LONGITUDE], mapCenter),
  };

  const directionsColor = G.getTheme('map.directionsColor');

  const wrapperProps = {
    width: '100%',
    height: '100%',
    boxShadow: G.getTheme('shadows.standard'),
  };

  if (R.not(useGoogleMap)) {
    const routes = [
      { waypoints: statusMessageWaypoints },
      { color: directionsColor, waypoints: eventWaypoints },
    ];

    return (
      <Box {...wrapperProps}>
        <LeafletMap
          height='100%'
          routes={routes}
          locations={locations}
          center={G.ifElse(G.isNilOrEmpty(mapCenter), {}, center)}
        />
      </Box>
    );
  }

  return (
    <Box {...wrapperProps}>
      <Map height='100%' center={center}>
        {
          mappedEventsForMap &&
          <Directions locations={eventWaypoints} options={{ color: directionsColor }} />
        }
        {
          R.gte(R.length(statusMessageWaypoints), 2) &&
          <Directions locations={statusMessageWaypoints} />
        }
        <MarkerWithInfo locations={G.makeLocationsWithTransform(locations)} />
      </Map>
    </Box>
  );
};
