import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// features
import { AuthWrapper } from '../../../features/permission/index';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// component virtualized-table
import { Checkbox } from '../ui';
//////////////////////////////////////////////////

export const CheckBoxComponent = ({
  ml,
  id,
  item,
  name,
  checked,
  disabled,
  mainColors,
  onOptionClick,
  justifyContent,
}: Object) => (
  <Flex
    ml={ml}
    height='100%'
    p='0 10px 0 5px'
    justifyContent={justifyContent}
  >
    <Checkbox
      name={name}
      type='checkbox'
      checked={checked}
      mainColors={mainColors}
      onChange={() => onOptionClick(id, item)}
      disabled={G.ifElse(G.isBoolean(disabled), disabled, false)}
    />
  </Flex>
);

export const ActionButton = ({ guid, item, actionData, iconColor }: Object) => {
  const { action, iconName, permissions, getPermissions, iconSize = [] } = actionData;

  let permissionsToUse = permissions;

  if (G.isFunction(getPermissions)) permissionsToUse = getPermissions(item);

  return (
    <AuthWrapper has={permissionsToUse}>
      <Box ml='8px' cursor='pointer' onClick={() => action(guid, item)}>
        {I[iconName](iconColor, ...iconSize)}
      </Box>
    </AuthWrapper>
  );
};

