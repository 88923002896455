// NOTE: check if we need constants here

export const defaultFilterFields = {
  to: null,
  next: null,
  last: null,
  from: null,
  guid: null,
  groupId: null,
  timeUnit: null,
  dataType: null,
  operation: null,
  stringValue: null,
  stringRange: null,
  numberRange: null,
  numberValue: null,
  booleanValue: null,
  propertyName: null,
  dateRelative: false,
  referenceName: null,
  referenceValue: null,
};

export const rangeObject = {
  to: null,
  from: null,
};
