import React from 'react';
import * as R from 'ramda';
import FileSaver from 'file-saver';
import { connect } from 'react-redux';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { Table } from '../table';
import { FormFooter2 } from '../form-footer';
import { openModal, closeModal } from '../modal/actions';
import { AsyncDocumentPreview } from '../document-preview';
// features
import { exportFactoringDocument } from '../../features/master-invoice/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover, withConnectModalAndLoaderActions, withComponentDidUpdatePropCallback } from '../../hocs';
// ui
import { Box, Flex } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// components async-documents
import SendDocuments from './send-documents';
import { getReport, tableSettings, tableSettings2, getColumnSettings } from './settings';
//////////////////////////////////////////////////

const lightGreyColor = G.getTheme('colors.lightGrey');

const actionsMap = {
  [GC.DOCUMENTS_TYPE_DRIVER_PAYROLL]: {
    remove: endpointsMap.driverPayrollDocument,
    sendViaEmail: endpointsMap.driverPayrollSendViaEmail,
    download: endpointsMap.driverPayrollDownloadDocument,
  },
  [GC.DOCUMENTS_TYPE_VENDOR_PAYROLL]: {
    remove: endpointsMap.vendorPayrollDocument,
    sendViaEmail: endpointsMap.vendorPayrollSendViaEmail,
    download: endpointsMap.vendorPayrollDownloadDocument,
  },
  [GC.DOCUMENTS_TYPE_MASTER_INVOICE]: {
    remove: endpointsMap.customerMasterInvoiceDocument,
    downloadZip: endpointsMap.customerMasterInvoiceDocumentsZip,
    download: endpointsMap.customerMasterInvoiceDownloadDocument,
  },
};

const withAsyncDocumentActions = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    removeDocuments: (props: Object) => async (payload: Object) => {
      const { itemList, openLoader, closeLoader, setItemList, documentsType } = props;

      openLoader();

      const guids = R.path(['guids'], payload);

      const options = {
        data: guids,
      };

      const endpoint = R.path([documentsType, 'remove'], actionsMap);

      const res = await sendRequest('delete', endpoint, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        const newItemList = R.reject((item: Object) => R.includes(R.prop(GC.FIELD_GUID, item), guids), itemList);
        setItemList(newItemList);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
    downloadDocumentsZip: ({ documentsType }: Object) => async ({ data, setSubmitting }: Object) => {
      const options = {
        data,
        resType: 'arraybuffer',
      };

      const endpoint = R.path([documentsType, 'downloadZip'], actionsMap);

      const res = await sendRequest('post', endpoint, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        G.saveFileFromResponse(res, `${documentsType}_zip`);
      } else {
        G.handleFailResponseSimple(res);
      }

      setSubmitting(false);
    },
    sendDocumentViaEmailRequest: (props: Object) => async (options: Object) => {
      const {
        itemList,
        openLoader,
        closeModal,
        closeLoader,
        setItemList,
        documentsType,
      } = props;

      const { mailTo, payrollGuid } = options;

      openLoader();

      const endpoint = R.path([documentsType, 'sendViaEmail'], actionsMap);

      const res = await sendRequest('post', endpoint, { data: options });

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        const index = R.findIndex(R.propEq(payrollGuid, GC.FIELD_PRIMARY_OBJECT_GUID), itemList);
        const newItemList = R.assocPath([index, GC.FIELD_DOCUMENT_SENT_TO], mailTo, itemList);

        closeModal();
        setItemList(newItemList);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  pure,
);

const enhanceDocuments = compose(
  withFixedPopover,
  withState('submitting', 'setSubmitting', false),
  withState('itemList', 'setItemList', ({ data }: Object) => R.map(R.assoc('selected', false), data)),
  withHandlers({
    handleUpdateItemListOnDataChange: (props: Object) => () => {
      const { data, setItemList } = props;

      setItemList(R.map(R.assoc('selected', false), data));
    },
  }),
  withComponentDidUpdatePropCallback({ propName: 'data', callbackName: 'handleUpdateItemListOnDataChange' }),
  withAsyncDocumentActions,
  withHandlers({
    handlePreviewDocument: (props: Object) => (data: Object) => {
      const { openModal, documentsType } = props;

      const fileName = data[GC.FIELD_FILE_NAME];
      const fileType = R.last(R.split('.', fileName));

      let modal;
      let component;

      if (G.notContain(fileType, GC.FILE_PREVIEW_SUPPORTED_EXTENSIONS)) {
        component = (
          <Box fontSize={14}>
            {
              G.getWindowLocale(
                'messages:file-not-supported-for-preview',
                'Sorry, file is not supported for preview',
              )
            }
          </Box>
        );

        modal = {
          p: 15,
          component,
          options: {
            width: 400,
            outsideCloseButton: true,
          },
        };
      } else {
        component = (
          <AsyncDocumentPreview
            fileType={fileType}
            asyncOptions={{ params: data, resType: 'arraybuffer' }}
            asyncEndpoint={R.path([documentsType, 'download'], actionsMap)}
          />
        );

        modal = {
          p: '0',
          component,
          options: {
            minWidth: 800,
            width: '95vw',
            height: '95vh',
            title: fileName,
            overflow: 'auto',
            outsideCloseButton: true,
          },
        };
      }

      openModal(modal);
    },
    handleSelectItem: (props: Object) => (data: string) => {
      const { itemList, setItemList } = props;

      if (R.equals(data, 'all')) {
        const value = R.not(R.all(
          (item: Object) => item.selected,
          itemList,
        ));

        return setItemList(R.map(
          (item: Object) => R.assoc('selected', value, item),
          itemList,
        ));
      }

      return setItemList(R.map(
        (item: Object) => {
          if (R.propEq(data, GC.FIELD_GUID, item)) {
            return R.assoc('selected', R.not(item.selected), item);
          }

          return item;
        },
        itemList,
      ));
    },
    handleDownloadZip: (props: Object) => () => {
      const { itemList, setSubmitting, downloadDocumentsZip } = props;

      const selectedList = R.compose(
        R.map(R.prop(GC.FIELD_GUID)),
        R.filter(R.prop('selected')),
      )(itemList);

      if (R.propEq(0, 'length', selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      setSubmitting(true);

      downloadDocumentsZip({ setSubmitting, data: selectedList });
    },
    handleRemoveDocuments: ({ itemList, removeDocuments }: Object) => () => {
      const selectedList = R.compose(
        R.map(R.prop(GC.FIELD_GUID)),
        R.filter(R.prop('selected')),
      )(itemList);

      if (R.propEq(0, 'length', selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      removeDocuments({ guids: selectedList });
    },
    handleRemoveDocument: ({ removeDocuments }: Object) => (documentGuid: string) => {
      const guids = R.of(Array, documentGuid);

      removeDocuments({ guids });
    },
    handleDownloadDocument: ({ documentsType }: Object) => async ({ params }: Object) => {
      const options = {
        params,
        resType: 'arraybuffer',
      };

      const endpoint = R.path([documentsType, 'download'], actionsMap);

      const res = await sendRequest('get', endpoint, options);

      const { data, status, headers } = res;

      if (G.isResponseSuccess(status)) {
        const file = new window.Blob(
          R.of(Array, data),
          { type: R.pathOr('application/pdf', ['content-type'], headers) },
        );

        FileSaver.saveAs(file, params[GC.FIELD_FILE_NAME]);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
    handleSendDocumentsViaEmail: (props: Object) => () => {
      const {
        itemList,
        openModal,
        payrollGuid,
        documentsType,
        payrollTypeGuid,
        sendDocumentViaEmailRequest,
      } = props;

      const documentGuids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(R.prop('selected')),
      )(R.or(itemList, []));

      if (R.isEmpty(documentGuids)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const isDriver = R.equals(documentsType, GC.DOCUMENTS_TYPE_DRIVER_PAYROLL);

      const submitAction = (mailTo: Object) =>
        sendDocumentViaEmailRequest({ mailTo, payrollGuid, documentGuids });

      const component = (
        <SendDocuments
          isDriver={isDriver}
          submitAction={submitAction}
          payrollTypeGuid={payrollTypeGuid}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:send-emails', 'Send Emails'),
        },
      };

      openModal(modal);
    },
    handleSendDocumentViaEmail: (props: Object) => (documentGuid: string) => {
      const {
        openModal,
        payrollGuid,
        documentsType,
        payrollTypeGuid,
        sendDocumentViaEmailRequest,
      } = props;

      const documentGuids = R.of(Array, documentGuid);

      const isDriver = R.equals(documentsType, GC.DOCUMENTS_TYPE_DRIVER_PAYROLL);

      const submitAction = (mailTo: Object) =>
        sendDocumentViaEmailRequest({ mailTo, payrollGuid, documentGuids });

      const component = (
        <SendDocuments
          isDriver={isDriver}
          submitAction={submitAction}
          payrollTypeGuid={payrollTypeGuid}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:send-emails', 'Send Emails'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const DocumentTable = (props: Object) => {
  const {
    version,
    loading,
    itemList,
    getDocuments,
    documentsType,
    asyncEndpoint,
    handleSelectItem,
    columnSettingsProps,
    handlePreviewDocument,
    handleDownloadDocument,
    exportFactoringDocument,
  } = props;

  const offset = itemList.length;

  const integrationAvailable = R.any(
    (doc: Object) => G.isNotNilAndNotEmpty(doc[GC.FIELD_INTEGRATION_TYPE]),
    itemList,
  );

  const columnSettings = getColumnSettings({
    getDocuments,
    asyncEndpoint,
    columnSettingsProps,
    exportFactoringDocument,
    download: handleDownloadDocument,
    previewDocument: handlePreviewDocument,
  });

  const allChecked = G.isAllChecked(itemList);

  const tableSettingsTuUse = R.equals(version, 2) ? tableSettings2 : tableSettings;

  const data = {
    loading,
    allChecked,
    pagination: {
      offset,
      limit: offset,
    },
    columnSettings,
    totalCount: offset,
    hasSelectable: false,
    onOptionClick: handleSelectItem,
    tableSettings: tableSettingsTuUse,
    itemList: G.ifElse(loading, [], itemList),
    report: getReport(documentsType, integrationAvailable),
  };

  return <Table {...data} />;
};

const DocumentList = enhanceDocuments((props: Object) => {
  const {
    version,
    submitting,
    wrapperStyles,
    documentsType,
    cancelBtnStyles,
    handleDownloadZip,
    mailIntegrationType,
    handleRemoveDocuments,
    handleSendDocumentsViaEmail,
  } = props;

  let actionButtons = [
    {
      action: handleRemoveDocuments,
      displayText: G.getWindowLocale('actions:remove', 'Remove'),
    },
  ];

  if (R.and(
    G.isNotNilAndNotEmpty(mailIntegrationType),
    R.includes(documentsType, [GC.DOCUMENTS_TYPE_DRIVER_PAYROLL, GC.DOCUMENTS_TYPE_VENDOR_PAYROLL]),
  )) {
    actionButtons = R.prepend(
      {
        action: handleSendDocumentsViaEmail,
        buttonStyles: { mr: 20, ml: 'auto', width: 160 },
        displayText: G.getWindowLocale('actions:send-documents', 'Send Documents'),
      },
      actionButtons,
    );
  }

  const footerBoxStyles = R.equals(version, 2) ? { p: 15, borderTop: `1px solid ${lightGreyColor}` } : { p: 15, };

  return (
    <Flex flexDirection='column' {...G.spreadUiStyles(wrapperStyles)}>
      <Box maxWidth='95vw'><DocumentTable {...props} /></Box>
      <FormFooter2
        boxStyles={footerBoxStyles}
        submitDisabled={submitting}
        actionButtons={actionButtons}
        submitAction={handleDownloadZip}
        cancelBtnStyles={cancelBtnStyles}
        submitBtnStyles={
          G.ifElse(
            R.equals(documentsType, GC.DOCUMENTS_TYPE_MASTER_INVOICE),
            { width: 125 },
            { display: 'none' },
          )
        }
        submitBtnText={
          G.ifElse(
            submitting,
            G.getWindowLocale('titles:loading', 'Loading...'),
            G.getWindowLocale('titles:download-zip', 'Download ZIP'),
          )
        }
      />
    </Flex>
  );
});

export default connect(null, {
  openModal,
  closeModal,
  exportFactoringDocument,
})(DocumentList);

export {
  enhanceDocuments,
};
