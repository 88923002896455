import styled, { keyframes } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const LoaderWrapper = styled.div`
  z-index: 1303;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: ${({ top }: Object) => top || 0};
  left: ${({ left }: Object) => left || 0};
  right: ${({ right }: Object) => right || 0};
  bottom: ${({ bottom }: Object) => bottom || 0};
  position: ${({ position }: Object) => position || 'fixed'};
  background: ${({ background }: Object) => background || 'rgba(53, 53, 53, 0.5)'};
`;

export const DimmerWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;
  padding-left: 55px;
  z-index: ${({ zIndex }: Object) => zIndex || 99};
  background-color: ${() => G.getTheme('dimmer.bgColor')};
`;

export const Rectangle = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
  background-color: ${() => G.getTheme('dimmer.contentBgColor')};
`;

export const TitleRectangle = styled.div`
  width: 50px;
  height: 20px;
  margin: 20px 10px;
  background-color: ${() => G.getTheme('dimmer.contentBgColor')};
`;

export const TableRectangle = styled.div`
  width: 550px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${() => G.getTheme('dimmer.tableBgColor')};
`;

export const squareRotate = keyframes`
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
`;

export const LoaderContent = styled.div`
  width: 40px;
  height: 40px;
  padding: 15px;
  position: fixed;
  border-radius: 2px;
  animation: ${squareRotate} 1.2s infinite ease-in-out;
  background-color: ${() => G.getTheme('loader.bgColor')};
`;
