import * as R from 'ramda';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect } from 'react';
import { compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const withTabsMui = compose(
  withState('activeMuiTab', 'setActiveMuiTab', ({ defaultActiveMuiTab }: Object) => defaultActiveMuiTab),
  withHandlers({
    handleSetActiveMuiTab: ({ setActiveMuiTab }: Object) => (value: string) => setActiveMuiTab(value),
  }),
);

const TabsMui = (props: Object) => {
  const {
    tabs,
    count,
    tabsProps,
    tabStyles,
    tabsStyles,
    permissions,
    activeMuiTab,
    setActiveMuiTab,
    notHasPermissions,
    handleClickMuiTab,
    defaultActiveMuiTab,
  } = props;

  useEffect(() => {
    if (G.isNilOrEmpty(defaultActiveMuiTab)) return;

    setActiveMuiTab(defaultActiveMuiTab);
  }, [defaultActiveMuiTab]);

  const handleChangeTab = (event: Object, newValue: string) => {
    G.callFunctionWithArgs(handleClickMuiTab, newValue);

    setActiveMuiTab(newValue);
  };

  return (
    <Tabs
      {...tabsProps}
      sx={tabsStyles}
      value={activeMuiTab}
      onChange={handleChangeTab}
    >
      {
        tabs.map(({
          text,
          value,
          withCount,
          clickHandler,
        }: Object, i: number) => {
          const key = `${value}${i}`;

          const label = G.ifElse(
            R.and(R.isNotNil(count), R.or(withCount, R.equals(value, activeMuiTab))),
            `${text} (${count})`,
            text,
          );

          const allow = G.getAccessForAmousCurrentUserByPermissions(permissions, notHasPermissions);

          if (allow) {
            return (
              <Tab
                key={key}
                label={label}
                value={value}
                sx={tabStyles}
              />
            );
          }

          return null;
        })
      }
    </Tabs>
  );
};

const withTabs2 = compose(
  withState('activeTab', 'setActiveTab', ({ defaultActiveTab }: Object) => R.or(defaultActiveTab, 0)),
  withHandlers({
    handleSetActiveTab: ({ setActiveTab }: Object) => (index: number) => setActiveTab(index),
  }),
);

const Tabs2 = (props: Object) => {
  const {
    tabs,
    count,
    activeTab,
    tabsProps,
    tabStyles,
    tabsStyles,
    permissions,
    setActiveTab,
    handleClickTab,
    defaultActiveTab,
    notHasPermissions,
  } = props;

  useEffect(() => {
    if (G.isNilOrEmpty(defaultActiveTab)) return;

    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  const handleChangeTab = (event: Object, newValue: string) => {
    G.callFunctionWithArgs(handleClickTab, newValue);

    setActiveTab(newValue);
  };

  return (
    <Tabs
      {...tabsProps}
      sx={tabsStyles}
      value={activeTab}
      onChange={handleChangeTab}
    >
      {
        tabs.map(({
          text,
          value,
          withCount,
          clickHandler,
        }: Object, i: number) => {
          const key = `${value}${i}`;

          const label = G.ifElse(
            R.and(R.isNotNil(count), R.or(withCount, R.equals(value, activeTab))),
            `${text} (${count})`,
            text,
          );

          const allow = G.getAccessForAmousCurrentUserByPermissions(permissions, notHasPermissions);

          if (allow) {
            return (
              <Tab
                key={key}
                label={label}
                value={value}
                sx={tabStyles}
              />
            );
          }

          return null;
        })
      }
    </Tabs>
  );
};

const TabsMuiEnhanced = withTabsMui(TabsMui);

export {
  TabsMui,
  withTabsMui,
  TabsMuiEnhanced,
  //
  Tabs2,
  withTabs2,
};
