import React from 'react';
// NOTE: custom markers do not clustering and cause render issues
// react-google-maps higher version has this issue resolved (TODO:)
// helpers/constants
// import * as G from '../../helpers';
// import * as GC from '../../constants';
// component simple-map-with-markers
// import GoogleMap from './components/google-map';
import LeafletMap from './components/leaflet-map';
//////////////////////////////////////////////////

const Map = (props: Object) => {
  // const useGoogleMap = G.isTrue(G.getAmousConfigByNameFromWindow(GC.UI_CUSTOMER_PORTAL_USE_GOOGLE_MAP));

  // if (useGoogleMap) return <GoogleMap {...props} />;

  // return <GoogleMap {...props} />;

  return <LeafletMap {...props} />;
};

export default Map;
