import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('timer', 'setTimer', ({ timer }: Object) => R.or(timer, 1200000)),
  withHandlers({
    handleStart: ({ timer, setTimer, handleReset }: Object) => () => {
      if (R.and(G.isZero(timer), G.isFunction(handleReset))) return handleReset();

      setTimer(R.subtract(timer, 100));
    },
  }),
  lifecycle({
    componentDidMount() {
      this.interval = setInterval(() => this.props.handleStart(), 100);
    },
    componentWillUnmount() {
      clearInterval(this.interval);
    },
  }),
  pure,
);

const getTimeString = (duration: number) => {
  const seconds = Math.floor((duration / 1000) % 60).toString().padStart(2, '0');
  const minutes = Math.floor((duration / (1000 * 60)) % 60).toString().padStart(2, '0');

  return `${minutes} : ${seconds}`;
};

export const CountDown = enhance(({ timer, styles }: Object) =>
  <Box {...G.spreadUiStyles(styles)} >{getTimeString(timer)}</Box>,
);
