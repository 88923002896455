import React from 'react';
import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { ReactSelect } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const fields = [
  { name: GC.FIELD_UNIT_ID, freezed: false, sequence: 1, reference: false },
];

const orderFields = [
  {
    sequence: 1,
    order: 'ASC',
    reference: false,
    name: GC.FIELD_UNIT_ID,
  },
];

const getSearchFilter = (searchText: string) => [
  {
    dataType: 'string',
    operation: 'contain',
    stringValue: searchText,
    propertyName: GC.FIELD_UNIT_ID,
  },
];

const enhance = compose(
  withState('totalCount', 'setTotalCount', null),
  withState('searchValue', 'setSearchValue', null),
  withState('isLoading', 'setLoadingStatus', false),
  withState('fleetEntities', 'setFleetEntities', []),
  withHandlers({
    handleGetFleetEntitiesListRequest: (props: Object) => async ({ searchText, isPagination }: Object = {}) => {
      const { isTruck, searchValue, fleetEntities, setTotalCount, setLoadingStatus, setFleetEntities } = props;

      const searchTextOrValue = R.or(searchText, searchValue);

      try {
        const endpoint = G.ifElse(
          isTruck,
          endpointsMap.truckListChildrenAndCurrent,
          endpointsMap.trailerListChildrenAndCurrent,
        );

        const reqData = {
          fields,
          limit: 10,
          orderFields,
          [GC.FIELD_CURRENT_BRANCH]: G.getAmousCurrentBranchGuidFromWindow(),
          searchCriteria: G.ifElse(G.isNotNil(searchTextOrValue), getSearchFilter(searchTextOrValue), []),
          offset: G.ifElse(R.and(isPagination, G.isNotNilAndNotEmpty(fleetEntities)), R.length(fleetEntities), 0),
        };

        const { data } = await sendRequest('post', endpoint, { data: reqData });

        const { results, totalCount } = data;

        if (totalCount) setTotalCount(totalCount);

        const newFleetEntities = G.isTrue(isPagination)
          ? R.concat(fleetEntities, results)
          : results;

        setFleetEntities(newFleetEntities);
      } catch (error) {
        G.handleException('error', 'handleGetFleetEntitiesListRequest exception');
      } finally {
        setLoadingStatus(false);
      }
    },
  }),
  withHandlers({
    handleChange: (props: Object) => (value: Object) => {
      const { fieldName, handleSelect, setTotalCount, setSearchValue, setSelectedFleetEntity } = props;

      if (R.isNil(value)) {
        setSelectedFleetEntity(null);

        return handleSelect(fieldName, null);
      }

      setTotalCount(null);
      setSearchValue(null);
      setSelectedFleetEntity(value);
      handleSelect(fieldName, G.getValueFromObject(value));
    },
    handleScroll: (props: Object) => () => {
      const { totalCount, fleetEntities, setLoadingStatus, handleGetFleetEntitiesListRequest } = props;

      if (R.gt(totalCount, R.length(fleetEntities))) {
        setLoadingStatus(true);
        handleGetFleetEntitiesListRequest({ isPagination: true });
      }
    },
    handleSearch: (props: Object) => (search: string) => {
      if (R.gt(R.length(search), 1)) {
        const { setSearchValue, setLoadingStatus, handleGetFleetEntitiesListRequest } = props;

        setSearchValue(search);
        setLoadingStatus(true);
        handleGetFleetEntitiesListRequest({ searchText: search });
      }
    },
    handleGetFleetEntityOptions: (props: Object) => () => {
      const { totalCount, setLoadingStatus, handleGetFleetEntitiesListRequest } = props;

      if (R.isNil(totalCount)) {
        setLoadingStatus(true);
        handleGetFleetEntitiesListRequest();
      }
    },
  }),
  pure,
);

const SelectFleetEntity = enhance((props: Object) => {
  const {
    value,
    hasError,
    fieldName,
    isLoading,
    isDisabled,
    handleChange,
    handleScroll,
    handleSearch,
    fleetEntities,
    selectedFleetEntity,
    useMenuPortalTarget,
    handleGetFleetEntityOptions,
  } = props;

  let options = G.mapUnitIdGuidObjectPropsToLabelValueObject(fleetEntities);

  if (G.isNotNilAndNotEmpty(selectedFleetEntity)) {
    options = R.prepend(selectedFleetEntity, options);
  }

  return (
    <ReactSelect
      value={value}
      id={fieldName}
      isClearable={true}
      hasError={hasError}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onChange={handleChange}
      onMenuScrollToBottom={handleScroll}
      onMenuOpen={handleGetFleetEntityOptions}
      useMenuPortalTarget={useMenuPortalTarget}
      onInputChange={G.setDebounce(handleSearch, 500)}
      options={R.uniqBy(R.prop(GC.FIELD_VALUE), options)}
    />
  );
});

const withSelectedFleetEntity = withState(
  'selectedFleetEntity',
  'setSelectedFleetEntity',
  ({ fleetEntityGuid, fleetEntityUnitId }: Object) => {
    if (G.isAnyNilOrEmpty([fleetEntityGuid, fleetEntityUnitId])) return null;

    return {
      [GC.FIELD_VALUE]: fleetEntityGuid,
      [GC.FIELD_LABEL]: fleetEntityUnitId,
    };
  },
);

export {
  SelectFleetEntity,
  withSelectedFleetEntity,
};
