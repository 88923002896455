import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

export const Wrapper = styled(Box)`
  position: fixed;
  width: max-content;
  border-radius: 2px;
  z-index: ${({ zIndex }: Object) => zIndex || 1};

  top: ${({ positions }: Object) => positions.top}px;
  background-color: ${() => G.getTheme('colors.white')};
  margin: ${({ positions }: Object) => positions.margin};
  box-shadow: ${() => `0 1px 8px 0 ${G.getTheme('colors.boxShadowGrey')}`};

  &[type='center'] {
    right: ${({ positions }: Object) => positions.right}px;
  }

  &[type='right'] {
    right: ${({ positions }: Object) => positions.right}px;
  }

  &[type='left'] {
    left: ${({ positions }: Object) => positions.right}px;
  }
`;

Wrapper.defaultProps = {
  p: '5px 0',
};
