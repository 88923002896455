import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState } from 'react-recompose';
import ReactTextMoreLess from 'react-text-more-less';
// components
import { TextComponent } from '../text';
// helpers constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const initialStyles = {
  fontSize: 12,
  width: '100%',
  maxHeight: 300,
  textAlign: 'justify',
  wordBreak: 'break-all',
};

const enhance = compose(
  withState('collapsed', 'setCollapsed', true),
  pure,
);

const ShowLessMoreComponent = ({ collapsed, setCollapsed, showLessMoreText }: Object) => (
  <TextComponent ml='5px' color={G.getTheme('colors.navyBlue')} onClick={() => setCollapsed(R.not(collapsed))}>
    ...{showLessMoreText}
  </TextComponent>
);

export const TextCollapse = enhance((props: Object) => (
  <ReactTextMoreLess
    text={props.text}
    collapsed={props.collapsed}
    lessHeight={props.lessHeight}
    onClick={() => props.setCollapsed(R.not(props.collapsed))}
    rootProps={{ style: R.mergeRight(initialStyles, props.rootProps)}}
    showLessElement={
      <ShowLessMoreComponent
        {...R.pick(['collapsed', 'setCollapsed'], props)}
        showLessMoreText={G.getWindowLocale('titles:show-less', 'Show Less')}
      />
    }
    showMoreElement={
      <ShowLessMoreComponent
        {...R.pick(['collapsed', 'setCollapsed'], props)}
        showLessMoreText={G.getWindowLocale('titles:show-more', 'Show More')}
      />
    }
  />
));
