import { createAction } from 'redux-act';
// helpers/constants
import * as GC from '../constants';
//////////////////////////////////////////////////

export const ACTION_TYPE_CLEAN_FORM_REDUCER = 'ACTION_TYPE_CLEAN_FORM_REDUCER';

export const sendLogOutRequest = createAction('sendLogOutRequest');

export const setGoogleApiKey = createAction('setGoogleApiKey');
export const globalCleanReports = createAction('globalCleanReports');
export const toggleSubmitLoading = createAction('toggleSubmitLoading');
export const initialDataLoadFail = createAction('initialDataLoadFail');
export const initSwitchBranchLogic = createAction('initSwitchBranchLogic');
export const initialDataLoadRequest = createAction('initialDataLoadRequest');
export const initialDataLoadSuccess = createAction('initialDataLoadSuccess');
export const addCarrierRatesRequest = createAction('addCarrierRatesRequest');
export const showRequestStatusModal = createAction('showRequestStatusModal');
export const callStatusCheckTelRequest = createAction('callStatusCheckTelRequest');
export const macroPointStopOrderRequest = createAction('macroPointStopOrderRequest');
export const sendTelToMacroPointRequest = createAction('sendTelToMacroPointRequest');
export const createLoadDocumentsRequest = createAction('createLoadDocumentsRequest');
export const getDocumentTemplateSuccess = createAction('getDocumentTemplateSuccess');
export const initialSplashScreenLoadFail = createAction('initialSplashScreenLoadFail');
export const initialSplashScreenLoadSuccess = createAction('initialSplashScreenLoadSuccess');
export const createPrintLoadDocumentsRequest = createAction('createPrintLoadDocumentsRequest');
export const hideListIssuesByListNameRequest = createAction('hideListIssuesByListNameRequest');
// load board
export const exportTelToLoadBoard = createAction('exportTelToLoadBoard');
export const removeTelFromLoadBoard = createAction('removeTelFromLoadBoard');
export const updateTelOnLoadBoardSuccess = createAction('updateTelOnLoadBoardSuccess');
export const addPostedShipmentStateRequest = createAction('addPostedShipmentStateRequest');
export const addPostedShipmentStateSuccess = createAction('addPostedShipmentStateSuccess');
export const updatePostedShipmentStateRequest = createAction('updatePostedShipmentStateRequest');
export const updatePostedShipmentStateSuccess = createAction('updatePostedShipmentStateSuccess');
export const deletePostedShipmentStateRequest = createAction('deletePostedShipmentStateRequest');
export const deletePostedShipmentStateSuccess = createAction('deletePostedShipmentStateSuccess');
// QB IIF
export const createQBIIFImportByInvoiceTypeRequest = createAction('createQBIIFImportByInvoiceTypeRequest');
// hot load
export const toggleMarkAsHotLoadByLoadTypeRequest = createAction('toggleMarkAsHotLoadByLoadTypeRequest');
export const toggleMarkAsHotLoadByLoadTypeSuccess = createAction('toggleMarkAsHotLoadByLoadTypeSuccess');
// fast load
export const toggleMarkAsFastLoadByLoadTypeRequest = createAction('toggleMarkAsFastLoadByLoadTypeRequest');
export const toggleMarkAsFastLoadByLoadTypeSuccess = createAction('toggleMarkAsFastLoadByLoadTypeSuccess');
// integration
export const smartlookInitAndUserIdentify = createAction('smartlookInitAndUserIdentify');
// user monitoring
export const getUserMonitoringIntegrationUseListRequest = createAction('getUserMonitoringIntegrationUseListRequest');
export const receivedUserMonitoringIntegrationUseListSuccess =
  createAction('receivedUserMonitoringIntegrationUseListSuccess');
// commission assignment
export const changeCommissionAssignmentRequest = createAction('changeCommissionAssignmentRequest');
export const changeCommissionAssignmentSuccess = createAction('changeCommissionAssignmentSuccess');
// external system
export const sendTelToExternalSystemRequest = createAction('sendTelToExternalSystemRequest');
export const sendCloToExternalSystemRequest = createAction('sendCloToExternalSystemRequest');
// accounting integration
export const sendInvoiceToBCByInvoiceTypeRequest = createAction('sendInvoiceToBCByInvoiceTypeRequest');
export const sendInvoiceToSageByInvoiceTypeRequest = createAction('sendInvoiceToSageByInvoiceTypeRequest');
// visit pages
export const visitNewDoPage = createAction(GC.VISIT_NEW_DO_PAGE);
export const visitTelListPage = createAction(GC.VISIT_TEL_LIST_PAGE);
export const visitCloListPage = createAction(GC.VISIT_CLO_LIST_PAGE);
export const visitLiteNewDoPage = createAction(GC.VISIT_LITE_NEW_DO_PAGE);
export const visitLoadBoardsPage = createAction(GC.VISIT_LOAD_BOARD_PAGE);
export const visitDriverCardsPage = createAction(GC.VISIT_DRIVERS_CARD_PAGE);
export const visitCarrierProfilePage = createAction(GC.VISIT_CARRIER_EDIT_PAGE);
export const visitNewDOQuoteEditPage = createAction(GC.VISIT_NEW_DO_QUOTE_PAGE);
export const visitCarrierProfilePageV2 = createAction(GC.VISIT_CARRIER_PROFILE_PAGE);
export const visitFleetTruckProfilePage = createAction(GC.VISIT_FLEET_TRUCK_EDIT_PAGE);
export const visitDispatchBoardCloPage = createAction(GC.VISIT_DISPATCH_BOARD_CLO_PAGE);
export const visitDispatchBoardTelPage = createAction(GC.VISIT_DISPATCH_BOARD_TEL_PAGE);
export const visitFleetDriverProfilePage = createAction(GC.VISIT_FLEET_DRIVER_EDIT_PAGE);
export const visitFleetVendorProfilePage = createAction(GC.VISIT_FLEET_VENDOR_EDIT_PAGE);
export const visitFleetProfileTruckPage = createAction(GC.VISIT_FLEET_PROFILE_TRUCK_PAGE);
export const visitFleetTrailerProfilePage = createAction(GC.VISIT_FLEET_TRAILER_EDIT_PAGE);
export const visitFleetProfileDriverPage = createAction(GC.VISIT_FLEET_PROFILE_DRIVER_PAGE);
export const visitFleetProfileVendorPage = createAction(GC.VISIT_FLEET_PROFILE_VENDOR_PAGE);
export const visitFleetProfileTrailerPage = createAction(GC.VISIT_FLEET_PROFILE_TRAILER_PAGE);
export const visitDispatchDetailsLoadPage = createAction(GC.VISIT_DISPATCH_DETAILS_LOAD_PAGE);
export const visitDispatchDetailsOrderPage = createAction(GC.VISIT_DISPATCH_DETAILS_ORDER_PAGE);
export const visitCarrierContractDetailsPage = createAction(GC.VISIT_CARRIER_CONTRACT_DETAILS_PAGE);
export const visitCustomerContractDetailsPage = createAction(GC.VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE);
export const visitClaimManagementClaimDetailsPage = createAction(GC.VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE);
// edi export
export const exportInvoicesToEDIRequest = createAction('exportInvoicesToEDIRequest');
// gmail integration
export const setMailServiceIntegrationType = createAction('setMailServiceIntegrationType');
export const connectToMailIntegrationRequest = createAction('connectToMailIntegrationRequest');
export const setConnectedToMailIntegrationEmails = createAction('setConnectedToMailIntegrationEmails');
export const disconnectFromMailIntegrationRequest = createAction('disconnectFromMailIntegrationRequest');
export const disconnectFromMailIntegrationSuccess = createAction('disconnectFromMailIntegrationSuccess');
export const setConnectionToMailIntegrationRequired = createAction('setConnectionToMailIntegrationRequired');
// quote requests
export const sendQuoteRequests = createAction('sendQuoteRequests');
// tel integration documents
export const getTelIntegrationDocumentsRequest = createAction('getTelIntegrationDocumentsRequest');
// driver onboarding integration
export const getShowDriverOnboardingTabRequest = createAction('getShowDriverOnboardingTabRequest');
export const getShowDriverOnboardingTabSuccess = createAction('getShowDriverOnboardingTabSuccess');

// report
export const updateReportFromReportListSuccess = createAction('updateReportFromReportListSuccess');
// socket
export const setSocketConnection = createAction('setSocketConnection');
