import * as R from 'ramda';
import React from 'react';
import { compose, withState, withHandlers } from 'react-recompose';
// helpers
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// forms
import { Input, InputWrapper } from '../../forms/ui';
// ui
import { AbsoluteBox } from '../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('passwordShown', 'setPasswordShown', false),
  withHandlers({
    handleShowPassword: (props: Object) => () => {
      props.setPasswordShown(R.not(props.passwordShown));
    },
  }),
);

export const PasswordInput = enhance((props: Object) => {
  const { isMobile, passwordShown, handleShowPassword, ...restProps } = props;

  return (
    <InputWrapper width='100%'>
      <Input {...restProps} type={G.ifElse(G.isTrue(passwordShown), 'text', 'password')} />
      <AbsoluteBox
        top='50%'
        zIndex='3'
        cursor='pointer'
        transform='translateY(-50%)'
        onClick={handleShowPassword}
        right={G.ifElse(G.isTrue(isMobile), 30, 10)}
        width={G.ifElse(G.isTrue(isMobile), 40, 20)}
        height={G.ifElse(G.isTrue(isMobile), 40, 20)}
      >
        {G.ifElse(G.isTrue(passwordShown), I.eyeShow, I.eyeHide)()}
      </AbsoluteBox>
    </InputWrapper>
  );
});
