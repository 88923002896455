import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Fieldset2 } from '../../../../forms';
// ui
import { Box, Span, Flex } from '../../../../ui';
//////////////////////////////////////////////////

const validationSchema = Yup.lazy(({ summarySubgroup }: Object) => Yup.object().shape({
  showLastDays: G.yupNumberNotRequired
    .min(0, G.getShouldBeFromToLocaleTxt(0, 100))
    .max(100, G.getShouldBeFromToLocaleTxt(0, 100)),
  [GC.FIELD_SUMMARY_GROUP]: G.ifElse(
    G.isNotNilAndNotEmpty(summarySubgroup),
    G.yupStringRequired,
  ),
}));

const inputWrapperStyles = {
  mb: 25,
  width: 150,
};

const fieldSettings = [
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: 'showLastDays',
    label: ['titles:days-before'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    options: 'groupByOptions',
    fieldName: GC.FIELD_GROUP_BY,
    label: ['titles:organize-by'],
  },
  {
    type: 'select',
    options: 'summaryOptions',
    label: ['titles:summary-group'],
    fieldName: GC.FIELD_SUMMARY_GROUP,
    inputWrapperStyles: {
      mb: 15,
      width: 150,
    },
  },
  {
    isMulti: true,
    type: 'select',
    options: 'summaryOptions',
    label: ['titles:summary-sub-group'],
    fieldName: GC.FIELD_SUMMARY_SUBGROUP,
    inputWrapperStyles: {
      mb: 15,
      width: 150,
    },
  },
];

const ReportSummary = (props: Object) => {
  const { groupByOptions, setFormValues, summaryOptions, initialValues } = props;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  const { values } = formik;

  useEffect(() => {
    setFormValues(R.mergeLeft(values));
  }, [values]);

  return (
    <Box width='100%'>
      <Flex
        p='0 15px'
        height={40}
        borderBottom='1px solid'
        justifyContent='space-between'
        color={G.getTheme('colors.light.black')}
        bg={G.getTheme('colors.light.lightGrey')}
        borderColor={G.getTheme('colors.lightGrey')}
      >
        <Span>{G.getWindowLocale('titles:settings', 'Settings')}</Span>
      </Flex>
      <Fieldset2
        {...formik}
        fields={fieldSettings}
        groupByOptions={groupByOptions}
        summaryOptions={G.addEmptyOptionToDropDown(summaryOptions)}
        fieldsWrapperStyles={{ mt: 20, pr: 10, width: 325, justifyContent: 'space-between' }}
      />
    </Box>
  );
};

export default ReportSummary;
