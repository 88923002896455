import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
import { compose, lifecycle } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// utilities
import { sendRequestWithQSParamsSerializer } from '../../utilities/http';
//////////////////////////////////////////////////

class InitialData extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { data: null, error: false, loading: true };
    this.setLoading = this.setLoading.bind(this);
    this.getFileRequest = this.getFileRequest.bind(this);
  }

  setLoading() {
    this.setState(R.assoc('loading', true, this.state));
  }

  async getFileRequest(endpoint: string | null, requestOptions: Object | null) {
    const {
      showMsg,
      closeModal,
      openLoader,
      closeLoader,
      asyncMethod,
      asyncOptions,
      asyncEndpoint,
      asyncShouldCloseModal,
    } = this.props;

    if (G.isFunction(openLoader)) openLoader({ showDimmer: true});

    const options = R.or(requestOptions, asyncOptions);

    const res = await sendRequestWithQSParamsSerializer(
      R.or(asyncMethod, 'get'),
      R.or(endpoint, asyncEndpoint),
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNil(data)) {
        const dataToState = new window.Blob(
          R.of(Array, data),
          { type: R.pathOr('application/pdf', ['headers', 'content-type'], res) },
        );
        const newState = P.$all(
          P.$set('loading', false),
          P.$set('data', dataToState),
          this.state,
        );
        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        R.or(showMsg, false),
        'withAsyncFile -> getFileRequest',
      );
    }

    if (G.isFunction(closeLoader)) closeLoader();

    if (R.and(G.isTrue(asyncShouldCloseModal), G.isFunction(closeModal))) closeModal();
  }
  render() {
    return <div>{this.props.render(this.state, this.getFileRequest, this.setLoading)}</div>;
  }
}

export const withAsyncFile = (Component: any) => (
  class extends React.Component {
    render() {
      return (
        <InitialData
          showMsg={this.props.showMsg}
          openLoader={this.props.openLoader}
          closeModal={this.props.closeModal}
          closeLoader={this.props.closeLoader}
          asyncOptions={this.props.asyncOptions}
          asyncEndpoint={this.props.asyncEndpoint}
          asyncMethod={R.or(this.props.asyncMethod, 'get')}
          asyncShouldCloseModal={this.props.asyncShouldCloseModal}
          render={(parentState: Object, getFileRequest: Function, setLoading: Function) =>
            <Component
              {...this.props}
              setLoading={setLoading}
              asyncInitialData={parentState}
              getFileRequest={getFileRequest}
            />
          }
        />
      );
    }
  }
);

export const withAsyncFileOnDidMount = compose(
  withAsyncFile,
  lifecycle({
    componentDidMount() {
      this.props.getFileRequest();
    },
  }),
);
