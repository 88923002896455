import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const filterTypesWithMuiTitleInput = ['date', 'string', 'number', 'reference', 'selectMultiple'];

export const getRowBgColor = (issues: Array, maxIssueWarningLevelValue: number) => {
  if (G.isNilOrEmpty(issues)) return null;

  if (R.all(R.propEq(true, 'hidden'), issues)) return null;

  const colors = [
    'colors.light.blue',
    'colors.light.warningBackground',
    'colors.light.criticalBackground',
  ];

  return G.getTheme(R.path([maxIssueWarningLevelValue], colors));
};

export const isColumnResizable = ({
  fieldName,
  fieldProps,
  columnSettings,
  withResizableColumns,
}: Object) => {
  const type = R.path([fieldName, 'type'], columnSettings);
  const hasOptions = R.isNotNil(R.prop(GC.FIELD_OPTIONS, fieldProps));
  const fieldType = R.prop(GC.FIELD_TYPE, fieldProps);
  const isDateType = R.equals(fieldType, 'date');
  const notResizable = R.path([fieldName, 'notResizable'], columnSettings);

  return R.and(
    withResizableColumns,
    R.not(
      G.isAnyTrue(
        isDateType,
        hasOptions,
        notResizable,
        R.includes(type, [
          'enum',
          'date',
          'boolean',
          'freightClass',
          'collectionOfEnums',
        ]),
      ),
    ),
  );
};

export const getRelativeWidth = ({
  type,
  resizable,
  needCalcWidth,
}: Object) => (
  G.ifElse(
    G.isAnyTrue(
      resizable,
      needCalcWidth,
      R.includes(type, [
        'enum',
        'collectionOfEnums',
      ]),
    ),
    'calc(100% - 30px);',
    'unset',
  )
);

export const getWidth = (props: Object) => {
  const { fieldName, filterProps, columnSettings, useNewTitleFilterInputs } = props;

  const columnWidth = R.pathOr(220, [fieldName, 'width'], columnSettings);

  const filterType = R.pathOr('string', [fieldName, 'type'], filterProps);

  if (G.isAllTrue(
    G.isTrue(useNewTitleFilterInputs),
    R.includes(filterType, filterTypesWithMuiTitleInput),
    R.and(R.gt(columnWidth, 100), R.lte(columnWidth, 160)),
  )) {
    return 160;
  }

  return columnWidth;
};

export const setName = (field: Object, { columnSettings }: Object) => {
  const { name, reference } = field;

  const fieldName = R.path([name, GC.FIELD_NAME], columnSettings);

  if (G.isTrue(R.pathOr(false, [name, 'useCustomName'], columnSettings))) return fieldName;

  if (R.includes('Assessorial', name)) return `CHRG: ${G.getAssessorialName(name)}`;

  if (G.isTrue(reference)) return G.getReferenceTitleName(name);

  if (G.isArray(fieldName)) {
    const fieldDivider = R.pathOr(': ', [name, 'divider'], columnSettings);

    return R.compose(
      R.join(fieldDivider),
      R.map((name: string) => G.getWindowLocale(name, ' ')),
    )(fieldName);
  }

  return G.getWindowLocale(fieldName, ' ');
};

export const getIsSearchableField = (props: Object) => {
  const {
    reference,
    searchable,
    disableRefFilter = false,
  } = props;

  const isSearchableFalse = G.isFalse(searchable);

  if (R.and(disableRefFilter, reference)) {
    return false;
  }

  if (R.and(isSearchableFalse, reference)) {
    return true;
  }

  if (isSearchableFalse) {
    return false;
  }

  return true;
};
