import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const Progress = styled.p`
  margin: 4px 0 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Inner = styled.div`
  width: 100%;
  height: 10px;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('progressBar.bgColor')};

  &:after {
    content: '';
    height: 100%;
    width: ${({ progress }: Object) => `${progress}%`};
    background-color: ${({ progressColor }: Object) => progressColor || G.getTheme('progressBar.progressBgColor')};
  }
`;
