import * as R from 'ramda';
import React from 'react';
import { FieldArray } from 'formik';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// features
import PC from '../../../features/permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import withChargeComments from '../../../hocs/with-charge-comments';
// forms
import { Checkbox } from '../../../forms/ui';
// ui
import { Box, Flex, RelativeBox, AbsoluteBox } from '../../../ui';
// component charge
import * as H from '../helpers';
import * as S from '../settings';
import { withDMCharges } from '../hocs/with-dm-charges';
import { withDOCharges, withDOCharges2 } from '../hocs/with-do-charges';
// components charge
import { Input } from '../ui';
import {
  ChargeInfo,
  FieldSelect,
  ChargesArrayHeader,
  ChargesArrayFooter,
  AccessorialsSelect,
} from '../components';
import {
  handleChangeCharge,
  setChargeQtyAndTotal,
  recalculateAllCharges,
  handleChangeChargeSelect,
} from './helpers';
//////////////////////////////////////////////////

const getShouldShowAdjustments = (charge: Object, cloRateAdjustments: any) => {
  const isMainCharge = G.isChargeTypeMain(charge);
  const rateType = G.getRateTypeFromCharge(charge);
  const rateTypeToShow = G.isChargeRateTypeCustomerRateOrLineHaul(rateType);

  return G.isAllTrue(
    G.isNotNilAndNotEmpty(cloRateAdjustments),
    isMainCharge,
    rateTypeToShow,
  );
};

const relativeBoxStyles = {
  mr: 10,
  flexGrow: 1,
};

const absoluteBoxStyles = {
  left: '5px',
  top: '-13px',
  fontSize: '11px',
  color: G.getTheme('colors.darkGrey'),
};

const absoluteBoxStyles2 = {
  left: '1px',
  top: '-19px',
  fontSize: '11px',
  color: G.getTheme('colors.darkGrey'),
};

const ChargeFields = (props: Object) => {
  const {
    charge,
    isVendor,
    loadType,
    isInvoice,
    authorities,
    glCodeOptions,
    handleChargeHold,
    handleChangeCharge,
  } = props;

  const { type, rate, glCode, quantity, currency, rateType, rateUnit, holdQuantity } = charge;

  const glCodeToUse = R.pathOr(glCode, [GC.FIELD_DROPDOWN_OPTION_GUID], glCode);

  const showCurrency = G.getAmousConfigByNameFromWindow(GC.RATE_ENGINE_MULTIPLE_CURRENCY_CHARGES);

  const rateTypeOptions = R.pathOr([], [type, loadType], S.rateTypeOptions);

  const rateTypeOptionsToUse = G.ifElse(
    G.isAllTrue(isVendor, G.isChargeTypeAdditional(type), G.isLoadTypeTel(loadType)),
    S.dmRateTypeVendorAdditionalOptions,
    rateTypeOptions,
  );

  return (
    <Flex flexGrow={1} justifyContent='space-between'>
      <RelativeBox {...relativeBoxStyles} flexBasis={100}>
        <AbsoluteBox {...absoluteBoxStyles}>
          {G.getWindowLocale('titles:rate', 'Rate')}
        </AbsoluteBox>
        <Input
          type='text'
          width='100%'
          value={R.or(rate, '')}
          name={GC.FIELD_CHARGE_RATE}
          onChange={(event: Object) => handleChangeCharge(event, charge)}
        />
      </RelativeBox>
      <RelativeBox {...relativeBoxStyles} flexBasis={110} flexShrink={0}>
        <AbsoluteBox {...absoluteBoxStyles}>
          {G.getWindowLocale('titles:type', 'Type')}
        </AbsoluteBox>
        <FieldSelect
          width='100%'
          value={R.or(rateType, '')}
          options={rateTypeOptionsToUse}
          name={GC.FIELD_CHARGE_RATE_TYPE}
          onChangeSelect={(event: Object) => handleChangeCharge(event, charge)}
        />
      </RelativeBox>
      <RelativeBox {...relativeBoxStyles} flexBasis={110} flexShrink={0}>
        <AbsoluteBox {...absoluteBoxStyles}>
          {G.getWindowLocale('titles:unit', 'Unit')}
        </AbsoluteBox>
        <FieldSelect
          width='100%'
          value={R.or(rateUnit, '')}
          name={GC.FIELD_CHARGE_RATE_UNIT}
          options={R.pathOr([], [rateType], S.rateUnitOptionsGroup)}
          onChangeSelect={(event: Object) => handleChangeCharge(event, charge)}
        />
      </RelativeBox>
      <RelativeBox {...relativeBoxStyles} flexBasis={100}>
        <AbsoluteBox {...absoluteBoxStyles}>
          {G.getWindowLocale('titles:qty', 'Qty')}
        </AbsoluteBox>
        <Input
          type='text'
          width='100%'
          value={R.or(quantity, '')}
          name={GC.FIELD_CHARGE_QUANTITY}
          disabled={R.includes(rateType, S.rateTypesWithoutQuantity)}
          onChange={(event: Object) => handleChangeCharge(event, charge)}
        />
      </RelativeBox>
      {
        R.not(G.isChargeRateTypeLineHaulOrCustomerLineHaul(rateType)) &&
        <RelativeBox mr={15} flexGrow={1}>
          <AbsoluteBox {...absoluteBoxStyles2}>
            {G.getWindowLocale('titles:hold', 'Hold')}
          </AbsoluteBox>
          <Checkbox
            type='checkbox'
            checked={holdQuantity}
            borderColor='colors.dark.grey'
            name={GC.FIELD_CHARGE_HOLD_QUANTITY}
            onChange={() => handleChargeHold(charge)}
          />
        </RelativeBox>
        }
      {
        isInvoice &&
        <RelativeBox {...relativeBoxStyles}>
          <AbsoluteBox {...absoluteBoxStyles}>
            {G.getWindowLocale('titles:gl', 'GL')}
          </AbsoluteBox>
          <FieldSelect
            width='100%'
            name={GC.FIELD_GL_CODE}
            value={R.or(glCodeToUse, '')}
            options={R.or(glCodeOptions, [])}
            disabled={G.notContain(PC.GL_CODE_WRITE, authorities)}
            onChangeSelect={(event: Object) => handleChangeCharge(event, charge)}
          />
        </RelativeBox>
      }
      {
        showCurrency &&
        <RelativeBox {...relativeBoxStyles} flexBasis={80} flexShrink={0}>
          <AbsoluteBox {...absoluteBoxStyles}>
            {G.getWindowLocale('titles:currency', 'Currency')}
          </AbsoluteBox>
          <FieldSelect
            width='100%'
            value={R.or(currency, '')}
            options={GC.CURRENCY_OPTIONS_2}
            name={GC.FIELD_CHARGE_CURRENCY}
            onChangeSelect={(event: Object) => handleChangeCharge(event, charge)}
          />
        </RelativeBox>
      }
    </Flex>
  );
};

const ChargeItem = (props: Object) => {
  const { charge, chargeType, accessorialOptions, handleChangeCharge, handleChangeChargeSelect } = props;

  return (
    <Box mt={20}>
      <Flex>
        <RelativeBox mx={10}>
          <AbsoluteBox {...absoluteBoxStyles}>
            {G.getWindowLocale('titles:charge-description', 'Charge Description')}
          </AbsoluteBox>
          {
            R.or(G.isChargeTypeMain(chargeType), G.isChargeTypeDiscount(chargeType)) &&
            <Input
              type='text'
              width={200}
              name={GC.FIELD_CHARGE_RATE_NAME}
              value={G.getDisplayedValueFromObject(charge)}
              onChange={(event: Object) => handleChangeCharge(event, charge)}
            />
          }
          {
            R.not(R.or(G.isChargeTypeMain(chargeType), G.isChargeTypeDiscount(chargeType))) &&
            <AccessorialsSelect
              width={200}
              options={accessorialOptions}
              name={G.getIdOrGuidFromObject(charge)}
              value={G.getDisplayedValueFromObject(charge)}
              onChangeSelect={(event: Object) => handleChangeChargeSelect(event, charge)}
            />
          }
        </RelativeBox>
        <ChargeFields {...props} />
      </Flex>
      <ChargeInfo {...props} />
    </Box>
  );
};

const getChargeIndexAndCurrentCharges = (props: Object, charge: Object) => {
  const { rateProps, chargesArrayName } = props;

  const { values } = rateProps;

  const currentCharges = R.pathOr([], [chargesArrayName], values);
  const chargeIdOrGuid = G.getIdOrGuidFromObject(charge);

  const index = R.findIndex(
    (item: Object) => R.or(
      R.propEq(chargeIdOrGuid, GC.FIELD_ID, item),
      R.propEq(chargeIdOrGuid, GC.FIELD_GUID, item),
    ),
    currentCharges,
  );

  return { index, currentCharges };
};

const enhance = compose(
  withState('lineHaulExpanded', 'setLineHaulExpanded', true),
  withState('discountExpanded', 'setDiscountExpanded', true),
  withState('fuelChargesExpanded', 'setFuelChargesExpanded', true),
  withState('additionalChargesExpanded', 'setAdditionalChargesExpanded', true),
  withHandlers({
    handleAddCharge: (props: Object) => ({ chargeType, arrayHelpers }: Object) => {
      const { rateProps } = props;

      const { accessorials } = rateProps;
      const { push } = arrayHelpers;

      const currency = R.path(['values', GC.FIELD_CURRENCY], rateProps);

      if (G.isChargeTypeMain(chargeType)) {
        const newCharge = S.createMainCharge();
        const glCode = R.pathOr(null, ['glCodeMappings', currency, GC.INVOICE_MAPPING_TYPE_LINE_HAUL], rateProps);

        return push(R.assoc(GC.FIELD_GL_CODE, glCode, newCharge));
      }

      if (G.isChargeTypeDiscount(chargeType)) {
        const newCharge = S.createDiscountCharge();
        const glCode = R.pathOr(null, ['glCodeMappings', currency, GC.INVOICE_MAPPING_TYPE_DISCOUNT], rateProps);

        return push(R.assoc(GC.FIELD_GL_CODE, glCode, newCharge));
      }

      if (G.isChargeTypeFuel(chargeType)) {
        const newCharge = S.createFuelCharge();
        const glCode = R.pathOr(null, ['glCodeMappings', currency, GC.INVOICE_MAPPING_TYPE_FUEL], rateProps);

        return push(R.assoc(GC.FIELD_GL_CODE, glCode, newCharge));
      }

      const firstAccessorial = R.omit(
        GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR,
        H.getFirstAccessorial(
          H.noFuelAccessorialFilter,
          R.values(accessorials),
        ),
      );

      if (G.isNotNilAndNotEmpty(firstAccessorial)) {
        const assessorialConfigGuid = R.pathOr(null, [GC.FIELD_ORIGINAL_CONFIG_GUID], firstAccessorial);
        const sharedAssessorialGuid = R.pathOr(null, [GC.FIELD_SHARED_ASSESSORIAL_GUID], firstAccessorial);
        const glCode = R.pathOr(
          null,
          [
            'glCodeMappings',
            currency,
            GC.INVOICE_MAPPING_TYPE_ASSESSORIALS,
            assessorialConfigGuid,
          ],
          rateProps,
        );
        const charge = R.compose(
          R.mergeRight({ glCode, sharedAssessorialGuid, assessorialConfigGuid }),
          R.mergeDeepRight(S.createAdditionCharge()),
          R.pick(S.accessorialFieldsToPick),
        )(firstAccessorial);

        const newCharge = setChargeQtyAndTotal(charge, props);

        push(newCharge);
      }
    },
    handleRemoveCharge: (props: Object) => (charge: Object) => {
      const { rateProps, chargesArrayName } = props;

      const { setFieldValue } = rateProps;
      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const updatedItems = R.remove(index, 1, currentCharges);
      const newCharges = recalculateAllCharges(updatedItems, props);

      setFieldValue(chargesArrayName, newCharges);
    },
    handleChangeCharge: (props: Object) => (event: Object, charge: Object) => {
      const { rateProps, chargesArrayName} = props;

      const { setFieldValue } = rateProps;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const newCharge = handleChangeCharge(event, charge, props);

      const updatedItems = R.update(index, newCharge, currentCharges);

      const newCharges = recalculateAllCharges(
        updatedItems,
        props,
        { omitRecalcChargeId: G.getIdOrGuidFromObject(newCharge) },
      );

      setFieldValue(chargesArrayName, newCharges);
    },
    handleChangeChargeSelect: (props: Object) => (event: Object, charge: Object) => {
      const { rateProps, chargesArrayName } = props;

      const { setFieldValue } = rateProps;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const newCharge = handleChangeChargeSelect(event, charge, props);

      const updatedItems = R.update(index, newCharge, currentCharges);

      const newCharges = recalculateAllCharges(
        updatedItems,
        props,
        { omitRecalcChargeId: G.getIdOrGuidFromObject(newCharge) },
      );

      setFieldValue(chargesArrayName, newCharges);
    },
    handleAddChargeComment: (props: Object) => (charge: Object) => {
      const { rateProps, chargesArrayName} = props;

      const { setFieldValue } = rateProps;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const updatedItems = R.update(index, charge, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
    handleChargeHold: (props: Object) => (charge: Object) => {
      const { rateProps, chargesArrayName} = props;

      const { setFieldValue } = rateProps;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const newCharge = R.assoc(GC.FIELD_CHARGE_HOLD_QUANTITY, R.not(G.getHoldQuantityFromCharge(charge)), charge);

      const updatedItems = R.update(index, newCharge, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
  }),
  withChargeComments('simple'),
  pure,
);

// TODO: it better to refactor with using only isInvoice and isVendor and omit isVendorInvoice
const commonChargeItemPropsArray = [
  'currency',
  'loadType',
  'isInvoice',
  'activeTab',
  'authorities',
  'glCodeOptions',
  'glCodeMappings',
  'isVendorInvoice',
  'handleChargeHold',
  'glCodeMappingList',
  'handleRemoveCharge',
  'handleChangeCharge',
  'handleAddChargeComment',
  'handleOpenChargeComment',
  'handleChangeChargeSelect',
];

const ChargesArray = enhance((props: Object) => {
  const {
    isVendor,
    currency,
    rateProps,
    isCarrier,
    isInvoice,
    isCustomer,
    isDriverRate,
    isVendorRate,
    isCarrierRate,
    handleAddCharge,
    customerLineHaul,
    chargesArrayName,
    lineHaulExpanded,
    discountExpanded,
    totalCustomersRate,
    cloRateAdjustments,
    setLineHaulExpanded,
    setDiscountExpanded,
    fuelChargesExpanded,
    setFuelChargesExpanded,
    additionalChargesExpanded,
    setAdditionalChargesExpanded,
    handleOpenLinehaulAdjustments,
  } = props;

  const { fuelSelectOptions, noFuelSelectOptions } = rateProps;

  const commonChargeItemProps = R.pick(commonChargeItemPropsArray, props);

  const charges = R.pathOr([], ['values', chargesArrayName], rateProps);

  let chargesToUse = charges;

  if (isInvoice) chargesToUse = R.reject(R.propEq(true, GC.FIELD_CHARGE_INTERNAL_EXPENSE), charges);

  const { mainCharges, fuelCharges, discountCharges, additionalCharges } = G.getFilteredByTypeCharges(chargesToUse);

  return (
    <Box mt={20}>
      <FieldArray
        name={chargesArrayName}
        render={(arrayHelpers: Object) => (
          <Box>
            <ChargesArrayHeader
              expanded={lineHaulExpanded}
              arrayHelpers={arrayHelpers}
              chargeType={GC.CHARGE_TYPE_MAIN}
              handleAddCharge={handleAddCharge}
              setExpanded={setLineHaulExpanded}
              title={G.getWindowLocale('titles:linehaul', 'Linehaul')}
            />
            <Box>
              {
                R.and(lineHaulExpanded, G.isNotNilAndNotEmpty(mainCharges)) &&
                mainCharges.map((item: string, i: number) => (
                  <ChargeItem
                    {...arrayHelpers}
                    {...commonChargeItemProps}
                    charge={item}
                    itemIndex={i}
                    key={item.id}
                    chargeType={GC.CHARGE_TYPE_MAIN}
                    customerLineHaul={customerLineHaul}
                    chargesCount={R.length(mainCharges)}
                    totalCustomersRate={totalCustomersRate}
                    handleOpenLinehaulAdjustments={handleOpenLinehaulAdjustments}
                    showAdjustments={getShouldShowAdjustments(item, cloRateAdjustments)}
                  />
                ))
              }
            </Box>
            <ChargesArrayFooter currency={currency} charges={mainCharges} />
          </Box>
        )}
      />
      {
        R.or(isCarrier, isCustomer) &&
        <FieldArray
          name={chargesArrayName}
          render={(arrayHelpers: Object) => (
            <Box>
              <ChargesArrayHeader
                arrayHelpers={arrayHelpers}
                expanded={discountExpanded}
                handleAddCharge={handleAddCharge}
                setExpanded={setDiscountExpanded}
                chargeType={GC.CHARGE_TYPE_DISCOUNT}
                title={G.getWindowLocale('titles:discount', 'Discount')}
              />
              <Box>
                {
                  R.and(discountExpanded, G.isNotNilAndNotEmpty(discountCharges)) &&
                  discountCharges.map((item: string, i: number) => (
                    <ChargeItem
                      {...arrayHelpers}
                      {...commonChargeItemProps}
                      charge={item}
                      itemIndex={i}
                      key={item.id}
                      customerLineHaul={customerLineHaul}
                      chargeType={GC.CHARGE_TYPE_DISCOUNT}
                      totalCustomersRate={totalCustomersRate}
                      chargesCount={R.length(discountCharges)}
                      handleOpenLinehaulAdjustments={handleOpenLinehaulAdjustments}
                      showAdjustments={getShouldShowAdjustments(item, cloRateAdjustments)}
                    />
                  ))
                }
              </Box>
              <ChargesArrayFooter currency={currency} charges={discountCharges} />
            </Box>
          )}
        />
      }
      <FieldArray
        name={chargesArrayName}
        render={(arrayHelpers: Object) => (
          <Box>
            {
              G.isNotNilAndNotEmpty(fuelSelectOptions) &&
              <ChargesArrayHeader
                arrayHelpers={arrayHelpers}
                expanded={fuelChargesExpanded}
                chargeType={GC.CHARGE_TYPE_FUEL}
                handleAddCharge={handleAddCharge}
                setExpanded={setFuelChargesExpanded}
                title={G.getWindowLocale('titles:fuel-charges', 'Fuel Charges')}
              />
            }
            <Box>
              {
                R.and(fuelChargesExpanded, G.isNotNilAndNotEmpty(fuelCharges)) &&
                fuelCharges.map((item: string, i: number) => (
                  <ChargeItem
                    {...arrayHelpers}
                    {...commonChargeItemProps}
                    key={item.id}
                    charge={item}
                    itemIndex={i}
                    chargeType={GC.CHARGE_TYPE_FUEL}
                    chargesCount={R.length(fuelCharges)}
                    accessorialOptions={fuelSelectOptions}
                  />
                ))
              }
            </Box>
            <ChargesArrayFooter currency={currency} charges={fuelCharges} />
          </Box>
        )}
      />
      <FieldArray
        name={chargesArrayName}
        render={(arrayHelpers: Object) => (
          <Box>
            {
              G.isNotNilAndNotEmpty(noFuelSelectOptions) &&
              <ChargesArrayHeader
                arrayHelpers={arrayHelpers}
                handleAddCharge={handleAddCharge}
                expanded={additionalChargesExpanded}
                chargeType={GC.CHARGE_TYPE_ADDITIONAL}
                setExpanded={setAdditionalChargesExpanded}
                title={G.getWindowLocale('titles:additional-charges', 'Additional Charges')}
              />
            }
            <Box>
              {
                R.and(additionalChargesExpanded, G.isNotNilAndNotEmpty(additionalCharges)) &&
                additionalCharges.map((item: string, i: number) => (
                  <ChargeItem
                    {...arrayHelpers}
                    {...commonChargeItemProps}
                    key={item.id}
                    charge={item}
                    itemIndex={i}
                    isVendor={isVendor}
                    chargeType={GC.CHARGE_TYPE_ADDITIONAL}
                    accessorialOptions={noFuelSelectOptions}
                    chargesCount={R.length(additionalCharges)}
                  />
                ))
              }
            </Box>
            <ChargesArrayFooter
              currency={currency}
              isAdditionalCharges={true}
              charges={additionalCharges}
              isDriverRate={isDriverRate}
              isVendorRate={isVendorRate}
              isCarrierRate={isCarrierRate}
            />
          </Box>
        )}
      />
    </Box>
  );
});

export const DMChargeComponent = withDMCharges(ChargesArray);

export const DOChargeComponent = withDOCharges(ChargesArray);

export const DOChargeComponent2 = withDOCharges2(ChargesArray);

export default ChargesArray;
