import styled from 'styled-components';
// ui
import { Box, Flex } from '../../ui';
// helpers
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const SliderWrap = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  width: ${({ width }: Object) => width || '63%'};
  max-width: ${({ maxWidth }: Object) => maxWidth};
  background-color: ${({ bgColor }: Object) => bgColor};
  min-width: ${({ minWidth }: Object) => minWidth || '500px'};
`;

export const BottomWrap = styled.div`
  left: 50%;
  width: 80%;
  height: 8px;
  bottom: 4vh;
  z-index: 13;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  min-width: ${({ minWidth }: Object) => minWidth || '300px'};
  & > div {
    width: 30%;
    height: 4px;
    margin: 0 5px;
    cursor: pointer;
    min-width: 17px;
    max-width: 30px;
    border-radius: 2px;
  }
`;

export const Bottom = styled.div`
  width: 30%;
  height: 4px;
  margin: 0 5px;
  cursor: pointer;
  min-width: 17px;
  max-width: 30px;
  border-radius: 2px;
  background-color: ${({ active }: Object) => G.ifElse(
    active,
    G.getTheme('colors.white'),
    G.getTheme('colors.dark.grey'))
  };
`;

export const SlideWrap = styled(Flex)`
  top: 0;
  left: 0;
  position: absolute;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
  background-position: center center;
  width: ${({ size }: Object) => size};
  height: ${({ size }: Object) => size};
  background-color: ${() => G.getTheme('colors.white')};
  background-size: ${({ bs }: Object) => bs || '100% 100%'};
  background-image: url(${({ bgImage }: Object) => bgImage});
  z-index: ${({ active }: Object) => G.ifElse(
    active,
    11,
    -1)
  };
  opacity: ${({ active }: Object) => G.ifElse(
    active,
    1,
    0)
  };
  & > .shadow {
    top: 0;
    left: 0;
    position: absolute;
    width: ${({ size }: Object) => size};
    height: ${({ size }: Object) => size};
  }
`;

export const ContentWrap = styled(Box)`
  font-size: 95px;
  line-height: 1.2;
  word-break: break-word;
  color: ${({ color }: Object) => color || G.getTheme('colors.black')};
  & > h1 {
    font-size: 95px;
    font-weight: 400;
    font-family: Urbanist;
    letter-spacing: normal;
  }
  & > p {
    font-size: 30px;
    font-weight: 400;
    font-family: Urbanist;
    letter-spacing: normal;
  }
`;
