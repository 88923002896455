import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { TextCollapse } from '../text-collapse';
// features
import { AuthWrapper } from '../../features/permission';
// forms
import { Error } from '../../forms/formik/fieldset2/ui';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, RelativeBox, MainActionButton } from '../../ui';
//////////////////////////////////////////////////

const Textarea = styled.textarea`
  width: 100%;
  height: 60px;
  border: none;
  resize: none;
  cursor: text;
  outline: none;
`;

const enhance = compose(
  withState('error', 'setError', null),
  withState('message', 'setMessage', undefined),
  withState('scrollDown', 'setScrollDown', true),
  withState('validation', 'setValidation', false),
  withHandlers(() => {
    const refs = {};
    return {
      handleChangeMessage: (props: Object) => (event: Event) => {
        const message = R.path(['currentTarget', GC.FIELD_VALUE], event);

        const { setError, setMessage, setValidation } = props;

        if (R.lt(1000, R.length(message))) {
          setValidation(true);
          setError(G.getShouldBeLessOrEqualLocaleTxt(1000));
        } else {
          setValidation(false);
          setError(null);
        }

        setMessage(message);
      },
      handleAddMessage: (props: Object) => () => {
        const {
          validation,
          setMessage,
          submitAction,
          setScrollDown,
        } = props;

        const message = R.pathOr('', [GC.FIELD_MESSAGE], props);

        if (G.isNotNilAndNotEmpty(R.trim(message))) {
          if (validation) return;

          submitAction({ message });
          setMessage('');
          setScrollDown(true);
        }
      },
      handleScrollWrapper: () => () => {
        const height = R.path(['scrollWrapper', 'scrollHeight'], refs);

        if (G.isNotNilAndNotEmpty(height)) {
          setTimeout(() => {
            if (G.isNotNil(refs.scrollWrapper)) refs.scrollWrapper.scrollTop = height;
          }, 0);
        }
      },
      handleSetRefs: ({ scrollDown, setScrollDown }: Object) => (ref: Object, name: string) => {
        refs[name] = ref;
        const condition = R.and(
          G.isTrue(scrollDown),
          G.isNotNil(refs.scrollWrapper),
        );

        if (condition) {
          refs.scrollWrapper.scrollTop = refs.scrollWrapper.scrollHeight;
          const scrolled = R.or(
            G.isZero(refs.scrollWrapper.scrollHeight, 0),
            G.isNotZero(refs.scrollWrapper.scrollTop, 0),
          );

          if (scrolled) setScrollDown(false);
        }
      },
    };
  }),
  withComponentDidUpdatePropCallback({
    propName: 'entities',
    callbackName: 'handleScrollWrapper',
  }),
  pure,
);

export const MessageItem = ({
  index,
  message,
  editPermissions,
  isActiveTabNotes,
  handlePinMessage,
  handleClickEditIcon,
}: Object) => {
  const { text, pinned, createdBy, createdDate, authorUserGuid } = message;

  const marginTop = G.ifElse(G.isZero(index), 0, '8px');
  const getUserInfo = R.compose(
    R.filter(G.isNotNilAndNotEmpty),
    R.values,
    R.pickAll(['authorLastName', 'authorFirstName']),
  )(message);
  let title = createdBy;
  let initials = R.take(1, createdBy);
  let pinnedStyles = {};

  if (G.isNotNilAndNotEmpty(getUserInfo)) {
    initials = R.map(R.take(1), getUserInfo);
    title = R.concat(R.join(' ', getUserInfo), ` (${createdBy})`);
  }

  if (pinned) {
    pinnedStyles = {
      top: 0,
      mb: '8px',
      zIndex: 11,
      position: 'sticky',
      bg: G.getTheme('colors.light.lightBlue'),
    };
  }

  const dateColor = G.getTheme(G.ifElse(
    pinned,
    'colors.light.mainDark',
    'colors.dark.grey',
  ));
  const pinIconColor = G.getThemeByCond(
    pinned,
    'colors.light.blue',
    'colors.dark.grey',
  );
  let editAllowed = true;

  if (G.isNotNil(authorUserGuid)) {
    editAllowed = G.isCurrentUserMessage(message);
  }

  return (
    <Box
      p='8px'
      fontSize={12}
      mt={marginTop}
      borderRadius='2px'
      bg={G.getTheme('colors.dark.mainLight')}
      color={G.getTheme('colors.greyMatterhorn')}
      {...pinnedStyles}
    >
      <Flex>
        <Flex
          width={30}
          height={30}
          title={title}
          border='1px solid'
          borderRadius='50%'
          justifyContent='center'
          textTransform='uppercase'
          borderColor={G.getTheme('colors.light.grey')}
        >
          {initials}
        </Flex>
        <Box ml={10} width='calc(100% - 60px)'>
          <Box maxWidth='100%' wordBreak='break-all'>
            {title}
          </Box>
          <Box mt='3px' color={dateColor}>
            {G.convertDateTimeToConfigFormat(createdDate)}
          </Box>
        </Box>
        {
          R.and(isActiveTabNotes, G.isFunction(handlePinMessage)) &&
          <AuthWrapper zIndex={10} has={editPermissions}>
            <Box
              ml='8px'
              cursor='pointer'
              onClick={() => handlePinMessage(message)}
            >
              {I.pinNew(pinIconColor)}
            </Box>
          </AuthWrapper>
        }
        {
          R.and(G.isFunction(handleClickEditIcon), editAllowed) &&
          <AuthWrapper zIndex={10} has={editPermissions}>
            <Box
              ml={10}
              cursor='pointer'
              onClick={(event: Object) => handleClickEditIcon(event, message)}
            >
              {I.threeDots()}
            </Box>
          </AuthWrapper>
        }
      </Flex>
      {
        pinned ? (
          <TextCollapse
            text={text}
            lessHeight={80}
            rootProps={{ maxHeight: 400, overflow: 'auto' }}
          />
        ) : (
          <Box mt={10} wordBreak='break-word'>
            {text}
          </Box>
        )
      }
    </Box>
  );
};

const MessageCenter = (props: Object) => {
  const {
    error,
    width,
    height,
    padding,
    message,
    entities,
    validation,
    placeholder,
    handleSetRefs,
    addPermissions,
    editPermissions,
    handleAddMessage,
    handlePinMessage,
    additionalStyles,
    isActiveTabNotes,
    handleClickEditIcon,
    handleChangeMessage,
  } = props;

  const sortedEntities = R.sort(R.descend(R.prop('pinned')), entities);

  const handleSetRef = (ref: Object) => handleSetRefs(ref, 'scrollWrapper');

  const borderColor = G.ifElse(
    validation,
    G.getTheme('forms.inputs.borderColorErr'),
    G.getTheme('colors.dark.grey'),
  );

  const handleKeyDown = (event: Object) => {
    if (R.equals(GC.EVENT_KEY_CODE_ENTER, event.keyCode)) {
      event.preventDefault();
      G.callFunction(handleAddMessage);
    }
  };

  return (
    <RelativeBox
      zIndex='0'
      p={padding}
      width={R.or(width, 350)}
      height={R.or(height, 550)}
    >
      <Box
        width='100%'
        overflowY='auto'
        ref={handleSetRef}
        css={additionalStyles}
        height='calc(100% - 115px)'
      >
        {
          R.isEmpty(entities) &&
          <Flex
            p='8px'
            height='100%'
            fontSize={30}
            borderRadius='2px'
            justifyContent='center'
            color={G.getTheme('colors.dark.grey')}
          >
            {G.getWindowLocale('titles:empty-list', 'Empty List')}
          </Flex>
        }
        {
          sortedEntities.map((entity: Object, index: number) => (
            <MessageItem
              key={index}
              index={index}
              message={entity}
              editPermissions={editPermissions}
              handlePinMessage={handlePinMessage}
              isActiveTabNotes={isActiveTabNotes}
              handleClickEditIcon={handleClickEditIcon}
            />
          ))
        }
      </Box>
      <AuthWrapper has={addPermissions}>
        <Flex
          p='8px'
          mt={10}
          width='100%'
          border='1px solid'
          borderRadius='4px'
          alignItems='flex-end'
          flexDirection='column'
          borderColor={borderColor}
        >
          <Textarea
            value={message}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onChange={handleChangeMessage}
          />
          {
            validation &&
            <Error left='10px' title={error} top='calc(100% - 8px)'>{error}</Error>
          }
          <MainActionButton
            height={25}
            textTransform='uppercase'
            onClick={handleAddMessage}
          >
            {R.pathOr(G.getWindowLocale('actions:send', 'Send'), ['addMessageText'], props)}
          </MainActionButton>
        </Flex>
      </AuthWrapper>
    </RelativeBox>
  );
};

export default enhance(MessageCenter);
