import * as R from 'ramda';
import { pure, compose } from 'react-recompose';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../../hocs';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const enhance = compose(
  withAsyncInitialDataOnDidMount,
  pure,
);

const AsyncComponent = (props: Object) => {
  const { data } = props.asyncInitialData;
  if (G.isNilOrEmpty(data)) return null;
  return props.renderComponent(R.mergeRight(props, { data }));
};

export default enhance(AsyncComponent);
