import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Span } from '../../ui';
// components
import { TextComponent } from '../text';
//////////////////////////////////////////////////

export const SearchMatch = (props: Object) => {
  const { value, color, bgColor, maxWidth, searchText } = props;
  if (G.isNilOrEmpty(searchText)) {
    return (
      <TextComponent
        title={value}
        display='block'
        withEllipsis={true}
        maxWidth={R.or(maxWidth, 300)}
      >
        {value}
      </TextComponent>
    );
  }
  const themeBg = G.getTheme('colors.light.mainRed');
  const themeColor = G.getTheme('colors.light.mainLight');
  const expression = new RegExp(searchText, 'gi');
  const matches = [];
  const parts = value.split(expression);
  value.replace(expression, (str: string) => matches.push(str));
  return (
    <TextComponent
      title={value}
      display='block'
      withEllipsis={true}
      maxWidth={R.or(maxWidth, 300)}
    >
      {
        parts.map((part: string, i: number) => (
          <Span key={`search-match-${part}-${i}`}>
            {part}
            <Span
              bg={R.or(bgColor, themeBg)}
              color={R.or(color, themeColor)}
            >
              {matches[i]}
            </Span>
          </Span>
        ))
      }
    </TextComponent>
  );
};
