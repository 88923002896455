import React from 'react';
import * as R from 'ramda';
// components
import { ConfirmComponent } from '../confirm';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const renderConfirmationModal = ({ action, openModal, closeModal }: Object) => {
  const component = (
    <ConfirmComponent
      textLocale={G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?')}
    />
  );

  const modal = {
    component,
    options: {
      width: 400,
      controlButtons: [
        {
          type: 'button',
          name: G.getWindowLocale('actions:confirm', 'Confirm'),
          action: () => {
            closeModal();
            G.callFunction(action);
          },
        },
      ],
    },
  };

  openModal(modal);
};

const getOperations = (guid: string, isArray: boolean) => ({
  get: (prev: Object, data: Object) => data,
  post: (prev: Object, data: Object) => G.ifElse(isArray, R.concat, R.prepend)(data, prev),
  put: (prev: Object, data: Object) => {
    const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), prev);

    return R.assoc(index, data, prev);
  },
  delete: (prev: Object) => {
    const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), prev);

    return R.remove(index, 1, prev);
  },
});

export {
  getOperations,
  renderConfirmationModal,
};
