import React from 'react';
import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { SelectCarriers } from '../select-carriers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import {
  withAsyncGetDriverListFullName,
  withAsyncGetUnitIdListByEntityType,
  withAsyncGetBranchChildrenWithShared,
} from '../../hocs';
// ui
import { Flex, CustomButton } from '../../ui';
// component additional filters
import TypeSelect from './type-select';
import FilterValue from './filter-value';
import { getFilterTypeOptions } from './settings';
//////////////////////////////////////////////////

const AdditionalFilters = (props: Object) => {
  const {
    handleClickApply,
    additionalFilterType,
    additionalFilterValue,
    setAdditionalFilterValue,
  } = props;

  const typeValue = G.getValueFromObject(additionalFilterType);

  return (
    <Flex
      pb={10}
      ml={50}
      height={36}
      data-testid='testid-AdditionalFilters'
    >
      <TypeSelect {...props} typeValue={typeValue} />
      {
        G.notEquals(typeValue, GC.ADDITIONAL_FILTER_TYPE_CARRIER) &&
        <FilterValue {...props} typeValue={typeValue} />
      }
      {
        R.equals(typeValue, GC.ADDITIONAL_FILTER_TYPE_CARRIER) &&
        <SelectCarriers
          mr={10}
          width={250}
          isMulti={true}
          isClearable={true}
          useMaxHeight={true}
          valueContainerMaxHeight={34}
          value={additionalFilterValue}
          useAdditionalBorderColor={true}
          fieldName={GC.ADDITIONAL_FILTER_TYPE_CARRIER}
          placeholder={G.getWindowLocale('titles:filter-value', 'Filter Value')}
          setFieldValue={(_: any, value: Object) => setAdditionalFilterValue(value)}
        />
      }
      <CustomButton
        px={10}
        height={34}
        type='button'
        cursor='pointer'
        onClick={handleClickApply}
        bgColor={G.getTheme('colors.dark.blue')}
        disabled={G.isNilOrEmpty(additionalFilterValue)}
      >
        {G.getWindowLocale('actions:apply', 'Apply')}
      </CustomButton>
    </Flex>
  );
};

const enhance = compose(
  withAsyncGetDriverListFullName,
  withAsyncGetUnitIdListByEntityType,
  withAsyncGetBranchChildrenWithShared,
  withState(
    'additionalFilterType',
    'setAdditionalFilterType',
    ({ additionalFilters, additionalFilterOptions }: Object) => {
      const value = G.getPropFromObject('additionalFilterType', additionalFilters);

      if (G.isNilOrEmpty(value)) return null;

      const label = R.compose(
        R.path([0, GC.FIELD_LABEL]),
        R.filter(R.propEq(value, GC.FIELD_VALUE)),
      )(R.or(additionalFilterOptions, getFilterTypeOptions()));

      return { value, label };
    },
  ),
  withState('additionalFilterValue', 'setAdditionalFilterValue', ({ additionalFilters }: Object) =>
    R.pathOr(null, ['additionalFilterValue'], additionalFilters)),
  withHandlers({
    handleClickApply: (props: Object) => () => {
      const { additionalFilterType, additionalFilterValue, additionalFiltersApplyHandler } = props;

      const filterType = G.getValueFromObject(additionalFilterType);

      let filterValue = additionalFilterValue;

      if (G.isArray(additionalFilterValue)) {
        filterValue = R.map((item: Object) => R.propOr(item, GC.FIELD_VALUE, item), additionalFilterValue);
      }

      additionalFiltersApplyHandler({
        additionalFilterType: filterType,
        additionalFilterValue: filterValue,
      });
    },
  }),
  withHandlers({
    setAdditionalFilterValueOnEnter: (props: Object) => (value: string) => {
      const { handleClickApply, setAdditionalFilterValue } = props;
      setAdditionalFilterValue(value, handleClickApply);
    },
  }),
  pure,
);

export default enhance(AdditionalFilters);
