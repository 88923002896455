import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box } from '../../ui';
// component/editable-box
import { Textarea } from './ui';
//////////////////////////////////////////////////

const EditableBox = (props: Object) => {
  const {
    value,
    boxStyles,
    wrapperStyles,
    onBlurCallback,
    textareaStyles,
  } = props;

  const [text, setText] = useState(value);

  const [isTextFocused, setIsTextFocused] = useState(false);

  useEffect(() => {
    setText(value);
  }, [value]);

  const onBlurHandler = (value: string) => {
    setIsTextFocused(false);

    G.callFunctionWithArgs(onBlurCallback, value);
  };

  return (
    <Box {...wrapperStyles}>
      { R.not(isTextFocused) && <Box {...boxStyles} onClick={() => setIsTextFocused(true)}>{text}</Box> }
      {
        isTextFocused &&
        <Textarea
          {...textareaStyles}
          value={text}
          autoFocus={true}
          onChange={(event: Object) => setText(G.getEventTargetValue(event))}
          onBlur={(event: Object) => onBlurHandler(G.getEventTargetValue(event))}
        />
      }
    </Box>
  );
};

EditableBox.defaultProps = {
  wrapperStyles: {
    p: '5px',
    height: 60,
  },
  textareaStyles: {
    fontSize: 14,
    height: '100%',
    color: G.getTheme('colors.dark.mainDark'),
  },
  boxStyles: {
    fontSize: 14,
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
};

export default EditableBox;
