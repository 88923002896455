import * as R from 'ramda';
import styled from 'styled-components';
import { height, minWidth, fontSize, borderRadius } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Button } from '../../ui';
//////////////////////////////////////////////////

const ToggleBtnUi = styled(Button)`
  ${height}
  ${minWidth}
  ${fontSize}
  ${borderRadius}

  background-color: ${({ checked }: Object) => (
    G.ifElse(
      R.equals(checked, true),
      G.getTheme('colors.dark.blue'),
      G.getTheme('colors.light.darkGrey'),
    ))};

  color: ${({ checked }: Object) => (
    G.ifElse(
      R.equals(checked, true),
      G.getTheme('colors.white'),
      G.getTheme('colors.light.mainLight'),
    ))};
`;

ToggleBtnUi.defaultProps = {
  height: 20,
  fontSize: 10,
  minWidth: 80,
  borderRadius: 10,
};

export {
  ToggleBtnUi,
};
