import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const defaultCreateCarrierFields = {
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_CARRIER_NAME]: null,
  [GC.FIELD_CARRIER_SYNC]: null,
  [GC.FIELD_CARRIER_MC_NUMBER]: null,
  [GC.FIELD_CARRIER_USDOT_NUMBER]: null,
  [GC.FIELD_CARRIER_SEND_PACKAGE]: false,
};

const defaultCreateCarrierValidationSchema = {
  [GC.FIELD_EMAIL]: Yup.array()
    .nullable(true),
  [GC.FIELD_CARRIER_MC_NUMBER]: Yup.string()
    .nullable(true)
    .max(8, G.getShouldBeFromToCharLocaleTxt(0, 8)),
  [GC.FIELD_CARRIER_USDOT_NUMBER]: Yup.string()
    .nullable(true)
    .max(12, G.getShouldBeFromToCharLocaleTxt(0, 12)),
  [GC.FIELD_CARRIER_NAME]: Yup.string()
    .nullable(true)
    .max(250, G.getShouldBeFromToCharLocaleTxt(0, 250)),
};

const requiredCreateCarrierSchema = {
  [GC.FIELD_EMAIL]: G.yupArrayRequired,
  [GC.FIELD_CARRIER_MC_NUMBER]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(8, G.getShouldBeFromToCharLocaleTxt(0, 8)),
  [GC.FIELD_CARRIER_USDOT_NUMBER]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(12, G.getShouldBeFromToCharLocaleTxt(0, 12)),
  [GC.FIELD_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(250, G.getShouldBeFromToCharLocaleTxt(0, 250)),
};

export const createCarrierValidationSchemaObject = (values: Object) => {
  const { sync, mcNumber, sendPackage, usDotNumber } = values;

  let requiredFields = R.append(
    GC.FIELD_NAME,
    R.or(G.getAmousConfigByNameFromWindow(GC.CARRIER_REQUIRED_FIELDS), []),
  );

  if (sync) {
    if (G.isAllNilOrEmpty([mcNumber, usDotNumber])) {
      requiredFields = [GC.FIELD_NAME, GC.FIELD_CARRIER_MC_NUMBER, GC.FIELD_CARRIER_USDOT_NUMBER];
    } else {
      requiredFields = R.of(Array, GC.FIELD_NAME);
    }
  }

  if (sendPackage) requiredFields = R.append(GC.FIELD_EMAIL, requiredFields);

  const schema = R.mergeRight(defaultCreateCarrierValidationSchema, R.pick(requiredFields, requiredCreateCarrierSchema));

  return schema;
};

export const createCarrierFields = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CARRIER_NAME,
    label: G.getWindowLocale('titles:company-name', 'Company Name'),
    inputWrapperStyles: {
      mt: 15,
      mb: 25,
      width: 310,
    },
  },
  {
    type: 'text',
    fieldName: GC.FIELD_CARRIER_USDOT_NUMBER,
    inputWrapperStyles: { mb: 25, width: 310 },
    label: G.getWindowLocale('titles:usdot-number', 'USDOT Number'),
    isRequired: () => R.includes(
      GC.FIELD_CARRIER_USDOT_NUMBER,
      R.or(G.getAmousConfigByNameFromWindow(GC.CARRIER_REQUIRED_FIELDS), []),
    ),
  },
  {
    type: 'text',
    fieldName: GC.FIELD_CARRIER_MC_NUMBER,
    inputWrapperStyles: { mb: 15, width: 310 },
    label: G.getWindowLocale('titles:mc-number', 'MC Number'),
    isRequired: () => R.includes(
      GC.FIELD_CARRIER_MC_NUMBER,
      R.or(G.getAmousConfigByNameFromWindow(GC.CARRIER_REQUIRED_FIELDS), []),
    ),
  },
  {
    type: 'toggle',
    shouldCustomChange: true,
    fieldName: GC.FIELD_CARRIER_SEND_PACKAGE,
    label: G.getWindowLocale('titles:send-onboarding-package', 'Send onboarding package'),
    inputWrapperStyles: {
      mb: 15,
      width: 310,
    },
  },
  {
    isRequired: true,
    type: 'multiEmail',
    fieldName: GC.FIELD_EMAIL,
    label: G.getWindowLocale('titles:carrier-emails', 'Carrier Emails'),
    inputWrapperStyles: {
      mb: 15,
      mt: 15,
      width: 310,
      display: (props: Object) => {
        const { values } = props;

        const { sendPackage } = values;

        return G.ifElse(
          sendPackage,
          'flex',
          'none',
        );
      },
    },
  },
];
