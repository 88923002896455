import * as R from 'ramda';
import { put, spawn, takeEvery, takeLatest, select } from 'redux-saga/effects';
// helpers/constants
import * as G from '../../../helpers';
// common idb
import * as A from './actions';
import { makeSelectIDB, makeSelectIDBProps, makeSelectResizeTable } from './selectors';
//////////////////////////////////////////////////

function* onCloseDatabase(onClosePromise: Promise) {
  yield onClosePromise;

  yield put(A.reportTableResizeIDBReconnect());
  yield put(A.setReportTableResize({}));
}

export function* initTableResizeIDB() {
  let onIDBCloseResolved;

  const onClosePromise = new Promise((res: Function) => {
    onIDBCloseResolved = res;
  });

  const idbProps = yield select(makeSelectIDBProps());
  const idb = yield G.createIndexedDB(idbProps, onIDBCloseResolved);

  yield put(A.initTableResizeIDBSuccess(idb));

  yield spawn(onCloseDatabase, onClosePromise);
}

function* getReportTableResize({ payload: reportGuid }: Object) {
  const idb = yield select(makeSelectIDB());
  const idbProps = yield select(makeSelectIDBProps());

  const result = yield G.readDataFromIDB({
    idb,
    idbProps,
    credentials: reportGuid,
  });

  const resizeTable = R.clone(yield select(makeSelectResizeTable()));

  resizeTable[reportGuid] = R.pathOr({}, ['value'], result);

  yield put(A.setReportTableResize(resizeTable));
}

function* updateTableResizeByGuid({ payload: dataToUpdate }: Object) {
  const { reportGuid, reportType, resizedTableField } = dataToUpdate;
  const { fieldName, width } = resizedTableField;

  const idb = yield select(makeSelectIDB());
  const idbProps = yield select(makeSelectIDBProps());

  const resizeTable = G.quickClone(yield select(makeSelectResizeTable()));
  const resizeByGuid = R.propOr({}, reportGuid, resizeTable);

  resizeByGuid[fieldName] = width;

  yield G.setDataToIDB({
    idb,
    idbProps,
    credentials: reportGuid,
    dataToSet: resizeByGuid,
    additionalData: { reportType },
  });

  resizeTable[reportGuid] = resizeByGuid;

  yield put(A.setReportTableResize(resizeTable));
}

function* deleteTableResizeByGuid({ payload: reportGuid }: Object) {
  const idb = yield select(makeSelectIDB());
  const idbProps = yield select(makeSelectIDBProps());
  const resizeTable = G.quickClone(yield select(makeSelectResizeTable()));

  yield G.deleteDataByKeyFromIDB({
    idb,
    idbProps,
    credentials: reportGuid,
  });

  resizeTable[reportGuid] = {};

  yield put(A.setReportTableResize(resizeTable));
}

function* deleteTableResizeByType({ payload: { reportType, reportGuid } }: Object) {
  const idb = yield select(makeSelectIDB());
  const idbProps = yield select(makeSelectIDBProps());
  const resizeTable = G.quickClone(yield select(makeSelectResizeTable()));

  yield G.deleteDataByIndexKeyFromIDB({
    idb,
    idbProps,
    credentials: reportType,
  });

  resizeTable[reportGuid] = {};

  yield put(A.setReportTableResize(resizeTable));
}
function* deleteAllResizeData() {
  const idb = yield select(makeSelectIDB());
  const idbProps = yield select(makeSelectIDBProps());

  yield G.deleteDatabase({
    idb,
    idbProps,
  });

  yield put(A.reportTableResizeIDBReconnect());
  yield put(A.setReportTableResize({}));
}

export default function* resizeTableWatcherSaga() {
  yield takeLatest(A.deleteAllResizeData, deleteAllResizeData);
  yield takeEvery(A.getReportTableResize, getReportTableResize);
  yield takeLatest(A.updateTableResizeByGuid, updateTableResizeByGuid);
  yield takeLatest(A.deleteTableResizeByGuid, deleteTableResizeByGuid);
  yield takeLatest(A.deleteTableResizeByType, deleteTableResizeByType);
  yield takeLatest(A.reportTableResizeIDBReconnect, initTableResizeIDB);
}
