import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal } from '../modal/actions';
import { openLoader, closeLoader } from '../loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// component tracking
import Tracking from './';
//////////////////////////////////////////////////

const withTracking = compose(
  connect(null, { openModal, openLoader, closeLoader }),
  withHandlers({
    handleShowTracking: (props: Object) => async (data: Object) => {
      const { openModal, openLoader, closeLoader } = props;

      const { guid, events, statusMessages } = data;

      let eventsToUse = events;
      let messages = statusMessages;

      if (G.isNilOrEmpty(events) && G.isNilOrEmpty(statusMessages)) {
        try {
          openLoader();

          const options = {
            params: {
              [GC.FIELD_LOAD_GUID]: guid,
            },
          };

          const responseEvents = await sendRequest('get', endpointsMap.getTelEndpoint(guid));
          const responseStatusMessages = await sendRequest('get', endpointsMap.telStatusMessageList, options);

          if (G.isResponseSuccess(responseEvents.status)) {
            eventsToUse = responseEvents.data.events;
          } else {
            G.handleFailResponseSimple(responseEvents);
          }

          if (G.isResponseSuccess(responseStatusMessages.status)) {
            messages = responseStatusMessages.data;
          } else {
            G.handleFailResponseSimple(responseStatusMessages);
          }

          closeLoader();
        } catch (error) {
          closeLoader();

          G.handleException(error, 'handleShowTracking');

          G.showToastrMessageSimple(
            'error',
            G.getWindowLocale(
              'messages:error:unknown',
              'Oops! The system is experiencing a problem. The issue has been reported.',
            ),
          );
        }
      }

      const statusMessagesToUse = R.compose(
        R.values,
        R.map((item: Object) => R.mergeRight(item, G.getPropFromObject('telStatusMessageLocation', item))),
      )(messages);

      const modalContent = (
        <Tracking
          events={eventsToUse}
          statusMessages={statusMessagesToUse}
          mappedEventsForMap={G.mapTelEventsForMap(eventsToUse)}
        />
      );

      const modal = {
        p: 15,
        component: modalContent,
        options: {
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          title: G.getWindowLocale('titles:tracking', 'Tracking'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export default withTracking;
