import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const getRateFromSharedAccessAndFuelPrices = (access: Object) => {
  const { rate, district, useFuelIndex, fuelIndexVariables } = access;

  const { fuelPrice, fuelPricesInfo } = G.getItemFromWindow('amousFuelIndexInfo');

  let rateToUse = rate;

  let fuelIndexInfo = null;

  const districtFuelPrice = R.path([district], fuelPrice);

  if (G.isAllTrue(G.isTrue(useFuelIndex), G.isArray(fuelIndexVariables), G.isNotNilAndNotEmpty(districtFuelPrice))) {
    fuelIndexVariables.forEach((item: Object) => {
      const { rate, valueTo, valueFrom } = item;

      if (R.and(R.gte(districtFuelPrice, valueFrom), R.lt(valueTo))) {
        rateToUse = rate;

        fuelIndexInfo = {
          district,
          rate: rateToUse,
          price: districtFuelPrice,
          priceDate: G.getPropFromObject('priceDate', fuelPricesInfo),
        };
      }
    });
  }

  return { fuelIndexInfo, rate: rateToUse };
};

export const setAccessorialsFromResponseToChargeUse = (access: Array, shared: Array) => {
  const accessorialGuids = R.map(R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID), R.or(access, []));

  const mappedShared = R.compose(
    R.map((item: Object) => {
      const { guid, name, rateUnit, rateType, useFuelIndex, assessorialGuid } = item;

      const accessorialConfig = R.find(R.propEq(assessorialGuid, GC.FIELD_ORIGINAL_CONFIG_GUID), access);

      const { fuelRelated } = accessorialConfig;

      const { rate, fuelIndexInfo } = getRateFromSharedAccessAndFuelPrices(item);

      return {
        ...R.pick([GC.FIELD_NON_TAXABLE, GC.FIELD_ADVANCE_PAYMENT], accessorialConfig),
        rate,
        guid,
        rateType,
        rateUnit,
        fuelIndexInfo,
        assessorialGuid,
        storedValue: name,
        originalConfigGuid: assessorialGuid,
        [GC.FIELD_SHARED_ASSESSORIAL_GUID]: guid,
        fuelRelated: R.or(useFuelIndex, fuelRelated),
        displayedValue: `${name} (${G.getWindowLocale('titles:shared', 'Shared')})`,
      };
    }),
    // TODO: check with be this filter
    R.filter(({ assessorialGuid }: Object) => R.includes(assessorialGuid, accessorialGuids)),
  )(shared);

  return R.concat(mappedShared, access);
};
