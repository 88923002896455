import * as R from 'ramda';
import React from 'react';
import { pure, compose, withProps } from 'react-recompose';
import { GoogleMap, TrafficLayer, withGoogleMap } from 'react-google-maps';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box } from '../../ui';
// component map
import { mapStyles, mapStylesDark } from './settings/map-styles';
//////////////////////////////////////////////////

export { Directions } from './components/directions';
export { StopInfo, StopMarker } from './components/stop-components';
export { MarkerWithInfo, FleetMarkerWithInfo } from './components/marker-with-info';

const enhance = compose(
  withProps((props: Object) => (
    {
      mapElement: <div style={{ height: '100%' }} />,
      containerElement: (
        <div
          style={{
            width: R.or(props.width, '100%'),
            height: R.or(props.height, 'calc(100vh - 140px)'),
          }} />
      ),
    }
  )),
  withGoogleMap,
  pure,
);

export const Map = enhance((props: Object) => {
  const {
    center,
    options,
    children,
    openMobMap,
    defaultZoom,
    handleSetRef,
    trafficLayer,
    handleCenterChanged,
  } = props;

  const mapSt = G.ifElse(
    G.isTrue(openMobMap),
    mapStylesDark,
    mapStyles,
  );

  return (
    <Box>
      <GoogleMap
        options={R.or(options, {})}
        defaultOptions={{ styles: mapSt }}
        ref={R.or(handleSetRef, () => {})}
        defaultZoom={R.or(defaultZoom, 4)}
        onCenterChanged={R.or(handleCenterChanged, () => {})}
        center={R.or(center, {lat: 38.755157, lng: -98.269035 })}
        defaultCenter={R.or(center, {lat: 38.755157, lng: -98.269035 })}
      >
        {R.or(children, null)}
      </GoogleMap>
      {trafficLayer && <TrafficLayer autoUpdate='true' />}
    </Box>
  );
});
