import * as R from 'ramda';
import { createSelector } from 'reselect';
// component/common filter
import { FILTER_FORM } from './constants';
//////////////////////////////////////////////////

const selectFormsStore = (state: Object) => state.form;

const makeSelectFormValues = () => createSelector(
  selectFormsStore,
  (form: Object) => R.pathOr(
    {},
    [FILTER_FORM, 'values'],
    form,
  ),
);

export { makeSelectFormValues };
