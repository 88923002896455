import * as Yup from 'yup';
import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../form-footer';
// forms
import { Fieldset2 } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// advance payment
import {
  updateAdvancePaymentStatusFieldSettings,
  defaultUpdateAdvancePaymentStatusFields,
  updateAdvancePaymentStatusValidationSchema,
} from './settings';
//////////////////////////////////////////////////

const editStatuses = {
  ACTIVE: {
    value: GC.ADVANCE_PAYMENT_STATUS_ACTIVATE,
    label: G.getWindowLocale('titles:activate', 'Activate'),
  },
  BLOCKED: {
    value: GC.ADVANCE_PAYMENT_STATUS_BLOCK,
    label: G.getWindowLocale('titles:block', 'Block'),
  },
  CANCELLED: {
    value: GC.ADVANCE_PAYMENT_STATUS_CANCEL,
    label: G.getWindowLocale('titles:cancel', 'Cancel'),
  },
};

const getEditStatusOptions = (status: string) => R.compose(
  G.addEmptyOptionToDropDown,
  R.values,
  R.dissoc(status),
)(editStatuses);

const enhance = compose(
  withFormik({
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: Yup.object().shape(updateAdvancePaymentStatusValidationSchema),
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultUpdateAdvancePaymentStatusFields,
      props.initialValues,
    ),
  }),
  pure,
);

const UpdateAdvancePaymentStatusForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={updateAdvancePaymentStatusFieldSettings}
      fieldsWrapperStyles={{ mt: 15, flexDirection: 'column' }}
      editStatusOptions={getEditStatusOptions(R.prop(GC.FIELD_STATUS, props))}
    />
    <FormFooter2 />
  </form>
);

export default enhance(UpdateAdvancePaymentStatusForm);
