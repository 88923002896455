import React from 'react';
import * as R from 'ramda';
// components
import TextComponent from '../text';
// features
import { itemSettings } from '../../features/template/report/settings/item';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const itemFields = [
  GC.FIELD_ITEM_FREIGHT_CLASS,
  GC.FIELD_ITEM_WEIGHT,
  GC.FIELD_ITEM_WEIGHT_TYPE,
  GC.FIELD_ITEM_QUANTITY,
  GC.FIELD_PACKAGE_TYPE,
  GC.FIELD_ITEM_VOLUME,
  GC.FIELD_ITEM_VOLUME_UOM,
];

const settings = {
  [GC.FIELD_ITEM_ID]: {
    name: 'titles:item-id',
    fieldType: 'customComponent',
  },
  ...R.pick(itemFields, itemSettings),
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
    fieldType: 'customComponent',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:created-date',
    fieldType: 'customComponent',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
    fieldType: 'customComponent',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:updated-date',
    fieldType: 'customComponent',
  },
};

export const columnSettings = R.map((item: Object) => R.assoc('width', R.propOr(100, 'width', item), item), settings);

export const wideColumnSettings = R.map(
  (item: Object) => R.assoc('width', R.propOr(150, 'width', item), item),
  settings,
);

export const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

export const tableSettings = {
  rowHeight: 30,
  cellFontSize: 12,
  titleFontSize: 11,
  minHeight: 'unset',
  titleRowHeight: 30,
  tableRowHeight: 30,
  allowEditBtn: false,
  useMainColors: true,
  checkBoxCellWidth: 0,
  moreBtnCellWidth: 50,
  allowSelectAll: false,
  expandableItems: false,
  searchableTitles: false,
  allowSelectItems: false,
};

const inputWrapperStyles = {
  width: 134,
  fontSize: 12,
  m: '15px 8px',
};

export const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed((item: Object, fieldName: string) => {
    const { type, options, fieldType = 'text' } = item;

    if (R.equals(fieldType, 'customComponent')) {
      const Component = ({ value }: Object) => {
        let displayedValue = G.ifElse(G.isString(value), value);

        if (R.equals(type, 'date')) {
          displayedValue = G.checkAndConvertMomentInstanceOrStringToFormattedDate(value, GC.DEFAULT_DATE_TIME_FORMAT);
        }

        return (
          <TextComponent display='block' withEllipsis={true} title={displayedValue}>
            {displayedValue}
          </TextComponent>
        );
      };

      return {
        fieldName,
        Component,
        inputWrapperStyles,
        type: 'customComponent',
      };
    }

    return {
      fieldName,
      inputWrapperStyles,
      options: G.addEmptyOptionToDropDown(options),
      type: G.ifElse(R.isNotNil(options), 'select', 'text'),
    };
  }),
)(settings);

export const getIsPriceSheetItemFieldsEditable = (values: Object) => G.notContain(
  R.prop(GC.FIELD_SOURCE, values), [GC.SOURCE_TYPE_EDI],
);
