import * as R from 'ramda';
import React from 'react';
import {
  pure,
  compose,
  withState,
  withHandlers,
  withPropsOnChange } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// forms
import { Info, Error, Label, FormGroup } from '../../forms';
// ui
import { Box, ReactSelect } from '../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState(
    'selectedValues',
    'setSelectedValues',
    ({ initialValues }: Object) => R.or(initialValues, []),
  ),
  withHandlers({
    handleMultiSelect: (props: Object) => (options: Object, fieldName: string) => {
      const selected = R.map((item: Oblect) => item, options);
      props.setSelectedValues(selected);
      props.change(fieldName, options);
      if (G.isTrue(props.quickFilterInReport)) {
        props.handleFilterValue(props.input.name, selected, props.sequence);
      }
    },
  }),
  withPropsOnChange(['input'], (props: Object) => props.setSelectedValues(R.path(['input', 'value'], props))),
  pure,
);

export const MultiSelect = enhance(({
  jc,
  zI,
  info,
  input,
  label,
  width,
  align,
  margin,
  options,
  display,
  sequence,
  direction,
  errorClass,
  fieldClass,
  placeholder,
  errorMargin,
  labelMargin,
  groupPadding,
  additionClass,
  errorFontSize,
  labelFontSize,
  labelPosition,
  handleMultiSelect,
  meta: { error, touched },
}: Object) => (
  <FormGroup
    jc={jc}
    align={align}
    p={groupPadding}
    additionClass={additionClass}
    display={R.or(display, 'flex')}
    direction={R.or(direction, 'column')}
  >
    {
      G.isNotNil(labelPosition) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
      </Label>
    }
    <Box
      m={margin}
      zIndex={zI}
      width={width}
    >
      <ReactSelect
        height={30}
        isMulti={true}
        name={input.name}
        options={options}
        isClearable={true}
        placeholder={placeholder}
        onChange={(values: Array) => handleMultiSelect(values, input.name, sequence)}
      />
    </Box>
    {
      info && <Info text={info} />
    }
    {
      R.and(touched, error) &&
      <Error
        m={errorMargin}
        errorClass={errorClass}
        fontSize={errorFontSize}
        className={G.ifElse(G.isString(errorClass), errorClass)}
      >
        {error}
      </Error>
    }
  </FormGroup>
));

export default MultiSelect;
