import { space } from 'styled-system';
import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const Wrapper = styled.div`
  & .popper {
    width: max-content;
    z-index: ${({ zi }: Object) => zi || 20};
    max-height: ${({ maxHeight }: Object) => maxHeight};
    overflow: ${({ overflow }: Object) => overflow || 'auto'};
    min-width: ${({ minWidth }: Object) => minWidth || '230px'};
    background-color: ${() => G.getTheme('colors.light.mainLight')};
    box-shadow: ${({ popperBoxShadow }: Object) => popperBoxShadow};
    border-radius: ${({ popperBorderRadius }: Object) => popperBorderRadius || '2px'};
    border: ${({ borderColor, borderWidth }: Object) => `${
      borderWidth || '2px solid'} ${borderColor || 'rgba(53, 53, 53, 0.3)'}`};
  }
`;

export const PopperComponentWrapper = styled.div`
  ${space}

  max-width: 100%;
  cursor: default;
  z-index: ${({ zi, popperZIndex }: Object) => popperZIndex || zi};
  & .popper-target {
    cursor: ${({ cursor }: Object) => cursor || 'default'};
    width:  ${({ wrapperWidth }: Object) => wrapperWidth || 'max-content'};
    max-width: ${({ wrapperMaxWidth }: Object) => wrapperMaxWidth || '100%'};
  }
  &:hover .popper-target * {
    ${({ activeStyles }: Object) => activeStyles || ''};
  }
`;
