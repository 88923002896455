
import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectReportsStore = (state: Object) => state.reports;
const selectFormsStore = (state: Object) => state.form;

const makeSelectReportsList = () => createSelector(
  selectReportsStore,
  (reportsState: Object) => reportsState.reports,
);

const makeSelectQuickFilteredParams = () => createSelector(
  selectReportsStore,
  (reportsState: Object) => reportsState.filterParams,
);

const makeSelectUsedReport = () => createSelector(
  selectReportsStore,
  (reportsState: Object) => reportsState.usedReport,
);

const makeSelectRequestStatus = () => createSelector(
  selectReportsStore,
  (reportsState: Object) => reportsState.requestPending,
);

const makeSelectIgnorePrompt = () => createSelector(
  selectReportsStore,
  (reportsState: Object) => reportsState.ignorePrompt,
);

const makeSelectUsedReportStatus = () => createSelector(
  selectReportsStore,
  (reportsState: Object) => reportsState.usedReportStatus,
);

const makeSelectAvailableReports = () => createSelector(
  selectReportsStore,
  (reportsState: Object) => reportsState.availableReports,
);

const makeSelectRenderColumn = () => createSelector(
  selectReportsStore,
  (reportState: Object) => reportState.renderFields,
);

const makeSelectFilterProps = () => createSelector(
  selectReportsStore,
  (reportState: Object) => reportState.filterProps,
);

const makeSelectOriginParams = () => createSelector(
  selectReportsStore,
  (reportState: Object) => reportState.originReportParam,
);

const makeSelectEditRouteReportForm = () => createSelector(
  selectFormsStore,
  (form: Object) => R.pathOr({}, ['EDIT_REPORT_FORM', 'values'], form),
);

export {
  selectReportsStore,
  makeSelectUsedReport,
  makeSelectReportsList,
  makeSelectFilterProps,
  makeSelectIgnorePrompt,
  makeSelectRenderColumn,
  makeSelectOriginParams,
  makeSelectRequestStatus,
  makeSelectUsedReportStatus,
  makeSelectAvailableReports,
  makeSelectQuickFilteredParams,
  makeSelectEditRouteReportForm,
};
