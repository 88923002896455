import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const filterTypesWithMuiTitleInput = ['string', 'number', 'reference', 'date'];

export const setName = (field: Object, { columnSettings }: Object) => {
  const fieldName = R.path([R.prop('name', field), 'name'], columnSettings);
  const fieldDivider = R.pathOr(': ', [R.prop('name', field), 'divider'], columnSettings);

  if (G.isArray(fieldName)) {
    return R.compose(
      R.join(fieldDivider),
      R.map((name: string) => G.getWindowLocale(name, ' ')),
    )(fieldName);
  }

  return G.getWindowLocale(fieldName, ' ');
};

export const getOptionsFromAdditionalSettings = ({
  columnSettings,
  additionalColumns,
}: Object) => {
  const additionalSettings = R.omit(additionalColumns, R.pickBy(R.propEq(true, 'additional'), columnSettings));

  if (R.isEmpty(additionalSettings)) return null;

  const options = R.map((key: string) => {
    const { name } = R.prop(key, additionalSettings);

    const label = G.getWindowLocale(name, '');

    return { label, value: key };
  }, R.keys(additionalSettings));

  return [
    { label: '', value: '' },
    ...options,
  ];
};
