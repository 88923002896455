import * as R from 'ramda';
import {
  pure,
  compose,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { REPORT_FILTER_DATE_LAST_NEXT_OPERATIONS } from '../../../filter/settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../../hocs';
// feature edit-report
import { rangeObject, defaultFilterFields } from './settings';
//////////////////////////////////////////////////

export const withReportFilterList = R.compose(
  withState('reportFilterGroups', 'setReportFilterGroups', []),
  withState('reportSimpleFiltersGroupId', 'setReportSimpleFiltersGroupId', null),
  withPropsOnChange(['filterFields'], (props: Object) => {
    const { filterFields, setReportFilterGroups, setReportSimpleFiltersGroupId } = props;

    const defaultGroupId = G.generateGuid();
    setReportSimpleFiltersGroupId(defaultGroupId);

    if (G.isNilOrEmpty(filterFields)) return;

    const groups = R.compose(
      R.groupBy(R.prop('groupId')),
      R.map((item: Object) => {
        const groupId = R.prop('groupId', item);

        if (G.isNilOrEmpty(groupId)) {
          return {
            ...item,
            withoutOrGroup: true,
            groupId: defaultGroupId,
          };
        }

        return item;
      }),
    )(filterFields);
    setReportFilterGroups(groups);
  }),
  withHandlers({
    handleAddFiltersGroup: (props: Object) => () => {
      const { reportFilterGroups, setReportFilterGroups, reportSimpleFiltersGroupId } = props;

      const groupId = reportSimpleFiltersGroupId;
      const filter = {
        ...defaultFilterFields,
        groupId,
        withoutOrGroup: true,
      };
      const newGroups = R.assoc(groupId, R.of(Array, filter), reportFilterGroups);
      setReportFilterGroups(newGroups);
    },
    handleAddReportGroup: (props: Object) => () => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      const groupId = G.generateGuid();
      const filter = R.assoc('groupId', groupId, defaultFilterFields);
      const newGroups = R.assoc(groupId, R.of(Array, filter), reportFilterGroups);
      setReportFilterGroups(newGroups);
    },
    handleAddReportFilter: (props: Object) => ({ groupId, withoutOrGroup }: Object) => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      const group = R.path([groupId], reportFilterGroups);
      const filter = {
        ...defaultFilterFields,
        groupId,
        withoutOrGroup,
      };
      const newGroups = R.assoc(groupId, R.append(filter, group), reportFilterGroups);
      setReportFilterGroups(newGroups);
    },
    handleRemoveReportFilter: (props: Object) => (groupId: string, filterIndex: string) => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      const groupsUpdated = R.dissocPath([groupId, filterIndex], reportFilterGroups);
      const group = R.path([groupId], groupsUpdated);

      if (G.isNilOrEmpty(group)) {
        return setReportFilterGroups(R.dissoc(groupId, reportFilterGroups));
      }

      return setReportFilterGroups(groupsUpdated);
    },
    handleSelectPropertyName: (props: Object) => (values: Object) => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      const {
        groupId,
        filterIndex,
        withoutOrGroup,
        data: { type, value, collection, referenceFieldName },
    } = values;

      let newFilter = {
        ...defaultFilterFields,
        groupId,
        withoutOrGroup,
        dataType: type,
        propertyName: value,
        collection: R.or(collection, false),
      };

      if (R.equals(type, 'reference')) {
        newFilter = {
          ...newFilter,
          referenceName: value,
          propertyName: 'references',
        };

        if (G.isNotNil(referenceFieldName)) {
          newFilter = R.assoc(GC.FIELD_REFERENCE_FIELD_NAME, referenceFieldName, newFilter);
        }
      }

      const groupsUpdated = R.assocPath([groupId, filterIndex], newFilter, reportFilterGroups);
      setReportFilterGroups(groupsUpdated);
    },
    handleSelectOperation: (props: Object) => (values: Object) => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      const { groupId, dataType, filterIndex, data: { value } } = values;

      const groupsUpdated = R.assocPath([groupId, filterIndex, 'operation'], value, reportFilterGroups);

      if (R.equals(dataType, 'date')) {
        const dateRelative = R.includes(value, REPORT_FILTER_DATE_LAST_NEXT_OPERATIONS);

        if (G.isTrue(dateRelative)) {
          const groups = R.compose(
            R.assocPath([groupId, filterIndex, 'timeUnit'], value),
            R.assocPath([groupId, filterIndex, 'operation'], null),
            R.assocPath([groupId, filterIndex, 'dateRelative'], dateRelative),
          )(groupsUpdated);

          return setReportFilterGroups(groups);
        }

        return setReportFilterGroups(R.assocPath([groupId, filterIndex, 'dateRelative'], dateRelative, groupsUpdated));
      }

      setReportFilterGroups(groupsUpdated);
    },
    handleChangeSelect: (props: Object) => ({ values, filterProp }: Object) => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      const { groupId, filterIndex, data: { value } } = values;

      const groupsUpdated = R.assocPath([groupId, filterIndex, filterProp], value, reportFilterGroups);
      setReportFilterGroups(groupsUpdated);
    },
    handleChangeInput: (props: Object) => ({ value, groupId, filterProp, filterIndex }: Object) => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      const groupsUpdated = R.assocPath([groupId, filterIndex, filterProp], value, reportFilterGroups);
      setReportFilterGroups(groupsUpdated);
    },
    handleChangeMultiSelect: (props: Object) => ({ options, groupId, filterIndex }: Object) => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      const groupsUpdated = R.assocPath([groupId, filterIndex, 'selectMultipleValue'], options, reportFilterGroups);
      setReportFilterGroups(groupsUpdated);
    },
    handleChangeRange: (props: Object) => ({ value, groupId, rangeType, filterProp, filterIndex }: Object) => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      const filterRange = R.path([groupId, filterIndex, filterProp], reportFilterGroups);
      let rangeUpdated = G.ifElse(
        G.isNilOrEmpty(filterRange),
        R.assoc(rangeType, value, rangeObject),
        R.assoc(rangeType, value, R.or(filterRange, {})),
      );

      if (R.all(G.isNilOrEmpty, R.values(rangeUpdated))) rangeUpdated = null;

      const groupsUpdated = R.assocPath([groupId, filterIndex, filterProp], rangeUpdated, reportFilterGroups);
      setReportFilterGroups(groupsUpdated);
    },
    handleChangeDate: (props: Object) => ({ value, groupId, rangeType, filterProp, filterIndex }: Object) => {
      const { reportFilterGroups, setReportFilterGroups } = props;

      if (R.equals(filterProp, 'dateRelative')) {
        const groupsUpdated = R.assocPath(
          [groupId, filterIndex, rangeType],
          G.ifElse(R.isEmpty(value), null, G.toNumber(value)),
          reportFilterGroups,
        );

        return setReportFilterGroups(groupsUpdated);
      }

      const valueToUse = G.ifElse(
        R.equals(value, 'Invalid date'),
        null,
        value,
      );
      const groupsUpdated = R.assocPath([groupId, filterIndex, rangeType], valueToUse, reportFilterGroups);
      setReportFilterGroups(groupsUpdated);
    },
  }),
  pure,
);

export const withPageReportFilter = compose(
  withState('filter', 'setFilter', ({ filterData }: Object) => filterData),
  withHandlers({
    handleSetFilter: ({ setFilter }: Object) => (propName: string, newProps: Object) => (
      setFilter(G.getPropFromObject(propName, newProps))
    ),
    handleRemoveFilter: (props: Object) => (event: Object) => {
      const {
        setUsedReport,
        isQuickFilter,
        selectedReport,
        getItemListRequest,
        setQuickFilterParams,
        getQuickFilteredListRequest,
        filterData: { searchCriteriaIndex },
      } = props;

      if (isQuickFilter) {
        setQuickFilterParams({});
        getQuickFilteredListRequest();
      } else {
        setUsedReport(R.dissocPath(['searchCriteria', searchCriteriaIndex], selectedReport));
        getItemListRequest(true);
      }
    },
    handleSaveFilter: (props: Object) => () => {
      const {
        isNew,
        filter,
        setFilter,
        setUsedReport,
        isQuickFilter,
        selectedReport,
        getItemListRequest,
        setQuickFilterParams,
        getQuickFilteredListRequest,
        filterData: { searchCriteriaIndex },
      } = props;

      const {
        name,
        groupId,
        dataType,
        origValue,
        propertyName,
        withoutOrGroup,
      } = filter;

      if (R.or(G.isNilOrEmpty(propertyName), R.equals(dataType, ''))) {
        return;
        // TODO: add validation
      }

      const groupIdToUse = G.ifElse(G.isTrue(withoutOrGroup), null, groupId);

      const dataToSave = R.head(R.of(Array, {
        ...filter,
        groupId: groupIdToUse,
        [GC.FIELD_NAME]: R.or(origValue, name),
      }));

      if (isQuickFilter) {
        setQuickFilterParams(dataToSave);
        getQuickFilteredListRequest();
      } else {
        const index = G.ifElse(
          isNew,
          R.length(G.getPropFromObject('searchCriteria', selectedReport)),
          searchCriteriaIndex,
        );

        setUsedReport(R.assocPath(['searchCriteria', index], dataToSave, selectedReport));
        getItemListRequest(true);

        setFilter(defaultFilterFields);
      }
    },
    handleSelectPropertyName: (props: Object) => (values: Object) => {
      const { setFilter } = props;

      const {
        groupId,
        withoutOrGroup,
        data: { type, value, collection, referenceFieldName },
      } = values;

      let newFilter = {
        ...defaultFilterFields,
        groupId,
        withoutOrGroup,
        dataType: type,
        propertyName: value,
        collection: R.or(collection, false),
      };

      if (R.equals(type, 'reference')) {
        newFilter = {
          ...newFilter,
          referenceName: value,
          propertyName: 'references',
        };

        if (G.isNotNil(referenceFieldName)) {
          newFilter = R.assoc(GC.FIELD_REFERENCE_FIELD_NAME, referenceFieldName, newFilter);
        }
      }

      setFilter(newFilter);
    },
    handleSelectOperation: (props: Object) => (values: Object) => {
      const { filter, setFilter } = props;

      const { dataType, data: { value } } = values;

      let newFilter = R.assoc('operation', value, filter);

      if (R.equals(dataType, 'date')) {
        const dateRelative = R.includes(value, REPORT_FILTER_DATE_LAST_NEXT_OPERATIONS);

        if (G.isTrue(dateRelative)) {
          newFilter = {
            ...filter,
            dateRelative,
            timeUnit: value,
            operation: null,
          };

          return setFilter(newFilter);
        }

        return setFilter(R.assoc('dateRelative', dateRelative, newFilter));
      }

      setFilter(newFilter);
    },
    handleChangeSelect: (props: Object) => ({ values, filterProp }: Object) => {
      const { filter, setFilter } = props;

      const { data: { value } } = values;

      setFilter(R.assoc(filterProp, value, filter));
    },
    handleChangeInput: (props: Object) => ({ value, filterProp }: Object) => {
      const { filter, setFilter } = props;

      setFilter(R.assoc(filterProp, value, filter));
    },
    handleChangeMultiSelect: (props: Object) => ({ options }: Object) => {
      const { filter, setFilter } = props;

      setFilter(R.assoc('selectMultipleValue', options, filter));
    },
    handleChangeRange: (props: Object) => ({ value, rangeType, filterProp }: Object) => {
      const { filter, setFilter } = props;

      const filterRange = G.getPropFromObject(filterProp, filter);

      let rangeUpdated = G.ifElse(
        G.isNilOrEmpty(filterRange),
        R.assoc(rangeType, value, rangeObject),
        R.assoc(rangeType, value, R.or(filterRange, {})),
      );

      if (R.all(G.isNilOrEmpty, R.values(rangeUpdated))) rangeUpdated = null;

      setFilter(R.assoc(filterProp, rangeUpdated, filter));
    },
    handleChangeDate: (props: Object) => ({ value, rangeType, filterProp }: Object) => {
      const { filter, setFilter } = props;

      if (R.equals(filterProp, 'dateRelative')) {
        setFilter(R.assoc(rangeType, G.ifElse(R.isEmpty(value), null, G.toNumber(value)), filter));
      }

      const valueToUse = G.ifElse(
        R.equals(value, 'Invalid date'),
        null,
        value,
      );

      setFilter(R.assoc(rangeType, valueToUse, filter));
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'filterData',
    callbackName: 'handleSetFilter',
  }),
  pure,
);
