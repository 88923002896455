import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { width, space, fontSize, maxWidth } from 'styled-system';
// components
import TextComponent from '../text';
// features
import { AuthByCurrentBranch } from '../../features/permission';
import { convertDataTypeToUiTableField } from '../../features/configurations/helpers';
// helpers
import * as G from '../../helpers';
// constants
import * as GC from '../../constants';
// forms
import { Checkbox } from '../../forms';
//////////////////////////////////////////////////

const renderMultiItems = (
  entity: Object,
  field: Object,
  index: string,
) => (
  <TableColumn key={index} width={field.width}>
    <TextComponent
      maxWidth='95%'
      display='block'
      withEllipsis={true}
      title={R.or(entity[field.nameForAttribute], []).join(', ')}
    >
      {R.or(entity[field.nameForAttribute], []).join(', ')}
    </TextComponent>
  </TableColumn>
);

export const TableContainer = styled.div`
  ${space}
  ${width}
  ${maxWidth}
  ${fontSize}

  border: 1px solid;
  position: relative;
  z-index: ${({ zi }: Object) => zi || 'auto'};
  min-width: ${({ minWidth }: Object) => minWidth};
  overflow: ${({ overflow }: string) => overflow || 'scroll'};
  max-height: ${({ maxHeight }: Object) => maxHeight || 'calc(100vh - 145px)'};
  border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('tables.rows.borderColor')};
  & * {
    z-index: auto;
  }
`;

// NOTE: border should be before
export const TableRow = styled.div`
  ${space}
  ${width}
  ${fontSize}

  border: ${({ isHeader }: boolean) => R.or(R.and(isHeader, 'none'), '1px solid')};
  border-top: none;
  border-left: none;
  flex-wrap: no-wrap;
  align-items: center;
  flex-direction: row;
  min-width: max-content;
  top: ${({ top }: Object) => top};
  text-align: ${({ ta }: Object) => ta};
  z-index: ${({ zIndex }: Object) => zIndex};
  position: ${({ position }: Object) => position};
  height: ${({ height }: Object) => height || 'auto'};
  justify-content: ${({ jc }: Object) => jc || 'center'};
  display: ${({ display }: boolean) => display || 'block'};
  min-height: ${({ minHeight }: Object) => minHeight || '56px'};
  color: ${({ textColor }: Object) => textColor || G.getTheme('tables.rows.textColor')};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('tables.rows.bgColor')};
  border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('tables.rows.borderColor')};
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FixedContentWrapper = styled.div`
  top: 61px;
  left: 56px;
  position: fixed;
  width: calc(100vw - 70px);
  transition: all .2s linear;
`;

export const TableColumn = styled.div`
  ${space}
  ${width}

  flex-grow: 0;
  flex-shrink: 0;
  vertical-align: top;
  display: inline-block;

  text-align: ${({ ta }: Object) => ta};
  word-break: ${({ brakeWord }: Object) => R.and(G.isTrue(brakeWord), 'break-word')};

  ${({ specificStyles }: Object) => specificStyles};
`;

TableColumn.defaultProps = {
  pr: 10,
};

// NOTE: border should be before
export const FixedColumnRow = styled.div`
  ${space}
  ${width}

  top: 0;
  left: 0;
  display: flex;
  border: ${({ isHeader }: boolean) => G.ifElse(isHeader, '2px solid', '1px solid')};
  border-top: none;
  border-left: none;
  align-items: center;
  height: ${({ height }: Object) => height || '40px'};
  position:  ${({ fixed }: Object) => G.ifElse(fixed, 'absolute', 'initial')};
  z-index:  ${({ fixed }: Object) => G.ifElse(fixed, 10, 'initial')} !important;
  color: ${({ textColor }: Object) => textColor || G.getTheme('tables.rows.textColor')};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('tables.header.greyBgColor')};
  border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('tables.rows.borderColor')};
`;

export const TableScrollWrapper = styled.div`
  display: flex;
  min-width: 100%;
  width: max-content;
  flex-direction: column;
`;

export const FixedRow = styled.div`
  ${space}

  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  position: absolute;
  border-right: 1px solid;
  border-bottom: 1px solid;
  width: calc(100vw - 75px);
  transition: width .2s linear;

  height: ${({ height }: Object) => height || '40px'};
  color: ${({ textColor }: Object) => textColor || G.getTheme('tables.rows.textColor')};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('tables.header.greyBgColor')};
  border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('tables.rows.borderColor')};
`;

export const FixedColumn = styled.div`
  top: 0;
  display: flex;
  position: absolute;
  width: max-content;
  height: max-content;
  flex-direction: column;
  max-height: calc(100vh - 155px);
  z-index: ${({ zIndex }: Object) => zIndex};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('tables.rows.bgColor')};
  ${({ horizontalPosition }: Object) => horizontalPosition}: ${({ distance }: Object) => distance};
  box-shadow: ${({ horizontalPosition }: Object) => (
    R.and(
      R.equals(horizontalPosition, 'left'),
      '2px 0px 1px 1px rgba(221, 221, 221, 0.5)',
    )
  )};
`;

const fixedRow = css`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  padding: 20px 15px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const fixedColumn = css`
  overflow: auto;
  padding-top: 56px;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const classStyles = {
  'fixedRow': fixedRow,
  'fixedColumn': fixedColumn,
};

export const EmptyList = styled.div`
  ${width}

  font-size: 36px;
  padding: 50px 0;
  text-align: center;
  color: ${() => G.getTheme('colors.lightGrey')};
`;

const getFieldText = (data: any) => {
  if (G.isArray(data)) {
    return R.compose(
      R.join(': '),
      R.map((dataItem: string) => G.getWindowLocale(dataItem, dataItem)),
    )(data);
  }
  return G.getWindowLocale(data, data);
};

export const renderFixedRow = (
  { handleRegisterChild }: Object,
  { rowHeight, paddingLeft, fixedRowFields }: Object,
) => (
  <FixedRow pl={paddingLeft} height={rowHeight}>
    <div css={classStyles.fixedRow} ref={(ref: object) => handleRegisterChild(ref, 'fixedRow')}>
      {
        G.getNewArrayFromObjectWithHandling(
        (field: Object, key: string) => {
          const text = getFieldText(field.name);
          return (
            <TableColumn key={key} width={field.width}>
              <TextComponent
                width='100%'
                title={text}
                display='block'
                withEllipsis={true}
              >
                {text}
              </TextComponent>
            </TableColumn>
          );
        }, fixedRowFields)
      }
    </div>
  </FixedRow>
);

export const renderFixedColumnRow = (
  { rowHeight, fixedColumnOptions }: Object,
  customRenderTableColumn: Function,
) => (
  <FixedColumnRow
    ta='left'
    py='18px'
    px='15px'
    fixed={true}
    height={rowHeight}
  >
    {R.and(G.isNotNil(customRenderTableColumn), customRenderTableColumn())}
    {
      fixedColumnOptions.map((option: Object, index: number) => (
        <TableColumn key={index} width={option.width}>
          {G.getWindowLocale(option.name, option.nameForAttribute)}
        </TableColumn>))
    }
  </FixedColumnRow>
);

export const renderTableColumn = (
  entity: Object,
  field: Object,
  index: string,
  customRenderTable: Function,
  customColumn: Object,
) => {
  if (R.equals(field.customLogic, true)) {
    return customRenderTable(entity, field, index);
  }
  if (R.is(Function, field.customLogic)) {
    return field.customLogic(entity[field.nameForAttribute], entity, field, index);
  }
  if (field.label) {
    return (
      <TableColumn key={index} width={field.width}>
        {customColumn[field.label](entity, field.nameForAttribute)}
      </TableColumn>
    );
  }
  if (G.isTrue(field.isRedirectLink)) {
    return (
      <TableColumn key={index} width={field.width}>
        <a href={G.makeURLString(entity.url)} target='_blank'>{entity.url}</a>
      </TableColumn>
    );
  }
  if (R.is(Boolean, entity[field.nameForAttribute])) {
    return (
      <TableColumn key={index} width={field.width} specificStyles={field.specificStyles}>
        {convertDataTypeToUiTableField(entity[field.nameForAttribute])}
      </TableColumn>
    );
  }
  if (G.isNotNil(entity[field.nameForAttribute])) {
    return (
      <TableColumn key={index} brakeWord={true} width={field.width} specificStyles={field.specificStyles}>
        <TextComponent
          display='block'
          withEllipsis={true}
          width={R.subtract(field.width, 15)}
          title={entity[field.nameForAttribute]}
        >
          {entity[field.nameForAttribute]}
        </TextComponent>
      </TableColumn>
    );
  }
  if (G.isNotNilAndNotEmpty(field.inputs)) {
    return (
      <TableColumn key={index} width={field.width} specificStyles={field.specificStyles}>
        {field.inputs.map((input: Object) => entity[input.nameForAttribute]).join(' ')}
      </TableColumn>
    );
  }
  return <TableColumn key={index} width={field.width} />;
};

export const renderCheckBox = (
  entity: Object,
  selectedList: Array,
  withPermit: boolean,
  handleSelect: Function,
) => (
  G.ifElse(
    G.isTrue(withPermit),
    <AuthByCurrentBranch elementBranchGuid={entity[GC.FIELD_BRANCH_GUID]}>
      <Checkbox
        type='checkbox'
        name={entity.guid}
        onClick={handleSelect}
        checked={R.includes(entity.guid, selectedList)} />
    </AuthByCurrentBranch>,
    <Checkbox
      type='checkbox'
      name={entity.guid}
      onClick={handleSelect}
      checked={R.includes(entity.guid, selectedList)} />,
  )
);

export const renderFixedRows = (
  entities: Array,
  customRenderTable: Function,
  {
    withPermit,
    handleSelect,
    selectedList,
    fixedColumnOptions,
  }: Object,
  columnsArr: Array,
) => {
  if (R.and(R.isEmpty(columnsArr), R.isEmpty(fixedColumnOptions))) return null;
  return (
    entities.map((entity: Object) => (
      <TableRow
        py='18px'
        ta='left'
        px='15px'
        height={56}
        display='flex'
        key={entity.guid}
      >
        <TableColumn width='40px'>
          {renderCheckBox(entity, selectedList, withPermit, handleSelect)}
        </TableColumn>
        {
          fixedColumnOptions.map((option: Object, index: number) => (
            renderTableColumn(entity, option, index, customRenderTable)
          ))
        }
      </TableRow>
    ))
  );
};

export const renderFixedRowsRight = (
  entities: Array,
  {
    withPermit,
    elementActionsComponent,
  }: Object,
) => (
  entities.map((entity: Object) => (
    <TableRow
      py='10px'
      ta='left'
      jc='center'
      height={56}
      width='50px'
      display='flex'
      key={entity.guid}
    >
      <TableColumn width='15px'>
        {
          G.ifElse(
            G.isTrue(withPermit),
            <AuthByCurrentBranch elementBranchGuid={entity[GC.FIELD_BRANCH_GUID]}>
              {elementActionsComponent(entity)}
            </AuthByCurrentBranch>,
            elementActionsComponent(entity),
          )
        }
      </TableColumn>
    </TableRow>
  ))
);

export const renderRows = (
  entities: Array = [],
  {
    columnsArr,
    customColumn,
    customRenderTable,
  }: Object,
) => (
  entities.map((entity: Object) => (
    <TableRow
      pl='15px'
      py='10px'
      ta='left'
      height={56}
      display='flex'
      jc='flex-start'
      key={entity.guid}
    >
      {
        G.getNewArrayFromObjectWithHandling(
          (field: Object, key: string) => renderTableColumn(
            entity,
            field,
            `${entity.guid}${key}`,
            customRenderTable,
            customColumn,
          ),
          columnsArr,
        )
      }
      <TableColumn width='50px' />
    </TableRow>
  ))
);

export const renderListItems = (
  entities: Array = [],
  columnsArr: Array = [],
  fixedColumnOptions: Array = [],
  customColumn: Object,
) => {
  if (R.isEmpty(entities)) {
    const txt = G.getWindowLocale('titles:empty-list', 'Empty List');
    return (
      <EmptyList>{txt}</EmptyList>
    );
  } else if (R.and(R.isEmpty(columnsArr), R.isEmpty(fixedColumnOptions))) {
    const txt = G.getWindowLocale(
      'messages:add-field-to-current-report',
      'Please, Select Report or Add Fields to Current Report',
    );
    return (
      <EmptyList>{txt}</EmptyList>
    );
  }
  return renderRows(entities, { columnsArr, customRenderTable: renderMultiItems, customColumn });
};

export const renderRightFixed = (
  handleRegisterChild: Function,
  elementActionsComponent: Function,
  entities: Array,
  columnsArr: Array,
  fixedColumnOptions: Array,
  withPermit: boolean = false,
) => {
  if (R.and(R.isEmpty(columnsArr), R.isEmpty(fixedColumnOptions))) return null;
  return (
    <FixedColumn zIndex='8' distance='5px' horizontalPosition='right'>
      <div css={classStyles.fixedColumn} ref={(c: Object) => handleRegisterChild(c, 'fixedColumnRight')}>
        {entities && renderFixedRowsRight(entities, { elementActionsComponent, withPermit })}
      </div>
    </FixedColumn>
  );
};

const renderTableCheckbox = (customCheckbox: any, handleSelect: Function) => {
  if (G.isNotNil(customCheckbox)) return customCheckbox();
  return (
    <Checkbox
      name='all'
      type='checkbox'
      onClick={handleSelect} />
  );
};

export const renderFixedColumn = (
  rowHeight: string,
  fixedColumnOptions: Array,
  entities: Array,
  renderOptions: Function,
  handleSelect: Function,
  handleRegisterChild: Function,
  columnsArr: Array,
  customCheckbox: Function,
) => (
  <FixedColumn zIndex='9' distance='0' horizontalPosition='left'>
    <div css={classStyles.fixedColumn} ref={(c: Object) => handleRegisterChild(c, 'fixedColumnLeft')}>
      <FixedColumnRow
        ta='left'
        px='15px'
        py='18px'
        fixed={true}
        height={rowHeight}
        bgColor={G.getTheme('tables.header.bgColor')}
        textColor={G.getTheme('tables.header.textColor')}
        borderColor={G.getTheme('tables.header.borderColor')}
      >
        <TableColumn width='40px'>{renderTableCheckbox(customCheckbox, handleSelect)}</TableColumn>
        {
          fixedColumnOptions.map((option: Object, index: number) => {
            const text = getFieldText(option.name);
            return (
              <TableColumn key={index} width={option.width}>
                <TextComponent
                  width='100%'
                  title={text}
                  display='block'
                  withEllipsis={true}
                >
                  {text}
                </TextComponent>
              </TableColumn>
            );
          })
        }
      </FixedColumnRow>
      {R.and(entities, renderFixedRows(entities, renderMultiItems, renderOptions, columnsArr))}
    </div>
  </FixedColumn>
);

export const renderFixedListItems = (
  rowHeight: string,
  tableContainerPaddingLeft: string,
  columnsArr: Array,
  handleRegisterChild: Function,
) => (
  <FixedRow
    height={rowHeight}
    pl={tableContainerPaddingLeft}
    bgColor={G.getTheme('tables.header.bgColor')}
    textColor={G.getTheme('tables.header.textColor')}
    borderColor={G.getTheme('tables.header.borderColor')}
  >
    <div css={classStyles.fixedRow} ref={(c: Object) => handleRegisterChild(c, 'fixedRow')}>
      {
        G.getNewArrayFromObjectWithHandling(
          (field: Object, key: string) => {
            const text = getFieldText(field.name);
            return (
              <TableColumn key={key} width={field.width}>
                <TextComponent
                  width='100%'
                  title={text}
                  display='block'
                  withEllipsis={true}
                >
                  {text}
                </TextComponent>
              </TableColumn>
            );
          },
          columnsArr,
        )
      }
      <TableColumn width='50px' />
    </div>
  </FixedRow>
);
