import * as R from 'ramda';
import React, { Fragment } from 'react';
// components
import { Table, TitleRowComponent } from '../table';
import { FormSectionHeader } from '../form-section-header';
// forms
import { Fieldset2 } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// price-sheet-items
import { report, fieldSettings, tableSettings, columnSettings, wideColumnSettings } from './settings';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const PriceSheetItems = (props: Object) => {
  const { values, editable, tableMarginBottom, additionalFormHeaderSectionStyles } = props;

  const items = R.propOr([], GC.SYSTEM_LIST_ITEMS, values);

  if (G.isNilOrEmpty(items)) return null;

  return (
    <FormSectionHeader
      title={G.getWindowLocale('titles:items', 'Items')}
      additionalStyles={additionalFormHeaderSectionStyles}
    >
      <Box
        mx={10}
        overflowY='auto'
        width='calc(100% - 20px)'
        mb={R.or(tableMarginBottom, 10)}
        border={G.ifElse(editable, '1px solid')}
        borderColor='lightGrey' // NOTE: important
      >
        {
          R.not(editable) &&
          <Table
            report={report}
            itemList={items}
            tableWrapperOverflow='unset'
            tableSettings={tableSettings}
            columnSettings={columnSettings}
          />
        }
        {
          editable &&
          <Fragment>
            <TitleRowComponent
              report={report}
              itemList={items}
              tableSettings={tableSettings}
              columnSettings={wideColumnSettings}
            />
            {
              items.map((_: any, itemIndex: number) => (
                <Fieldset2
                  {...props}
                  key='itemIndex'
                  fieldsetType='array'
                  itemIndex={itemIndex}
                  fields={fieldSettings}
                  arrayName={GC.SYSTEM_LIST_ITEMS}
                  fieldsWrapperStyles={{ flexWrap: 'nowrap', width: 'max-content' }}
                />
              ))
            }
          </Fragment>
        }
      </Box>
    </FormSectionHeader>
  );
};

export default PriceSheetItems;
