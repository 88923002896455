import * as R from 'ramda';
import React from 'react';
import { OverlayView } from 'react-google-maps';
import {
  pure,
  compose,
  withState,
  withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
// component map
import {
  CloseButton,
  CustomMarker,
  CustomSvgMarker,
  CustomInfoWindow,
  CustomMarkerContent,
  CustomSvgMarkerContent } from '../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState(
    'locations',
    'setLocations',
    (props: Object) => props.locations.map((location: Object) => R.assoc('isOpen', false, location)),
  ),
  withHandlers({
    handleSetFocused: (props: Object) => (guid: string) => {
      const index = R.findIndex(R.propEq(guid, 'guid'))(props.locations);
      const locations = R.without([props.locations[index]], props.locations);
      const newLocations = R.append(props.locations[index], locations);
      props.setLocations(newLocations);
    },
  }),
  withHandlers({
    handleSetDirections: ({ setDirections }: Object) => (directions: Object) => (
      setDirections((oldDirections: Array) => R.concat(R.of(Array, directions), oldDirections))
    ),
    handleSetOpenInfoWindow: (props: Object) => (e: Event, guid: string, toOpen: boolean) => {
      G.stopPropagation(e);

      const index = R.findIndex(R.propEq(guid, 'guid'))(props.locations);
      const location = R.assoc('isOpen', toOpen, props.locations[index]);
      const locations = R.without([props.locations[index]], props.locations);
      let newLocations = R.append(location, locations);

      if (G.isFalse(toOpen)) newLocations = R.sort((item: Object) => item.main, newLocations);

      props.setLocations(newLocations);
    },
  }),
  pure,
);

const getPixelPositionOffset = (width: number, height: number) => (
  { x: -(width / 2), y: -(height * 1.4) }
);

export const MarkerWithInfo = enhance((props: Object) => (
  <Box>
    {
      G.isNotNil(props.locations) &&
      props.locations.map((location: Object, i: number) => (
        <OverlayView
          position={location.latLng}
          key={R.or(location.guid, i)}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <Box
            width='0'
            height='0'
            cursor='pointer'
            overflow='visible'
          >
            <Box transform='translate(-20px, -150%)'>
              <CustomMarker
                color={location.color}
                active={location.isOpen}
                transform={location.transform}
              >
                <CustomMarkerContent onClick={(e: Object) => props.handleSetOpenInfoWindow(e, location.guid, true)}>
                  {location.markerContent}
                </CustomMarkerContent>
              </CustomMarker>
              {
                R.and(location.isOpen, G.isNotNil(location.infoContent)) &&
                <CustomInfoWindow
                  transform={location.infoTransform}
                  infoBorderColor={location.infoBorderColor}
                  onClick={() => props.handleSetFocused(location.guid)}
                >
                  <CloseButton
                    color={location.infoBorderColor}
                    onClick={(e: Object) => props.handleSetOpenInfoWindow(e, location.guid, false)} />
                  {location.infoContent}
                </CustomInfoWindow>
              }
            </Box>
          </Box>
        </OverlayView>
      ))
    }
  </Box>
));

export const FleetMarkerWithInfo = enhance((props: Object) => (
  <Box>
    {
      G.isNotNil(props.locations) &&
      props.locations.map((location: Object, index: number) => (
        <OverlayView
          key={index}
          position={location.latLng}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <CustomSvgMarker>
            <CustomSvgMarkerContent onClick={(e: Object) => props.handleSetOpenInfoWindow(e, location.guid, true)}>
              {location.markerContent}
            </CustomSvgMarkerContent>
            {
              R.and(location.isOpen, G.isNotNil(location.infoContent)) &&
              <CustomInfoWindow
                p={props.p}
                maxWidth={props.maxWidth}
                infoBorderColor={props.infoBorderColor}
              >
                <CloseButton
                  color={R.or(props.closeButtonColor, props.infoBorderColor)}
                  onClick={(e: Object) => props.handleSetOpenInfoWindow(e, location.guid, false)} />
                {location.infoContent}
              </CustomInfoWindow>
            }
          </CustomSvgMarker>
        </OverlayView>
      ))
    }
  </Box>
));
