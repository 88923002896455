import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../text';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

export const AddressBlock = (props: Object) => {
  const { width, color, location, fontSize } = props;
  const { zip, city, state, country, address1, address2, locationName } = location;

  const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');
  const addressText = R.trim(`${R.or(address1, '')} ${R.or(address2, '')}`);
  const cityStateZipText = R.compose(
    R.join(', '),
    R.filter(G.isNotNilAndNotEmpty),
  )([city, state, zip]);

  return (
    <Box
      fontSize={R.or(fontSize, 12)}
      width={R.or(width, 'fit-content')}
      color={R.or(color, greyMatterhornColor)}
    >
      <Flex py='2px'>
        {I.locationMarker()}
        {
          G.isNotNilAndNotEmpty(locationName) &&
          <TextComponent
            ml='6px'
            display='block'
            withEllipsis={true}
            title={locationName}
            maxWidth='calc(100% - 16px)'
          >
            {locationName}
          </TextComponent>
        }
      </Flex>
      {
        G.isNotEmpty(addressText) &&
        <TextComponent
          py='2px'
          display='block'
          maxWidth='100%'
          withEllipsis={true}
          title={addressText}
        >
          {addressText}
        </TextComponent>
      }
      {
        G.isNotEmpty(cityStateZipText) &&
        <TextComponent
          py='2px'
          display='block'
          maxWidth='100%'
          withEllipsis={true}
          title={cityStateZipText}
        >
          {cityStateZipText}
        </TextComponent>
      }
      {
        G.isNotEmpty(country) &&
        <TextComponent
          py='2px'
          display='block'
          maxWidth='100%'
          title={country}
          withEllipsis={true}
        >
          {country}
        </TextComponent>
      }
    </Box>
  );
};
