import styled from 'styled-components';
//////////////////////////////////////////////////

export const RightTitlePanel = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  z-index: ${({ zIndex }: Object) => zIndex};
  & #dropdown-user-export {
    & > div > img {
      height: 20px;
    }
  }
`;
