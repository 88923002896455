import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, SimpleWrapper } from '../../ui';
//////////////////////////////////////////////////

const InfoPair = ({ title, value }: Object) => (
  <Flex my='2px' alignItems='flex-start'>
    <Box whiteSpace='nowrap'>{title}:</Box>
    <Box ml='5px'>{value}</Box>
  </Flex>
);

export const StatusMessageInfoContent = ({ location }: Object) => {
  const { statusDate, statusCode, statusReasonCode } = location;

  const dateString = G.convertDateTimeToConfigFormat(statusDate);
  const statusCodeValue = G.getDisplayedValueFromObject(statusCode);
  const statusReasonCodeValue = G.getDisplayedValueFromObject(statusReasonCode);

  return (
    <SimpleWrapper
      jc='center'
      height='100%'
      maxWidth={250}
      flexDirection='column'
      alignItems='flex-start'
    >
      {
        G.isNotNilAndNotEmpty(statusCodeValue) &&
        <InfoPair value={statusCodeValue} title={G.getWindowLocale('titles:status-code', 'Status Code')} />
      }
      {
        G.isNotNilAndNotEmpty(statusReasonCodeValue) &&
        <InfoPair value={statusReasonCodeValue} title={G.getWindowLocale('titles:status-reason', 'Status Reason')} />
      }
      <Box my='2px'>{I.calendar()} {`${G.getWindowLocale('titles:date', 'Date')}:`} {dateString}</Box>
      <Box my='2px'>{I.locationMarker()} {G.concatLocationFields(location)}</Box>
    </SimpleWrapper>
  );
};

export const StatusMessageMarkerContent = ({ title }: Object) => (
  <SimpleWrapper jc='center' height='100%' alignItems='center'>{title}</SimpleWrapper>
);
