import React from 'react';
import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { EmptyList } from '../list';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, SimpleWrapper, scrollableContainerCss4px } from '../../ui';
// component tracking
import StatusMessage from './status-message';
import TrackingAnalytic from './tracking-analytic';
import { MapWithStatusMessages } from './map-with-status-messages';
//////////////////////////////////////////////////

const StatusMessageList = ({ statusMessages, setNewCenter }: Object) => {
  const statusMessagesToUse = R.reverse(statusMessages);
  const txt = G.getWindowLocale('titles:no-status-messages', 'No Status Messages');

  if (R.isEmpty(statusMessages)) {
    return (<EmptyList width='100%'>{txt}</EmptyList>);
  }

  return statusMessagesToUse.map((item: Object, index: number) => (
    <StatusMessage key={index} item={item} index={index} setNewCenter={setNewCenter} />
  ));
};

const enhance = compose(
  withState('mapCenter', 'setMapCenter', null),
  withHandlers({
    handleCenterChanged: ({ setMapCenter }: Object) => (point: Object) => setMapCenter(point),
  }),
  pure,
);

const Tracking = enhance((props: Object) => {
  const { events, mapCenter, statusMessages, mappedEventsForMap, handleCenterChanged } = props;

  return (
    <Box width={900} height={500}>
      <TrackingAnalytic events={events} pings={R.length(statusMessages)} />
      <SimpleWrapper flexDirection='row' alignItems='flex-start'>
        <SimpleWrapper
          p='2px'
          width={400}
          height={470}
          overflow='auto'
          flexDirection='column'
          alignItems='flex-start'
          css={scrollableContainerCss4px}
        >
          <StatusMessageList statusMessages={statusMessages} setNewCenter={handleCenterChanged} />
        </SimpleWrapper>
        <SimpleWrapper ml='2px' height={470} width={500}>
          <MapWithStatusMessages
            mapCenter={mapCenter}
            statusMessages={statusMessages}
            mappedEventsForMap={mappedEventsForMap}
          />
        </SimpleWrapper>
      </SimpleWrapper>
    </Box>
  );
});

export default Tracking;
