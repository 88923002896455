import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { getInvoiceGlCode2, withComponentDidUpdatePropCallback } from '../../../hocs';
import withLinehaulAdjustments from '../../../hocs/with-linehaul-adjustments';
// component charge
import { recalculateAllCharges } from '../formik/helpers';
import {
  setGlCodeToCharges,
  withRecalculateChargesOnChangeTotals,
  withRecalculateChargesOnChangeRecalculateId,
} from './';
//////////////////////////////////////////////////

const withRecalculateAllCharges = compose(
  withHandlers({
    handleRecalculateAllCharges: () => (propName: string, props: Object) => {
      const { rateProps, isInvoice, isCarrier, isEditMode } = props;

      const { values, setValues } = rateProps;
      const {
        charges,
        fleetVendorCharges,
        carrierRateCharges,
        primaryDriverCharges,
        secondaryDriverCharges,
      } = values;

      if (G.isAllTrue(isInvoice, isCarrier, isEditMode)) {
        const newValues = R.assoc(
          GC.FIELD_CHARGES,
          recalculateAllCharges(charges, props),
          values,
        );

        return setValues(newValues);
      }

      if (G.isTrue(isCarrier)) {
        const newValues = R.assoc(
          GC.FIELD_CARRIER_RATE_CHARGES,
          recalculateAllCharges(carrierRateCharges, props),
          values,
        );

        return setValues(newValues);
      }

      const newValues = {
        ...values,
        [GC.FIELD_CHARGES]: recalculateAllCharges(charges, props),
        [GC.FIELD_FLEET_VENDOR_CHARGES]: recalculateAllCharges(fleetVendorCharges, props),
        [GC.FIELD_PRIMARY_DRIVER_CHARGES]: recalculateAllCharges(primaryDriverCharges, props),
        [GC.FIELD_SECONDARY_DRIVER_CHARGES]: recalculateAllCharges(secondaryDriverCharges, props),
      };

      setValues(newValues);
    },
  }),
  withRecalculateChargesOnChangeTotals,
  withRecalculateChargesOnChangeRecalculateId,
  pure,
);

const withInitialGLCodeMappings = compose(
  withHandlers({
    handleSetInitialGLCodes: (props: Object) => () => {
      const { rateProps, isInvoice, isCarrier, isEditMode, isFleetInvoice, glCodeMappings, chargesArrayName } = props;

      const { values, setValues } = rateProps;
      const { carrierRateCharges } = values;

      if (R.not(isInvoice)) return;

      if (R.and(isInvoice, G.isTrue(isEditMode))) return;

      const invoiceCurrency = G.getCurrencyFromObject2(values);

      if (G.isTrue(isCarrier)) {
        const newValues = R.assoc(
          GC.FIELD_CARRIER_RATE_CHARGES,
          setGlCodeToCharges(recalculateAllCharges(carrierRateCharges, props), glCodeMappings, invoiceCurrency),
          values,
        );

        return setValues(newValues);
      }

      if (G.isTrue(isFleetInvoice)) {
        const { primaryDriverCharges, fleetVendorCharges, secondaryDriverCharges } = values;

        const newValues = {
          ...values,
          fleetVendorCharges: setGlCodeToCharges(fleetVendorCharges, glCodeMappings, invoiceCurrency),
          primaryDriverCharges: setGlCodeToCharges(primaryDriverCharges, glCodeMappings, invoiceCurrency),
          secondaryDriverCharges: setGlCodeToCharges(secondaryDriverCharges, glCodeMappings, invoiceCurrency),
        };

        return setValues(newValues);
      }

      const currentCharges = R.pathOr([], ['values', chargesArrayName], rateProps);
      const newValues = {
        ...values,
        [chargesArrayName]: setGlCodeToCharges(currentCharges, glCodeMappings, invoiceCurrency),
      };

      setValues(newValues);
    },
    handleChangeCurrency: (props: Object) => (propName: string, newProps: Object, prevProps: string) => {
      const {
        rateProps,
        isInvoice,
        isCarrier,
        isDriverRate,
        isCarrierRate,
        glCodeMappings,
        isFleetInvoice,
        chargesArrayName,
      } = props;

      const { values, setValues } = rateProps;

      const convertedTotal = G.convertCurrencyByExchangeRateToFloatOrNull(
        prevProps.customerTotal,
        prevProps.currency,
        newProps.currency,
      );

      if (R.not(isInvoice)) {
        if (R.or(isDriverRate, isCarrierRate)) {
          return setValues(R.assoc(GC.FIELD_CUSTOMER_TOTAL, convertedTotal, values));
        }

        return;
      }

      const currentCharges = R.pathOr([], ['values', chargesArrayName], rateProps);
      const invoiceCurrency = G.getPropFromObject(propName, newProps);
      const invoiceGlCode = getInvoiceGlCode2(glCodeMappings, invoiceCurrency);

      if (G.isTrue(isFleetInvoice)) {
        const { primaryDriverCharges, fleetVendorCharges, secondaryDriverCharges } = values;

        const newValues = {
          ...values,
          [GC.FIELD_CUSTOMER_TOTAL]: convertedTotal,
          [GC.FIELD_GL_CODE]: G.fromNullToEmptyString(invoiceGlCode),
          fleetVendorCharges: setGlCodeToCharges(fleetVendorCharges, glCodeMappings, invoiceCurrency),
          primaryDriverCharges: setGlCodeToCharges(primaryDriverCharges, glCodeMappings, invoiceCurrency),
          secondaryDriverCharges: setGlCodeToCharges(secondaryDriverCharges, glCodeMappings, invoiceCurrency),
        };

        return setValues(newValues);
      }

      if (G.isTrue(isCarrier)) {
        const newValues = {
          ...values,
          [GC.FIELD_CUSTOMER_TOTAL]: convertedTotal,
          [GC.FIELD_GL_CODE]: G.fromNullToEmptyString(invoiceGlCode),
          [chargesArrayName]: setGlCodeToCharges(currentCharges, glCodeMappings, invoiceCurrency),
        };

        return setValues(newValues);
      }

      const newValues = {
        ...values,
        [GC.FIELD_GL_CODE]: G.fromNullToEmptyString(invoiceGlCode),
        [chargesArrayName]: setGlCodeToCharges(currentCharges, glCodeMappings, invoiceCurrency),
      };

      setValues(newValues);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'glCodeMappings',
    callbackName: 'handleSetInitialGLCodes',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_CURRENCY,
    callbackName: 'handleChangeCurrency',
  }),
  pure,
);

export const withDMCharges = compose(
  withRecalculateAllCharges,
  withLinehaulAdjustments,
  withInitialGLCodeMappings,
);
