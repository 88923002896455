import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

export const Wrapper = styled(Box)`
  display: ${({ display }: Object) => display || 'unset'};
  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

export const StatusLabel = styled.div`
  top: -8px;
  right: -8px;
  width: 17px;
  height: 17px;
  position: absolute;
  border-radius: 50%;
  display: inline-block;
  border: ${() => `1px solid ${G.getTheme('statusLabel.borderColor')}`};
  background-color: ${({ status }: Object) => G.getTheme(G.ifElse(
    status,
    'statusLabel.bgPositiveExternal',
    'statusLabel.bgNegativeExternal',
  ))};
  & div {
    top: 4px;
    width: 7px;
    right: 4px;
    height: 7px;
    margin: auto;
    position: absolute;
    border-radius: 50%;
    background-color: ${({ status }: Object) => G.getTheme(G.ifElse(
      status,
      'statusLabel.bgPositiveInternal',
      'statusLabel.bgNegativeInternal',
    ))};
  }
`;
