import React from 'react';
import * as R from 'ramda';
import ReactDOM from 'react-dom';
import {
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'react-recompose';
// component actions-dropdown
import { Wrapper } from './ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('coords', 'setCoords', { right: 0 }),
  withHandlers({
    setPosition: (props: Object) => (parentOptions: Object, elOptions: any) => {
      const { type, setCoords, minusTop } = props;

      const positionType = {
        left: parentOptions.left,
        right: R.subtract(window.innerWidth, parentOptions.right),
        center: R.sum([
          window.innerWidth,
          -parentOptions.left,
          -R.divide(parentOptions.width, 2),
          -R.divide(elOptions.width, 2),
        ]),
      };

      let position = {
        margin: '10px 0 0 0',
        right: positionType[type],
        top: R.subtract(parentOptions.bottom, R.or(minusTop, 10)),
      };

      if (R.lt(R.subtract(window.innerHeight, R.add(parentOptions.bottom, elOptions.height)), 60)) {
        const openTopParams = {
          margin: '0 0 10px 0',
          top: R.sum([parentOptions.top, -10, -elOptions.height]),
        };

        position = R.mergeRight(position, openTopParams);
      }

      setCoords(position);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { parent, setPosition } = this.props;

      const elOptions = ReactDOM.findDOMNode(this).getBoundingClientRect();
      const parentOptions = ReactDOM.findDOMNode(parent).getBoundingClientRect();

      setPosition(parentOptions, elOptions);
    },
  }),
);

export const Popup = enhance(({ type, coords, children }: Object) => (
  <Wrapper type={type} positions={coords} data-testid='testid-Popup-Wrapper'>
    {children}
  </Wrapper>
));
