import React from 'react';
import * as R from 'ramda';
// components
import TextComponent from '../text';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Text, AbsoluteBox, RelativeBox, SimpleWrapper } from '../../ui';
// component tracking
import { StyledCard } from './ui';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const StatusMessage = ({ item, index, setNewCenter }: Object) => {
  const { statusDate } = item;

  const dateString = G.convertDateTimeToConfigFormat(statusDate);
  const statusCodePath = [GC.FIELD_STATUS_MESSAGE_CODE, GC.FIELD_DISPLAYED_VALUE];
  const statusReasonCodePath = [GC.FIELD_STATUS_MESSAGE_REASON_CODE, GC.FIELD_DISPLAYED_VALUE];
  const title = G.getWindowLocale('titles:click-to-zoom-in-to-the-point', 'Click To Zoom In To The Point');

  return (
    <StyledCard title={title} onClick={() => setNewCenter(item)}>
      <SimpleWrapper>
        <RelativeBox mr={10}>
          {I.locationMarker(darkBlueColor, 32, 32)}
          <AbsoluteBox
            top={0}
            width={31}
            height={24}
            justifyContent='center'
          >
            <Text
              fontSize={11}
              fontWeight={600}
              color={darkBlueColor}
            >
              {R.inc(index)}
            </Text>
          </AbsoluteBox>
        </RelativeBox>
        <Box>
          {
            G.isNotNilAndNotEmpty(R.path(statusCodePath, item)) &&
            <SimpleWrapper mb='5px' alignItems='flex-start'>
              <TextComponent>
                {R.path(statusCodePath, item)}
              </TextComponent>
              {
                G.isNotNilAndNotEmpty(R.path(statusReasonCodePath, item)) &&
                <TextComponent ml='5px'>
                  {`(${R.path(statusReasonCodePath, item)})`}
                </TextComponent>
              }
            </SimpleWrapper>
          }
          <Box mb='5px'>{I.calendar()} {dateString}</Box>
          <Box>{I.locationMarker()} {G.concatLocationFields(item)}</Box>
        </Box>
      </SimpleWrapper>
    </StyledCard>
  );
};

export default StatusMessage;
