import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../text';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const defaultStyles = { mx: '5px' };

export const TextBtn1 = (props: Object) => {
  const { text, styles, bgColor, textColor, borderColor, onClickHandler } = props;

  const whiteColor = G.getTheme('colors.white');
  const blueColor = G.getTheme('colors.dark.blue');

  const bgColorToUse = R.or(bgColor, whiteColor);
  const textColorToUse = R.or(textColor, blueColor);
  const borderColorToUse = R.or(borderColor, blueColor);
  const stylesToUse = G.mergeAndSpreadUiStyles(defaultStyles, styles);

  return (
    <TextComponent
      p='3px 8px'
      cursor='pointer'
      bg={bgColorToUse}
      borderRadius='4px'
      color={textColorToUse}
      onClick={onClickHandler}
      border={`1px solid ${borderColorToUse}`}
      {...stylesToUse}
    >
      {text}
    </TextComponent>
  );
};
