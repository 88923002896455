import React from 'react';
import * as R from 'ramda';
// components
import { Label } from '../label';
// features
import { AuthWrapper } from '../../features/permission';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Flex } from '../../ui';
//////////////////////////////////////////////////

export const PageTabs = (props: Object) => {
  const {
    zI,
    top,
    tabs,
    height,
    position,
    activeTab,
    withAudit,
    setActiveTab,
    isLabelExist = false,
  } = props;

  let pageNavTabs = tabs;

  if (withAudit) {
    const iconColor = G.getTheme(G.ifElse(
      R.equals(activeTab, 'audit'),
      'detailPage.content.iconColor',
      'detailPage.content.grayIconColor',
    ));

    let auditNavItem = {
      tabName: 'audit',
      action: () => setActiveTab('audit'),
      frontIcon: I.renderAuditHistoryIcon(iconColor),
    };

    if (isLabelExist) {
      auditNavItem = R.assoc('label', G.getWindowLocale('titles:audit', 'Audit'), auditNavItem);
    }

    pageNavTabs = R.append(auditNavItem, tabs);
  }

  return (
    <Flex
      width={39}
      right='0px'
      zIndex={zI}
      border='1px solid'
      top={R.or(top, 250)}
      alignItems='flex-end'
      flexDirection='column'
      borderTopLeftRadius={10}
      height={R.or(height, 130)}
      borderBottomLeftRadius={10}
      justifyContent='space-around'
      position={R.or(position, 'fixed')}
      bg={G.getTheme('colors.light.lightGrey')}
      borderColor={G.getTheme('colors.lightGrey')}
    >
      {
        pageNavTabs.map((tab: Object, i: number) => {
          const { action, tabName, permissions } = tab;

          return (
            <AuthWrapper key={i} has={permissions}>
              <Label {...tab} frontAction={() => (action(tabName))} />
            </AuthWrapper>
          );
        })
      }
    </Flex>
  );
};
