import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// components
import { closeAllModals } from '../components/modal/actions';
// features
import {
  sendLogOutRequest,
  receivedLogInSuccess,
  receivedNalLogInSuccess,
  receivedBoshLogInSuccess,
  receivedSessionValidationSuccess,
} from '../features/auth/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

const getExchangeRateMapByDate = async (userBranchGuid: string) => {
  try {
    const options = {
      params: {
        [GC.BRANCH_GUID]: R.or(G.getAmousCurrentBranchGuidFromWindow(), userBranchGuid),
      },
    };

    const res = await sendRequest('get', endpointsMap.exchangeRateMapByDate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.setItemToWindow('amousExchangeRateMapByDate', data);
    }
  } catch (error) {
    G.handleException('error', 'getExchangeRateMapByDate exception');
  }
};

const getExchangeRateMapByDateLogic = createLogic({
  warnTimeout: 0,
  cancelType: sendLogOutRequest,
  type: [
    receivedLogInSuccess,
    receivedNalLogInSuccess,
    receivedBoshLogInSuccess,
    receivedSessionValidationSuccess,
  ],
  process({ getState, cancelled$ }: Object) {
    const state = getState();

    const userBranchGuid = R.or(
      R.path(['auth', 'loginData', 'enterprise_guid'], state),
      R.path(['auth', 'sessionData', 'enterprise_guid'], state),
    );

    getExchangeRateMapByDate(userBranchGuid);

    const interval = setInterval(() => {
      getExchangeRateMapByDate(userBranchGuid);
    }, 3600000);

    cancelled$.subscribe(() => {
      clearInterval(interval);
    });
  },
});

const locationChangeLogic = createLogic({
  type: GC.LOCATION_CHANGE_ACTION,
  validate({ action }: Object, allow: void) {
    allow(action);
  },
  process(_: any, dispatch: void, done: void) {
    dispatch(closeAllModals());
    done();
  },
});

export default [locationChangeLogic, getExchangeRateMapByDateLogic];
