import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../form-footer';
// forms
import { FieldsetComponent } from '../../forms/formik/fieldset';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const defaultStyles = {
  width: '280px',
  afterTop: '15px',
  errorTop: '110%',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  errorTextOverflow: 'ellipsis',
  inputsFlexDirection: 'column',
};

const fieldSettings = [
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:user-name',
    fieldName: GC.FIELD_USERNAME,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:password',
    fieldName: GC.FIELD_PASSWORD,
  },
];

const defaultFields = {
  [GC.FIELD_USERNAME]: null,
  [GC.FIELD_PASSWORD]: null,
};

const validationSchema = () => Yup.object().shape({
  [GC.FIELD_USERNAME]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_PASSWORD]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
});

const enhance = compose(
  withFormik({
    validationSchema,
    mapPropsToValues: () => defaultFields,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const ConnectToCustomMailForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent {...G.getFormikProps(props)} fields={fieldSettings} flexDirection='column' />
    <FormFooter boxStyles={{ p: '10px 5px' }} closeModal={props.closeModal} />
  </form>
);

export default enhance(ConnectToCustomMailForm);
