import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, withProps } from 'react-recompose';
// components
import { FormFooter2 } from '../form-footer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Fieldset2 } from '../../forms';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../hocs';
// utilities
import endpointsMap from '../../utilities/endpoints';
// insurance
import {
  insuranceFields,
  defaultInsuranceFields,
  insuranceValidationSchema } from './settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('saveAndAddNew', 'setSaveAndAddNew', false),
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(insuranceValidationSchema),
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultInsuranceFields,
      props.initialValues,
    ),
    handleSubmit: (values: Object, { props, resetForm, setSubmitting }: Object) => {
      const { submitAction, saveAndAddNew } = props;

      const failCallback = () => setSubmitting(false);

      const successCallback = () => {
        setSubmitting(false);

        if (saveAndAddNew) resetForm();
      };

      if (saveAndAddNew) {
        submitAction({ values, resetForm, failCallback, successCallback, shouldCloseModal: false });
      } else {
        submitAction({ values, failCallback, successCallback });
      }
    },
  }),
  withProps(() => {
    const names = GC.CARRIER_INSURANCE_TYPE;
    const asyncEndpoint = endpointsMap.branchConfigsEndpoint;
    const asyncOptions = {
      params: {
        names,
        [GC.BRANCH_GUID]: G.getAmousCurrentBranchGuidFromWindow(),
      },
    };

    return { asyncOptions, asyncEndpoint };
  }),
  withAsyncInitialDataOnDidMount,
  withProps(({ asyncInitialData }: Object) => {
    const insuranceType = R.compose(
      G.addEmptyOptionToDropDown,
      G.getOptionsFromConfigValueByParentGuidOrGuid,
      R.pathOr([], ['data', 'dropdowns', 0, 'options']),
    )(asyncInitialData);

    return { optionsForSelect: { insuranceType } };
  }),
  pure,
);

const darkBlueColor = G.getTheme('colors.dark.blue');

const getSaveAndAddNewButton = (props: Object) => {
  const { values, setSaveAndAddNew } = props;

  if (G.isNotNil(values.version)) return null;

  return [{
    type: 'submit',
    action: () => setSaveAndAddNew(true),
    displayText: G.getWindowLocale('actions:save-and-add-new', 'Save and Add New'),
    buttonStyles: {
      mr: 20,
      ml: 'auto',
      bgColor: 'none',
      background: 'none',
      border: '1px solid',
      borderRadius: '5px',
      width: 'max-content',
      textColor: darkBlueColor,
      textTransform: 'uppercase',
      borderColor: darkBlueColor,
    },
  }];
};

const fieldsWrapperStyles = {
  pt: 15,
  width: 600,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const InsuranceForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      {...props.optionsForSelect}
      fields={insuranceFields}
      fieldsWrapperStyles={fieldsWrapperStyles}
    />
    <FormFooter2
      actionButtons={getSaveAndAddNewButton(props)}
      submitAction={() => props.setSaveAndAddNew(false)}
    />
  </form>
);

export default enhance(InsuranceForm);
