import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex, CancelButton, ActionButton } from '../../ui';
//////////////////////////////////////////////////

const commonBtnStyles = {
  height: 32,
  width: 150,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const cancelStyles = {
  ...commonBtnStyles,
  mr: 20,
  textColor: G.getTheme('colors.greyMatterhorn'),
  borderColor: G.getTheme('colors.greyMatterhorn'),
};

const submitStyles = {
  ...commonBtnStyles,
  bgColor: G.getTheme('colors.white'),
  textColor: G.getTheme('colors.dark.blue'),
};

const additionalBtnStyles = {
  ...submitStyles,
  mr: 20,
  textTransform: 'initial',
};

const ButtonsBlock = ({ buttons, saveAsNew, showSaveAsNew, saveAsNewBtnType }: Object) => (
  <Box>
    {
      G.isTrue(showSaveAsNew) &&
      <ActionButton
        {...additionalBtnStyles}
        onClick={saveAsNew}
        type={R.or(saveAsNewBtnType, 'button')}
      >
        {G.getWindowLocale('actions:save-as-new', 'Save As New')}
      </ActionButton>
    }
    {
      buttons.map(({ type, action, width, btnText, disabled, handleClick }: Object, i: number) => (
        <ActionButton
          {...additionalBtnStyles}
          key={i}
          disabled={disabled}
          width={R.or(width, 'auto')}
          type={R.or(type, 'button')}
          onClick={R.or(action, handleClick)}
        >
          {btnText}
        </ActionButton>
      ))
    }
  </Box>
);

export const FormButtons2 = (props: Object) => {
  const {
    pl,
    pr,
    style,
    zIndex,
    formId,
    btnText,
    position,
    submitting,
    saveDisabled,
    showSaveAsNew,
    justifyContent,
    hideFormSaveBtn,
    handleClickSave,
    saveAsNewBtnType,
    showCancel = true,
    handleClickCancel,
    handleClickSaveAsNew,
  } = props;

  return (
    <Flex
      left='0px'
      height={60}
      bottom='0px'
      width='100%'
      style={style}
      pl={R.or(pl, 70)}
      pr={R.or(pr, 20)}
      borderTop='1px solid'
      zIndex={R.or(zIndex, 12)}
      transition='all .2s linear'
      bg={G.getTheme('colors.white')}
      position={R.or(position, 'fixed')}
      justifyContent={R.or(justifyContent, 'space-between')}
      borderColor={G.getTheme('forms.actionsFooter.borderTopColor')}
    >
      {
        G.isTrue(showCancel) &&
        <CancelButton
          {...cancelStyles}
          type='button'
          onClick={handleClickCancel}
        >
          {G.getWindowLocale('actions:cancel', 'Cancel')}
        </CancelButton>
      }
      <Flex {...G.ifElse(showCancel, {}, { width: '100%', justifyContent: 'flex-end' })}>
        <ButtonsBlock
          showSaveAsNew={showSaveAsNew}
          saveAsNew={handleClickSaveAsNew}
          saveAsNewBtnType={saveAsNewBtnType}
          buttons={R.pathOr([], ['buttons'], props)}
        />
        {
          R.not(G.isTrue(hideFormSaveBtn)) &&
          <ActionButton
            {...submitStyles}
            type='submit'
            form={formId}
            onClick={handleClickSave}
            disabled={R.or(saveDisabled, submitting)}
            cursor={G.ifElse(R.or(saveDisabled, submitting), 'not-allowed', 'pointer')}
          >
            {R.or(btnText, G.getWindowLocale('actions:save', 'Save'))}
          </ActionButton>
        }
      </Flex>
    </Flex>
  );
};
