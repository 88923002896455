import * as R from 'ramda';
import React from 'react';
import { pure, branch, compose, lifecycle, withState, withHandlers, renderNothing } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../hocs';
// ui
import { Box, Flex } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const NormalizedCurrency = ({ p, title, total, normalizedTotal }: Object) => {
  const { currency, exchangeRate, destinationCurrency } = normalizedTotal;

  const calcNormalizedTotal = R.multiply(total, exchangeRate);

  const normalizedText = R.or(title, G.getWindowLocale('titles:normalized-total', 'Normalized Total'));

  return (
    <Flex p={p || 15} justifyContent='center'>
      <Box
        p='5px'
        borderRadius='5px'
        bg='titleDarkBlue'
        color={G.getTheme('colors.white')}
      >
        {`${normalizedText}:
        ${G.getCurrencySymbol(R.or(destinationCurrency, currency))} ${
          G.NaNToZero(G.mathRoundNumber(calcNormalizedTotal))}`}
      </Box>
    </Flex>
  );
};

const enhance = compose(
  withState('normalizedTotal', 'setNormalizedTotal', ({ normalizedTotal }: Object) => normalizedTotal),
  withHandlers({
    handleGetNormalizedTotal: (props: Object) => async () => {
      const { sourceCurrency, setNormalizedTotal } = props;

      if (G.isNilOrEmpty(sourceCurrency)) return;

      const branchGuid = G.getAmousCurrentBranchGuidFromWindow();

      const options = {
        params: {
          sourceCurrency,
          [GC.BRANCH_GUID]: branchGuid,
        },
      };

      const res = await sendRequest('post', endpointsMap.exchangeRateByMasterCurrency, options);

      const { data, status } = res;

      const amousExchangeRateByBranch = G.getItemFromWindow('amousExchangeRateByBranch');

      G.setItemToWindow(
        'amousExchangeRateByBranch',
        R.assocPath([branchGuid, sourceCurrency], data, amousExchangeRateByBranch),
      );

      if (G.isResponseSuccess(status)) {
        if (R.propEq(sourceCurrency, GC.FIELD_DESTINATION_CURRENCY, data)) {
          setNormalizedTotal(null);
        } else {
          setNormalizedTotal(data);
        }
      } else {
        G.handleException('error', 'handleGetNormalizedTotal exception');
      }
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'sourceCurrency',
    callbackName: 'handleGetNormalizedTotal',
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetNormalizedTotal();
    },
  }),
  branch(
    ({ sourceCurrency, normalizedTotal }: Object) => G.isAnyTrue(
      G.isNilOrEmpty(sourceCurrency),
      G.isNilOrEmpty(normalizedTotal),
      R.pathEq(sourceCurrency, [GC.FIELD_CURRENCY], normalizedTotal),
      R.pathEq(sourceCurrency, [GC.FIELD_DESTINATION_CURRENCY], normalizedTotal),
    ),
    renderNothing,
  ),
  pure,
);

export default enhance(NormalizedCurrency);
