/*global google*/
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withScriptjs } from 'react-google-maps';
import { createStructuredSelector } from 'reselect';
import { compose, withProps, withHandlers } from 'react-recompose';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
// components
import { Map, StopInfo, StopMarker, MarkerWithInfo } from '../map';
// helpers/constants
import * as G from '../../helpers';
import { makeSelectGoogleApiKey } from '../../common/selectors';
// features
import { convertLoadEventsForMap } from '../../features/dispatch-planner-events/helpers';
//////////////////////////////////////////////////

const locationsWithContent = (stops: Array) => (
  R.map((stop: Object) => ({
    ...stop,
    markerContent: <StopMarker {...stop} />,
    infoContent: <StopInfo {...stop} items={stop.items} />,
  }), R.values(stops))
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  googleAPIKey: makeSelectGoogleApiKey(state),
});

const defaultDrawingModes = [
  'POLYGON',
  'RECTANGLE',
];

const enhance = compose(
  connect(mapStateToProps),
  withProps((props: Object) => ({
    googleMapURL: G.makeGoogleAPILinkWithKey(props.googleAPIKey),
    drawingModes: R.pathOr(defaultDrawingModes, ['drawingModes'], props),
    locations: G.makeLocationsWithTransform(locationsWithContent(convertLoadEventsForMap(props.locations))),
    loadingElement: (
      <div style={{ height: '100%' }} />
    ),
  })),
  withScriptjs,
  withHandlers({
    handleCompletePolygon: (props: Object) => (polygon: Object) => {
      const { locations, handleCompleteSelection } = props;

      const results = R.filter(
        ({ latLng }: Object) => google.maps.geometry.poly.containsLocation(
          latLng,
          polygon,
        ),
        locations,
      );

      const callback = () => polygon.setMap(null);

      G.callFunctionWithArgs(handleCompleteSelection, { results, callback });
    },
    handleCompleteRectangle: (props: Object) => (rectangle: Object) => {
      const { locations, handleCompleteSelection } = props;

      const results = R.filter(
        ({ latLng }: Object) => rectangle.getBounds().contains(
          latLng,
          rectangle,
        ),
        locations,
      );

      const callback = () => rectangle.setMap(null);

      G.callFunctionWithArgs(handleCompleteSelection, { results, callback });
    },
  }),
);

const GoogleMap = enhance((props: Object) => (
  <Map>
    <DrawingManager
      defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
      onPolygonComplete={props.handleCompletePolygon}
      onRectangleComplete={props.handleCompleteRectangle}
      defaultOptions={{
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.RECTANGLE,
            // google.maps.drawing.OverlayType.CIRCLE, TODO: check if needed
          ],
        },
        polygonOptions: {
          zIndex: 1,
          editable: true,
          fillOpacity: 1,
          strokeWeight: 5,
          clickable: false,
          fillColor: '#ffff00',
        },
      }}
    />
    <MarkerWithInfo locations={props.locations} />
  </Map>
));

export default GoogleMap;
