import * as R from 'ramda';
import React from 'react';
// components
import TextComponent from '../text';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// component filter
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

const LabelWrapper = (props: Object) => (
  <Flex
    p='2px 5px'
    height={22}
    minWidth={47}
    m='0 10px 0 3px'
    overflow='hidden'
    border='1px solid'
    borderRadius='30px'
    maxWidth={R.or(props.maxWidth, 'max-content')}
    borderColor={G.getTheme('colors.light.darkGrey')}
  >
    <TextComponent
      width='100%'
      maxWidth='95%'
      withEllipsis={true}
      display='inline-block'
      title={props.displayText}
    >
      {props.displayText}
    </TextComponent>
    <Box
      ml='5px'
      height={15}
      cursor='pointer'
      borderRadius='50%'
      onClick={props.handleClick}
      bg={G.getTheme('colors.light.darkGrey')}
    >
      {I.crossInRound()}
    </Box>
  </Flex>
);

export default LabelWrapper;
