import React from 'react';
import * as R from 'ramda';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { ReactSelect } from '../../ui';
// components additional-filters
import { getFilterTypeOptions } from './settings';
//////////////////////////////////////////////////

const TypeSelect = ({ handleChange, additionalFilterType, additionalFilterOptions }: Object) => (
  <ReactSelect
    onChange={handleChange}
    value={additionalFilterType}
    shouldNotGetValueFromOptions={true}
    options={R.or(additionalFilterOptions, getFilterTypeOptions())}
    placeholder={G.getWindowLocale('titles:tilter-type', 'Filter Type')}
    additionalStyles={{
      container: (baseStyles: Object) => ({
        ...baseStyles,
        width: 230,
        margin: '0 10px',
      }),
    }}
  />
);

const enhance = compose(
  withHandlers({
    handleGetEntitiesByTypeValue: (props: Object) => (typeValue: string) => {
      const {
        asyncUnitIdList,
        getAsyncGetUnitIdList,
        asyncDriverListFullName,
        getBranchChildrenWithShared,
        getAsyncGetDriverListFullName,
        asyncBranchChildrenWithShared,
      } = props;

      if (R.equals(typeValue, GC.ADDITIONAL_FILTER_TYPE_BRANCH)) {
        if (G.isNilOrEmpty(asyncBranchChildrenWithShared)) return getBranchChildrenWithShared();
      }

      if (R.equals(typeValue, GC.ADDITIONAL_FILTER_TYPE_DRIVER)) {
        if (G.isNilOrEmpty(asyncDriverListFullName)) return getAsyncGetDriverListFullName();
      }

      if (R.equals(typeValue, GC.ADDITIONAL_FILTER_TYPE_TRUCK)) {
        if (G.isNilOrEmpty(asyncUnitIdList)) return getAsyncGetUnitIdList();
      }
    },
  }),
  withHandlers({
    handleChange: (props: Object) => (value: any) => {
      const {
        setAdditionalFilterType,
        setAdditionalFilterValue,
        handleGetEntitiesByTypeValue,
        additionalFiltersApplyHandler,
      } = props;

      setAdditionalFilterType(value, () => {
        const typeValue = R.path(['value'], value);

        setAdditionalFilterValue('');

        if (R.isNil(value)) {
          additionalFiltersApplyHandler({
            additionalFilterType: null,
            additionalFilterValue: null,
          });
        }

        handleGetEntitiesByTypeValue(typeValue);
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { typeValue, handleGetEntitiesByTypeValue } = this.props;

      if (G.isNotNilAndNotEmpty(typeValue)) handleGetEntitiesByTypeValue(typeValue);
    },
  }),
  pure,
);

export default enhance(TypeSelect);
