import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Span } from '../../ui';
//////////////////////////////////////////////////

const RequestStatusModal = (props: Object) => {
  const { title, status, errors, requestStatusInfo } = props;

  const colors = {
    SUCCESS: 'colors.light.blue',
    FAILED: 'colors.light.mainRed',
    PARTIAL_SUCCESS: 'colors.dark.yellow',
  };

  return (
    <Box>
      <Box
        p='8px'
        fontSize={14}
        fontWeight='bold'
        color={G.getTheme('colors.light.mainLight')}
        bg={G.getTheme(R.pathOr('colors.light.blue', [status], colors))}
      >
        {title}
      </Box>
      <Box p={15}>
        <Box mx='8px' fontSize={14} fontWeight='bold'>
          {G.getWindowLocale('titles:request-status', 'Request Status')}:
        </Box>
        <Box p='3px 8px' fontSize={12}>{requestStatusInfo}</Box>
        {
          G.isNotNilAndNotEmpty(errors) &&
          <Box>
            <Box m='8px 8px 0 8px' fontSize={14} fontWeight='bold'>
              {G.getWindowLocale('titles:errors', 'Errors')}:
            </Box>
            {
              errors.map(({ key, messageArray }: Object, i: number) => (
                <Box key={i} p='3px 8px' fontSize={12} wordBreak='break-all'>
                  <Span mr='6px' fontWeight='bold'>{key}:</Span>
                  {R.join(', ', messageArray)}
                </Box>
              ))
            }
          </Box>
        }
      </Box>
    </Box>
  );
};

export default RequestStatusModal;
