import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Form, Fieldset2 } from '../../../forms';
// hocs
import { withAsyncGetBranchListByType } from '../../../hocs';
// components report-format
import { getReportFields, validationSchema, defaultReportFields } from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncGetBranchListByType(GC.BRANCH_TYPE_ENUM_CUSTOMER),
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultReportFields,
      initialValues,
    ),
  }),
  pure,
);

export const ReportForm = enhance((props: Object) => {
  const { roles, handleSubmit, branchListByType } = props;

  const roleOptions = G.getOptionsFromDataArrayByPath([GC.FIELD_NAME], [GC.FIELD_GUID], roles);

  return (
    <Form id='form_wrapper' onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        roleOptions={roleOptions}
        fields={getReportFields(roles)}
        fieldsWrapperStyles={{ mt: 15 }}
        customerOptions={R.or(branchListByType, [])}
      />
      <FormFooter2 />
    </Form>
  );
});
