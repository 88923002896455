import * as R from 'ramda';
import { space } from 'styled-system';
import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Flex } from '../../ui';
//////////////////////////////////////////////////

export const BlankCardWrapper = styled(Flex)`
  padding: 15px;
  margin: 30px 10px;
  border-radius: 7px;
  background-color: ${() => G.getTheme('colors.white')};
  box-shadow: 0 4px 25px 0 ${() => G.getTheme('colors.boxShadowLightGrey')};
`;

const getTableRowBgColor = (props: Object, hover: boolean) => (
  R.or(
    props.bgColor,
    G.ifElse(
      props.active,
      G.getTheme(G.ifElse(
        props.mainColors,
        R.or(props.activeRowColor, 'tables.rows.bgColorActive'),
        'tables.rows.additionalBgColor',
      )),
      G.ifElse(
        hover,
        G.getTheme('tables.rows.hoverBgColor'),
        G.getTheme(G.ifElse(
          R.prop('highlighted', props),
          'tables.rows.highlightedBgColor',
          'tables.rows.bgColor',
        )),
      ),
    ),
  )
);

export const LoaderWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  position: absolute;

  background-color: ${() => G.getTheme('colors.boxShadowGrey')};
`;

export const RowWrapper = styled.div`
  border-bottom: 1px solid ${() => G.getTheme('colors.lightGrey')};

  background-color: ${(props: Object) => getTableRowBgColor(props)};

  &:hover {
    background-color: ${(props: Object) => getTableRowBgColor(props, true)};
  }
`;

export const StickyRowWrapper = styled(Flex)`
  z-index: 999;
  position: sticky !important;
  position: -webkit-sticky !important;

  background-color: ${() => G.getTheme('colors.light.lightGrey')};
`;

export const CellWrapper = styled(Flex)`
  height: 100%;

  box-shadow: ${({ withBoxShadow }: Object) => (
    withBoxShadow && `inset -2px 0px 0px ${G.getTheme('colors.boxShadowLightGrey')}`
  )};
`;

export const EditableCellWrapper = styled(CellWrapper)`
  & input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
  }
`;

export const Checkbox = styled.input`
  ${space}

  z-index: 0;
  position: relative;

  &:focus {
    outline: none;
  }

  &:before {
    left: 0;
    top: 50%;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: absolute;
    transform: translate(0, -50%);

    background-color: ${() => G.getTheme('forms.checkboxes.bgColor')};

    border: 1px solid ${({ disabled }: Object) =>
      G.getTheme(G.ifElse(
        G.isTrue(disabled),
        'colors.light.lightGrey',
        'forms.checkboxes.borderColor',
        ),
      )
    };
  }

  &:checked:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: absolute;

    background-color: ${() => G.getTheme('forms.checkboxes.bgColorChecked')};

    border: 1px solid ${({ disabled, mainColors }: Object) => G.getTheme(G.ifElse(
      disabled,
      'colors.light.lightGrey',
      G.ifElse(
        G.isTrue(mainColors),
        'forms.checkboxes.borderColorChecked',
        'forms.checkboxes.additionalBorderColor',
      ),
    ))};
  }

  &:checked:after {
    left: 0;
    top: 50%;
    width: 3px;
    height: 8px;
    content: '';
    position: absolute;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(0, -90%);

    border: 1px solid ${(props: Object) => G.getTheme(G.ifElse(
      props.mainColors,
      'forms.checkboxes.textColorChecked',
      'forms.checkboxes.additionalTextColor',
    ))};
  }
`;

export const TitleCellWrapper = styled(Flex)`
  background-color: ${() => G.getTheme('colors.light.lightGrey')};
`;

export const TableWrapper = styled(Flex)`
  overflow: auto;
  position: relative;
  border-radius: 0px;

  background-color: ${() => G.getTheme('colors.white')};
  border: 1px solid ${() => G.getTheme('colors.lightGrey')};
`;
