import styled from 'styled-components';
import { space, color, height, fontSize } from 'styled-system';
//////////////////////////////////////////////////

const Textarea = styled.textarea`
  ${space}
  ${color}
  ${height}
  ${fontSize}

  width: 100%;
  cursor: text;
  resize: none;
  border: none;
  outline: none;
`;

Textarea.defaultProps = {
  fontSize: 12,
  height: '100%',
  backgroundColor: 'transparent',
};

export {
  Textarea,
};

