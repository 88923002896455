import * as R from 'ramda';
import React, { Fragment } from 'react';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

const expandedEnhance = compose(
  withState('expanded', 'setExpanded', R.prop('expanded')),
  withHandlers({
    handleToggle: ({ expanded, setExpanded, handleToggleSection }: Object) => () => {
      setExpanded(R.not);
      G.callFunctionWithArgs(handleToggleSection, expanded);
    },
  }),
  pure,
);

export const FormSectionHeader = expandedEnhance((props: Object) => {
  const { title, action, expanded, children, setExpanded, handleToggle, additionalStyles } = props;

  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');
  const darkBlueColor = G.getTheme('colors.dark.blue');

  return (
    <Fragment>
      <Flex
        p='5px'
        my='5px'
        fontWeight='bold'
        alignItems='center'
        color={darkBlueColor}
        justifyContent='center'
        textTransform='uppercase'
        bg={G.getTheme('colors.whiteGrey')}
        {...additionalStyles}
      >
        <Box mr={15} ml='auto'>{title}</Box>
        {
          G.isFunction(action) &&
          <Flex
            cursor='pointer'
            onClick={() => {
              action();

              if (R.not(expanded)) {
                setExpanded(true);
              }
            }}
          >
            {I.plusRound(darkBlueColor)}
          </Flex>
        }
        <Flex
          px='6px'
          ml='auto'
          cursor='pointer'
          onClick={handleToggle}
        >
          {I[toggleIconName](darkBlueColor)}
        </Flex>
      </Flex>
      {G.isTrue(expanded) && children}
    </Fragment>
  );
});
