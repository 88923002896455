import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../text';
import { PopperWrapper } from '../popper';
// features
import { AuthWrapper } from '../../features/permission';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, StyledLink } from '../../ui';
//////////////////////////////////////////////////

const Actions = ({ pageHeaderActions }: Object) => {
  const lightGreyColor = G.getTheme('colors.lightGrey');
  const lightBlueColor = G.getTheme('colors.light.blue');
  const lighterGreyColor = G.getTheme('colors.light.lightGrey');
  const textStyles = {
    mr: '8px',
    mt: '8px',
    fontSize: 11,
    p: '4px 8px',
    display: 'block',
    cursor: 'pointer',
    withEllipsis: true,
    borderRadius: '4px',
    textAlign: 'center',
    color: G.getTheme('colors.white'),
    bg: G.getTheme('colors.dark.blue'),
  };

  return (
    <Box p={10} pt='0px' width={400}>
      {
        pageHeaderActions.map(({ title, actions }: Object, index: number) => (
          <Box mt={10} key={index} borderRadius='5px' bg={lighterGreyColor}>
            <Box
              p='5px'
              fontSize={12}
              fontWeight='bold'
              textAlign='center'
              bg={lightGreyColor}
              borderTopLeftRadius='5px'
              borderTopRightRadius='5px'
            >
              {title}
            </Box>
            <Flex p='8px' pt='0px' flexWrap='wrap'>
              {actions.map((item: Object, index: number) => {
                const { type, link, text, action, permissions } = item;
                if (R.equals(type, 'link')) {
                  return (
                    <Flex mt='8px'>
                      <Box mr='8px'>{I.truckStop()}</Box>
                      <StyledLink
                        href={link}
                        fontSize={11}
                        target='_blank'
                        color={lightBlueColor}
                        hoverFontWeight='bold'
                        textDecoration='underline'
                      >
                        {text}
                      </StyledLink>
                    </Flex>
                  );
                }

                return (
                  <AuthWrapper key={index} has={permissions}>
                    <TextComponent {...textStyles} title={text} onClick={action}>
                      {text}
                    </TextComponent>
                  </AuthWrapper>
                );
              })}
            </Flex>
          </Box>
        ))
      }
    </Box>
  );
};

const getActionsText = (count: number) => G.ifElse(
  G.isZero(count),
  G.getWindowLocale('titles:actions', 'Actions'),
  `${G.getWindowLocale('titles:actions', 'Actions')} (${count})`,
);

export const PageHeaderActions = ({ count, pageHeaderActions }: Object) => (
  <PopperWrapper
    type='common'
    icon={I.arrowDownSimple()}
    text={getActionsText(count)}
    additionalStyles={{ popperComponentWrapperMargin: '0 0 0 15px' }}
    popperContent={<Actions pageHeaderActions={pageHeaderActions} />} />
);
