import React from 'react';
// component progress-bar
import { Progress, Wrapper, Inner } from './ui';
//////////////////////////////////////////////////

export const ProgressBar = (props: Object) => (
  <Wrapper>
    <Inner {...props} />
    {props.isShowProgress && <Progress>{props.progress}%</Progress>}
  </Wrapper>
);
