import * as R from 'ramda';
import React from 'react';
import { OuterClick } from 'react-outer-click';
import { compose, withState, withHandlers } from 'react-recompose';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('zIndex', 'setZIndex', (props: Object) => {
    const zIndex = R.path(['initialZIndex'], props);

    return zIndex;
  }),
  withHandlers({
    handleClick: (props: Object) => () => {
      const { setZIndex, zIndexOnClick } = props;

      setZIndex(R.or(zIndexOnClick, 1000));
    },
    handleOuterClick: (props: Object) => () => {
      const { setZIndex } = props;

      const zIndex = R.path(['initialZIndex'], props);

      setZIndex(zIndex);
    },
  }),
);

const ZIndexClick = enhance((props: Object) => {
  const { zIndex, children, handleClick, handleOuterClick } = props;

  return (
    <OuterClick onOuterClick={handleOuterClick}>
      <Box zIndex={zIndex} onClick={handleClick}>
        {children}
      </Box>
    </OuterClick>
  );
});

export default ZIndexClick;
