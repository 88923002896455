import React from 'react';
import * as R from 'ramda';
import { OuterClick } from 'react-outer-click';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import { Label } from '../label';
// features
import { AuthWrapper } from '../../features/permission';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Span, Flex, BoxHovered } from '../../ui';
// component actions-dropdown
import { Popup } from './popup';
//////////////////////////////////////////////////

const enhance = compose(
  withState('isOpen', 'setOpen', false),
  withHandlers({
    handleClick: (props: Object) => (e: Event, value: boolean) => {
      const { setOpen, callback } = props;

      if (R.not(R.pathOr(true, ['noPropagation'], props))) G.stopPropagation(e);

      if (G.isFunction(callback)) callback(value);

      setOpen(value);
    },
    handleOptionClick: (props: Object) => (event: Event, option: Object) => {
      G.stopPropagation(event);

      const { isOpen, setOpen } = props;

      setOpen(R.not(isOpen));

      if (option.action) {
        if (option.params) {
          option.action(option.params);
        } else {
          option.action();
        }
      }
    },
  }),
);

const Dropdown = (props: Object) => {
  const {
    guid,
    name,
    isOpen,
    options,
    position,
    minusTop,
    children,
    handleOptionClick,
  } = props;

  if (R.not(isOpen)) return null;

  const el = document.getElementById(`dropdown-${R.or(name, guid)}`);

  return (
    <Popup
      name={name}
      parent={el}
      type={position}
      minusTop={minusTop}
    >
      {
        G.isNotNilAndNotEmpty(options) &&
        options.map((option: Object, i: number) => (
          <AuthWrapper
            key={i}
            has={option.permissions}
          >
            <BoxHovered
              p='0 10px'
              width='100%'
              cursor='pointer'
              lineHeight={1.8}
              color={G.getTheme('dropDown.itemTextColor')}
              onClick={(e: Object) => handleOptionClick(e, option)}
              hoverColor={G.getTheme('dropDown.item.hover.textColor')}
              hoverBackgroundColor={G.getTheme('dropDown.item.hover.bgColor')}
            >
              <Label
                {...option}
                endLabel={option.endLabel}
                frontLabel={option.frontLabel}
              >
                <Span>{option.text}</Span>
              </Label>
            </BoxHovered>
          </AuthWrapper>
        ))
      }
      {G.isNilOrEmpty(options) && children}
    </Popup>
  );
};

const Component = (props: Object) => {
  const {
    type,
    name,
    guid,
    setOpen,
    endIcon,
    frontIcon,
    handleClick,
  } = props;

  const isClickType = R.equals(type, 'click');

  const handlers = G.ifElse(
    isClickType,
    { onClick: (e: Object) => handleClick(e, true) },
    {
      onMouseEnter: () => setOpen(true),
      onMouseLeave: () => setOpen(false),
    },
  );

  return (
    <Flex
      data-testid='testid-ActionsDropdown-wrapper'
      {...handlers}
      alignItems='center'
    >
      <Box
        color='gre'
        cursor='pointer'
        id={`dropdown-${R.or(name, guid)}`}
        color={G.getTheme(G.ifElse(
          isClickType,
          'dropDown.head.textColor',
          'dropDown.head.hover.textColor',
        ))}
      >
        <Label endIcon={endIcon} frontIcon={frontIcon}>{name}</Label>
      </Box>
      {
        isClickType &&
        <OuterClick onOuterClick={(e: Object) => handleClick(e, false)}>
          <Dropdown {...props} />
        </OuterClick>
      }
      {
        R.not(isClickType) && <Dropdown {...props} />
      }
    </Flex>
  );
};

export const ActionsDropdown = enhance(Component);
