// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import {
  ITEM_TEMPERATURE_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
} from '../../helpers/options';
//////////////////////////////////////////////////

const styles = {
  width: '220px',
  afterTop: '15px',
  errorTop: '110%',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  errorTextOverflow: 'ellipsis',
  inputsFlexDirection: 'column',
};

const compensationWeightRangeFields = [
  {
    ...styles,
    step: 'any',
    type: 'number',
    loc: 'titles:min-weight',
    fieldName: GC.FIELD_MIN_WEIGHT,
  },
  {
    ...styles,
    step: 'any',
    type: 'number',
    loc: 'titles:max-weight',
    fieldName: GC.FIELD_MAX_WEIGHT,
  },
  {
    ...styles,
    type: 'select',
    loc: 'titles:weight-uom',
    fieldName: GC.FIELD_WEIGHT_UOM,
    options: DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  },
];

const compensationTemperatureRangeFields = [
  {
    ...styles,
    step: 'any',
    type: 'number',
    loc: 'titles:min-temperature',
    fieldName: GC.FIELD_MIN_TEMPERATURE,
  },
  {
    ...styles,
    step: 'any',
    type: 'number',
    loc: 'titles:max-temperature',
    fieldName: GC.FIELD_MAX_TEMPERATURE,
  },
  {
    ...styles,
    type: 'select',
    loc: 'titles:temperature-uom',
    options: ITEM_TEMPERATURE_OPTIONS,
    fieldName: GC.FIELD_TEMPERATURE_UOM,
  },
];

const compensationQuantityRangeFields = [
  {
    ...styles,
    step: 'any',
    type: 'number',
    loc: 'titles:min-quantity',
    fieldName: GC.FIELD_MIN_QUANTITY,
  },
  {
    ...styles,
    step: 'any',
    type: 'number',
    loc: 'titles:max-quantity',
    fieldName: GC.FIELD_MAX_QUANTITY,
  },
  {
    ...styles,
    type: 'select',
    loc: 'titles:package-type',
    fieldName: GC.FIELD_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  },
];

const compensationDistanceRangesFields = [
  {
    ...styles,
    step: 'any',
    type: 'number',
    loc: 'titles:distance-range-from',
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM,
  },
  {
    ...styles,
    step: 'any',
    type: 'number',
    loc: 'titles:distance-range-to',
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO,
  },
  {
    ...styles,
    type: 'select',
    loc: 'titles:distance-range-uom',
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM,
    options: G.addEmptyOptionToDropDown(DEFAULT_DISTANCE_UOM_OPTIONS),
  },
];

const compensationDistanceRangeSection = {
  section: GC.FIELD_DISTANCE,
  title: 'titles:distance-range',
  fields: compensationDistanceRangesFields,
};

const compensationWeightRangeSection = {
  section: GC.FIELD_WEIGHT,
  title: 'titles:weight-range',
  fields: compensationWeightRangeFields,
};

const compensationTemperatureRangeSection = {
  section: GC.FIELD_TEMPERATURE,
  title: 'titles:temperature-range',
  fields: compensationTemperatureRangeFields,
};

const compensationQuantityRangeSection = {
  section: GC.FIELD_QUANTITY,
  title: 'titles:quantity-range',
  fields: compensationQuantityRangeFields,
};

export const compensationRangeGroupSection = [
  compensationWeightRangeSection,
  compensationDistanceRangeSection,
  compensationQuantityRangeSection,
  compensationTemperatureRangeSection,
];

const defaultWeightRangeFields = {
  [GC.FIELD_WEIGHT_UOM]: null,
  [GC.FIELD_MIN_WEIGHT]: null,
  [GC.FIELD_MAX_WEIGHT]: null,
};

const defaultDistanceRangeFields = {
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM]: null,
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: null,
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: null,
};

const defaultTemperatureRangeFields = {
  [GC.FIELD_MIN_TEMPERATURE]: null,
  [GC.FIELD_MAX_TEMPERATURE]: null,
  [GC.FIELD_TEMPERATURE_UOM]: null,
};

const defaultQuantityRangeFields = {
  [GC.FIELD_MIN_QUANTITY]: null,
  [GC.FIELD_MAX_QUANTITY]: null,
  [GC.FIELD_PACKAGE_TYPE]: null,
};

export const defaultRangeGroupFields = {
  [GC.FIELD_WEIGHT]: defaultWeightRangeFields,
  [GC.FIELD_DISTANCE]: defaultDistanceRangeFields,
  [GC.FIELD_QUANTITY]: defaultQuantityRangeFields,
  [GC.FIELD_TEMPERATURE]: defaultTemperatureRangeFields,
};
