import * as R from 'ramda';
import styled from 'styled-components';
import { space, width, fontSize } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// forms
import { SelectWrapper } from '../../forms/ui';
import { renderBorderColor } from '../../forms/formik/fieldset2/ui';
//////////////////////////////////////////////////

export const SectionHeader = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  font-weight: 600;
  text-align: center;
  align-items: center;
  color: ${() => G.getTheme('colors.dark.blue')};
  background-color: ${() => G.getTheme('colors.whiteGrey')};
  & > div {
    width: 15%;
  }
  & > div:nth-child(2) {
    opacity: ${({ isInvoice, isCloRate }: Object) => G.ifElse(R.or(isInvoice, isCloRate), 0, 1)};
  }
  & > div:nth-child(3) {
    width: 40%;
  }
  & > div:first-child {
    width: 30%;
  }
`;

export const CustSelectWrapper = styled(SelectWrapper)`
  display: inline-block;
  &:after {
    top: 13px;
    right: 15px;
  }
`;

export const Input = styled.input`
  ${space}
  ${width}
  ${fontSize}

  cursor: text;
  height: 30px;
  outline: none;
  line-height: 30px;
  border-radius: 4px;
  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};
  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }
`;

Input.defaultProps = {
  pr: 25,
  pl: 15,
};

export const SelectComponent = styled.select`
  ${space}
  ${width}
  ${fontSize}

  z-index: 0;
  height: 30px;
  outline: none;
  appearance: none;
  line-height: 30px;
  position: relative;
  border-radius: 4px;

  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

SelectComponent.defaultProps = {
  pl: 15,
  pr: 25,
};

export const InputSelect = styled.select`
  ${space}
  ${width}

  height: 30px;
  outline: none;
  appearance: none;
  line-height: 30px;
  position: relative;
  border-radius: 4px;
  padding-right: 25px;

  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;
