import * as R from 'ramda';
import React from 'react';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { Table } from '../table';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withConnectModalAndLoaderActions } from '../../hocs';
// ui
import { ZOrderWrapper } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

export const tableSettings = {
  tableRowHeight: 40,
  titleRowHeight: 40,
  allowEditBtn: false,
  useMainColors: true,
  allowSelectAll: false,
  checkBoxCellWidth: 27,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 140px)',
};

export const settings = {
  carrierNames: {
    width: 200,
    name: 'titles:carriers',
  },
  carrierEmailGroupNames: {
    width: 200,
    name: 'titles:carrier-email-groups',
  },
  [GC.FIELD_MODE]: {
    width: 300,
    name: 'titles:mode-of-transportation',
    customComponent: R.path(['data', GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE]),
  },
  [GC.FIELD_EXPIRATION_DATE_TIME]: {
    width: 200,
    name: 'titles:expiration-date',
  },
  createdDateTime: {
    width: 200,
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
};

const reportFields = R.map(
  (name: string, sequence: number) => ({ name, sequence }),
  R.keys(settings),
);

const enhance = compose(
  withState('itemList', 'setItemList', []),
  withHandlers({
    handleGetItemList: ({ telGuid, setItemList, openLoader, closeLoader }: Object) => async () => {
      openLoader();
      const options = {
        params: { telGuid },
      };

      const res = await sendRequest('get', endpointsMap.telRateRequestList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setItemList(data);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetItemList();
    },
  }),
  pure,
);

export const TelRateRequestList = enhance(({ itemList }: Object) => (
  <ZOrderWrapper zIndex='1'>
    <Table
      loading={false}
      itemList={itemList}
      hasSelectable={false}
      columnSettings={settings}
      tableSettings={tableSettings}
      totalCount={R.length(itemList)}
      report={{ fields: reportFields }}
    />
  </ZOrderWrapper>
));

export const withOpenTelRateRequestList = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    handleOpenTelRateRequestList: (props: Object) => (telGuid: string) => {
      const component = <TelRateRequestList {...props} telGuid={R.pathOr(telGuid, [GC.FIELD_TEL_GUID], props)} />;

      const modal = {
        p: '0px',
        component,
        options: {
          maxWidth: '90vw',
          overflow: 'auto',
          maxHeight: '90vh',
          outsideCloseButton: true,
          title: G.getWindowLocale('titles:carriers-bids', 'Carriers Bids'),
        },
      };

      props.openModal(modal);
    },
  }),
);
