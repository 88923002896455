import * as R from 'ramda';
import React from 'react';
// components
import { Label } from '../label';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

export const FormGroupTitleMultiple = (props: Object) => (
  <Flex
    position='relative'
    bg={G.getTheme('colors.dark.darkBlue')}
    justifyContent={R.propOr('start', 'jc', props)}
    color={G.getTheme('forms.group.title.textColor')}
  >
    <Box
      pl={15}
      lineHeight='30px'
      mb={R.propOr(5, 'mb', props)}
      bg={G.getTheme('colors.dark.darkBlue')}
      height={R.propOr(30, 'height', props)}
      color={G.getTheme('forms.group.title.textColor')}
    >
      <Label
        endAction={props.onClickLabel}
        frontAction={props.onClickFrontLabel}
        endIcon={R.propOr(null, 'endIcon', props)}
        endLabel={R.propOr(null, 'endLabel', props)}
        frontIcon={R.propOr(null, 'frontIcon', props)}
        frontLabel={R.propOr(null, 'frontLabel', props)}
      >
        {props.title}
      </Label>
    </Box>
    {
      props.showArrowToggle &&
      <Box
        top='8px'
        right={16}
        position='absolute'
        onClick={props.onToggleFormGroup}
        transform={R.and(props.isOpened, 'rotate(180deg)')}
      >
        {I.arrowDownSimple(G.getTheme('forms.group.title.textColor'))}
      </Box>
    }
    {props.children}
  </Flex>
);
