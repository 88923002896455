import * as R from 'ramda';
import React from 'react';
// features
import { LoginPageTitle } from '../../features/auth/login-page-title';
// helpers
import * as G from '../../helpers';
// ui
import { Flex } from '../../ui/box';
import { SlideWrap, ContentWrap } from './ui';
////////////////////////////////////////////////

const PreviewSlide = (props: Object) => {
  const {
    i,
    color,
    slide,
    active,
    splashScreen,
    splashScreenSetting,
    handleGetInfoPanelRef,
  } = props;

  const getSlideContent = () => {
    if (R.or(
      G.isTrue(splashScreen),
      G.isNotNilAndNotEmpty(R.path(['panels'], splashScreenSetting),
    ))) {
      return (
        <ContentWrap
          width='80%'
          color={color}
          ref={(ref: Object) => handleGetInfoPanelRef(ref, i)}
        />
      );
    }

    return (
      <ContentWrap width='80%'>
        <LoginPageTitle />
      </ContentWrap>
    );
  };

  return (
    <SlideWrap
      bs='cover'
      size='100%'
      active={active}
      alignItems='center'
      bgImage={slide.img}
      justifyContent='center'
    >
      <Flex
        pt={50}
        className='shadow'
        alignItems='flex-start'
        justifyContent='center'
      >
        {getSlideContent()}
      </Flex>
    </SlideWrap>
  );
};
export default PreviewSlide;
