import styled, { css } from 'styled-components';
import { width, height, maxWidth } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const Container = styled.div`
  ${width}
  ${height}
  ${maxWidth}

  border-radius: 4px;
  position: relative;
  display: inline-flex;
  flex-direction: column;

  background-color: ${() => G.getTheme('colors.white')};
`;

Container.defaultProps = {
  width: 180,
  maxWidth: 180,
};

const Label = styled.label`
  top: 0;
  left: 0;
  z-index: 11;
  font-size: 12px;
  overflow: hidden;
  line-height: 14px;
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
  text-overflow: ellipsis;
  transform-origin: top left;
  max-width: calc(100% - 24px);
  transform: translate(8px, 9px) scale(1);
  color: ${() => G.getTheme('colors.darkGrey')};
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

  ${({ shouldUpdateStyles }: Object) => shouldUpdateStyles && css`
    user-select: none;
    max-width: calc(133% - 32px);
    color: ${G.getTheme('colors.azure')};
    transform: translate(14px, -6px) scale(0.75);
  `}
`;

const InputFieldContainer = styled.div`
  cursor: text;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box;
`;

const FakeOutlineContainer = styled.fieldset`
  left: 0;
  right: 0;
  margin: 0;
  bottom: 0;
  top: -5px;
  min-width: 0%;
  padding: 0 8px;
  text-align: left;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
  border: 1px solid ${() => G.getTheme('colors.borderGray')};

  ${({ shouldUpdateStyles }: Object) => shouldUpdateStyles && css`
    border: 1.5px solid  ${G.getTheme('colors.azure')};
  `}
`;

const FakeOutlineContainerWithoutBorder = styled.fieldset`
  left: 0;
  right: 0;
  margin: 0;
  bottom: 0;
  top: -5px;
  border: none;
  min-width: 0%;
  padding: 0 8px;
  text-align: left;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
`;

const FakeLabel = styled.span`
  opacity: 0;
  padding-left: 5px;
  padding-right: 5px;
  visibility: visible;
  display: inline-block;
`;

const FakeLabelContainer = styled.legend`
  padding: 0;
  width: auto;
  float: unset;
  height: 11px;
  font-size: 9px;
  display: block;
  overflow: hidden;
  max-width: 0.01px;
  visibility: hidden;
  white-space: nowrap;
  transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;

  ${({ shouldUpdateStyles }: Object) => shouldUpdateStyles && css`
    max-width: 100%;
    transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  `}
`;

const InputField = styled.input`
  border: 0;
  width: 100%;
  outline: none;
  font: inherit;
  display: block;
  background: none;
  padding: 9px 8px;
  box-sizing: content-box;
  color: ${() => G.getTheme('colors.textDark')};

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    margin: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;

export {
  Label,
  Container,
  FakeLabel,
  InputField,
  FakeLabelContainer,
  InputFieldContainer,
  FakeOutlineContainer,
  FakeOutlineContainerWithoutBorder,
};
