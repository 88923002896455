import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../form-footer';
import { closeModal } from '../modal/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../features/branch/selectors';
// forms
import { Fieldset2 } from '../../forms/formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../hocs';
// ui
import { Box, ActionButton } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature carrier
import { createCarrierRequest } from '../../features/carrier/actions';
import {
  createCarrierFields,
  defaultCreateCarrierFields,
  createCarrierValidationSchemaObject,
} from './field-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: () => defaultCreateCarrierFields,
    validationSchema: () => Yup.lazy((values: Object) => (
      Yup.object().shape(createCarrierValidationSchemaObject(values))
    )),
    handleSubmit: (values: Object, { props }: Object) => props.createCarrierRequest(values),
  }),
  withState('configuredSendPackage', 'setConfiguredSendPackage', false),
  withHandlers({
    handleGetOnboardingPackageFromConfig: (props: Object) => async () => {
      const { currentBranchGuid, setConfiguredSendPackage } = props;

      const options = {
        params: { [GC.BRANCH_GUID]: currentBranchGuid },
      };

      const res = await sendRequest('get', endpointsMap.carrierOnboardingPackageList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setConfiguredSendPackage(G.isNotNilAndNotEmpty(data));
      } else {
        G.handleException('error', 'handleGetOnboardingPackageFromConfig exception');
      }
    },
    handleCustomChange: (props: Object) => (event: Object) => {
      const { handleChange, configuredSendPackage } = props;

      if (G.isFalse(configuredSendPackage)) {
        const message = G.getWindowLocale(
          'messages:carrier-onboarding-package-is-not-configured',
          'Carrier onboarding package is not configured',
        );

        return G.showToastrMessageSimple('info', message);
      }

      handleChange(event);
    },
    handleSetFieldTouchedEmails: ({ touched, setFieldTouched }: Object) => () => {
      if (G.isArray(G.getPropFromObject(GC.FIELD_EMAIL, touched))) {
        setFieldTouched(GC.FIELD_EMAIL, true, false);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetOnboardingPackageFromConfig();
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'touched',
    callbackName: 'handleSetFieldTouchedEmails',
  }),
  pure,
);

const getSynchronizationText = () => {
  const synchronizationMap = {
    [GC.CARRIER_SYNCHRONIZATION_TYPE_RMIS]: [
      'actions:save-and-sync-with-rmis',
      'Save And Sync With RMIS',
    ],
    [GC.CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS]: [
      'actions:save-and-sync-with-my-carrier-packets',
      'Save And Sync With My Carrier Packets',
    ],
  };

  const localeArr = R.pathOr(
    ['actions:save-and-sync-with-safer-watch', 'Save And Sync With Safer Watch'],
    [G.getCarrierSynchronizationType()],
    synchronizationMap,
  );

  return G.getWindowLocale(...localeArr);
};

export const CreateCarrierForm = enhance((props: Object) => {
  const {
    values,
    setValues,
    handleSubmit,
    handleCustomChange,
  } = props;

  const darkBlueColor = G.getTheme('colors.dark.blue');

  const synchronizationCondition = R.or(G.showSaferWatch(), G.showCarrierSynchronization());

  const hideSaveButton = R.and(
    synchronizationCondition,
    G.isTrue(G.getAmousConfigByNameFromWindow(GC.CARRIER_GENERAL_SAFER_WATCH_SYNC_REQUIRED)),
  );

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={createCarrierFields}
          handleCustomChange={handleCustomChange}
        />
        <Box>
          <FormFooter2
            boxStyles={{ justifyContent: 'space-between' }}
            submitBtnText={G.getWindowLocale('actions:save', 'Save')}
            submitAction={() => setValues(R.assoc(GC.FIELD_CARRIER_SYNC, false, values))}
            submitStyles={{ darkBlueColor, display: G.ifElse(hideSaveButton, 'none', 'initial') }}
          />
          {
            synchronizationCondition &&
            <ActionButton
              mt={10}
              height={32}
              width='100%'
              type='submit'
              border='1px solid'
              borderRadius='5px'
              textColor={darkBlueColor}
              borderColor={darkBlueColor}
              bgColor={G.getTheme('colors.white')}
              onClick={() => setValues(R.assoc(GC.FIELD_CARRIER_SYNC, true, values))}
            >
              {getSynchronizationText()}
            </ActionButton>
          }
        </Box>
      </form>
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
});

export default connect(mapStateToProps, {
  closeModal,
  createCarrierRequest,
})(enhance(CreateCarrierForm));
