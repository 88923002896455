import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, withPropsOnChange } from 'react-recompose';
// components
import { FormFooter2 } from '../../form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const ADD_COLUMN = 'add-column';

const AddNewColumnForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      fields={props.fieldSettings}
      {...G.getFormikProps(props)}
    />
    <FormFooter2
      boxStyles={{ p: '15px 0' }}
      submitBtnStyles={{ marginLeft: 15 }}
      submitBtnText={G.getWindowLocale('titles:add', 'Add')}
    />
  </form>
);

const getFieldSettings = (newColumnOptions: Object) => [
  {
    type: 'select',
    fieldName: ADD_COLUMN,
    options: newColumnOptions,
    label: ['titles:add-new-column', 'Add New Column'],
    inputWrapperStyles: { mt: 10, mb: 25, width: '100%' },
  },
];

const enhance = compose(
  withPropsOnChange(['newColumnOptions'], (props: Object) => ({
    fieldSettings: getFieldSettings(props.newColumnOptions),
  })),
  withFormik({
    handleSubmit: ({ [ADD_COLUMN]: addColumn }: Object, { props }: Object) => {
      props.submitAction(addColumn);
    },
    mapPropsToValues: () => ({
      [ADD_COLUMN]: null,
    }),
    validationSchema: Yup.object().shape({
      [ADD_COLUMN]: Yup.string().required(),
    }),
  }),
  pure,
);

export default enhance(AddNewColumnForm);
