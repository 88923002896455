import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withState, withProps, withHandlers, renderNothing } from 'react-recompose';
// components
import { PageTitle } from '../page-title';
import { closeModal, openModal } from '../modal/actions';
// features
import { makeSelectCurrentUserName } from '../../features/auth/selectors';
import { makeSelectRoleAvailableList } from '../../features/role/selectors';
import { makeSelectAccessorialsList } from '../../features/configurations/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../features/permission/selectors';
import { makeSelectCrossDockLocationOptions } from '../../features/dispatch-board-new/selectors';
import { makeSelectAvailableReportGeoFencingZoneList } from '../../features/geo-fencing-zone/report/selectors';
import {
  makeSelectLoadEventRefTypes,
  makeSelectAllAvailableCloReferenceTypes,
  makeSelectAllAvailableTelReferenceTypes,
  makeSelectAvailableReferenceTypesByScope,
  makeSelectAllAvailableClosAndTelsReferenceTypes,
  makeSelectAllAvailableReferenceTypesByScopeName,
  makeSelectAllAvailableCloAndCloInvoiceReferenceTypes,
} from '../../features/reference/selectors';
// forms
import { Label, Checkbox } from '../../forms/ui';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withComponentDidUpdatePropCallback } from '../../hocs/common';
// ui
import {
  Box,
  Flex,
  ActionButton,
  RelativeFlex,
  StickedWrapper,
  scrollableContainerCss3px,
} from '../../ui';
// component edit-report
import SortList from './components/sort-list';
import FieldList from './components/field-list';
import FilterList from './components/filter-list';
import { ReportForm } from './components/report-form';
import ReportSummary from './components/report-summary';
import { ReportFormButtons } from './components/form-buttons';
import { withReportSortList } from './components/sort-list/hocs';
import { withReportFieldList } from './components/field-list/hocs';
import { withReportFilterList } from './components/filter-list/hocs';
import { makeSelectRequestStatus, makeSelectUsedReportStatus } from './selectors';
import { buttons, buildReport, getAssessorials, isNotReportValid, getAvailableFilterFields } from './helpers';
//////////////////////////////////////////////////

const setEditReportFormProps = ({ usedReport, availableFields, showReportSummary }: Object) => {
  const {
    name,
    prompt,
    fields,
    roleGuids,
    orderFields,
    description,
    customerGuids,
    defaultReport,
  } = R.or(usedReport, {});


  const reportFields = R.sort(
    (elem1: Object, elem2: Object) => R.subtract(elem1.sequence, elem2.sequence),
    R.filter(
      ({ name }: Object) => G.notEquals(name, GC.FIELD_GUID),
      R.or(fields, []),
    ),
  );

  const sortFields = R.sort(
    (elem1: Object, elem2: Object) => R.subtract(elem1.sequence, elem2.sequence),
    R.filter(
      ({ name }: Object) => (
        R.or(
          G.notEquals(name, 'roles'),
          G.notEquals(name, GC.FIELD_GUID),
        )
      ), R.or(orderFields, []),
    ),
  );

  const filterFields = R.filter(
    ({ name }: Object) => G.notEquals(name, GC.FIELD_GUID),
    G.getOrElse(usedReport, 'searchCriteria', []),
  );


  let initialValues = {
    name,
    roleGuids,
    description,
    customerGuids,
    defaultReport,
  };

  if (G.isTrue(showReportSummary)) {
    initialValues = {
      ...initialValues,
      [GC.FIELD_GROUP_BY]: R.pathOr(null, [GC.FIELD_GROUP_BY], usedReport),
      [GC.FIELD_SUMMARY_GROUP]: R.pathOr(null, [GC.FIELD_SUMMARY_GROUP], usedReport),
      [GC.FIELD_SHOW_LAST_DAYS]: R.pathOr(null, [GC.FIELD_SHOW_LAST_DAYS], usedReport),
      [GC.FIELD_SUMMARY_SUBGROUP]: R.pathOr(null, [GC.FIELD_SUMMARY_SUBGROUP], usedReport),
    };
  }

  return {
    sortFields,
    reportFields,
    filterFields,
    initialValues,
    availableFields,
    prompt: R.or(prompt, false),
  };
};

const reportEnhancer = compose(
  withState('prompt', 'setPrompt', ({ prompt }: Object) => prompt),
  withHandlers({
    handleCreateReport: (props: Object) => (values: Object) => {
      const { closeModal, createReportRequest } = props;

      const newReport = {
        ...buildReport({ ...props, isCreate: true }),
        ...values,
        guid: null,
        version: null,
      };

      if (isNotReportValid(newReport)) return;

      createReportRequest(newReport);

      G.callFunctionTwice(closeModal);
    },
    handleEditReport: (props: Object) => (values: Object) => {
      const { closeModal, updateReportRequest } = props;

      const newReport = { ...buildReport({ ...props, isUpdate: true }), ...values };

      if (isNotReportValid(newReport)) return;

      updateReportRequest(newReport);

      G.callFunctionTwice(closeModal);
    },
  }),
);

export const enhance = compose(
  withState('formValues', 'setFormValues', R.propOr({}, 'initialValues')),
  withState('showIssues', 'setShowIssues', R.pathOr(false, ['usedReport', 'showIssues'])),
  withState(
    GC.FIELD_AVOID_RELATED_VALUE_DUPLICATES,
    'setAvoidRelatedValueDuplicates',
    R.pathOr(false, ['usedReport', GC.FIELD_AVOID_RELATED_VALUE_DUPLICATES]),
  ),
  withProps((props: Object) => {
    const { fields, disableRefFilter, allAvailableRefTypesByScopeName } = props;

    const reportType = R.path(['usedReport', GC.FIELD_TYPE], props);

    return ({
      availableFields: G.setFilterByParams(
        G.getRefListByReportType(allAvailableRefTypesByScopeName, reportType),
        fields,
        {
          reportType,
          disableRefFilter,
          assessorialList: getAssessorials(props),
        },
      ),
    });
  }),
  withProps((props: Object) => setEditReportFormProps(props)),
  withReportSortList,
  withReportFieldList,
  withReportFilterList,
  branch(
    ({ requestPending, initialDataLoaded }: Object) => (
      R.or(
        R.not(initialDataLoaded),
        G.isTrue(requestPending),
      )
    ),
    renderNothing,
  ),
  reportEnhancer,
  withHandlers({
    handlePrompt: ({ prompt, setPrompt }: Object) => () => {
      setPrompt(R.not(prompt));
    },
    handleUseAction: (props: Object) => () => {
      const { setReport, onReportSet, closeModal } = props;

      const newReport = {
        ...buildReport(props),
        roleGuids: [],
        guid: 'draft',
        description: '',
        name: 'Draft Report',
        defaultReport: false,
      };

      if (isNotReportValid(newReport)) return;

      if (G.isFunction(setReport)) setReport(newReport);

      if (G.isFunction(onReportSet)) onReportSet();

      closeModal();
    },
    handleClickSave: (props: Object) => () => {
      const {
        itemList,
        openModal,
        formValues,
        originParams,
        initialValues,
        usedReportStatus,
        handleEditReport,
      } = props;

      const originData = G.ifElse(usedReportStatus, originParams, R.mergeRight(initialValues, formValues));

      const component = (
        <ReportForm
          roles={R.values(itemList)}
          initialValues={originData}
          submitAction={handleEditReport}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 400,
          overflow: 'auto',
          maxHeight: '80vh',
          title: G.getWindowLocale('actions:edit-report', 'Edit Report'),
        },
      };

      openModal(modal);
    },
    handleClickSaveAsNew: ({ itemList, openModal, createNewReportText, handleCreateReport }: Object) => () => {
      const component = <ReportForm roles={R.values(itemList)} submitAction={handleCreateReport} />;

      const modal = {
        p: 15,
        component,
        options: {
          width: 400,
          overflow: 'auto',
          maxHeight: '80vh',
          title: R.or(createNewReportText, G.getWindowLocale('titles:create-new-report', 'Create New Report')),
        },
      };

      openModal(modal);
    },
    handleClickCancel: ({ closeModal }: Object) => () => closeModal(),
    setSubmitButtonStatus: ({ usedReport, userLoginId }: Object) => () => {
      const { owner } = usedReport;

      const reportGuid = G.getOrElse(usedReport, GC.FIELD_GUID, 'draft');

      return (
        R.or(
          G.notEquals(owner, userLoginId),
          R.or(
            reportGuid.includes('Default'),
            R.equals(reportGuid, 'draft'),
          ),
        )
      );
    },
  }),
  pure,
);

const EditReportForm = (props: Object) => {
  const {
    hasIssues,
    showIssues,
    usedReport,
    hideUseBtn,
    setShowIssues,
    hideSaveAsNew,
    editReportText,
    showIssuesLabel,
    handleUseAction,
    updateReportText,
    showReportSummary,
    disableSortByFields,
    disableFilterByFields,
    setSubmitButtonStatus,
    disableSetReportFields,
    AlternativeReportButtons,
    avoidRelatedValueDuplicates,
    setAvoidRelatedValueDuplicates,
  } = props;

  const availableForFilter = getAvailableFilterFields(props);

  const isCustomerInvoiceReport = R.propEq(GC.CUSTOMER_INVOICE_REPORT, GC.FIELD_TYPE, usedReport);
  const isPivotCustomerInvoiceReport = R.propEq(GC.PIVOT_CUSTOMER_INVOICE_REPORT, GC.FIELD_TYPE, usedReport);

  return (
    <Box
      overflowX='auto'
      id='edit_report_wrapper'
      height='calc(100vh - 135px)'
      css={scrollableContainerCss3px}
      bg={G.getTheme('pages.layOutBgColor')}
    >
      <StickedWrapper left='0px' top='0px' bgColor='white' zIndex={12}>
        <Flex>
          <PageTitle
            version={2}
            withCount={false}
            title={`${R.or(editReportText, G.getWindowLocale('titles:edit-report', 'Edit Report'))}: ${
              usedReport.name}`}
          />
          {
            G.isTrue(hasIssues) &&
            <Flex m='0 0 15px 15px'>
              <Checkbox
                id='issues'
                type='checkbox'
                checked={showIssues}
                onChange={() => setShowIssues(R.not(showIssues))}
              />
              <Label ml={10} htmlFor='issues' cursor='pointer'>
                {
                  R.or(showIssuesLabel, G.getWindowLocale('titles:show-notifications', 'Show Notifications'))
                }
              </Label>
            </Flex>
          }
          {
            R.or(isCustomerInvoiceReport, isPivotCustomerInvoiceReport) &&
            <Flex m='0 0 15px 15px'>
              <Checkbox
                type='checkbox'
                checked={avoidRelatedValueDuplicates}
                id={GC.FIELD_AVOID_RELATED_VALUE_DUPLICATES}
                onChange={() => setAvoidRelatedValueDuplicates(R.not(avoidRelatedValueDuplicates))}
              />
              <Label ml={10} cursor='pointer' htmlFor={GC.FIELD_AVOID_RELATED_VALUE_DUPLICATES}>
                {
                  G.ifElse(
                    isCustomerInvoiceReport,
                    G.getWindowLocale(
                      'titles:avoid-related-number-duplicates-excel',
                      'Avoid Related Number Duplicates In Excel',
                    ),
                    G.getWindowLocale(
                      'titles:avoid-related-number-duplicates',
                      'Avoid Related Number Duplicates',
                    ),
                  )
                }
              </Label>
            </Flex>
          }
        </Flex>
      </StickedWrapper>
      <RelativeFlex
        zIndex={1}
        maxWidth={1520}
        width='max-content'
        overflowY='visible'
        borderTop='1px solid'
        alignItems='flex-start'
        maxHeight='calc(100vh - 200px)'
        borderColor={G.getTheme('colors.lightGrey')}
      >
        {
          R.not(G.isTrue(disableSetReportFields)) && <FieldList {...props} />
        }
        <Flex flexDirection='column'>
          {
            showReportSummary && <ReportSummary {...props} />
          }
          {
            showReportSummary &&
            <Box
              width='1px'
              zIndex={11}
              borderLeft='1px solid'
              bg={G.getTheme('colors.light.mainLight')}
              borderColor={G.getTheme('colors.lightGrey')}
            />
          }
          {
            R.not(G.isTrue(disableSortByFields)) &&
            <SortList {...props} />
          }
        </Flex>
        {
          R.not(G.isTrue(disableFilterByFields)) &&
          <FilterList {...props} availableFields={availableForFilter} useMenuPortalTarget={true} />
        }
      </RelativeFlex>
      {R.isNil(AlternativeReportButtons) && (
        <ReportFormButtons
          {...props}
          pl={15}
          width='auto'
          style={{ position: 'absolute' }}
          submitting={setSubmitButtonStatus()}
          showSaveAsNew={R.not(hideSaveAsNew)}
          buttons={G.ifElse(G.isTrue(hideUseBtn), [], buttons(handleUseAction))}
          btnText={R.or(updateReportText, G.getWindowLocale('actions:update-report', 'Update Report'))}
        />
      )}
      {G.isNotNil(AlternativeReportButtons) && (
        <AlternativeReportButtons
          {...props}
          pl={15}
          width='auto'
          style={{ position: 'absolute' }}
        />
      )}
    </Box>
  );
};

const mapGeoFencingProps = (state: Object) => createStructuredSelector({
  geoFencingZones: makeSelectAvailableReportGeoFencingZoneList(state),
});

export const outsideReportEnhance = compose(
  connect(mapGeoFencingProps),
  withReportFilterList,
  withHandlers({
    handleAddFiltersGroupOnDidMount: (props: Object) => () => {
      const { reportFilterGroups, handleAddFiltersGroup, reportSimpleFiltersGroupId } = props;

      if (G.isNilOrEmpty(G.getPropFromObject(reportSimpleFiltersGroupId, reportFilterGroups))) {
        handleAddFiltersGroup();
      }
    },
    handleUseReport: (props: Object) => () => {
      const {
        prompt,
        usedReport,
        closePopper,
        setUsedReport,
        handleListRequest,
        reportFilterGroups,
        reportSimpleFiltersGroupId,
      } = props;

      const exactFilters = R.map(
        (item: Object) => R.assoc('groupId', null, item),
        R.pathOr([], [reportSimpleFiltersGroupId], reportFilterGroups),
      );

      let newReport = {
        ...usedReport,
        roleGuids: [],
        guid: 'draft',
        description: '',
        name: 'Draft Report',
        defaultReport: false,
        searchCriteria: exactFilters,
      };

      if (G.isTrue(prompt)) {
        newReport = R.assoc('searchCriteria', exactFilters, usedReport);
      }

      if (isNotReportValid(newReport)) return;

      setUsedReport(newReport);

      handleListRequest(true);
      closePopper();
    },
    handleCleanFilters: (props: Object) => () => {
      const { reportFilterGroups, setReportFilterGroups, reportSimpleFiltersGroupId } = props;

      setReportFilterGroups(R.assoc(reportSimpleFiltersGroupId, [], reportFilterGroups));
    },
    handleCloseFilterPopup: (props: Object) => () => {
      const {
        prompt,
        usedReport,
        closePopper,
        setUsedReport,
        handleListRequest,
        reportFilterGroups,
        reportSimpleFiltersGroupId,
      } = props;

      const exactFilters = G.getPropFromObject(reportSimpleFiltersGroupId, reportFilterGroups);

      if (G.isNilOrEmpty(exactFilters)) {
        let newReport = {
          ...usedReport,
          roleGuids: [],
          guid: 'draft',
          description: '',
          searchCriteria: [],
          name: 'Draft Report',
          defaultReport: false,
        };

        if (G.isTrue(prompt)) {
          newReport = R.assoc('searchCriteria', [], usedReport);
        }

        setUsedReport(newReport);

        handleListRequest(true);
        closePopper();
      }
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'reportSimpleFiltersGroupId',
    callbackName: 'handleAddFiltersGroupOnDidMount',
  }),
  withComponentDidUpdatePropCallback({
    propName: 'reportFilterGroups',
    callbackName: 'handleCloseFilterPopup',
  }),
  pure,
);

export const OutsideReportExactFilterList = outsideReportEnhance((props: Object) => {
  const {
    closePopper,
    handleUseReport,
    handleCleanFilters,
    reportFilterGroups,
    filterPopupMinWidth,
    reportSimpleFiltersGroupId,
  } = props;

  return (
    <Box minWidth={R.or(filterPopupMinWidth, 620)}>
      <FilterList
        {...props}
        useMenuPortalTarget={true}
        availableFields={getAvailableFilterFields(props)}
        reportFilterGroups={R.pick([reportSimpleFiltersGroupId], reportFilterGroups)}
      />
      <Flex m={10} justifyContent='flex-end'>
        <ActionButton
          height={30}
          type='button'
          minWidth={160}
          display='block'
          borderRadius='4px'
          textTransform='uppercase'
          onClick={handleCleanFilters}
        >
          {G.getWindowLocale('actions:clean-and-close', 'Clean And Close')}
        </ActionButton>
        <ActionButton
          mx={15}
          height={30}
          type='button'
          display='block'
          borderRadius='4px'
          onClick={closePopper}
          textTransform='uppercase'
        >
          {G.getWindowLocale('actions:close', 'Close')}
        </ActionButton>
        <ActionButton
          height={30}
          type='button'
          display='block'
          borderRadius='4px'
          textTransform='uppercase'
          onClick={handleUseReport}
        >
          {G.getWindowLocale('actions:use', 'Use')}
        </ActionButton>
      </Flex>
    </Box>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectRoleAvailableList(state),
  userLoginId: makeSelectCurrentUserName(state),
  requestPending: makeSelectRequestStatus(state),
  assessorials: makeSelectAccessorialsList(state),
  // TODO: remove or uncomment after testing
  // formValues: makeSelectEditRouteReportForm(state),
  usedReportStatus: makeSelectUsedReportStatus(state),
  loadEventRefList: makeSelectLoadEventRefTypes(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  cloRefList: makeSelectAllAvailableCloReferenceTypes(state),
  telRefList: makeSelectAllAvailableTelReferenceTypes(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  crossDockLocationOptions: makeSelectCrossDockLocationOptions(state),
  geoFencingZones: makeSelectAvailableReportGeoFencingZoneList(state),
  closTelsRefList: makeSelectAllAvailableClosAndTelsReferenceTypes(state),
  cloInvoiceCloRefList: makeSelectAllAvailableCloAndCloInvoiceReferenceTypes(state),
  allAvailableRefTypesByScopeName: makeSelectAllAvailableReferenceTypesByScopeName(state),
});

export const EditReport = connect(mapStateToProps, {
  openModal,
  closeModal,
})(enhance(EditReportForm));

export default EditReport;
