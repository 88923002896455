import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Span } from '../../ui';
//////////////////////////////////////////////////

const getFontWeight = (chunk: string, highlight: string) => G.ifElse(
  R.equals(R.toLower(chunk), R.toLower(highlight)),
  600,
  500,
);

const getBgColor = (chunk: string, highlight: string, highlightBgColor: string) => G.ifElse(
  R.equals(R.toLower(chunk), R.toLower(highlight)),
  highlightBgColor,
  'none',
);

export const HighlightedText = (props: Object) => {
  const { text, highlight, highlightBgColor } = props;

  if (G.isNilOrEmpty(highlight)) {
    return <Span>{text}</Span>;
  }

  const chunks = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <Span>
      {
        chunks.map((chunk: string, i: number) => (
          <Span
            key={i}
            color={getFontWeight(chunk, highlight)}
            bg={getBgColor(chunk, highlight, highlightBgColor)}
          >
            {chunk}
          </Span>
        ))
      }
    </Span>
  );
};
