import React from 'react';
import * as R from 'ramda';
// icons
import * as I from '../../../svgs';
// ui
import { IconWrapper } from '../../../ui';
// groupable-table
import TableHeaderCell from './table-header-cell';
import { TableHeader, StickyElement } from '../ui';
//////////////////////////////////////////////////

const DotsComponent = ({ handleClickResetIcon }: Object) => (
  <StickyElement
    p='8px'
    ml='auto'
    height='100%'
    justify='center'
    direction='column'
    title='Reset table columns width'
  >
    <IconWrapper px={12} pt={4} cursor='pointer' onClick={(e: Object) => handleClickResetIcon(e)}>
      {I.threeDots()}
    </IconWrapper>
  </StickyElement>
);

const TableHeaderComponent = (props: Object) => {
  const {
    filterProps,
    tableSettings,
    resizeObserver,
    titleSortValues,
    tableTitleFilters,
    handleClickResetIcon,
  } = props;
  const { columnSettings, titleRowHeight, withResizableColumns } = tableSettings;

  return (
    <TableHeader height={titleRowHeight}>
      {R.map((key: string) => {
        const column = R.prop(key, columnSettings);
        const fieldProps = R.prop(key, filterProps);

        const titleFilterValue = R.propOr({}, key, tableTitleFilters);
        const titleSortValue = R.propOr({}, key, titleSortValues);

        return (
          <TableHeaderCell
            {...props}
            fieldName={key}
            column={column}
            fieldProps={fieldProps}
            tableSettings={tableSettings}
            resizeObserver={resizeObserver}
            titleSortValue={titleSortValue}
            titleFilterValue={titleFilterValue}
            key={`groupable-table-header-cell-${key}`}
          />
        );
      }, R.keys(columnSettings))}
      { withResizableColumns && <DotsComponent handleClickResetIcon={handleClickResetIcon} />}
    </TableHeader>
  );
};

export default TableHeaderComponent;
