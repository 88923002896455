import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../components/text';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// forms
import { SelectWrapper } from '../../forms';
// ui
import { Box, Span, Flex, Text, RelativeBox, AbsoluteBox } from '../../ui';
// component charge
import * as S from './settings';
import { Input, SelectComponent, CustSelectWrapper } from './ui';
//////////////////////////////////////////////////

// TODO: refactor, only used on CancelCloForm

const getCurrency = (item: Object) => R.prop(GC.FIELD_CURRENCY, item);

const getChargeCommentText = (props: Object) => R.pathOr(
  G.getWindowLocale('actions:add-comments', 'Add Comments'),
  ['chargeState', GC.FIELD_CHARGE_COMMENTS],
  props,
);

const ChargeComment = (props: Object) => (
  <span
    onClick={() => props.handleOpenChargeComment(props.chargeState)}
    title={G.getWindowLocale('actions:add-comments', 'Add Comments')}
  >
    {I.comment2()}
  </span>
);

const FieldSelect = (props: Object) => (
  <SelectWrapper afterTop={13} afterRight={15}>
    <SelectComponent
      pr={25}
      name={props.name}
      value={R.or(props.value, '')}
      onChange={props.onChangeSelect}
      width={R.or(props.width, '150')}
      disabled={R.or(props.disabled, false)}
    >
      {
        props.options.map((option: string, index: number) => (
          <option
            key={index}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))
      }
    </SelectComponent>
  </SelectWrapper>
);

const AccessorialsSelect = (props: Object) => {
  let options = R.pathOr([], ['options'], props);
  const accessorial = R.pathOr('', [GC.FIELD_VALUE], props);
  const isNotExistedAccessorial = R.compose(
    R.isNil,
    R.find(R.propEq(accessorial, GC.FIELD_VALUE)),
  )(options);
  if (G.isTrue(isNotExistedAccessorial)) {
    options = R.insert(
      1,
      { value: accessorial, label: accessorial },
      options,
    );
  }

  return (
    <CustSelectWrapper width={R.or(props.width, '150')}>
      <SelectComponent
        pr={25}
        name={props.name}
        value={R.or(props.value, '')}
        onChange={props.onChangeSelect}
        width={R.or(props.width, '150')}
        disabled={R.or(props.disabled, false)}
      >
        {
          options.map(({ label, value, disabled }: string, index: number) => (
            <option
              key={index}
              value={value}
              disabled={disabled}
            >
              {label}
            </option>
          ))
        }
      </SelectComponent>
    </CustSelectWrapper>
  );
};

const ChargeTotal = (props: Object) => (
  <Flex>
    <Text
      fontSize='14px'
      fontWeight='bold'
      textTransform='uppercase'
      color={G.getTheme('colors.darkGrey')}
    >
      {`${G.getWindowLocale('titles:total', 'Total')}:`}
    </Text>
    <TextComponent
      ml='10px'
      fontSize='14px'
      fontWeight='bold'
      withEllipsis={true}
      display='inline-block'
      color={G.getTheme('colors.black')}
      title={`${G.getCurrencySymbol(getCurrency(props))} ${
        G.NaNToZero(G.mathRoundNumber(R.path(['chargeState', GC.FIELD_CHARGE_TOTAL], props)))}
      `}
    >
      {
        `${G.getCurrencySymbol(getCurrency(props))} ${
          G.NaNToZero(G.mathRoundNumber(R.path(['chargeState', GC.FIELD_CHARGE_TOTAL], props)))}
        `
      }
    </TextComponent>
  </Flex>
);

const getRateFromCharge = (props: Object) => {
  const rate = R.path(['chargeState', GC.FIELD_CHARGE_RATE], props);

  if (R.isNil(rate)) {
    return '';
  }

  return rate;
};

const relativeBoxStyles = {
  mx: '10px',
  flexGrow: 1,
};

const absoluteBoxStyles = {
  left: '5px',
  top: '-13px',
  fontSize: '11px',
  color: G.getTheme('colors.darkGrey'),
};

const ChargeFields = (props: Object) => (
  <Flex flexGrow={1} justifyContent='spaceBetween'>
    <RelativeBox {...relativeBoxStyles} minWidth='100px'>
      <AbsoluteBox {...absoluteBoxStyles}>
        {G.getWindowLocale('titles:rate', 'Rate')}
      </AbsoluteBox>
      <Input
        type='text'
        width='100%'
        value={getRateFromCharge(props)}
        name={`${props.keyToStateHead}.${GC.FIELD_CHARGE_RATE}`}
        onChange={(event: Object) => props.handleChangeCharge(event, props.chargeState)}
      />
    </RelativeBox>
    <RelativeBox {...relativeBoxStyles} minWidth='100px'>
      <AbsoluteBox {...absoluteBoxStyles}>
        {G.getWindowLocale('titles:type', 'Type')}
      </AbsoluteBox>
      <FieldSelect
        width='100%'
        name={`${props.keyToStateHead}.${GC.FIELD_CHARGE_RATE_TYPE}`}
        value={R.or(R.path(['chargeState', GC.FIELD_CHARGE_RATE_TYPE], props), '')}
        options={R.pathOr([], [props.chargeState.type, props.loadType], S.rateTypeOptions)}
        onChangeSelect={(event: Object) => props.handleChangeCharge(event, props.chargeState)}
      />
    </RelativeBox>
    <RelativeBox {...relativeBoxStyles} minWidth='100px'>
      <AbsoluteBox {...absoluteBoxStyles}>
        {G.getWindowLocale('titles:unit', 'Unit')}
      </AbsoluteBox>
      <FieldSelect
        width='100%'
        options={R.propOr(
          [],
          R.path(['chargeState', GC.FIELD_CHARGE_RATE_TYPE], props),
          S.rateUnitOptionsGroup,
        )}
        name={`${props.keyToStateHead}.${GC.FIELD_CHARGE_RATE_UNIT}`}
        value={R.or(R.path(['chargeState', GC.FIELD_CHARGE_RATE_UNIT], props), '')}
        onChangeSelect={(event: Object) => props.handleChangeCharge(event, props.chargeState)}
      />
    </RelativeBox>
    <RelativeBox {...relativeBoxStyles}>
      <AbsoluteBox {...absoluteBoxStyles}>
        {G.getWindowLocale('titles:qty', 'Qty')}
      </AbsoluteBox>
      <Input
        type='text'
        width='100%'
        disabled={R.includes(
          R.path(['chargeState', GC.FIELD_CHARGE_RATE_TYPE], props),
          S.rateTypesWithoutQuantity,
        )}
        name={`${props.keyToStateHead}.${GC.FIELD_CHARGE_QUANTITY}`}
        value={R.pathOr(0, ['chargeState', GC.FIELD_CHARGE_QUANTITY], props)}
        onChange={(event: Object) => props.handleChangeCharge(event, props.chargeState)}
      />
    </RelativeBox>
  </Flex>
);

const getTaxableText = (props: Object) => G.ifElse(
  G.isTrue(R.path(['chargeState', GC.FIELD_CHARGE_NON_TAXABLE], props)),
  G.getWindowLocale('titles:not-taxable', 'Not Taxable'),
  G.getWindowLocale('titles:taxable', 'Taxable'),
);

const getExpenseText = (props: Object) => G.ifElse(
  G.isTrue(R.path(['chargeState', GC.FIELD_CHARGE_INTERNAL_EXPENSE], props)),
  G.getWindowLocale('titles:internal-expense', 'Internal Expense'),
  G.getWindowLocale('titles:not-internal-expense', 'Not Internal Expense'),
);

const ChargeInfo = (props: Object) => (
  <Flex p='10px'>
    <Flex flexGrow={1}>
      {
        R.includes(
          R.path(['chargeState', GC.FIELD_CHARGE_TYPE], props),
          [GC.CHARGE_TYPE_ADDITIONAL, GC.CHARGE_TYPE_CANCEL_CLO],
        ) &&
        <Span
          mr='15px'
          cursor='pointer'
          title={G.getWindowLocale('titles:remove-charge', 'Remove Charge')}
          onClick={() => {
            const { chargeState, handleRemoveAccessorials, additionalRemoveAccessorialHandler } = props;

            handleRemoveAccessorials(G.getPropFromObject(GC.FIELD_ID, chargeState));
            G.callFunctionWithArgs(additionalRemoveAccessorialHandler, chargeState);
          }}
        >
          {I.closeIcon(G.getTheme('colors.light.black'), 15, 15)}
        </Span>
      }
      <Flex cursor='pointer'>
        <ChargeComment
          chargeState={props.chargeState}
          handleOpenChargeComment={props.handleOpenChargeComment}
        />
        <TextComponent
          px='5px'
          fontSize='12px'
          display='block'
          maxWidth='250px'
          withEllipsis={true}
          title={getChargeCommentText(props)}
          color={G.getTheme('colors.dark.blue')}
          onClick={() => props.handleOpenChargeComment(props.chargeState)}
        >
          {getChargeCommentText(props)}
        </TextComponent>
      </Flex>
      {
        G.notEquals(R.path(['chargeState', GC.FIELD_CHARGE_TYPE], props), GC.CHARGE_TYPE_MAIN) &&
        <Flex mx='10px'>
          {G.convertDataTypeToUiField(R.not(R.path(['chargeState', GC.FIELD_CHARGE_NON_TAXABLE], props)))}
          <Text
            px='5px'
            fontSize='12px'
            color={G.getTheme('colors.dark.blue')}
          >
            {getTaxableText(props)}
          </Text>
        </Flex>
      }
      {
        R.path(['chargeState', GC.FIELD_CHARGE_INTERNAL_EXPENSE], props) &&
        <Flex>
          {G.convertDataTypeToUiField(R.path(['chargeState', GC.FIELD_CHARGE_INTERNAL_EXPENSE], props))}
          <Text
            px='5px'
            fontSize='12px'
            color={G.getTheme('colors.dark.blue')}
          >
            {getExpenseText(props)}
          </Text>
        </Flex>
      }
    </Flex>
    <ChargeTotal {...props} />
  </Flex>
);

export const AdditionalCharge = (props: Object) => (
  <Box my='20px'>
    <Flex>
      <RelativeBox mx='10px'>
        <AbsoluteBox {...absoluteBoxStyles}>
          {G.getWindowLocale('titles:charge-description', 'Charge Description')}
        </AbsoluteBox>
        <AccessorialsSelect
          width='150'
          options={props.noFuelSelectOptions}
          name={R.path(['chargeState', 'id'], props)}
          onChangeSelect={props.handleChangeChargeSelect}
          value={R.path(['chargeState', GC.FIELD_CHARGE_RATE_NAME], props)}
        />
      </RelativeBox>
      <ChargeFields {...props} />
    </Flex>
    <ChargeInfo {...props} />
  </Box>
);
