import * as R from 'ramda';
import React from 'react';
// components
import { Label } from '../label';
// icons
import * as I from '../../svgs';
// forms
import { FormGroupTitle, FormGroupTitleWrapper } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
// component form-group-title
import { DownArrow } from './ui';
//////////////////////////////////////////////////

export const FormGroupTitleComponent = ({
  mt,
  mb,
  text,
  endIcon,
  endLabel,
  children,
  isOpened,
  frontIcon,
  frontLabel,
  onClickLabel,
  withArrowDown,
  onToggleFormGroup,
  onClickFrontLabel,
}: Object) => (
  <FormGroupTitleWrapper onClick={onToggleFormGroup}>
    <FormGroupTitle mb={R.or(mb, '5px')} mt={R.or(mt, '0px')}>
      <Label
        frontIcon={frontIcon}
        frontLabel={frontLabel}
        endIcon={R.or(endIcon, null)}
        frontAction={onClickFrontLabel}
        endLabel={R.or(endLabel, null)}
        endAction={(e: Event) => {
          G.stopPropagation(e);
          onClickLabel();
        }}
      >
        {text}
      </Label>
      {children}
    </FormGroupTitle>
    {
      G.isNotNil(withArrowDown) &&
      <DownArrow isOpened={isOpened}>
        {I.arrowDownSimple(G.getTheme('icons.iconColorWhite'))}
      </DownArrow>
    }
  </FormGroupTitleWrapper>
);
