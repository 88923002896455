import * as R from 'ramda';
import styled from 'styled-components';
import {
  space,
  color,
  layout,
  border,
  flexbox,
  position,
  typography,
} from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const Text = styled.div`
  ${space}
  ${color}
  ${layout}
  ${border}
  ${flexbox}
  ${position}
  ${typography}

  cursor: ${({ cursor }: Object) => cursor};
  word-break: ${({ wordBreak }: Object) => wordBreak || 'unset'};
  white-space: ${({ whiteSpace }: Object) => whiteSpace || 'nowrap'};
  text-decoration: ${({ textDecoration }: Object) => textDecoration};
  text-transform: ${({ textTransform }: Object) => R.or(textTransform, 'unset')};
  text-overflow: ${({ withEllipsis }: Object) => G.ifElse(G.isTrue(withEllipsis), 'ellipsis', 'clip')}
`;

Text.defaultProps = {
  minWidth: 'unset',
  maxWidth: 'unset',
  display: 'inline',
  overflow: 'hidden',
  textAlign: 'unset',
  fontWeight: 'unset',
};
