import styled from 'styled-components';
//////////////////////////////////////////////////

export const CustomImg = styled.img`
  top: 11px;
  right: 16px;
  position: absolute;
  transform: ${({ isOpened }: Object) => isOpened && 'rotate(180deg)'};
`;

export const DownArrow = styled.div`
  right: 16px;
  position: absolute;
  top: ${({topPosition}: Object) => topPosition || '7px'};
  transform: ${({isOpened}: Object) => isOpened && 'rotate(180deg)'};
`;
