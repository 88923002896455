import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleClick: (props: Object) => () => props.toggle(props.data),
  }),
  pure,
);

const ToggleButton = enhance((props: Object) => {
  const { data, mainColor, handleClick } = props;
  const color = G.getTheme(G.ifElse(
    G.isTrue(mainColor),
    'tables.rows.toggleIconColor',
    'tables.rows.additionalToggleIconColor',
  ));
  return (
    <Flex
      p='0 8px'
      height='100%'
      justify='center'
      direction='column'
      onClick={handleClick}
    >
      {
        G.ifElse(
          G.isTrue(data.expanded),
          I.arrowUpSimple(color),
          I.arrowDownSimple(color),
        )
      }
    </Flex>
  );
});

export default ToggleButton;
