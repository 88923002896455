import * as R from 'ramda';
import {
  pure,
  withState,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature edit-report
import {
  getAvailableForReports,
  reorderFieldsOnDrugEnd,
  mapFieldsWithSequenceFromIndex,
} from '../../helpers';
//////////////////////////////////////////////////

export const withReportFieldList = R.compose(
  withState('reportFieldList', 'setReportFieldList', []),
  withState('availableForReports', 'setAvailableForReports', []),
  withState('defaultFieldsCount', 'setDefaultFieldsCount', 5),
  withPropsOnChange(['reportFields'], (props: Object) => {
    const {
      reportFields,
      reportFieldList,
      setReportFieldList,
      setAvailableForReports,
    } = props;

    if (G.isNilOrEmpty(reportFields)) return;

    if (G.deepEquals(reportFields, reportFieldList)) return;

    setReportFieldList(reportFields);
    setAvailableForReports(getAvailableForReports(props, reportFields));
  }),
  withHandlers({
    handleAddReportField: (props: Object) => () => {
      const {
        reportFieldList,
        setReportFieldList,
        setAvailableForReports,
      } = props;

      const newField = {
        name: null,
        freezed: false,
        reference: false,
        collection: false,
        sequence: R.inc(R.length(reportFieldList)),
      };

      const fieldsUpdated = R.append(newField, reportFieldList);

      setReportFieldList(fieldsUpdated);

      setAvailableForReports(getAvailableForReports(props, fieldsUpdated));
    },
    handleAddAllReportField: (props: Object) => () => {
      const {
        reportFieldList,
        availableFields,
        setReportFieldList,
        setAvailableForReports,
      } = props;

      const filtered = R.filter(({ name }: Object) => G.isNotNilAndNotEmpty(name), R.or(reportFieldList, []));
      const keys = R.compose(
        R.keys,
        R.indexBy(R.prop(GC.FIELD_NAME)),
      )(filtered);
      const length = R.length(keys);
      const availableList = R.compose(
        G.mapIndexed(({ type, value, collection = false }: Object, index: number) => ({
          collection,
          guid: null,
          name: value,
          freezed: false,
          sequence: R.add(R.inc(index), length),
          reference: R.equals(type, 'reference'),
        })),
        R.reject(({ value }: Object) => R.includes(value, keys)),
      )(availableFields);

      const result = R.concat(filtered, availableList);

      setReportFieldList(result);

      setAvailableForReports(getAvailableForReports(props, result));
    },
    handleAddReportFields: (props: Object) => () => {
      const {
        reportFieldList,
        defaultFieldsCount,
        setReportFieldList,
        setAvailableForReports,
      } = props;

      const newField = {
        name: null,
        freezed: false,
        reference: false,
        collection: false,
      };

      const newFields = R.times(() => R.clone(newField), defaultFieldsCount);
      const fieldsUpdated = R.concat(reportFieldList, newFields);

      setReportFieldList(fieldsUpdated);

      setAvailableForReports(getAvailableForReports(props, fieldsUpdated));
    },
    handleRemoveReportField: (props: Object) => (filterIndex: string) => {
      const {
        reportFieldList,
        setReportFieldList,
        setAvailableForReports,
      } = props;

      const fieldsUpdated = mapFieldsWithSequenceFromIndex(R.dissocPath([filterIndex], reportFieldList));

      setReportFieldList(fieldsUpdated);

      setAvailableForReports(getAvailableForReports(props, fieldsUpdated));
    },
    handleSelectReportFieldName: (props: Object) => (values: Object) => {
      const {
        reportFieldList,
        setReportFieldList,
        setAvailableForReports,
      } = props;

      const { fieldIndex, data: { type, value, origValue, collection, referenceFieldName } } = values;

      let newField = {
        origValue,
        name: value,
        freezed: false,
        collection: R.or(collection, false),
        reference: R.equals(type, 'reference'),
      };

      if (G.isNotNil(referenceFieldName)) {
        newField = R.assoc(GC.FIELD_REFERENCE_FIELD_NAME, referenceFieldName, newField);
      }

      const fieldsUpdated = R.assocPath([fieldIndex], newField, reportFieldList);

      setReportFieldList(fieldsUpdated);

      setAvailableForReports(getAvailableForReports(props, fieldsUpdated));
    },
    handleChangeReportFieldFrozen: (props: Object) => ({ freezed, fieldIndex }: Object) => {
      const {
        reportFieldList,
        setReportFieldList,
        setAvailableForReports,
      } = props;

      const fieldsUpdated = R.assocPath([fieldIndex, 'freezed'], R.not(freezed), reportFieldList);

      setReportFieldList(fieldsUpdated);

      setAvailableForReports(getAvailableForReports(props, fieldsUpdated));
    },
    handleReportFieldSortDragEnd: (props: Object) => (result: Object) => {
      const {
        reportFieldList,
        setReportFieldList,
        setAvailableForReports,
      } = props;

      const reorderedFields = reorderFieldsOnDrugEnd(reportFieldList, result);

      setReportFieldList(reorderedFields);

      setAvailableForReports(getAvailableForReports(props, reorderedFields));
    },
  }),
  pure,
);
