import * as R from 'ramda';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react';
// components
import { FormFooter2 } from '../form-footer';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

const useDebounceEffect = (fn: Function, waitTime: number, deps: Array = []) => {
  useEffect(() => {
    const t = setTimeout(() => fn(...deps), waitTime);

    return () => clearTimeout(t);
  }, deps);
};

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => centerCrop(
  makeAspectCrop({ unit: '%', width: 90 }, aspect, mediaWidth, mediaHeight),
  mediaWidth,
  mediaHeight,
);

const canvasPreview = (
  image: Object,
  canvas: Object,
  crop: Object,
  scale: number = 1,
  rotate: number = 0,
) => {
  const TO_RADIANS = R.divide(Math.PI, 180);
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const pixelRatio = window.devicePixelRatio;
  const scaleX = R.divide(image.naturalWidth, image.width);
  const scaleY = R.divide(image.naturalHeight, image.height);

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = R.multiply(crop.x, scaleX);
  const cropY = R.multiply(crop.y, scaleY);

  const rotateRads = R.multiply(rotate, TO_RADIANS);
  const centerX = R.divide(image.naturalWidth, 2);
  const centerY = R.divide(image.naturalHeight, 2);

  ctx.save();
  ctx.translate(R.negate(cropX), R.negate(cropY));
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.translate(R.negate(centerX), R.negate(centerY));

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();
};

const ImageCropper = (props: Object) => {
  const { file, submitAction } = props;

  const [crop, setCrop] = useState();
  const [imgSrc, setImgSrc] = useState('');
  const [aspect, setAspect] = useState(16 / 9);
  const [completedCrop, setCompletedCrop] = useState();

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const handleImageLoad = (event: Object) => {
    const { width, height } = event.currentTarget;

    setCrop(centerAspectCrop(width, height, aspect));
  };

  const generateFile = useCallback(async () => {
    const { current: image } = imgRef;

    const { current: previewCanvas } = previewCanvasRef;

    const scaleX = R.divide(image.naturalWidth, image.width);
    const scaleY = R.divide(image.naturalHeight, image.height);

    const offscreen = new OffscreenCanvas( // eslint-disable-line
      R.multiply(completedCrop.width, scaleX),
      R.multiply(completedCrop.height, scaleY),
    );

    const ctx = offscreen.getContext('2d');

    if (R.not(ctx)) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    const fileName = `${G.generateGuid()}.png`;
    const img = new File([blob], fileName, blob); // eslint-disable-line

    submitAction(img);
  }, [completedCrop]);

  useEffect(() => {
    const reader = new FileReader(); // eslint-disable-line

    reader.addEventListener('load', () => setImgSrc(reader.result.toString()));
    reader.readAsDataURL(file);
  }, [file]);

  useDebounceEffect(
    async () => {
      if (G.isAllTrue(
        R.isNotNil(R.prop('current', imgRef)),
        R.isNotNil(R.prop('width', completedCrop)),
        R.isNotNil(R.prop('height', completedCrop)),
        R.isNotNil(R.prop('current', previewCanvasRef)),
      )) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop],
  );

  useDebounceEffect(() => setAspect(undefined), 100, []);

  const { imageStyles, canvasStyles } = useMemo(() => ({
    imageStyles: {
      maxWidth: 1000, maxHeight: 500,
    },
    canvasStyles: {
      width: 150,
      height: 150,
      marginLeft: 25,
      borderRadius: 4,
    },
  }), []);

  return (
    <Box minWidth={400}>
      <Flex>
        <ReactCrop
          crop={crop}
          minWidth={100}
          aspect={aspect}
          minHeight={100}
          onComplete={(crop: Object) => setCompletedCrop(crop)}
          onChange={(_: any, percentCrop: Object) => setCrop(percentCrop)}
        >
          <img
            alt='crop'
            src={imgSrc}
            ref={imgRef}
            style={imageStyles}
            onLoad={handleImageLoad}
          />
        </ReactCrop>
        {
          completedCrop &&
          <canvas style={canvasStyles} ref={previewCanvasRef} />
        }
      </Flex>
      <FormFooter2 boxStyles={{ mt: 25 }} submitAction={() => generateFile()} />
    </Box>
  );
};

export default ImageCropper;
