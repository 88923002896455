import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectResizeTableStore = (state: Object) => state.resizeTable;

const makeSelectResizeTable = () => createSelector(
  selectResizeTableStore,
  ({ reportTableResize }: Object) => reportTableResize,
);

const makeSelectIDB = () => createSelector(
  selectResizeTableStore,
  ({ idb }: Object) => idb,
);

const makeSelectIDBProps = () => createSelector(
  selectResizeTableStore,
  ({ idbProps }: Object) => idbProps,
);

export {
  makeSelectIDB,
  makeSelectIDBProps,
  makeSelectResizeTable,
};
