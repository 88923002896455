import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// components audio-player
import * as A from './actions.js';
//////////////////////////////////////////////////

const initialState = {
  playing: false,
  src: 'https://amous-front-dev.s3.amazonaws.com/definite.mp3',
};

const setAudioPlaying = (state: Object, value: Object) => (
  P.$set('playing', value, state)
);

export default createReducer({
  [A.setAudioPlaying]: setAudioPlaying,
}, initialState);
