import React from 'react';
import * as R from 'ramda';
import { shouldUpdate } from 'react-recompose';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// hocs
import { withDnDStartEnd } from '../../../../hocs';
// ui
import { Box, Span, Text, Flex, scrollableContainerCss3px } from '../../../../ui';
// component edit-report
import { FieldSelect } from '../field-select';
// feature edit-report
import { defaultReportField } from './settings';
//////////////////////////////////////////////////

const SortHeader = (props: Object) => {
  const {
    maxFields,
    addFieldText,
    addFieldsText,
    availableFields,
    reportFieldList,
    setReportFieldList,
    defaultFieldsCount,
    handleAddReportField,
    handleAddReportFields,
    setDefaultFieldsCount,
    setAvailableForReports,
  } = props;

  const count = R.compose(
    R.length,
    R.keys,
  )(reportFieldList);

  const inputStyles = {
    width: '22px',
    height: '22px',
    outline: 'none',
    fontSize: '12px',
    textAlign: 'center',
    marginRight: '15px',
    borderRadius: '2px',
    border: `1px solid ${G.getTheme('colors.darkGrey')}`,
  };

  const handleChange = (event: Object) => {
    const value = R.compose(
      R.when(
        parseInt,
        R.clamp(1, 50),
      ),
      R.replace(/[^\d]/g, ''),
      R.trim,
      R.defaultTo(''),
    )(event.target.value);

    setDefaultFieldsCount(value);
  };

  const handleRemoveAll = () => {
    setAvailableForReports(availableFields);
    setReportFieldList(R.of(Array, defaultReportField));
  };

  return (
    <Flex
      p='0 15px'
      height={40}
      width='100%'
      borderBottom='1px solid'
      justifyContent='space-between'
      color={G.getTheme('colors.light.black')}
      bg={G.getTheme('colors.light.lightGrey')}
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <Span>{G.getWindowLocale('titles:fields', 'Fields')}</Span>
      {
        G.isNotNil(maxFields) &&
        <span>{`${count}/${maxFields}`}</span>
      }
      <Box
        ml={10}
        fontSize={12}
        color={G.getTheme('colors.light.blue')}
      >
        {
          R.gt(R.length(reportFieldList), 1) &&
          <Span
            mr={15}
            cursor='pointer'
            onClick={handleRemoveAll}
          >
            {G.getWindowLocale('titles:remove-all', 'Remove All')}
          </Span>
        }
        <Span
          mr='5px'
          cursor='pointer'
          onClick={handleAddReportFields}
        >
          {R.or(addFieldsText, G.getWindowLocale('titles:add-fields', 'Add Fields'))}
        </Span>
        <input
          type='text'
          style={inputStyles}
          onChange={handleChange}
          value={defaultFieldsCount}
        />
        <Span
          cursor='pointer'
          onClick={handleAddReportField}
        >
          {R.or(addFieldText, G.getWindowLocale('titles:add-field', 'Add Field'))}
        </Span>
        {/* TODO: we have performance issue here, have to add better solution */}
        {/* {
          R.isNil(maxFields) && maxCurrentFieldsLength &&
          <Span
            mr={15}
            cursor='pointer'
            onClick={handleAddAllReportField}
          >
            {G.getWindowLocale('titles:add-all-fields', 'Add All Fields')}
          </Span>
        } */}
      </Box>
    </Flex>
  );
};

const ItemAction = (props: Object) => {
  const { freezed, fieldIndex, handleRemoveReportField, handleChangeReportFieldFrozen } = props;

  const darkBlueThemeColor = 'colors.dark.blue';
  const pinText = G.ifElse(
    G.isTrue(freezed),
    G.getWindowLocale('titles:unpin', 'Unpin'),
    G.getWindowLocale('titles:pin', 'Pin'),
  );
  const pinColor = G.getThemeByCond(
    G.isTrue(freezed),
    'colors.light.blue',
    'icons.iconColorGrey',
  );

  return (
    <Flex>
      <Box>
        <Flex
          ml={10}
          alignItems='center'
          title={G.getWindowLocale('titles:drag-and-drop', 'Drag And Drop')}
        >
          {I.dnd(G.getTheme(darkBlueThemeColor), 20, 20)}
        </Flex>
      </Box>
      <Box>
        <Flex
          ml={10}
          cursor='pointer'
          alignItems='center'
          onClick={() => handleRemoveReportField(fieldIndex)}
          title={G.getWindowLocale('titles:remove', 'Remove')}
        >
          {I.trash(G.getTheme(darkBlueThemeColor))}
        </Flex>
      </Box>
      <Box>
        <Flex
          ml={10}
          cursor='pointer'
          alignItems='center'
          onClick={() => handleChangeReportFieldFrozen({ freezed, fieldIndex })}
          title={pinText}
        >
          {I.pin(pinColor)}
        </Flex>
      </Box>
    </Flex>
  );
};

const FieldItem = (props: Object) => {
  const {
    name,
    zIndex,
    provided,
    fieldIndex,
    collection,
    availableFields,
    availableForReports,
    handleSelectReportFieldName,
  } = props;


  const field = availableFields.find((af: Object) => R.equals(name, af.value));

  let options = availableForReports;

  if (field) {
    options = R.concat([field], options);
  }

  return (
    <Flex py={10} alignItems='center' zIndex={zIndex}>
      <ItemAction {...props} />
      <FieldSelect
        value={name}
        options={options}
        provided={provided}
        collection={collection}
        fieldIndex={fieldIndex}
        id={`report-field-${fieldIndex}`}
        action={handleSelectReportFieldName}
      />
    </Flex>
  );
};

const enhance = shouldUpdate((props: Object, nextProps: Object) => {
  const toDiff = ['dndProcessing', 'reportFieldList', 'availableFields'];

  const shouldUpdate = R.any((field: string) => (
    G.notEquals(R.prop(field, props), R.prop(field, nextProps))
  ))(toDiff);

  return shouldUpdate;
});

const Fields = enhance((props: Object) => {
  const {
    dndProcessing,
    reportFieldList,
    availableFields,
    availableForReports,
    handleRemoveReportField,
    handleSelectReportFieldName,
    handleChangeReportFieldFrozen,
  } = props;

  const border = G.ifElse(G.isTrue(dndProcessing), '1px dashed grey', 'none');
  const itemBorderColor = G.getTheme('colors.lightGrey');

  const reportFieldListToUse = R.map(
    (field: Object) => {
      const { name, origValue, referenceFieldName } = field;

      if (R.or(R.isNil(referenceFieldName), G.isNotNilAndNotEmpty(origValue))) return field;

      return R.assoc(
        GC.FIELD_NAME,
        `Ref (${G.getWindowLocale(...GC.referenceFieldNameLocaleMap[referenceFieldName])}): ${name}`,
        field,
      );
    },
    reportFieldList,
  );

  const maxIndex = R.multiply(R.length(availableFields), 5);

  return (
    <Flex border={border} flexDirection='column'>
      {
        reportFieldListToUse.map((field: Object, index: number) => (
          <Draggable
            key={index}
            index={index}
            draggableId={`report-field-list-dnd-item-${index}`}
          >
            {(provided: Object) => (
              <Box
                width='100%'
                alignItems='start'
                border='1px solid'
                borderColor={itemBorderColor}
                zIndex={R.subtract(maxIndex, R.inc(index))}
              >
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <FieldItem
                    {...field}
                    fieldIndex={index}
                    provided={provided}
                    availableFields={availableFields}
                    reportFieldList={reportFieldListToUse}
                    availableForReports={availableForReports}
                    handleRemoveReportField={handleRemoveReportField}
                    handleSelectReportFieldName={handleSelectReportFieldName}
                    handleChangeReportFieldFrozen={handleChangeReportFieldFrozen}
                  />
                </div>
                {provided.placeholder}
              </Box>
            )}
          </Draggable>
        ))
      }
    </Flex>
  );
});

const onDragEnd = (result: Object, props: Object) => {
  const { handleDragEnd, handleReportFieldSortDragEnd } = props;
  handleReportFieldSortDragEnd(result);
  handleDragEnd(result);
};

const FieldList = withDnDStartEnd((props: Object) => {
  const { reportFieldList, handleDragStart } = props;

  const fieldsLength = R.keys(reportFieldList);

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={(result: Object) => onDragEnd(result, props)}>
      <Flex flexDirection='column'>
        <SortHeader {...props} />
        <Box
          overflowY='auto'
          height='calc(100vh - 225px)'
          css={scrollableContainerCss3px}
        >
          <Box>
            {
              G.isNilOrEmpty(fieldsLength) &&
              <Box p={15}>
                <Flex flexDirection='column'>
                  <Text fontSize={16} color={G.getTheme('colors.dark.grey')}>
                    {
                      G.getWindowLocale(
                        'messages:you-do-not-have-any-fields-for-this-report',
                        "You don't have any fields for this Report",
                      )
                    }
                  </Text>
                </Flex>
              </Box>
            }
          </Box>
          {
            G.isNotNilAndNotEmpty(fieldsLength) &&
            <Droppable droppableId='reportFields'>
              {(provided: Object, snapshot: Object) => (
                <div ref={provided.innerRef}>
                  <Fields snapshot={snapshot} {...props} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          }
        </Box>
      </Flex>
    </DragDropContext>
  );
});

export default FieldList;
