import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const validateMinesOrFloat = (value: Object) => {
  if (R.equals('-', value)) {
    return false;
  }
  return R.not(R.test(GC.FLOAT_REGEXP, Number(value).toFixed(4)));
};

export const isInvalidChargeRateField = ({ name, value }: Object) => R.and(
  R.test(/\.rate$/, name),
  validateMinesOrFloat(value),
);

// accessorials
export const getFirstAccessorial = (filter: Function, accessorials: Array) => R.compose(
  R.when(R.is(Object), R.identity()),
  R.head(),
  R.filter(filter),
)(accessorials);

const fuelAccessorialFilter = (item: Object) => G.isTrue(
  R.prop(GC.FIELD_ACCESSORIAL_FUEL_RELATED, item),
);

const fuelAccessorialFilterForDO = (item: Object) => R.and(
  G.isTrue(R.prop(GC.FIELD_ACCESSORIAL_FUEL_RELATED, item)),
  R.or(
    G.isFalse(R.prop(GC.FIELD_CHARGE_INTERNAL_EXPENSE, item)),
    G.isNilOrEmpty(R.prop(GC.FIELD_CHARGE_INTERNAL_EXPENSE, item)),
  ),
);

const noFuelAccessorialFilterForDO = (item: Object) => {
  const { advancePayment, internalExpense } = item;

  if (G.isTrue(advancePayment)) return false;

  if (G.isTrue(internalExpense)) return false;

  return G.isFalse(R.prop(GC.FIELD_ACCESSORIAL_FUEL_RELATED, item));
};

export const noFuelAccessorialFilter = (item: Object) => G.isFalse(
  R.prop(GC.FIELD_ACCESSORIAL_FUEL_RELATED, item),
);

const getAccessorialLabel = (item: Object) => {
  const { advancePayment, displayedValue, internalExpense } = item;

  let label = displayedValue;

  if (G.isTrue(internalExpense)) {
    label = `${displayedValue} (${G.getWindowLocale('titles:internal-expense', 'Internal Expense')})`;
  }

  if (G.isTrue(advancePayment)) {
    label = `${displayedValue} (${G.getWindowLocale('titles:advance-payment', 'Advance Payment')})`;
  }

  return label;
};

const getOptionsFromAccessorials = (filter: Function, accessorials: Array) => R.compose(
  R.prepend(GC.EMPTY_OPTION_OBJECT),
  R.map((item: Object) => ({
    label: getAccessorialLabel(item),
    value: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
    [GC.FIELD_ACCESSORIAL_CONFIG_GUID]: G.getPropFromObject(GC.FIELD_ORIGINAL_CONFIG_GUID, item),
    [GC.FIELD_SHARED_ASSESSORIAL_GUID]: G.getPropFromObject(GC.FIELD_SHARED_ASSESSORIAL_GUID, item),
  })),
  R.filter(filter),
)(accessorials);

export const getAccessorialsForState = (data: Object, loadType: string) => {
  const accessorials = R.compose(
    R.indexBy(R.prop(GC.FIELD_DISPLAYED_VALUE)),
    R.map((item: Object) => R.assoc(GC.FIELD_CHARGE_QUANTITY, 1, item)),
  )(data);

  const noFuelFilter = G.ifElse(
    G.isLoadTypeClo(loadType),
    noFuelAccessorialFilterForDO,
    noFuelAccessorialFilter,
  );

  const fuelFilter = G.ifElse(
    G.isLoadTypeClo(loadType),
    fuelAccessorialFilterForDO,
    fuelAccessorialFilter,
  );

  const fuelSelectOptions = getOptionsFromAccessorials(fuelFilter, data);
  const noFuelSelectOptions = getOptionsFromAccessorials(noFuelFilter, data);

  return {
    accessorials,
    fuelSelectOptions,
    noFuelSelectOptions,
  };
};
// accessorials

// rate type/unit
const getDefaultRateUnit = (rateType: string, options: Object) => {
  const map = {
    [GC.CHARGE_RATE_TYPE_TIME]: GC.CHARGE_RATE_UNIT_TYPE_HOUR,
    [GC.CHARGE_RATE_TYPE_DISCOUNT]: GC.CHARGE_RATE_UNIT_TYPE_FLAT,
    [GC.CHARGE_RATE_TYPE_LINE_HAUL]: GC.CHARGE_RATE_UNIT_TYPE_PERCENT,
    [GC.CHARGE_RATE_TYPE_CUSTOMER_RATE]: GC.CHARGE_RATE_UNIT_TYPE_FLAT,
    [GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL]: GC.CHARGE_RATE_UNIT_TYPE_PERCENT,
    [GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL]: GC.CHARGE_RATE_UNIT_TYPE_FLAT,
    [GC.CHARGE_RATE_TYPE_VOLUME]: G.ifElse(
      G.isNilOrEmpty(R.prop(GC.FIELD_TOTAL_TRIP_VOLUME, options)),
      '',
      R.prop(GC.FIELD_TOTAL_TRIP_VOLUME_UOM, options),
    ),
    [GC.CHARGE_RATE_TYPE_QUANTITY]: G.ifElse(
      G.isNilOrEmpty(R.prop(GC.FIELD_TOTAL_QUANTITIES, options)),
      '',
      R.head(R.keys(R.pathOr({}, [GC.FIELD_TOTAL_QUANTITIES], options))),
    ),
    [GC.CHARGE_RATE_TYPE_DISTANCE]: G.ifElse(
      G.isNilOrEmpty(R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, options)),
      GC.UOM_KILOMETER,
      R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, options),
    ),
    [GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE]: G.ifElse(
      G.isNilOrEmpty(R.prop(GC.FIELD_DEADHEAD_DISTANCE_UOM, options)),
      GC.UOM_KILOMETER,
      R.prop(GC.FIELD_DEADHEAD_DISTANCE_UOM, options),
    ),
    [GC.CHARGE_RATE_TYPE_WEIGHT]: G.ifElse(
      G.isNilOrEmpty(R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT_UOM, options)),
      GC.UOM_KILOGRAM,
      R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT_UOM, options),
    ),
  };

  return R.prop(rateType, map);
};

export const getDefaultChargeUnitFromChargeRateType = (rateType: string, props: Object) => {
  const pick = [
    GC.FIELD_TOTAL_QUANTITIES,
    GC.FIELD_TOTAL_TRIP_VOLUME,
    GC.FIELD_DEADHEAD_DISTANCE,
    GC.FIELD_TOTAL_TRIP_WEIGHT,
    GC.FIELD_TOTAL_TRIP_DISTANCE,
    GC.FIELD_TOTAL_TRIP_VOLUME_UOM,
    GC.FIELD_DEADHEAD_DISTANCE_UOM,
    GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
  ];

  const options = R.pick(pick, props);

  return getDefaultRateUnit(rateType, options);
};

// calc charge total
export const isCustomerRateWithPercent = (rateType: string, rateUnit: string) => G.isAllTrue(
  R.equals(rateType, GC.CHARGE_RATE_TYPE_CUSTOMER_RATE),
  R.equals(rateUnit, GC.CHARGE_RATE_UNIT_TYPE_PERCENT),
);

export const isCustomerLineHaulWithPercent = (rateType: string, rateUnit: string) => G.isAllTrue(
  R.equals(rateType, GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL),
  R.equals(rateUnit, GC.CHARGE_RATE_UNIT_TYPE_PERCENT),
);

export const isDiscountWithPercent = (rateType: string, rateUnit: string) => G.isAllTrue(
  R.equals(rateType, GC.CHARGE_RATE_TYPE_DISCOUNT),
  R.equals(rateUnit, GC.CHARGE_RATE_UNIT_TYPE_PERCENT),
);

export const isLineHaulWithPercent = (rateType: string, rateUnit: string) => G.isAllTrue(
  R.equals(rateType, GC.CHARGE_RATE_TYPE_LINE_HAUL),
  R.equals(rateUnit, GC.CHARGE_RATE_UNIT_TYPE_PERCENT),
);

export const isTaxableTotalWithPercent = (rateType: string, rateUnit: string) => G.isAllTrue(
  R.equals(rateType, GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL),
  R.equals(rateUnit, GC.CHARGE_RATE_UNIT_TYPE_PERCENT),
);

export const calcSimpleChargeTotal = (chargeState: Object) => R.multiply(
  R.prop(GC.FIELD_CHARGE_RATE, chargeState),
  R.propOr(1, GC.FIELD_CHARGE_QUANTITY, chargeState),
);
