import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Flex } from '../../ui';
//////////////////////////////////////////////////

export const CalendarWrapper = styled(Flex)`
  & .react-datepicker__navigation {
    z-index: 11;
  }
  & .react-datepicker__triangle {
    display: ${({ withoutArrow }: Object) => G.ifElse(G.isTrue(withoutArrow), 'none', 'block')};
  }
  & input {
    color: ${({ color }: Object) => color || 'unset'};
    border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('inputs.borderColor')};
  }
`;
