import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../text';
// features
import { integrationTypeLocale } from '../../features/master-invoice/constants';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

const darkGreyColor = G.getTheme('colors.darkGrey');
const lightGreyColor = G.getTheme('colors.lightGrey');

const rowHeight = 30;
const tableMaxHeight = 'calc(100vh - 300px)';

const getReport = (documentsType: string, integrationAvailable: boolean) => {
  if (R.equals(documentsType, GC.DOCUMENTS_TYPE_MASTER_INVOICE)) {
    let fields = [
      { name: GC.FIELD_DOCUMENT_FILE_NAME, sequence: 1 },
      { name: GC.FIELD_CREATED_DATE, sequence: 2 },
      { name: GC.FIELD_CREATED_BY, sequence: 3 },
      { name: GC.FIELD_INTEGRATION_TYPE, sequence: 4 },
      { name: GC.FIELD_DOCUMENT_SENT_TO, sequence: 5 },
      { name: GC.FIELD_SENT_DATE, sequence: 6 },
      { name: GC.FIELD_SENT_BY, sequence: 7 },
    ];

    if (G.isTrue(integrationAvailable)) {
      fields = R.prepend(
        { name: 'sendAction', sequence: 0 },
        fields,
      );
    }

    return { fields };
  }

  return {
    fields: [
      { name: GC.FIELD_DOCUMENT_FILE_NAME, sequence: 1 },
      { name: GC.FIELD_DOCUMENT_SENT_TO, sequence: 2 },
      { name: GC.FIELD_CREATED_DATE, sequence: 3 },
      { name: GC.FIELD_CREATED_BY, sequence: 4 },
    ],
  };
};

const tableSettings = {
  allowEditBtn: false,
  allowSelectAll: true,
  checkBoxCellWidth: 40,
  allowSelectItems: true,
  maxHeight: tableMaxHeight,
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
};

const tableSettings2 = {
  rowHeight: 40,
  maxHeight: 244,
  cellFontSize: 11,
  titleFontSize: 11,
  titleRowHeight: 32,
  tableRowHeight: 40,
  allowEditBtn: false,
  allowSelectAll: true,
  checkBoxCellWidth: 40,
  withBoxShadow: 'none',
  allowSelectItems: true,
  titleTextColor: darkGreyColor,
  additionalTitleBgColor: lightGreyColor,
  checkBoxCellBorderColor: lightGreyColor,
  tableWrapperProps: {
    bg: lightGreyColor,
    withoutBorder: true,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
};

const getColumnSettings = ({
  download,
  getDocuments,
  asyncEndpoint,
  previewDocument,
  columnSettingsProps,
  exportFactoringDocument,
}: Object) => ({
  sendAction: {
    width: 100,
    customComponent: ({ data }: Object) => {
      if (G.isNilOrEmpty(data[GC.FIELD_INTEGRATION_TYPE])) return null;

      const options = {
        params: { [GC.FIELD_PRIMARY_OBJECT_GUID]: data[GC.FIELD_PRIMARY_OBJECT_GUID] },
      };

      return (
        <Box
          cursor='pointer'
          color={G.getTheme('colors.light.blue')}
          onClick={() => exportFactoringDocument({
            documentGuid: data[GC.FIELD_GUID],
            callback: () => getDocuments(asyncEndpoint, options),
          })}
        >
          {G.getWindowLocale('actions:send-to-factoring', 'Send To Factoring')}
        </Box>
      );
    },
  },
  [GC.FIELD_DOCUMENT_FILE_NAME]: {
    name: 'titles:document-name',
    width: R.pathOr(300, [GC.FIELD_DOCUMENT_FILE_NAME, 'width'], columnSettingsProps),
    customComponent: ({ data }: Object) => (
      <Flex>
        <Box
          pr='8px'
          cursor='pointer'
          title={G.getWindowLocale('actions:download', 'Download')}
          onClick={() => download({
            params: {
              [GC.FIELD_FILE_NAME]: data[GC.FIELD_DOCUMENT_FILE_NAME],
              [GC.FIELD_PRIMARY_OBJECT_GUID]: data[GC.FIELD_PRIMARY_OBJECT_GUID],
            },
          })}
        >
          {I.download()}
        </Box>
        <TextComponent
          cursor='pointer'
          withEllipsis={true}
          color={G.getTheme('colors.light.blue')}
          maxWidth={R.pathOr(264, [GC.FIELD_DOCUMENT_FILE_NAME, 'width'], columnSettingsProps)}
          title={`${G.getWindowLocale('titles:click-for-preview', 'Click For Preview')} - ${
            data[GC.FIELD_DOCUMENT_FILE_NAME]}`}
          onClick={() => previewDocument({
            [GC.FIELD_FILE_NAME]: data[GC.FIELD_DOCUMENT_FILE_NAME],
            [GC.FIELD_PRIMARY_OBJECT_GUID]: data[GC.FIELD_PRIMARY_OBJECT_GUID],
          })}
        >
          {data[GC.FIELD_DOCUMENT_FILE_NAME]}
        </TextComponent>
      </Flex>
    ),
  },
  [GC.FIELD_DOCUMENT_SENT_TO]: {
    width: 200,
    name: 'titles:sent-to',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
    width: R.pathOr(200, [GC.FIELD_CREATED_DATE, 'width'], columnSettingsProps),
  },
  [GC.FIELD_CREATED_BY]: {
    width: 100,
    name: 'titles:created-by',
  },
  [GC.FIELD_INTEGRATION_TYPE]: {
    width: 100,
    name: 'titles:integration-type',
    customComponent: ({ data }: Object) => {
      const text = R.pathOr(
        data[GC.FIELD_INTEGRATION_TYPE],
        [data[GC.FIELD_INTEGRATION_TYPE]], integrationTypeLocale,
      );

      return (
        <TextComponent
          title={text}
          maxWidth={84}
          withEllipsis={true}
        >
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_DOCUMENT_SENT_TO]: {
    width: 200,
    name: 'titles:sent-to',
  },
  [GC.FIELD_SENT_DATE]: {
    width: 200,
    name: 'titles:exported-date',
    customComponent: ({ data }: Object) => {
      const info = R.path(['exportInfo', 0, GC.FIELD_SENT_DATE], data);

      if (R.isNil(info)) return null;

      const title = G.createLocalDateTimeFromInstanceOrISOString(info, GC.DEFAULT_DATE_TIME_FORMAT);

      return (
        <TextComponent
          title={title}
          maxWidth={184}
          withEllipsis={true}
        >
          {title}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_SENT_BY]: {
    width: 100,
    name: 'titles:exported-by',
    customComponent: ({ data }: Object) => {
      const info = R.path(['exportInfo', 0, GC.FIELD_SENT_BY], data);

      if (R.isNil(info)) return null;

      return (
        <TextComponent
          title={info}
          maxWidth={184}
          withEllipsis={true}
        >
          {info}
        </TextComponent>
      );
    },
  },
});

export {
  getReport,
  tableSettings,
  tableSettings2,
  getColumnSettings,
};
