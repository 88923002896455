import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectOrderTableStore = (state: Object) => state.orderTable;

const makeSelectOrderTable = () => createSelector(
  selectOrderTableStore,
  ({ reportTableOrder }: Object) => reportTableOrder,
);

const makeSelectIDB = () => createSelector(
  selectOrderTableStore,
  ({ idb }: Object) => idb,
);

const makeSelectIDBProps = () => createSelector(
  selectOrderTableStore,
  ({ idbProps }: Object) => idbProps,
);

export {
  makeSelectIDB,
  makeSelectIDBProps,
  makeSelectOrderTable,
};
