import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
// components loader
import { closeLoader } from './actions';
import { LoaderWrapper, LoaderContent } from './ui';
//////////////////////////////////////////////////

const LoaderComponent = (props: Object) => (
  <LoaderWrapper
    {...R.pathOr({}, ['wrapperStyles'], props)}
    position={props.position}
    onClick={props.closeLoader}
  >
    <LoaderContent />
  </LoaderWrapper>
);

const Loader = connect(null, {
  closeLoader,
})(LoaderComponent);

export default Loader;

export {
  Loader,
  LoaderComponent,
};
