import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectModalStore = (state: Object) => state.modal;

const makeSelectModal = () => createSelector(
  selectModalStore,
  (modal: Object) => modal,
);

const makeSelectModalStatus = () => createSelector(
  selectModalStore,
  ({ isOpened }: Object) => isOpened,
);

export {
  makeSelectModal,
  selectModalStore,
  makeSelectModalStatus,
};
