import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, BoxHovered, StyledLink } from '../../ui';
// component async-documents
import { enhanceDocuments } from './documents-list';
//////////////////////////////////////////////////

const greyColor = G.getTheme('colors.dark.grey');
const blueColor = G.getTheme('colors.light.blue');
const darkGreyColor = G.getTheme('colors.darkGrey');
const textColor = G.getTheme('colors.greyMatterhorn');

const textStyles = {
  fontSize: 12,
  color: textColor,
  wordBreak: 'break-all',
};

const DocumentName = (props: Object) => {
  const { url, documentAction, documentFilename } = props;

  if (G.isNilOrEmpty(documentFilename)) {
    return (
      <StyledLink
        {...textStyles}
        target='_blank'
        maxWidth='100%'
        color={blueColor}
        href={G.makeURLString(url)}
      >
        {url}
      </StyledLink>
    );
  }

  return (
    <BoxHovered
      {...textStyles}
      maxWidth='100%'
      cursor='pointer'
      color={blueColor}
      hoverColor={blueColor}
      onClick={documentAction}
      hoverTextDecoration='underline'
    >
      {documentFilename}
    </BoxHovered>
  );
};

const Documents = (props: Object) => {
  const {
    showSend,
    documents,
    handleRemoveDocument,
    handlePreviewDocument,
    handleDownloadDocument,
    handleSendDocumentViaEmail,
  } = props;

  const byTxtLocale = G.getWindowLocale('titles:by', 'by');
  const addedTxtLocale = G.getWindowLocale('titles:added', 'Added');

  return documents.map((document: Object, index: number) => {
    const {
      url,
      guid,
      createdBy,
      createdDate,
      documentFilename,
      primaryObjectGuid,
    } = document;

    const params = {
      [GC.FIELD_FILE_NAME]: documentFilename,
      [GC.FIELD_PRIMARY_OBJECT_GUID]: primaryObjectGuid,
    };

    const downloadAction = () => handleDownloadDocument({ params });

    const previewAction = () => handlePreviewDocument(params);

    const sendAction = () => handleSendDocumentViaEmail(guid);

    const removeAction = () => handleRemoveDocument(guid);

    return (
      <Flex mt='8px' key={index} width='100%' alignItems='flex-start'>
        <Flex width='calc(100% - 80px)'>
          <Box mr='7px' height={20}>
            {G.getDocumentIconByDocumentFileName(documentFilename)}
          </Box>
          <Box width='calc(100% - 30px)'>
            <DocumentName
              url={url}
              documentAction={downloadAction}
              documentFilename={documentFilename}
            />
            <Box
              {...textStyles}
              mt='5px'
              maxWidth='100%'
              color={greyColor}
            >
              {addedTxtLocale} {G.convertDateTimeToConfigFormat(createdDate)} {byTxtLocale} {createdBy}
            </Box>
          </Box>
        </Flex>
        <Flex ml='auto'>
          {
            G.isNilOrEmpty(url) &&
            <Box
              mr='8px'
              cursor='pointer'
              onClick={previewAction}
              title={G.getWindowLocale('actions:preview', 'Preview')}
            >
              {I.eye(darkGreyColor)}
            </Box>
          }
          {
            showSend &&
            <Box
              mr='8px'
              cursor='pointer'
              onClick={sendAction}
              title={G.getWindowLocale('titles:send-email', 'Send Email')}
            >
              {I.renderMailIcon(darkGreyColor)}
            </Box>
          }
          <Box
            cursor='pointer'
            onClick={removeAction}
            title={G.getWindowLocale('titles:remove-document', 'Remove Document')}
          >
            {I.remove(darkGreyColor)}
          </Box>
        </Flex>
      </Flex>
    );
  });
};

const DocumentsCard = (props: Object) => {
  const { loading, itemList } = props;

  if (G.isTrue(loading)) return null;

  return (
    <Documents {...props} documents={R.or(itemList, [])} />
  );
};

export default enhanceDocuments(DocumentsCard);
