import React from 'react';
import * as R from 'ramda';
import { shouldUpdate } from 'react-recompose';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// hocs
import { withDnDStartEnd } from '../../../../hocs';
// ui
import { Box, Span, Text, Flex } from '../../../../ui';
// component edit-report
import { FieldSelect } from '../field-select';
//////////////////////////////////////////////////

const SortHeader = (props: Object) => {
  const { reportSortList, handleAddSortField } = props;

  const sortLength = R.length(reportSortList);

  return (
    <Flex
      p='0 15px'
      height={40}
      borderBottom='1px solid'
      justifyContent='space-between'
      color={G.getTheme('colors.light.black')}
      bg={G.getTheme('colors.light.lightGrey')}
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <Span>{G.getWindowLocale('titles:sort', 'Sort')}</Span>
      {
        R.lt(sortLength, 5) &&
        <Box
          ml={10}
          fontSize={12}
          cursor='pointer'
          onClick={handleAddSortField}
          color={G.getTheme('colors.light.blue')}
        >
          {G.getWindowLocale('titles:add-field', 'Add Field')}
        </Box>
      }
    </Flex>
  );
};

const FilterItemAction = (props: Object) => {
  const { fieldIndex, handleRemoveSortField } = props;

  return (
    <Flex>
      <Box>
        <Flex
          ml={10}
          alignItems='center'
          title={G.getWindowLocale('titles:drag-and-drop', 'Drag And Drop')}
        >
          {I.dnd(G.getTheme('colors.dark.blue'), 20, 20)}
        </Flex>
      </Box>
      <Box>
        <Flex
          ml={10}
          cursor='pointer'
          alignItems='center'
          onClick={() => handleRemoveSortField(fieldIndex)}
          title={G.getWindowLocale('titles:remove', 'Remove')}
        >
          {I.trash(G.getTheme('colors.dark.blue'))}
        </Flex>
      </Box>
    </Flex>
  );
};

const SortItem = (props: Object) => {
  const {
    name,
    order,
    zIndex,
    provided,
    fieldIndex,
    availableFields,
    availableForSort,
    handleSelectSortName,
    handleChangeFieldOrder,
  } = props;

  const orderIcon = G.ifElse(R.equals(order, 'ASC'), I.arrowUp, I.arrowDown);
  const orderToUpdate = G.ifElse(R.equals(order, 'ASC'), 'DESC', 'ASC');
  const field = availableFields.find((af: Object) => R.equals(name, af.value));

  let options = availableForSort;

  if (field) {
    options = R.concat([field], options);
  }

  return (
    <Flex py={10} zIndex={zIndex} alignItems='center'>
      <FilterItemAction {...props} />
      <FieldSelect
        value={name}
        options={options}
        provided={provided}
        fieldIndex={fieldIndex}
        action={handleSelectSortName}
        id={`report-sort-${fieldIndex}`}
      />
      <Flex pr={10}>
        <Span
          title={order}
          cursor='pointer'
          onClick={() => handleChangeFieldOrder({ fieldIndex, order: orderToUpdate })}
        >
          {orderIcon(G.getTheme('colors.dark.blue'))}
        </Span>
      </Flex>
    </Flex>
  );
};

const enhance = shouldUpdate((props: Object, nextProps: Object) => {
  const toDiff = ['dndProcessing', 'reportSortList', 'availableFields'];

  const shouldUpdate = R.any((field: string) => (
    G.notEquals(R.prop(field, props), R.prop(field, nextProps))
  ))(toDiff);

  return shouldUpdate;
});

const Fields = enhance((props: Object) => {
  const {
    dndProcessing,
    reportSortList,
    availableFields,
    availableForSort,
    handleSelectSortName,
    handleRemoveSortField,
    handleChangeFieldOrder } = props;

  const border = G.ifElse(G.isTrue(dndProcessing), '1px dashed grey', 'none');

  return (
    <Flex border={border} flexDirection='column'>
      {
        reportSortList.map((field: Object, index: number) => (
          <Draggable
            key={index}
            index={index}
            draggableId={`report-sort-list-dnd-item-${index}`}
          >
            {(provided: Object) => (
              <Box
                width='100%'
                alignItems='start'
                border='1px solid'
                borderColor={G.getTheme('colors.lightGrey')}
                zIndex={G.curriedParseInt(R.divide(150, R.inc(index)))}
              >
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <SortItem
                    {...field}
                    fieldIndex={index}
                    provided={provided}
                    reportSortList={reportSortList}
                    availableFields={availableFields}
                    availableForSort={availableForSort}
                    handleSelectSortName={handleSelectSortName}
                    handleRemoveSortField={handleRemoveSortField}
                    handleChangeFieldOrder={handleChangeFieldOrder}
                  />
                </div>
                {provided.placeholder}
              </Box>
            )}
          </Draggable>
        ))
      }
    </Flex>
  );
});

const onDragEnd = (result: Object, props: Object) => {
  const { handleDragEnd, handleSortDragEnd } = props;
  handleSortDragEnd(result);
  handleDragEnd(result);
};

const SortList = withDnDStartEnd((props: Object) => {
  const { reportSortList, handleDragStart } = props;

  const sortLength = R.keys(reportSortList);

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={(result: Object) => onDragEnd(result, props)}>
      <Box width='auto'>
        <SortHeader {...props} />
        <Box>
          {
            G.isNilOrEmpty(sortLength) &&
            <Box p={15}>
              <Flex flexDirection='column'>
                <Text fontSize={16} color={G.getTheme('colors.dark.grey')}>
                  {
                    G.getWindowLocale(
                      'messages:you-do-not-have-any-sorting-for-this-report',
                      "You don't have any sorting for this Report",
                    )
                  }
                </Text>
              </Flex>
            </Box>
          }
        </Box>
        {
          G.isNotNilAndNotEmpty(sortLength) &&
          <Droppable droppableId='sortFields'>
            {(provided: Object, snapshot: Object) => (
              <div ref={provided.innerRef}>
                <Fields snapshot={snapshot} {...props} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        }
      </Box>
    </DragDropContext>
  );
});

export default SortList;
