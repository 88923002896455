import React, { memo } from 'react';
import Dropzone from 'react-dropzone';
// components
import { TextComponent } from '../text';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
// component activities
import { renderConfirmationModal } from './helpers';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blackColor = G.getTheme('colors.black');
const darkGreyColor = G.getTheme('colors.#7D828C');
const lightGreyColor = G.getTheme('colors.lightGrey');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const wrapperStyles = {
  mb: 10,
  p: '7px',
  borderRadius: '4px',
  border: '1px solid',
  color: greyMatterhornColor,
  borderColor: lightGreyColor,
};

const ImagePreview = (props: Object) => {
  const { openModal, filePublicLink, documentFilename, thumbnailFilePublicLink } = props;

  const wrapperStyles = {
    mr: 15,
    width: 50,
    height: 45,
    flexShrink: 0,
    justifyContent: 'center',
  };

  if (G.isNilOrEmpty(thumbnailFilePublicLink)) {
    return (
      <Flex {...wrapperStyles}>
        {G.getDocumentIconByDocumentFileName(documentFilename, 30, 30)}
      </Flex>
    );
  }

  const fileNotAvailable = G.getWindowLocale('messages:file-not-available', 'The file is not available');

  const handleOpenPreview = () => {
    const component = (
      <Box height='90vh'>
        {
          filePublicLink ?
            <img
              width='100%'
              height='100%'
              src={filePublicLink}
              alt={fileNotAvailable}
              style={{ objectFit: 'contain' }}
            /> :
            fileNotAvailable
        }
      </Box>
    );

    const modal = {
      p: '0px',
      component,
      options: {
        minWidth: 500,
        height: '90vh',
        outsideCloseButton: true,
        maxWidth: 'calc(100vw - 50px)',
      },
    };

    openModal(modal);
  };

  const title = `${G.getWindowLocale('actions:click-to-view', 'Click To View')} ${documentFilename}`;

  return (
    <Flex {...wrapperStyles} cursor='pointer'>
      <img
        height={45}
        width='100%'
        title={title}
        alt={fileNotAvailable}
        onClick={handleOpenPreview}
        src={thumbnailFilePublicLink}
        style={{ objectFit: 'contain' }}
      />
    </Flex>
  );
};

const Document = memo((props: Object) => {
  const { document, openModal, closeModal, withThumbnail, handleDocuments, handleDownloadOrPreviewDocument } = props;

  const { guid, createdBy, createdDate, documentFilename } = document;

  const formattedCreatedDate = G.convertDateTimeToConfigFormat(createdDate);

  return (
    <Flex {...wrapperStyles} fontSize={12}>
      {
        G.isTrue(withThumbnail) &&
        <ImagePreview {...document} openModal={openModal} />
      }
      <Box width={`calc(100% - ${G.ifElse(withThumbnail, 65, 0)}px)`}>
        <Flex justifyContent='space-between'>
          <TextComponent withEllipsis={true} title={documentFilename} maxWidth='calc(100% - 75px)'>
            {documentFilename}
          </TextComponent>
          <Flex>
            {
              G.isNilOrFalse(withThumbnail) &&
              <Box mr={10} cursor='pointer' onClick={() => handleDownloadOrPreviewDocument({ guid })}>
                {I.eye(darkGreyColor)}
              </Box>
            }
            <Box
              mr={10}
              cursor='pointer'
              onClick={() => handleDownloadOrPreviewDocument({ guid, actionType: 'download' })}
            >
              {I.downloadDocument(darkGreyColor)}
            </Box>
            <Box
              cursor='pointer'
              onClick={() =>
                renderConfirmationModal({ openModal, closeModal, action: () => handleDocuments('delete', {}, guid) })
              }
            >
              {I.trash(darkGreyColor)}
            </Box>
          </Flex>
        </Flex>
        <Flex mt='5px'>
          <TextComponent
            mr='5px'
            title={createdBy}
            withEllipsis={true}
            color={darkGreyColor}
            maxWidth='calc(60% - 45px)'
          >
            {createdBy}
          </TextComponent>
          <TextComponent
            withEllipsis={true}
            color={darkGreyColor}
            maxWidth='calc(40% - 45px)'
            title={formattedCreatedDate}
          >
            {formattedCreatedDate}
          </TextComponent>
        </Flex>
      </Box>
    </Flex>
  );
});

const AddDocument = ({ handleDocuments }: Object) => (
  <Dropzone
    className='drop-zone'
    onDrop={(files: Array) => handleDocuments(
      'post',
      { data: G.makeDataForMultipleDocuments({ [GC.FIELD_FILES]: files }) },
    )}
  >
    {({ getRootProps, getInputProps }: Object) => (
      <Flex
        {...getRootProps()}
        m={15}
        px={15}
        height={60}
        bg={whiteColor}
        cursor='pointer'
        borderRadius='4px'
        textAlign='center'
        border='1px dashed'
        wordBreak='break-word'
        justifyContent='center'
        width='calc(100% - 30px)'
        color={G.getTheme('colors.dark.blue')}
        borderColor={G.getTheme('listActions.newBorderColor')}
      >
        <input {...getInputProps()} />
        Drag and Drop or select files from your Computer
      </Flex>
    )}
  </Dropzone>
);

const tabsProps = {
  textColor: 'inherit',
  scrollButtons: 'auto',
  indicatorColor: blackColor,
  style: { minHeight: '26px', borderBottom: `1px solid ${lightGreyColor}` },
  TabIndicatorProps: {
    sx: {
      height: 3,
      backgroundColor: blackColor,
    },
  },
};

const toolbarSettings = {
  link: { inDropdown: true },
  list: { inDropdown: true },
  inline: { inDropdown: true },
  history: { inDropdown: true },
  textAlign: { inDropdown: true },
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'link',
    'emoji',
    'history',
    'list',
    'textAlign',
    'colorPicker',
    'remove',
  ],
};

export {
  Document,
  tabsProps,
  AddDocument,
  toolbarSettings,
};
