import * as R from 'ramda';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
// components modal
import * as A from './actions.js';
//////////////////////////////////////////////////

const initialState = {
  modal: [],
  isOpened: false,
};

const openModal = (state: Object, payload: Object) => ({
  isOpened: true,
  modal: R.append(payload, state.modal),
});

const closeModal = (state: Object) => ({
  modal: R.init(state.modal),
  isOpened: G.isNotEmpty(R.init(state.modal)),
});

const closeAllModals = () => initialState;

export default createReducer({
  [A.openModal]: openModal,
  [A.closeModal]: closeModal,
  [A.closeAllModals]: closeAllModals,
}, initialState);
