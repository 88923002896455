import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 280,
};

const calendarInputWrapperStyles = {
  width: '100%',
};

export const insuranceFields = [
  {
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    calendarInputWrapperStyles,
    label: ['titles:effective-date'],
    fieldName: GC.FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE,
    maxDate: (props: Object) =>
      G.subtractMomentTime(R.path(['values', GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE], props), 1, 'days'),
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    calendarInputWrapperStyles,
    label: ['titles:expiration-date'],
    fieldName: GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE,
    minDate: (props: Object) =>
      G.addMomentTime(R.path(['values', GC.FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE], props), 1, 'days'),
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:insurance-name'],
    fieldName: GC.FIELD_CARRIER_INSURANCE_INSURANCE_NAME,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:policy-number'],
    fieldName: GC.FIELD_CARRIER_INSURANCE_POLICY_NUMBER,
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:insurance-type'],
    options: GC.FIELD_CARRIER_INSURANCE_TYPE,
    fieldName: GC.FIELD_CARRIER_INSURANCE_TYPE,
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:coverage-amount'],
    fieldName: GC.FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT,
  },
  {
    type: 'textarea',
    label: ['titles:description'],
    inputWrapperStyles: { mb: 25, width: 600 },
    fieldName: GC.FIELD_CARRIER_INSURANCE_DESCRIPTION,
  },
];

export const defaultInsuranceFields = {
  [GC.FIELD_CARRIER_INSURANCE_TYPE]: '',
  [GC.FIELD_CARRIER_INSURANCE_DESCRIPTION]: '',
  [GC.FIELD_CARRIER_INSURANCE_POLICY_NUMBER]: '',
  [GC.FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE]: '',
  [GC.FIELD_CARRIER_INSURANCE_INSURANCE_NAME]: '',
  [GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE]: '',
  [GC.FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT]: '',
};

export const insuranceValidationSchema = {
  [GC.FIELD_CARRIER_INSURANCE_TYPE]: Yup.string().required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT]: Yup.number()
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 1000000000))
    .max(1000000000, G.getShouldBeFromToLocaleTxt(1, 1000000000)),
};

export const insuranceSettings = {
  [GC.FIELD_CARRIER_INSURANCE_EFFECTIVE_DATE]: {
    width: 250,
    name: 'titles:effective-date',
  },
  [GC.FIELD_CARRIER_INSURANCE_EXPIRATION_DATE]: {
    name: 'titles:expiration-date',
    width: 250,
  },
  [GC.FIELD_CARRIER_INSURANCE_INSURANCE_NAME]: {
    width: 250,
    name: 'titles:insurance-name',
  },
  [GC.FIELD_CARRIER_INSURANCE_TYPE]: {
    width: 250,
    name: 'titles:insurance-type',
  },
  [GC.FIELD_CARRIER_INSURANCE_POLICY_NUMBER]: {
    width: 250,
    name: 'titles:policy-number',
  },
  [GC.FIELD_CARRIER_INSURANCE_DESCRIPTION]: {
    width: 400,
    name: 'titles:description',
  },
  [GC.FIELD_CARRIER_INSURANCE_COVERAGE_AMOUNT]: {
    width: 200,
    name: 'titles:coverage-amount',
  },
};
