import React from 'react';
import * as R from 'ramda';
import { compose, withState, withHandlers } from 'react-recompose';
// ui
import { Flex } from '../../ui';
// component local-loader
import { Wrapper } from './ui';
//////////////////////////////////////////////////

export const withLocalLoaderHoc = compose(
  withState('localLoaderOpen', 'toggle', false),
  withHandlers({
    handleOpenLocalLoader: (props: Object) => () => props.toggle(true),
    handleCloseLocalLoader: (props: Object) => () => props.toggle(false),
  }),
);

export const LocalLoader = (props: Object) => (
  <Flex
    p={props.p}
    bg={props.bg}
    height={props.height}
    justifyContent='center'
    display={props.display}
    overflow={props.overflow}
    position={props.position}
    minWidth={props.minWidth}
    maxHeight={props.maxHeight}
    minHeight={props.minHeight}
    width={props.width || '100%'}
    data-testid='testid-localLoader'
    flexDirection={props.flexDirection}
    alignItems={R.or(props.alignItems, 'center')}
  >
    {
      props.localLoaderOpen && <Wrapper ><div /><div /><div /><div /></Wrapper>
    }
    {
      R.not(props.localLoaderOpen) && props.children
    }
  </Flex>
);

export default LocalLoader;
