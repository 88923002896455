import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const getReportTableOrder = createAction('getReportTableOrder');
export const setReportTableOrder = createAction('setReportTableOrder');
export const deleteAllReportOrderData = createAction('deleteAllOrderData');
export const resetReportTableOrder = createAction('resetReportTableOrder');
export const updateTableOrderByGuid = createAction('updateTableOrderByGuid');
export const deleteTableOrderByGuid = createAction('deleteTableOrderByGuid');
export const initTableOrderIDBSuccess = createAction('initTableOrderIDBSuccess');
export const reportTableOrderIDBReconnect = createAction('reportTableOrderIDBReconnect');
