import * as R from 'ramda';
import React, { Fragment, useCallback } from 'react';
// components
import { TextComponent } from '../text';
// forms
import { Toggle } from '../../forms';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, Span } from '../../ui';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const lightGreyColor = G.getTheme('colors.light.lightGrey');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const Actions = (props: Object) => {
  const {
    item,
    updateItem,
    removeItem,
  } = props;

  return (
    <Flex>
      <Span
        mr={10}
        cursor='pointer'
        onClick={() => removeItem(item)}
        title={G.getWindowLocale('titles:remove', 'Remove')}
      >
        {I.trash(darkBlueColor)}
      </Span>
      <Span
        mr={10}
        cursor='pointer'
        onClick={() => updateItem(item)}
        title={G.getWindowLocale('titles:edit', 'Edit')}
      >
        {I.pencil(darkBlueColor)}
      </Span>
    </Flex>
  );
};

// TODO: check active logic and handler
const ActiveAction = (props: Object) => {
  const {
    item,
    updateItem,
  } = props;

  const { active } = item;

  const handleChange = useCallback((e: Object) => {
    const active = G.getEventTargetChecked(e);

    updateItem(R.assoc('active', active, item));
  }, [updateItem]);

  return (
    <Flex>
      {G.getWindowLocale('titles:active', 'Active')}
      <Box ml={10}>
        <Toggle icons={false} checked={active} onChange={handleChange} />
      </Box>
    </Flex>
  );
};

const ItemsList = (props: Object) => {
  const {
    listHook,
    initialItems,
    AddComponent,
    itemToTextMapper,
    showActive = false,
    showActions = false,
  } = props;

  const { items, addItem, updateItem, removeItem } = listHook(initialItems);

  return (
    <Fragment>
      {
        AddComponent ? <AddComponent addItem={addItem} /> : null
      }
      {
        G.isNotNilAndNotEmpty(items) &&
        <Box my={15} width='100%'>
          {
            items.map((item: Object, index: number) => {
              const text = itemToTextMapper(item);

              const idOrGuid = G.getIdOrGuidFromObject(item);

              const itemsLength = R.length(items);

              return (
                <Flex
                  py={15}
                  width='100%'
                  key={`${idOrGuid}-${index}`}
                  borderTop={`1px solid ${lightGreyColor}`}
                  borderBottom={R.equals(itemsLength, R.inc(index)) ? `1px solid ${lightGreyColor}` : 'none'}
                >
                  {
                    showActions &&
                    <Actions
                      item={item}
                      addItem={addItem}
                      updateItem={updateItem}
                      removeItem={removeItem}
                    />
                  }
                  {
                    showActive &&
                    <ActiveAction
                      item={item}
                      updateItem={updateItem}
                    />
                  }
                  <TextComponent
                    ml={15}
                    title={text}
                    withEllipsis={true}
                    color={greyMatterhornColor}
                  >
                    {text}
                  </TextComponent>
                </Flex>
              );
            })
          }
        </Box>
      }
    </Fragment>
  );
};

export {
  ItemsList,
};
