// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const getFilterTypeOptions = () => ([
  {
    value: GC.ADDITIONAL_FILTER_TYPE_DRIVER,
    label: G.getWindowLocale('titles:driver', 'Driver'),
  },
  {
    value: GC.ADDITIONAL_FILTER_TYPE_CARRIER,
    label: G.getWindowLocale('titles:carrier', 'Carrier'),
  },
  {
    value: GC.ADDITIONAL_FILTER_TYPE_BRANCH,
    label: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: GC.ADDITIONAL_FILTER_TYPE_INVOICE_NUMBER,
    label: G.getWindowLocale('titles:invoice-number', 'Invoice #'),
  },
  {
    value: GC.ADDITIONAL_FILTER_TYPE_CLO_PRIMARY_REFERENCE_VALUE,
    label: G.getWindowLocale('titles:primary-reference-name', 'Primary Reference: Name'),
  },
  {
    value: GC.ADDITIONAL_FILTER_TYPE_BILL_TO_NAME,
    label: G.getWindowLocale('titles:bill-to-name', 'Bill To: Name'),
  },
]);
