import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// forms
import { FieldsetComponent } from '../../forms/formik/fieldset';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
// compensation-range-group
import { defaultRangeGroupFields, compensationRangeGroupSection } from './field-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('rangeGroup', 'setRangeGroup', ({ initialValues }: Object) => {
    if (G.isNilOrEmpty(initialValues)) {
      return {
        [GC.FIELD_WEIGHT]: false,
        [GC.FIELD_DISTANCE]: false,
        [GC.FIELD_QUANTITY]: false,
        [GC.FIELD_TEMPERATURE]: false,
      };
    }
    const checkIsOpenedRangeSection = (section: string) => R.compose(
      R.any(G.isNotNilAndNotEmpty),
      R.values,
      R.pickAll(R.keys(R.prop(section, defaultRangeGroupFields))),
    )(initialValues);

    return {
      [GC.FIELD_WEIGHT]: checkIsOpenedRangeSection(GC.FIELD_WEIGHT),
      [GC.FIELD_DISTANCE]: checkIsOpenedRangeSection(GC.FIELD_DISTANCE),
      [GC.FIELD_QUANTITY]: checkIsOpenedRangeSection(GC.FIELD_QUANTITY),
      [GC.FIELD_TEMPERATURE]: checkIsOpenedRangeSection(GC.FIELD_TEMPERATURE),
    };
  }),
  withHandlers({
    handleToggleRangeSection: (props: Object) => (section: string) => {
      const { values, setValues, rangeGroup, setRangeGroup } = props;
      const currentSection = R.prop(section, rangeGroup);
      const newRangeGroup = R.assoc(section, R.not(currentSection), rangeGroup);
      if (currentSection) {
        const newValues = R.omit(R.keys(R.prop(section, defaultRangeGroupFields)), values);
        setValues(newValues);
      }
      setRangeGroup(newRangeGroup);
    },
  }),
  pure,
);

const CompensationRangeGroup = (props: Object) => {
  const { rangeGroup, handleToggleRangeSection } = props;
  const darkBlueColor = G.getTheme('colors.dark.blue');
  const whiteGreyColor = G.getTheme('colors.whiteGrey');

  return (
    <Box width='100%'>
      {compensationRangeGroupSection.map(({ title, fields, section }: Object) => (
        <Box mb={10} key={section}>
          <Box bg={whiteGreyColor}>
            <Flex
              ml={15}
              cursor='pointer'
              width='max-content'
              onClick={() => handleToggleRangeSection(section)}
            >
              <Box m={10} fontSize={14} fontWeight='bold' color={darkBlueColor}>
                {G.getWindowLocale(title)}
              </Box>
              {
                G.ifElse(
                  R.prop(section, rangeGroup),
                  I.minusInRound,
                  I.plusRound,
                )()
              }
            </Flex>
          </Box>
          {
            R.prop(section, rangeGroup) &&
            <FieldsetComponent
              {...G.getFormikProps(props)}
              fields={fields}
              fieldsetPadding='10px 15px' />
          }
        </Box>
      ))}
    </Box>
  );
};

export default enhance(CompensationRangeGroup);
