import * as R from 'ramda';
// helpers/constants
import * as GC from '../../constants';
import { genShortId } from '../../helpers/ids';
import { getWindowLocale } from '../../helpers/locale';
import {
  DEFAULT_TIME_RATE_UNIT_OPTIONS,
  DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_LINE_HAUL_RATE_UNIT_OPTIONS,
  DEFAULT_TAXABLE_TOTAL_RATE_UNIT_OPTIONS,
  DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS,
  DEFAULT_DEADHEAD_DISTANCE_RATE_UNIT_OPTIONS,
} from '../../helpers/options';
//////////////////////////////////////////////////

export const accessorialFieldsToPick = [
  // NOTE: guid should be first here
  GC.FIELD_GUID,
  GC.FIELD_RATE,
  GC.FIELD_DEDUCTION,
  GC.FIELD_STORED_VALUE,
  GC.FIELD_ADVANCE_PAYMENT,
  GC.FIELD_CHARGE_QUANTITY,
  GC.FIELD_FUEL_INDEX_INFO,
  GC.FIELD_CHARGE_RATE_NAME,
  GC.FIELD_CHARGE_RATE_TYPE,
  GC.FIELD_CHARGE_RATE_UNIT,
  GC.FIELD_CHARGE_NON_TAXABLE,
  GC.FIELD_CHARGE_RATE_NAME_STORED,
  GC.FIELD_CHARGE_INTERNAL_EXPENSE,
];

export const accessorialFieldsToPick2 = R.tail(accessorialFieldsToPick);

const flatLabel = getWindowLocale('titles:flat', 'Flat');
const timeLabel = getWindowLocale('titles:time', 'Time');
const stopLabel = getWindowLocale('titles:stop', 'Stop');
const storedMainLabel = getWindowLocale('titles:lhc', 'LHC');
const weightLabel = getWindowLocale('titles:weight', 'Weight');
const volumeLabel = getWindowLocale('titles:volume', 'Volume');
const storedDiscountLabel = getWindowLocale('titles:dcc', 'DCC');
const haulLabel = getWindowLocale('titles:line-haul', 'Line Haul');
const discountLabel = getWindowLocale('titles:discount', 'Discount');
const distanceLabel = getWindowLocale('titles:distance', 'Distance');
const deadheadLabel = getWindowLocale('titles:deadhead', 'Deadhead');
const quantityLabel = getWindowLocale('titles:quantity', 'Quantity');
const customerLabel = getWindowLocale('titles:customer-rate', 'Customer Rate');
const taxableTotalLabel = getWindowLocale('titles:taxable-total', 'Taxable Total');
const customerLineHaulLabel = getWindowLocale('titles:customer-line-haul', 'Customer Line Haul');

export const mainCharge = {
  [GC.FIELD_CHARGE_RATE]: 0,
  [GC.FIELD_CHARGE_TOTAL]: 0,
  [GC.FIELD_CHARGE_QUANTITY]: 1,
  [GC.FIELD_CHARGE_CURRENCY]: null,
  [GC.FIELD_CHARGE_COMMENTS]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
  [GC.FIELD_CHARGE_NON_TAXABLE]: false,
  [GC.FIELD_DISPLAYED_VALUE]: haulLabel,
  [GC.FIELD_CHARGE_HOLD_QUANTITY]: false,
  [GC.FIELD_STORED_VALUE]: storedMainLabel,
  [GC.FIELD_CHARGE_TYPE]: GC.CHARGE_TYPE_MAIN,
  [GC.FIELD_CHARGE_RATE_TYPE]: GC.CHARGE_RATE_TYPE_FLAT,
};

export const discountCharge = {
  [GC.FIELD_CHARGE_RATE]: 0,
  [GC.FIELD_CHARGE_TOTAL]: 0,
  [GC.FIELD_CHARGE_QUANTITY]: 1,
  [GC.FIELD_CHARGE_CURRENCY]: null,
  [GC.FIELD_CHARGE_COMMENTS]: null,
  [GC.FIELD_CHARGE_NON_TAXABLE]: true,
  [GC.FIELD_CHARGE_HOLD_QUANTITY]: false,
  [GC.FIELD_DISPLAYED_VALUE]: discountLabel,
  [GC.FIELD_STORED_VALUE]: storedDiscountLabel,
  [GC.FIELD_CHARGE_TYPE]: GC.CHARGE_TYPE_DISCOUNT,
  [GC.FIELD_CHARGE_RATE_TYPE]: GC.CHARGE_RATE_TYPE_DISCOUNT,
  [GC.FIELD_CHARGE_RATE_UNIT]: GC.CHARGE_RATE_UNIT_TYPE_FLAT,
};

export const fuelCharge = {
  [GC.FIELD_CHARGE_RATE]: 0,
  [GC.FIELD_CHARGE_TOTAL]: 0,
  [GC.FIELD_CHARGE_QUANTITY]: 1,
  [GC.FIELD_STORED_VALUE]: null,
  [GC.FIELD_CHARGE_CURRENCY]: null,
  [GC.FIELD_CHARGE_COMMENTS]: null,
  [GC.FIELD_DISPLAYED_VALUE]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
  [GC.FIELD_CHARGE_NON_TAXABLE]: true,
  [GC.FIELD_CHARGE_HOLD_QUANTITY]: false,
  [GC.FIELD_CHARGE_TYPE]: GC.CHARGE_TYPE_FUEL,
  [GC.FIELD_CHARGE_RATE_TYPE]: GC.CHARGE_RATE_TYPE_FLAT,
};

export const additionalCharge = {
  [GC.FIELD_CHARGE_RATE]: 0,
  [GC.FIELD_CHARGE_TOTAL]: 0,
  [GC.FIELD_CHARGE_QUANTITY]: 1,
  [GC.FIELD_STORED_VALUE]: null,
  [GC.FIELD_CHARGE_CURRENCY]: null,
  [GC.FIELD_DISPLAYED_VALUE]: null,
  [GC.FIELD_CHARGE_COMMENTS]: null,
  [GC.FIELD_CHARGE_RATE_UNIT]: null,
  [GC.FIELD_CHARGE_NON_TAXABLE]: true,
  [GC.FIELD_CHARGE_HOLD_QUANTITY]: false,
  [GC.FIELD_CHARGE_TYPE]: GC.CHARGE_TYPE_ADDITIONAL,
  [GC.FIELD_CHARGE_RATE_TYPE]: GC.CHARGE_RATE_TYPE_FLAT,
};

export const createMainCharge = () => ({
  id: genShortId(),
  ...mainCharge,
});

export const createFuelCharge = () => ({
  id: genShortId(),
  ...fuelCharge,
});

export const createDiscountCharge = () => ({
  id: genShortId(),
  ...discountCharge,
});

export const createAdditionCharge = () => ({
  id: genShortId(),
  ...additionalCharge,
});

const doRateTypeMainOptions = [
  { label: flatLabel, value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: distanceLabel, value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: weightLabel, value: GC.CHARGE_RATE_TYPE_WEIGHT },
  { label: volumeLabel, value: GC.CHARGE_RATE_TYPE_VOLUME },
  { label: quantityLabel, value: GC.CHARGE_RATE_TYPE_QUANTITY },
  { label: timeLabel, value: GC.CHARGE_RATE_TYPE_TIME },
];

const dmRateTypeMainOptions = [
  { label: flatLabel, value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: distanceLabel, value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: weightLabel, value: GC.CHARGE_RATE_TYPE_WEIGHT },
  { label: volumeLabel, value: GC.CHARGE_RATE_TYPE_VOLUME },
  { label: quantityLabel, value: GC.CHARGE_RATE_TYPE_QUANTITY },
  { label: timeLabel, value: GC.CHARGE_RATE_TYPE_TIME },
  { label: customerLabel, value: GC.CHARGE_RATE_TYPE_CUSTOMER_RATE },
  { label: customerLineHaulLabel, value: GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL },
];

const rateTypeDiscountOptions = [
  { label: discountLabel, value: GC.CHARGE_RATE_TYPE_DISCOUNT},
];

const doRateTypeFuelOptions = [
  { label: flatLabel, value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: distanceLabel, value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: haulLabel, value: GC.CHARGE_RATE_TYPE_LINE_HAUL },
];

const dmRateTypeFuelOptions = [
  { label: flatLabel, value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: distanceLabel, value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: deadheadLabel, value: GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE },
  { label: haulLabel, value: GC.CHARGE_RATE_TYPE_LINE_HAUL },
];

const doRateTypeAdditionalOptions = [
  { label: flatLabel, value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: distanceLabel, value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: haulLabel, value: GC.CHARGE_RATE_TYPE_LINE_HAUL },
  { label: weightLabel, value: GC.CHARGE_RATE_TYPE_WEIGHT },
  { label: volumeLabel, value: GC.CHARGE_RATE_TYPE_VOLUME },
  { label: quantityLabel, value: GC.CHARGE_RATE_TYPE_QUANTITY },
  { label: timeLabel, value: GC.CHARGE_RATE_TYPE_TIME },
  { label: stopLabel, value: GC.CHARGE_RATE_TYPE_STOP },
  { label: taxableTotalLabel, value: GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL },
];

const cancelCLoRateTypeAdditionalOptions = [
  { label: flatLabel, value: GC.CHARGE_RATE_TYPE_FLAT },
];

const dmRateTypeAdditionalOptions = [
  { label: flatLabel, value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: distanceLabel, value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: haulLabel, value: GC.CHARGE_RATE_TYPE_LINE_HAUL },
  { label: weightLabel, value: GC.CHARGE_RATE_TYPE_WEIGHT },
  { label: volumeLabel, value: GC.CHARGE_RATE_TYPE_VOLUME },
  { label: quantityLabel, value: GC.CHARGE_RATE_TYPE_QUANTITY },
  { label: timeLabel, value: GC.CHARGE_RATE_TYPE_TIME },
  { label: stopLabel, value: GC.CHARGE_RATE_TYPE_STOP },
  { label: deadheadLabel, value: GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE },
  { label: taxableTotalLabel, value: GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL },
];

export const dmRateTypeVendorAdditionalOptions = [
  ...dmRateTypeAdditionalOptions,
  { label: customerLabel, value: GC.CHARGE_RATE_TYPE_CUSTOMER_RATE },
  { label: customerLineHaulLabel, value: GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL },
];

export const rateTypeOptions = {
  [GC.CHARGE_TYPE_MAIN]: {
    [GC.FIELD_CLO]: doRateTypeMainOptions,
    [GC.FIELD_TEL]: dmRateTypeMainOptions,
  },
  [GC.CHARGE_TYPE_DISCOUNT]: {
    [GC.FIELD_CLO]: rateTypeDiscountOptions,
    [GC.FIELD_TEL]: rateTypeDiscountOptions,
  },
  [GC.CHARGE_TYPE_FUEL]: {
    [GC.FIELD_CLO]: doRateTypeFuelOptions,
    [GC.FIELD_TEL]: dmRateTypeFuelOptions,
  },
  [GC.CHARGE_TYPE_ADDITIONAL]: {
    [GC.FIELD_CLO]: doRateTypeAdditionalOptions,
    [GC.FIELD_TEL]: dmRateTypeAdditionalOptions,
  },
  [GC.CHARGE_TYPE_CANCEL_CLO]: {
    [GC.FIELD_CLO]: cancelCLoRateTypeAdditionalOptions,
    [GC.FIELD_TEL]: cancelCLoRateTypeAdditionalOptions,
  },
};

export const rateUnitOptionsGroup = {
  [GC.CHARGE_RATE_TYPE_VOLUME]: DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_TIME]: R.tail(DEFAULT_TIME_RATE_UNIT_OPTIONS),
  [GC.CHARGE_RATE_TYPE_QUANTITY]: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_WEIGHT]: R.tail(DEFAULT_WEIGHT_RATE_UNIT_OPTIONS),
  [GC.CHARGE_RATE_TYPE_DISTANCE]: R.tail(DEFAULT_DISTANCE_RATE_UNIT_OPTIONS),
  [GC.CHARGE_RATE_TYPE_DISCOUNT]: R.tail(DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS),
  [GC.CHARGE_RATE_TYPE_LINE_HAUL]: R.tail(DEFAULT_LINE_HAUL_RATE_UNIT_OPTIONS),
  [GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL]: R.tail(DEFAULT_TAXABLE_TOTAL_RATE_UNIT_OPTIONS),
  [GC.CHARGE_RATE_TYPE_CUSTOMER_RATE]: R.tail(DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS),
  [GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL]: R.tail(DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS),
  [GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE]: R.tail(DEFAULT_DEADHEAD_DISTANCE_RATE_UNIT_OPTIONS),
};

export const rateTypesWithoutQuantity = [GC.CHARGE_RATE_TYPE_FLAT, GC.CHARGE_RATE_TYPE_DISCOUNT];

export const chargeTypeOptions = [
  {
    value: GC.CHARGE_TYPE_MAIN,
    label: getWindowLocale('titles:line-haul', 'Line Haul'),
  },
  {
    value: GC.CHARGE_TYPE_DISCOUNT,
    label: getWindowLocale('titles:discount', 'Discount'),
  },
  {
    value: GC.CHARGE_TYPE_FUEL,
    label: getWindowLocale('titles:fuel', 'Fuel'),
  },
  {
    value: GC.CHARGE_TYPE_ADDITIONAL,
    label: getWindowLocale('titles:additional', 'Additional'),
  },
];
