import * as R from 'ramda';
import React from 'react';
import { Waypoint } from 'react-waypoint';
// components
import { TextComponent } from '../text';
import { FormGroupTitleComponent } from '../form-group-title';
import { TableRow, TableColumn, TableContainer } from '../list';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Checkbox, FormGroupWrapper } from '../../forms';
// icons
import * as I from '../../svgs';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const renderContent = (content: any, isNotHeader: boolean) => {
  if (R.and(R.is(String, content), isNotHeader)) {
    return (
      <TextComponent
        width='100%'
        title={content}
        withEllipsis={true}
        display='inline-block'
      >
        {content}
      </TextComponent>
    );
  }

  return content;
};

const getContent = (field: Object, key: string, type: string, props: Object) => {
  const { label, uiType, uiField, options, customLogic } = field;

  const keys = key.split(':');
  const value = R.path(keys, props.entity);
  const condition = R.and(R.is(Object, value), G.isNot(Array, value));

  let content = G.ifElse(condition, R.pathOr('', ['displayedValue'], value), value);

  if (R.is(Boolean, value)) content = G.ifElse(G.isTrue(value), I.uiTrue, I.uiFalse)();

  if (R.is(Array, value)) content = R.join(', ', value);

  if (R.and(G.notEquals(type, 'header'), G.isNotNil(label))) content = label(props.entity);

  if (G.isNotNilAndNotEmpty(options)) content = G.getDisplayValueFromOptionsByStoredValue(content, options);

  const entity = R.path(['entity', key], props);

  if (R.and(G.isNotNil(customLogic), G.isNotNil(entity))) content = customLogic(entity, props);

  if (R.and(G.isFunction(customLogic), G.isTrue(uiField))) content = customLogic(entity, props);

  if (R.equals(uiType, 'enum')) return G.getEnumLocale(value);

  return content;
};

const TableColumnActions = (props: Object) => {
  const {
    entity,
    selectable,
    notEditable,
    columnWidth,
    notDeletable,
    handleEditRow,
    handlePreview,
    handlePrintRow,
    handleDeleteRow,
    handleSelectEntity,
    handleDownloadMail,
    additionalColumnActions,
  } = props;

  const iconColor = G.getTheme('colors.dark.blue');
  const withPreviewAction = R.path(['withPreviewAction'], entity);
  const isHiddenEditIcon = R.pathOr(notEditable, ['notEditable'], entity);
  const isHiddenDeleteIcon = R.pathOr(notDeletable, ['notDeletable'], entity);
  const specificStyles = { display: 'flex', alignItems: 'center', justifyContent: 'space-around' };

  return (
    <TableColumn width={columnWidth} specificStyles={specificStyles}>
      {
        selectable &&
        <Checkbox
          type='checkbox'
          checked={entity.selected}
          onChange={() => handleSelectEntity(R.prop(GC.FIELD_GUID, entity), entity)}
        />
      }
      {
        R.not(isHiddenDeleteIcon) &&
        <Box height={15} cursor='pointer' onClick={() => handleDeleteRow(entity)}>
          {I.trash(iconColor)}
        </Box>
      }
      {
        R.not(isHiddenEditIcon) &&
        <Box height={15} cursor='pointer' onClick={() => handleEditRow(entity)}>
          {I.pencil(iconColor)}
        </Box>
      }
      {
        G.isFunction(handleDownloadMail) &&
        <Box height={20} cursor='pointer' onClick={() => handleDownloadMail(entity)}>
          {I.downloadMail(iconColor)}
        </Box>
      }
      {
        G.isFunction(handlePrintRow) &&
        <Box height={15} cursor='pointer' onClick={() => handlePrintRow(entity)}>
          {I.printer(iconColor)}
        </Box>
      }
      {
        withPreviewAction &&
        <Box height={15} cursor='pointer' onClick={() => handlePreview(entity)}>
          {I.eye(iconColor)}
        </Box>
      }
      {
        G.isArray(additionalColumnActions) &&
        additionalColumnActions.map(({ icon, title, height, handleClick, getVisibility }: Object, index: number) => (
          <Box
            key={index}
            cursor='pointer'
            title={R.or(title, '')}
            height={R.or(height, 15)}
            onClick={() => handleClick(entity)}
            visibility={G.isFunction(getVisibility) ? getVisibility(entity) : 'visible'}
          >
            {icon}
          </Box>
        ))
      }
    </TableColumn>
  );
};

const HeaderTableColumn = (props: Object) => {
  const { entities, selectable, columnWidth, handleSelectEntity } = props;

  if (selectable) {
    const allChecked = G.isAllChecked(entities);

    return (
      <TableColumn pl='7px' width={columnWidth}>
        <Checkbox
          type='checkbox'
          checked={allChecked}
          onChange={() => handleSelectEntity('all')}
        />
      </TableColumn>
    );
  }

  return <TableColumn width={columnWidth} />;
};

const renderTableColumns = (props: Object, type: string) => {
  const columnData = [];
  const columnWidth = R.or(props.tableColumnWidth, 70);

  if (R.equals(type, 'header')) {
    columnData.push(
      <HeaderTableColumn {...props} key='header-row' columnWidth={columnWidth} />,
    );
  } else {
    columnData.push(
      <TableColumnActions {...props} columnWidth={columnWidth} key='table-column-actions' />,
    );
  }

  R.forEachObjIndexed((field: Object, key: string) => {
    const content = getContent(field, key, type, props);
    columnData.push(
      <TableColumn
        key={key}
        width={field.width}
        specificStyles={{
          paddingRight: '10px',
          wordBreak: 'break-word',
        }}
      >
        {
          renderContent(
            G.ifElse(
              R.equals(type, 'header'),
              G.getWindowLocale(field.name, '...'),
              content,
            ),
            G.notEquals(type, 'header'),
          )
        }
      </TableColumn>,
    );
  }, props.entitiesFields);

  return columnData;
};

const renderTableRows = (props: Object) => props.entities.map((entity: Object, i: number) => (
  <TableRow
    key={i}
    ta='left'
    jc='baseline'
    height='auto'
    display='flex'
    bgColor={entity.backgroundColor}
    p={R.or(props.tableRowPadding, '5px 15px')}
  >
    {
      renderTableColumns({
        entity,
        ...props,
      })
    }
  </TableRow>
));

export const FormGroupTitlePanel = (props: Object) => {
  const {
    isOpened,
    panelTitle,
    customPanel,
    handleAddClick,
    withoutArrowDown,
    handleToggleFormGroup,
  } = props;

  if (G.isNotNil(customPanel)) return customPanel(props);

  const icon = G.ifElse(props.isHiddenAddIcon, null, I.plusRound(G.getTheme('icons.iconColorWhite')));

  return (
    <FormGroupTitleComponent
      mb='0px'
      endIcon={icon}
      text={panelTitle}
      isOpened={isOpened}
      onClickLabel={handleAddClick}
      onToggleFormGroup={handleToggleFormGroup}
      withArrowDown={G.ifElse(withoutArrowDown, null, true)}
    />
  );
};

export const FormGroupTable = (props: Object) => (
  <FormGroupWrapper zi={props.zI} isOpened={props.isOpened} position={props.position}>
    <FormGroupTitlePanel {...props} />
    {
      G.isNotNil(props.entities) &&
      <TableContainer overflow={props.overflow} maxHeight={R.or(props.tableMaxHeight, null)}>
        <TableRow
          top='0'
          py='5px'
          px={15}
          ta='left'
          zIndex={10}
          jc='baseline'
          display='flex'
          isHeader={false}
          position='sticky'
          bgColor={G.getTheme('tables.header.greyBgColor')}
          borderColor={G.getTheme('tables.rows.borderColor')}
        >
          {
            renderTableColumns({ entity: {}, ...props }, 'header')
          }
        </TableRow>
        {
          renderTableRows(props)
        }
        {
          G.isTrue(props.withPagination) && R.gt(props.totalCount, props.pagination.offset) &&
          <Waypoint onEnter={props.handleLoadMoreEntities} />
        }
      </TableContainer>
    }
  </FormGroupWrapper>
);
