import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const defaultReportFields = {
  [GC.FIELD_NAME]: '',
  [GC.FIELD_ROLE_GUIDS]: [],
  [GC.FIELD_DESCRIPTION]: '',
  [GC.FIELD_CUSTOMER_GUIDS]: [],
  [GC.FIELD_REPORT_DEFAULT]: false,
};

export const validationSchema = () => Yup.object().shape({
  [GC.FIELD_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(3, G.getShouldBeFromToLocaleTxt(3, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(3, 40)),
  [GC.FIELD_DESCRIPTION]: Yup.string()
    .nullable(true)
    .min(0, G.getShouldBeFromToLocaleTxt(0, 255))
    .max(255, G.getShouldBeFromToLocaleTxt(0, 255)),
});

const inputWrapperStyles = { mb: 25, width: 370 };

const reportFields = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    autoComplete: 'off',
    fieldName: GC.FIELD_NAME,
    label: ['titles:report-name', 'Report Name'],
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_DESCRIPTION,
    label: ['titles:description', 'Description'],
    inputWrapperStyles: {...inputWrapperStyles, mb: 20},
  },
  {
    type: 'checkbox',
    inputWrapperStyles,
    fieldName: GC.FIELD_REPORT_DEFAULT,
    label: ['titles:set-as-default-report', 'Set as Default Report'],
  },
  {
    isMulti: true,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    options: 'customerOptions',
    fieldName: GC.FIELD_CUSTOMER_GUIDS,
    label: ['titles:customers', 'Customers'],
    inputWrapperStyles: {...inputWrapperStyles, zIndex: 11},
    closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
  },
];

export const getReportFields = (roles: Array) => {
  if (G.isNilOrEmpty(roles)) return reportFields;

  return R.insert(1, {
    isMulti: true,
    type: 'reactSelect',
    options: 'roleOptions',
    fieldName: GC.FIELD_ROLE_GUIDS,
    label: ['titles:roles', 'Roles'],
    inputWrapperStyles: {...inputWrapperStyles, zIndex: 12},
  }, reportFields);
};
