import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
// components
import { Label } from '../label';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const Download = (props: Object) => {
  const {
    name,
    icon,
    entity,
    action } = props;
  return (
    <Box onClick={() => action(entity)}>
      <Label frontLabel={icon}>
        {R.or(R.prop('documentFilename', entity), R.prop(name, entity))}
      </Label>
    </Box>
  );
};

export const DownloadWithLabel = (props: Object) => {
  const {
    name,
    icon,
    entity,
    downloadAction } = props;
  if (R.and(R.isNil(R.prop('documentFilename', entity)), R.isNil(R.prop(name, entity)))) return null;
  const DownloadConnect = connect(null, { action: downloadAction })(Download);
  return <DownloadConnect icon={icon} name={name} entity={entity} />;
};
