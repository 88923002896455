import React from 'react';
import * as R from 'ramda';
// components
import { PopperComponent } from '../popper';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

const Error = ({ error }: Object) => (
  <Box p='5px' color='light.mainRed'>
    {error}
  </Box>
);

const Errors = ({ errors, wrapperPadding = 10 }: Object) => (
  <Flex p={wrapperPadding}>
    {
      errors.map((error: string, index: number) => (
        <Error key={index} error={`${R.inc(index)}. ${error}`} />
      ))
    }
  </Flex>
);

const ErrorsPopover = ({ errors, wrapperPadding }: Object) => {
  if (G.isNilOrEmpty(errors)) return null;

  return (
    <PopperComponent
      type='hover'
      position='right'
      borderColor={G.getTheme('listActions.borderColor')}
      content={<Errors errors={errors} wrapperPadding={wrapperPadding} />}
    >
      <Box mx={10}>{I.warningIcon()}</Box>
    </PopperComponent>
  );
};

export default ErrorsPopover;
