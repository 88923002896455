import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// hocs
import { withComponentDidUpdatePropCallback } from '../../../hocs';
// charge
import { getAutoAccessorials } from './with-async-charge-accessorials';
//////////////////////////////////////////////////

export const withRecalculateChargesOnChangeTotals = compose(
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_DEADHEAD_DISTANCE,
    callbackName: 'handleRecalculateAllCharges',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_DEADHEAD_DISTANCE_UOM,
    callbackName: 'handleRecalculateAllCharges',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_TOTAL_TRIP_DISTANCE,
    callbackName: 'handleRecalculateAllCharges',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    callbackName: 'handleRecalculateAllCharges',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_TOTAL_TRIP_WEIGHT,
    callbackName: 'handleRecalculateAllCharges',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    callbackName: 'handleRecalculateAllCharges',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_TOTAL_TRIP_VOLUME,
    callbackName: 'handleRecalculateAllCharges',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_TOTAL_TRIP_VOLUME_UOM,
    callbackName: 'handleRecalculateAllCharges',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_CUSTOMER_TOTAL,
    callbackName: 'handleRecalculateAllCharges',
  }),
  pure,
);

export const withRecalculateAllChargesId = compose(
  withState('recalculateAllChargesId', 'setRecalculateAllChargesId', () => G.genShortId()),
  withHandlers({
    handleSetRecalculateAllChargesId:
      ({ setRecalculateAllChargesId }: Object) => () => setRecalculateAllChargesId(G.genShortId()),
  }),
  pure,
);

export const withRecalculateChargesOnChangeRecalculateId = compose(
  withComponentDidUpdatePropCallback({
    propName: 'recalculateAllChargesId',
    callbackName: 'handleRecalculateAllCharges',
  }),
  pure,
);

const getGlCodeFromMappings = (
  chargeCurrency: any,
  invoiceCurrency: any,
  mappingType: string,
  glCodeMappings: Object,
) => {
  const mappings1 = R.path([chargeCurrency], glCodeMappings);
  const mappings2 = R.path([invoiceCurrency], glCodeMappings);

  let value = null;

  if (R.includes(mappingType, R.keys(mappings1))) {
    value = G.getPropFromObject(mappingType, mappings1);
  }

  if (G.isAllTrue(
    G.isNilOrEmpty(value),
    G.isNilOrEmpty(chargeCurrency),
    R.includes(mappingType, R.keys(mappings2)),
  )) {
    value = G.getPropFromObject(mappingType, mappings2);
  }

  if (R.and(
    G.isNilOrEmpty(value),
    R.includes(mappingType, R.keys(glCodeMappings)),
  )) {
    value = G.getPropFromObject(mappingType, glCodeMappings);
  }

  return value;
};

export const getChargeGlCodeByCurrency = (charge: Object, invoiceCurrency: any, glCodeMappings: Object) => {
  const { type, currency, assessorialConfigGuid } = charge;

  const fuel = getGlCodeFromMappings(currency, invoiceCurrency, GC.INVOICE_MAPPING_TYPE_FUEL, glCodeMappings);
  const main = getGlCodeFromMappings(currency, invoiceCurrency, GC.INVOICE_MAPPING_TYPE_LINE_HAUL, glCodeMappings);
  const discount = getGlCodeFromMappings(currency, invoiceCurrency, GC.INVOICE_MAPPING_TYPE_DISCOUNT, glCodeMappings);
  const assessorials = getGlCodeFromMappings(currency, invoiceCurrency, GC.INVOICE_MAPPING_TYPE_ASSESSORIALS, glCodeMappings);

  const glCodeMap = { fuel, main, discount };

  let glCode = G.getPropFromObject(type, glCodeMap);

  if (G.isChargeTypeAdditional(type)) glCode = G.getPropFromObject(assessorialConfigGuid, assessorials);

  return glCode;
};

export const setGlCodeToCharges = (charges: Array, glCodeMappings: Object, invoiceCurrency: string) => {
  if (G.isNilOrEmpty(charges)) return charges;

  const mapper = (item: Object) => {
    const glCode = getChargeGlCodeByCurrency(item, invoiceCurrency, glCodeMappings);

    return R.assoc(GC.FIELD_GL_CODE, glCode, item);
  };

  const { mainCharges, fuelCharges, discountCharges, additionalCharges } = G.getFilteredByTypeCharges(charges);

  const newMainCharges = R.map(
    (charge: Object) => mapper(charge),
    mainCharges,
  );
  const newDiscountCharges = R.map(
    (charge: Object) => mapper(charge),
    discountCharges,
  );
  const newFuelCharges = R.map(
    (charge: Object) => mapper(charge),
    fuelCharges,
  );
  const newAdditionalCharges = R.map(
    (charge: Object) => mapper(charge),
    additionalCharges,
  );

  return R.reduce(R.concat, newMainCharges, [newDiscountCharges, newFuelCharges, newAdditionalCharges]);
};

export const getSharedAccessorialsApplyTo = async (props: Object, branchGuid: string) => {
  try {
    const { cloGuid, loadData, stopCount, accessorials } = props;

    if (G.isNotNilAndNotEmpty(cloGuid)) {
      const options = {
        params: {
          applyToType: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_CUSTOMER,
        },
      };

      const endpoint = endpointsMap.getCloAutoApplySharedAssessorials(cloGuid);

      const { data, status } = await sendRequest('get', endpoint, options);

      if (G.isResponseSuccess(status)) {
        return getAutoAccessorials({ stopCount, accessorials, autoAccessorials: R.or(data, []) });
      }
    }

    if (R.or(G.isNilOrEmpty(loadData), G.isNilOrEmpty(branchGuid))) return [];

    const origin = R.path(['events', 0, 'location'], loadData);
    const destination = R.path(['location'], R.last(R.pathOr([], ['events'], loadData)));

    if (R.or(G.isNilOrEmpty(origin), G.isNilOrEmpty(destination))) return [];

    const options = {
      data: {
        origin,
        destination,
        [GC.BRANCH_GUID]: branchGuid,
        applyToType: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_CUSTOMER,
      },
    };

    const endpoint = endpointsMap.sharedAccessorialListApplyTo;

    const { data, status } = await sendRequest('post', endpoint, options);

    if (G.isResponseSuccess(status)) {
      return getAutoAccessorials({ stopCount, accessorials, autoAccessorials: R.or(data, []) });
    }

    return [];
  } catch (error) {
    G.handleException('error', 'getSharedAccessorialsApplyTo exception');
  }
};
