import * as R from 'ramda';
import React from 'react';
// component drawing-map
import GoogleMap from './google-map';
import LeafletMap from './leaflet-map';
//////////////////////////////////////////////////

const DrawingMap = (props: Object) => {
  const { mapSource } = props;

  if (R.equals(mapSource, 'googlemaps')) return <GoogleMap {...props} />;

  return <LeafletMap {...props} />;
};

export default DrawingMap;
