import * as R from 'ramda';
import React from 'react';
import { compose, withState, withHandlers } from 'react-recompose';
// helpers
import * as G from '../../helpers';
// ui
import {
  Label,
  Container,
  FakeLabel,
  InputField,
  FakeLabelContainer,
  InputFieldContainer,
  FakeOutlineContainer,
  FakeOutlineContainerWithoutBorder,
} from './ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('isFocused', 'setIsFocused', false),
  withHandlers({
    handleFocus: (props: Object) => () => props.setIsFocused(true),
    handleBlur: (props: Object) => () => props.setIsFocused(false),
  }),
);

export const MuiTextInput = enhance((props: Object) => {
  const {
    value,
    label,
    onChange,
    inputRef,
    isFocused,
    handleBlur,
    onKeyPress,
    handleFocus,
    placeholder,
    withoutBorder,
    type = 'string',
    readOnly = false,
    inputStyles = {},
    onClick = () => {},
  } = props;

  const { width, height, maxWidth } = inputStyles;

  const isFocusedOrIsValue = R.or(isFocused, G.isNotNilAndNotEmpty(value));

  const OutlineContainer = G.isTrue(withoutBorder) ? FakeOutlineContainerWithoutBorder : FakeOutlineContainer;

  return (
    <Container width={width} height={height} maxWidth={maxWidth}>
      <Label shouldUpdateStyles={isFocusedOrIsValue}>
        { label }
      </Label>
      <InputFieldContainer>
        <InputField
          type={type}
          value={value}
          title={label}
          ref={inputRef}
          onClick={onClick}
          onChange={onChange}
          readOnly={readOnly}
          onBlur={handleBlur}
          isFocused={isFocused}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          placeholder={G.ifElse(R.and(isFocused, G.isNilOrEmpty(value)), placeholder)}
        />
        <OutlineContainer shouldUpdateStyles={isFocusedOrIsValue}>
          <FakeLabelContainer shouldUpdateStyles={isFocusedOrIsValue}>
            <FakeLabel title={label}>
              { label }
            </FakeLabel>
          </FakeLabelContainer>
        </OutlineContainer>
      </InputFieldContainer>
    </Container>
  );
});
