import React from 'react';
import * as R from 'ramda';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withDnDStartEnd } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, RelativeFlex } from '../../../ui';
//////////////////////////////////////////////////

const PinnedReport = (props: Object) => {
  const {
    guid,
    name,
    type,
    index,
    border,
    isActive,
    darkColor,
    innerBorder,
    pinnedReport,
    defaultReport,
    lightGreyColor,
    handleSelectReport,
    activeTabBorderColor,
    handlePinOrUnpinReport,
    changeDefaultReportRequest,
  } = props;

  return (
    <Tab
      label={name}
      component={() => (
        <Box
          py='5px'
          border={border}
          borderTopLeftRadius='5px'
          borderTopRightRadius='5px'
          ml={G.isZero(index, 0, '5px')}
          borderColor={activeTabBorderColor}
          bg={G.ifElse(isActive, lightGreyColor, 'initial')}
        >
          <Flex px='5px' borderRight={innerBorder} borderColor={darkColor}>
            <Box
              fontSize={12}
              cursor='pointer'
              color={darkColor}
              onClick={() => handleSelectReport(guid)}
              fontWeight={G.ifElse(isActive, 'bold', 'normal')}
            >
              {name}
            </Box>
            {
              isActive &&
              <Flex ml={10}>
                <Flex
                  p='0 3px'
                  cursor='pointer'
                  onClick={() => changeDefaultReportRequest({ reportGuid: guid, reportType: type })}
                >
                  {I.pinNew(G.getThemeByCond(defaultReport, 'colors.black', 'colors.dark.grey'), 8, 10)}
                </Flex>
                <Flex
                  p='3px'
                  cursor='pointer'
                  onClick={() => handlePinOrUnpinReport({ pinnedReport, reportGuid: guid })}
                >
                  {I.closeIcon(darkColor, 8)}
                </Flex>
              </Flex>
            }
          </Flex>
        </Box>
      )}
    />
  );
};

export const PinnedReports = withDnDStartEnd((props: Object) => {
  const {
    list,
    handleDragEnd,
    selectedReport,
    handleDragStart,
    handleSelectReport,
    handlePinOrUnpinReport,
    pinnedReportsPanelAction,
    changeDefaultReportRequest,
    pinnedReportsPanelActionTitle,
  } = props;

  const guid = G.getGuidFromObject(selectedReport);
  const darkColor = G.getTheme('colors.black');
  const lightGreyColor = G.getTheme('colors.light.lightGrey');
  const activeTabBorderColor = G.getTheme('colors.light.middleGrey');
  const value = R.indexOf(R.find(R.propEq(guid, GC.FIELD_GUID), list), list);

  const onDragEnd = (result: Object) => {
    const startIndex = R.path(['source', 'index'], result);
    const endIndex = R.path(['destination', 'index'], result);

    if (R.or(R.not(result.destination), R.equals(startIndex, endIndex))) return;

    const item = R.path([startIndex], list);

    const newList = R.compose(
      R.insert(endIndex, item),
      R.remove(startIndex, 1),
    )(list);

    handlePinOrUnpinReport(R.map(G.getGuidFromObject, newList));

    handleDragEnd();
  };

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={(result: Object) => onDragEnd(result, props)}>
      <Droppable droppableId='pinnedReports' direction='horizontal'>
        {(provided: Object) => (
          <div ref={provided.innerRef}>
            <RelativeFlex
              pt='6px'
              zIndex='0'
              justifyContent='space-between'
              bg={G.getTheme('colors.white')}
              borderColor={G.getTheme('colors.lightGrey')}
            >
              {
                G.isNotNilAndNotEmpty(list) &&
                <Tabs
                  value={value}
                  variant='scrollable'
                  scrollButtons='auto'
                  indicatorColor='primary'
                  style={{ minHeight: '26px' }}
                  TabIndicatorProps={{
                    sx: {
                      height: 3,
                      backgroundColor: activeTabBorderColor,
                    },
                  }}
                >
                  {
                    list.map((reportData: Object, index: number) => {
                      const isActive = R.pathEq(guid, [GC.FIELD_GUID], reportData);

                      const innerBorder = G.ifElse(
                        R.or(isActive, R.equals(index, R.dec(value))),
                        'none',
                        '1px solid',
                      );

                      return (
                        <Draggable
                          key={index}
                          index={index}
                          draggableId={`pinned-report-dnd-${index}`}
                        >
                          {(provided: Object) => (
                            <div>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <PinnedReport
                                  {...reportData}
                                  index={index}
                                  isActive={isActive}
                                  darkColor={darkColor}
                                  innerBorder={innerBorder}
                                  lightGreyColor={lightGreyColor}
                                  handleSelectReport={handleSelectReport}
                                  activeTabBorderColor={activeTabBorderColor}
                                  handlePinOrUnpinReport={handlePinOrUnpinReport}
                                  border={G.ifElse(isActive, '1px solid', 'none')}
                                  changeDefaultReportRequest={changeDefaultReportRequest}
                                />
                              </div>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  }
                </Tabs>
              }
              {
                G.isFunction(pinnedReportsPanelAction) &&
                <Flex
                  px={10}
                  height={25}
                  fontSize={13}
                  flexShrink='0'
                  cursor='pointer'
                  onClick={pinnedReportsPanelAction}
                  title={pinnedReportsPanelActionTitle}
                  color={G.getTheme('colors.dark.blue')}
                >
                  {pinnedReportsPanelActionTitle}
                </Flex>
              }
            </RelativeFlex>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});
