import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../actions-element';
// features
import PC from '../../../features/permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const enhance = compose(
  withHandlers({
    handleResetByReportGuid: (props: Object) => () => {
      const {
        closeFixedPopup,
        handleClickResetByGuid,
      } = props;

      handleClickResetByGuid();

      closeFixedPopup();
    },
  }),
  pure,
);

const getOptions = ({
  handleResetByReportGuid,
}: Object) => ([
  {
    permissions: [PC.ANY],
    action: handleResetByReportGuid,
    frontIcon: I.refresh(iconColor, 15, 15),
    text: G.getWindowLocale(
      'actions:reset-current-dashboard-columns-width',
      'Reset current dashboard columns width',
    ),
  },
]);

export const ResizeActions = (props: Object) => <ActionsElement options={getOptions(props)} />;

export default enhance(ResizeActions);
