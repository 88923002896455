/*global google*/
import * as R from 'ramda';
import React from 'react';
import { DirectionsRenderer } from 'react-google-maps';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// component map
import { getPolylineOptions } from '../settings/map-styles';
//////////////////////////////////////////////////

export const GetMultipleRoute = (offsetLocations: Array = [], callback: Function, hideDirectionsApiError: boolean) => {
  if (R.and(R.isEmpty(offsetLocations), R.lte(R.length(offsetLocations), 2))) return;
  const DirectionsService = new google.maps.DirectionsService();
  let waypoints = R.clone(offsetLocations);
  let origin;
  let destination;
  if (G.isNotEmpty(waypoints)) {
    origin = R.head(waypoints);
    waypoints = R.drop(1, waypoints);
    destination = R.last(waypoints);
    waypoints = R.dropLast(1, waypoints);
    waypoints = R.map((location: Object) => ({ location, stopover: true }), waypoints);
  }
  DirectionsService.route({
    origin,
    waypoints,
    destination,
    travelMode: google.maps.TravelMode.DRIVING,
  }, (result: Object, status: string) => {
    if (R.equals(status, google.maps.DirectionsStatus.OK)) {
      callback(result);
    } else {
      console.error(`error fetching directions ${result}`);
      if (G.isTrue(hideDirectionsApiError)) return;
      G.showToastrMessageSimple('error', 'There are no available directions for selected route!');
    }
  });
};

const enhance = compose(
  withState('directions', 'setDirections', []),
  withHandlers({
    handleSetDirections: ({ setDirections }: Object) => (directions: Object) => setDirections(directions),
  }),
  lifecycle({
    componentDidMount() {
      const { locations, handleSetDirections, hideDirectionsApiError } = this.props;
      if (R.lt(locations.length, 2)) return;
      GetMultipleRoute(locations, handleSetDirections, hideDirectionsApiError);
    },
  }),
  pure,
);

export const Directions = enhance((props: Object) => (
  R.and(G.isNotNilAndNotEmpty(props.directions), R.gte(props.locations.length, 2)) &&
  <DirectionsRenderer
    directions={props.directions}
    options={{ markerOptions: {visible: false}, polylineOptions: getPolylineOptions(props.options)}} />
));
