import * as R from 'ramda';
import React, { Fragment } from 'react';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { TextComponent } from '../text';
// forms
import { Label, MultiEmailInput } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { isValidEmail } from '../../helpers/validators';
// ui
import {
  Box,
  Flex,
  BoxHovered,
  AbsoluteBox,
  RelativeBox,
  RelativeFlex } from '../../ui';
//////////////////////////////////////////////////

const eventKeyCodes = [GC.EVENT_KEY_CODE_TAB, GC.EVENT_KEY_CODE_ENTER, GC.EVENT_KEY_CODE_COMMA];

const handleValidateEmail = (
  value: string,
  errors: Object,
  emailId: string,
  setErrors: Function,
  emails: Object = {},
) => {
  if (G.isFalse(isValidEmail(value))) {
    const error = {
      [GC.FIELD_VALUE]: value,
      [GC.FIELD_LABEL]: G.getWindowLocale('titles:is-not-email', 'is not a valid email address'),
    };
    setErrors(R.assoc(emailId, error, errors));
  } else if (R.or(
    R.includes(emailId, R.keys(errors)),
    G.isNotNilAndNotEmpty(R.find(R.propEq(value, GC.FIELD_EMAIL), R.values(emails))),
  )) {
    const error = {
      [GC.FIELD_VALUE]: value,
      [GC.FIELD_LABEL]: G.getWindowLocale('titles:has-already-been-added', 'has already been added'),
    };
    setErrors(R.assoc(emailId, error, errors));
  } else {
    setErrors(R.dissoc(emailId, errors));
  }
};

const enhance = compose(
  withState('email', 'setEmail', null),
  withState('errors', 'setErrors', null),
  withState('inputValue', 'setInputValue', null),
  withState('editingEmail', 'setEditingEmail', false),
  withState('editedEmailId', 'setEditedEmailId', null),
  withState('selectedEmail', 'setSelectedEmail', null),
  withState('contactOptions', 'setContactOptions', []),
  withState('isOpenedOuterMenu', 'setIsOpenedOuterMenu', false),
  withState('emails', 'setEmails', ({ emails }: Object) => {
    if (G.isNilOrEmpty(emails)) return {};
    return R.compose(
      R.indexBy(R.prop('emailId')),
      R.map((item: Object) => R.assoc(GC.FIELD_EMAIL, item, { emailId: G.genShortId() })),
      R.uniq,
      R.filter((item: Object) => isValidEmail(item)),
    )(emails);
  }),
  withHandlers({
    handleRemoveEmail: (props: Object) => (emailId: string) => {
      const { emails, errors, setEmails, setErrors } = props;
      if (R.equals(emailId, 'all')) {
        setEmails({});
        setErrors({});
      } else {
        setEmails(R.dissoc(emailId, emails));
        setErrors(R.dissoc(emailId, errors));
      }
    },
    handlePaste: (props: Object) => (event: Object) => {
      const { emails, setEmails } = props;
      event.preventDefault();
      const paste = event.clipboardData.getData('text');
      const pastedEmails = R.uniq(paste.match(GC.EMAIL_REGEXP_GLOBAL));
      if (G.isNotNilAndNotEmpty(pastedEmails)) {
        const toBeAdded = R.compose(
          R.indexBy(R.prop('emailId')),
          R.map((item: string) => R.assoc(GC.FIELD_EMAIL, item, { emailId: G.genShortId() })),
          R.filter((item: Object) => G.notContain(item, emails)),
        )(pastedEmails);
        setEmails(R.mergeRight(emails, toBeAdded));
      }
    },
    handleSetEditEmailRef: ({ email, editingEmail }: Object) => (ref: Object) => {
      if (editingEmail) {
        ref.focus();
        ref.setSelectionRange(0, R.length(email));
      }
    },
    handleSelectEmailFromContacts: (props: Object) => (email: string, event: Object) => {
      const {
        emails,
        errors,
        setErrors,
        setEmails,
        setInputValue,
        setIsOpenedOuterMenu } = props;
      event.preventDefault();
      setInputValue('');
      setIsOpenedOuterMenu(false);
      const emailId = G.genShortId();
      if (G.isNotNilAndNotEmpty(R.find(R.propEq(email, GC.FIELD_EMAIL), R.values(emails)))) {
        const error = {
          [GC.FIELD_VALUE]: email,
          [GC.FIELD_LABEL]: G.getWindowLocale('titles:has-already-been-added', 'has already been added'),
        };
        setErrors(R.assoc(emailId, error, errors));
      }
      setEmails(R.assoc(emailId, { email, emailId }, emails));
    },
    handleEditEmail: (props: Object) => (emailItem: string, emailId: number) => {
      const {
        errors,
        setEmail,
        setErrors,
        setEditingEmail,
        setEditedEmailId } = props;
      setEmail(emailItem);
      setEditingEmail(true);
      setEditedEmailId(emailId);
      handleValidateEmail(emailItem, errors, emailId, setErrors);
    },
    handleSetEditedEmail: (props: Object) => (event: Object, value: string, id: any = null) => {
      const {
        emails,
        errors,
        setEmail,
        setErrors,
        setEmails,
        setEditingEmail,
        setEditedEmailId } = props;
      if (G.notContain(event.keyCode, eventKeyCodes)) return;
      const emailId = R.or(id, G.genShortId());
      const newEmails = R.assoc(emailId, { emailId, [GC.FIELD_EMAIL]: value }, emails);
      setEmail('');
      setEditingEmail(false);
      setEditedEmailId(emailId);
      handleValidateEmail(value, errors, emailId, setErrors);
      const isInListEditedEmail = R.compose(
        R.includes(value),
        R.values,
        R.map(({ email }: string) => email),
      )(emails);
      if (G.isFalse(isValidEmail(value))) {
        const error = {
          [GC.FIELD_VALUE]: value,
          [GC.FIELD_LABEL]: G.getWindowLocale('titles:is-not-email', 'is not a valid email address'),
        };
        setErrors(R.assoc(emailId, error, errors));
      } else if (isInListEditedEmail) {
        const error = {
          [GC.FIELD_VALUE]: value,
          [GC.FIELD_LABEL]: G.getWindowLocale('titles:has-already-been-added', 'has already been added'),
        };
        setErrors(R.assoc(emailId, error, errors));
      } else {
        setErrors(R.dissoc(emailId, errors));
      }
      return setEmails(newEmails);
    },
    handleOnOuterClick: (props: Object) => (event: Object, value: string, id: any = null) => {
      const {
        emails,
        errors,
        setEmail,
        setErrors,
        setEmails,
        setInputValue,
        setEditingEmail,
        setEditedEmailId,
        setIsOpenedOuterMenu } = props;
      if (G.isNilOrEmpty(value)) return;
      const emailId = R.or(id, G.genShortId());
      const newEmails = R.assoc(emailId, { emailId, [GC.FIELD_EMAIL]: value }, emails);
      setEmail('');
      setInputValue('');
      setEditingEmail(false);
      setEditedEmailId(emailId);
      setIsOpenedOuterMenu(false);
      handleValidateEmail(value, errors, emailId, setErrors);
      return setEmails(newEmails);
    },
    handleSetEmail: (props: Object) => (event: Object, value: string) => {
      const {
        emails,
        errors,
        setErrors,
        setEmails,
        setInputValue,
        contactOptions,
        isOpenedOuterMenu,
        setIsOpenedOuterMenu } = props;
      if (R.includes(event.keyCode, eventKeyCodes)) {
        event.preventDefault();
        if (G.isNilOrEmpty(value)) return;
        const emailId = G.genShortId();
        const newValue = G.ifElse(
          isOpenedOuterMenu,
          R.path([0, GC.FIELD_EMAIL], contactOptions),
          value,
        );
        const newEmails = R.assoc(emailId, { emailId, [GC.FIELD_EMAIL]: newValue }, emails);
        setInputValue('');
        setEmails(newEmails);
        setIsOpenedOuterMenu(false);
        handleValidateEmail(newValue, errors, emailId, setErrors, emails);
      }
    },
    handleChange: (props: Object) => (event: Object) => {
      const {
        contactList,
        setInputValue,
        setContactOptions,
        setIsOpenedOuterMenu } = props;
      const value = R.or(R.path(['currentTarget', GC.FIELD_VALUE], event), null);
      setInputValue(value);
      const options = R.filter(
        ({ email, lastName, firstName }: Object) =>
          R.includes(value, firstName) || R.includes(value, lastName) || R.includes(value, email),
        contactList,
      );
      setContactOptions(options);
      setIsOpenedOuterMenu(G.isNotNilAndNotEmpty(options));
    },
  }),
  withPropsOnChange(['emails'], (props: Object) => {
    const {
      emails,
      errors,
      fieldName,
      setFieldValue,
      setFieldError,
      setIsValidEmails } = props;
    const fieldValue = R.compose(
      R.uniq,
      R.values,
      R.map(({ email }: Object) => email),
      R.filter(({ email }: Object) => isValidEmail(email)),
    )(R.or(emails, []));
    const fieldError = R.compose(
      R.values,
      R.map(({ value, label }: Object) => `${value} ${label}`),
    )(R.or(errors, []));
    setFieldError(fieldName, fieldError);
    setFieldValue(fieldName, fieldValue, false);
    setIsValidEmails(G.isNilOrEmpty(fieldError));
  }),
  pure,
);

const EmailItem = (props: Object) => {
  const {
    email,
    errors,
    emailId,
    setEmail,
    emailItem,
    editingEmail,
    editedEmailId,
    handleEditEmail,
    handleRemoveEmail,
    handleOnOuterClick,
    handleSetEditedEmail,
    handleSetEditEmailRef } = props;
  if (R.and(editingEmail, R.equals(editedEmailId, emailId))) {
    return (
      <OuterClick onOuterClick={(event: Object) => handleOnOuterClick(event, email, emailId)}>
        <MultiEmailInput
          p='5px'
          height={20}
          value={email}
          minWidth='50%'
          autoComplete='off'
          id={GC.FIELD_EMAIL}
          ref={handleSetEditEmailRef}
          background={G.getTheme('colors.light.transparentLightBlue')}
          onKeyDown={(event: Object) => handleSetEditedEmail(event, email, emailId)}
          onChange={(event: Object) => setEmail(R.path(['currentTarget', GC.FIELD_VALUE], event))} />
      </OuterClick>
    );
  }
  const hasError = R.pathEq(emailItem, [emailId, GC.FIELD_VALUE], errors);
  const getTitle = G.ifElse(
    hasError,
    R.path([emailId, GC.FIELD_LABEL], errors),
    G.getWindowLocale('titles:edit', 'Edit'),
  );
  const getEmailColor = G.getTheme(G.ifElse(
    hasError,
    'colors.light.mainRed',
    'colors.light.blue',
  ));
  return (
    <Flex
      p='5px'
      fontSize={12}
      color={getEmailColor}
    >
      <TextComponent
        maxWidth='90%'
        title={getTitle}
        withEllipsis={true}
        onDoubleClick={() => handleEditEmail(emailItem, emailId)}
      >
        {emailItem}
      </TextComponent>
      <Box
        ml='3px'
        px='4px'
        fontSize={14}
        cursor='pointer'
        borderRadius='50%'
        border='1px solid'
        borderColor={getEmailColor}
        color={G.getTheme('colors.light.grey')}
        onClick={() => handleRemoveEmail(emailId)}
      >
        &times;
      </Box>
    </Flex>
  );
};

const SearchEmailOuterMenu = (props: Object) => {
  const { width, contactOptions, handleSelectEmailFromContacts } = props;
  return (
    <AbsoluteBox
      left='0'
      top='105%'
      zIndex={11}
      width={width}
      display='block'
      maxHeight={200}
      overflow='auto'
      borderRadius={4}
      border='1px solid'
      background={G.getTheme('colors.white')}
      borderColor={G.getTheme('colors.dark.grey')}
      boxShadow='0 2px 4px 0 rgba(129, 129, 129, 0.2)'
    >
      {
        contactOptions.map(({ email, lastName, firstName }: Object, index: number) => (
          <BoxHovered
            p={10}
            key={index}
            cursor='pointer'
            flexDirection='column'
            hoverFontWeight='bold'
            color={G.getTheme('colors.light.mainDark')}
            hoverColor={G.getTheme('colors.dark.blue')}
            onClick={(event: Object) => handleSelectEmailFromContacts(email, event)}
          >
            {
              R.or(G.isNotNilAndNotEmpty(firstName), G.isNotNilAndNotEmpty(lastName)) &&
              <Box mb='5px'>
                {firstName} {lastName}
              </Box>
            }
            <Box>
              {email}
            </Box>
          </BoxHovered>
        ))
      }
    </AbsoluteBox>
  );
};

export const SearchEmail = enhance((props: Object) => {
  const {
    my,
    width,
    email,
    title,
    errors,
    emails,
    margin,
    zIndex,
    labelPl,
    setEmail,
    fieldName,
    labelColor,
    fieldError,
    inputValue,
    handlePaste,
    borderColor,
    borderRadius,
    editingEmail,
    handleChange,
    editedEmailId,
    labelFontSize,
    handleSetEmail,
    contactOptions,
    setFieldTouched,
    handleEditEmail,
    isOpenedOuterMenu,
    handleRemoveEmail,
    handleOnOuterClick,
    handleSetEditedEmail,
    handleSetEditEmailRef,
    handleSelectEmailFromContacts,
  } = props;

  const EmailItemProps = {
    email,
    errors,
    setEmail,
    editingEmail,
    editedEmailId,
    handleEditEmail,
    handleRemoveEmail,
    handleOnOuterClick,
    handleSetEditedEmail,
    handleSetEditEmailRef,
  };

  let inputError = fieldError;

  if (G.isArray(inputError)) {
    inputError = R.join(', ', inputError);
  }

  return (
    <Box width={width} m={R.or(margin, '5px auto 10px auto')}>
      <Label
        pl={labelPl || 10}
        htmlFor={fieldName}
        fontSize={labelFontSize || 14}
        color={R.or(labelColor, G.getTheme('colors.light.black'))}
      >
        {R.or(title, G.getWindowLocale('titles:to', 'To'))}
      </Label>
      <RelativeFlex
        width='100%'
        height='auto'
        zIndex={zIndex}
        minHeight='30px'
        border='1px solid'
        my={R.or(my, '5px')}
        background={G.getTheme('colors.white')}
        borderRadius={R.or(borderRadius, '2px')}
        borderColor={R.or(borderColor, G.getTheme('forms.inputs.borderColor'))}
      >
        <Flex width='100%' height='auto' flexWrap='wrap'>
          {
            R.values(emails).map(({ email, emailId }: Object, index: number) => (
              <EmailItem {...EmailItemProps} key={index} emailItem={email} emailId={emailId} />
            ))
          }
          {
            G.isFalse(editingEmail) &&
            <OuterClick
              width='100%'
              as={RelativeBox}
              onOuterClick={(event: Object) => handleOnOuterClick(event, inputValue)}
            >
              <Fragment>
                <MultiEmailInput
                  p='5px'
                  height={20}
                  width='100%'
                  id={fieldName}
                  value={inputValue}
                  autoComplete='off'
                  onPaste={handlePaste}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched(fieldName, true, false)}
                  onKeyDown={(event: Object) => handleSetEmail(event, inputValue)}
                  placeholder={G.getWindowLocale(
                    'titles:enter-email-and-press-enter',
                    'Enter email and press Enter',
                  )}
                />
                {
                  isOpenedOuterMenu &&
                  <SearchEmailOuterMenu
                    width={width}
                    contactOptions={contactOptions}
                    handleSelectEmailFromContacts={handleSelectEmailFromContacts}
                  />
                }
              </Fragment>
            </OuterClick>
          }
          {
            G.isNotNilAndNotEmpty(inputError) &&
            <AbsoluteBox left='5px' top='101%' width='100%'>
              <TextComponent
                fontSize={11}
                maxWidth='90%'
                title={inputError}
                withEllipsis={true}
                color={G.getTheme('forms.inputs.errorTextColor')}
              >
                {inputError}
              </TextComponent>
            </AbsoluteBox>
          }
        </Flex>
        {
          G.isNotNilAndNotEmpty(emails) &&
          <Box
            mx='3px'
            px='4px'
            fontSize={14}
            cursor='pointer'
            borderRadius='50%'
            border='1px solid'
            color={G.getTheme('colors.black')}
            onClick={() => handleRemoveEmail('all')}
            borderColor={G.getTheme('colors.light.blue')}
            title={G.getWindowLocale('actions:clean', 'Clean')}
          >
            &times;
          </Box>
        }
      </RelativeFlex>
    </Box>
  );
});
