import * as R from 'ramda';
import { $all, $set } from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
// components loader
import * as A from './actions.js';
//////////////////////////////////////////////////

const initialState = {
  loaderCount: 0,
  isOpened: false,
  showDimmer: false,
};

const openLoader = (state: Object, payload: Object = false) => (
  $all(
    $set('isOpened', true),
    $set('showDimmer', payload.showDimmer),
    $set('loaderCount', R.add(state.loaderCount, 1)),
    state,
  )
);

const closeLoader = (state: Object) => (
  $all(
    $set('isOpened', R.gt(R.subtract(state.loaderCount, 1), 0)),
    $set('showDimmer', R.gt(R.subtract(state.loaderCount, 1), 0)),
    $set(
      'loaderCount',
      G.ifElse(
        R.gte(R.subtract(state.loaderCount, 1), 0),
        R.subtract(state.loaderCount, 1),
        0,
      ),
    ),
    state,
  )
);


export default createReducer({
  [A.openLoader]: openLoader,
  [A.closeLoader]: closeLoader,
}, initialState);
