import * as R from 'ramda';
import React from 'react';
import { css } from 'styled-components';
import { compose, withState } from 'react-recompose';
// components
import { AuthWrapper } from '../../features/permission';
// component page-actions
import { Wrapper, animation } from './ui';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex, FixedBox, RelativeBox, AbsoluteBox, ActionButton } from '../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('expanded', 'toggle', false),
);

const Count = ({ type, count, bgColor }: Object) => {
  const lightColor = G.getTheme('colors.light.mainLight');
  let styles = {
    top: -10,
    width: 25,
    height: 25,
    right: -10,
    zIndex: 11,
    fontSize: 12,
    color: lightColor,
    borderRadius: '50%',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    border: `1.5px solid ${lightColor}`,
    bg: R.or(bgColor, G.getTheme('colors.light.blue')),
  };

  if (R.equals(type, 'massAction')) {
    styles = R.mergeRight(styles, {
      top: -7,
      right: -7,
      width: 20,
      height: 20,
      fontSize: 11,
    });
  }

  return (
    <AbsoluteBox {...styles}>{R.or(count, 0)}</AbsoluteBox>
  );
};

const calculateHeight = (props: Object) => {
  if (R.not(props.expanded)) return '0px';

  return R.add(R.multiply(props.length, 45), 50);
};

const getActionsText = (mainAction: Object) => {
  if (G.isNotNilAndNotEmpty(mainAction)) return G.getWindowLocale('titles:other-actions', 'Other Actions');

  return G.getWindowLocale('titles:actions', 'Actions');
};

export const getButtonsStyles = (color: string) => {
  const whiteColor = G.getTheme('colors.white');
  const blueColor = G.getTheme('colors.dark.blue');
  const greenColor = G.getTheme('colors.softGreen');

  const commonStyles = {
    ml: 10,
    height: 32,
    fontSize: 14,
    p: '4px 16px',
    borderRadius: 16,
    textColor: whiteColor,
  };

  const mainActionButtonStyles = {
    ...commonStyles,
    bgColor: R.or(color, greenColor),
  };

  const otherActionsButtonStyles = {
    ...commonStyles,
    bgColor: blueColor,
  };

  return { mainActionButtonStyles, otherActionsButtonStyles };
};

const Component = (props: Object) => {
  const {
    count,
    toggle,
    expanded,
    mainAction,
    mainActions,
    shadowColor,
    listActions,
  } = props;

  const wrapperBg = G.getTheme('colors.light.blue');
  const actionBg = G.getTheme('colors.light.darkGrey');
  const actionColor = G.getTheme('colors.light.mainLight');
  const showMainAction = R.not(R.and(
    G.isCurrentBranchTypeCustomer(),
    R.pathEq(true, ['hideIfBranchTypeCustomer'], mainAction),
  ));

  const { mainActionButtonStyles, otherActionsButtonStyles } = getButtonsStyles(R.pathOr(null, ['color'], mainAction));

  return (
    <Flex>
      {
        R.and(showMainAction, G.isNotNilAndNotEmpty(mainAction)) &&
        <AuthWrapper has={mainAction.permissions}>
          <RelativeBox>
            <ActionButton
              {...mainActionButtonStyles}
              cursor='pointer'
              onClick={mainAction.action}
            >
              {mainAction.text}
            </ActionButton>
            {
              R.and(G.isNotNilAndNotEmpty(count), mainAction.showCount) &&
              <Count count={count} bgColor={mainActionButtonStyles.bgColor} />
            }
          </RelativeBox>
        </AuthWrapper>
      }
      {
        R.and(showMainAction, G.isArray(mainActions)) &&
        mainActions.map(({ text, action, styles = {}, permissions }: Object, index: number) => (
          <AuthWrapper key={index} has={permissions}>
            <ActionButton
              {...mainActionButtonStyles}
              {...styles}
              cursor='pointer'
              onClick={action}
            >
              {text}
            </ActionButton>
          </AuthWrapper>
        ))
      }
      {
        G.isNotNilAndNotEmpty(listActions) &&
        <Box
          onMouseEnter={() => toggle(true)}
          onMouseLeave={() => toggle(false)}
        >
          <ActionButton
            {...otherActionsButtonStyles}
            cursor='initial'
          >
            {getActionsText(mainAction)}
          </ActionButton>
          {G.isNotNilAndNotEmpty(count) && <Count count={count} />}
          {
            <AbsoluteBox
              pl={10}
              zIndex={3}
              right='0px'
              bottom={32}
              width={G.ifElse(expanded, 'auto', 0)}
            >
              <Flex
                pb={10}
                cursor='pointer'
                alignItems='flex-end'
                flexDirection='column'
                transition='all 0.35s linear'
                opacity={G.ifElse(expanded, 1, '0')}
                transform={G.ifElse(expanded, 'scale(1)', 'scale(0)')}
                maxHeight={calculateHeight({
                  expanded: props.expanded,
                  length: R.length(R.or(listActions, [])),
                })}
              >
                {
                  listActions.map((action: Object, index: number) => (
                    <AuthWrapper
                      key={index}
                      has={action.permissions}
                    >
                      <Box p='6px 8px'>
                        <Box
                          p='5px'
                          bg={actionBg}
                          fontSize={13}
                          borderRadius='5px'
                          textAlign='center'
                          width='max-content'
                          color={actionColor}
                          animation={css`${animation} 0.3s linear`}
                        >
                          {action.text}
                        </Box>
                      </Box>
                      <Wrapper
                        p='3px'
                        mb={10}
                        bg={wrapperBg}
                        onClick={action.action}
                        shadowColor={shadowColor}
                      >
                        {
                          R.equals(action.type, 'massAction') &&
                          <Count count={count} type={action.type} />
                        }
                        {action.icon}
                      </Wrapper>
                    </AuthWrapper>
                  ))
                }
              </Flex>
            </AbsoluteBox>
          }
        </Box>
      }
    </Flex>
  );
};

export const PageActions = enhance((props: Object) => (
  <FixedBox
    right={30}
    zIndex={11}
    bottom={R.pathOr(10, ['bottom'], props)}
  >
    <Component {...props} />
  </FixedBox>
));
