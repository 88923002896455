import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../form-footer';
// forms
import { Fieldset2 } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// advance payment
import {
  addAdvancePaymentFieldSettings,
  defaultAddAdvancePaymentFields,
  addAdvancePaymentValidationSchemaObject,
} from './settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    validationSchema: Yup.object().shape(addAdvancePaymentValidationSchemaObject),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultAddAdvancePaymentFields,
      initialValues,
    ),
  }),
  withHandlers({
    handleSelectDriver: (props: Object) => (event: Object) => {
      const { values, setValues, selectedRateDrivers } = props;

      const driver = R.path(['currentTarget', GC.FIELD_VALUE], event);

      if (G.isNilOrEmpty(driver)) return;

      const { loginId, lastName, firstName, driverNumber } = R.prop(driver, selectedRateDrivers);

      const valuesToMerge = {
        [GC.FIELD_DRIVER_GUID]: driver,
        [GC.FIELD_ADVANCE_PAYMENT_FLEET_ID]: loginId,
        [GC.FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME]: lastName,
        [GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER]: driverNumber,
        [GC.FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME]: firstName,
      };

      setValues(R.mergeRight(values, valuesToMerge));
    },
  }),
  pure,
);

const AddAdvancePaymentForm = (props: Object) => {
  const { handleSubmit, optionsForSelect, handleSelectDriver, disableSelectDriverField } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...optionsForSelect}
        {...G.getFormikProps(props)}
        optionsForSelect={optionsForSelect}
        fields={addAdvancePaymentFieldSettings}
        handleCustomChange={handleSelectDriver}
        handlers={{ disableSelectDriverField: () => disableSelectDriverField }}
        fieldsWrapperStyles={{ mt: 15, width: 630, justifyContent: 'space-between' }}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(AddAdvancePaymentForm);
