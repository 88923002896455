import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { LocalLoader } from '../local-loader';
// helpers
import * as G from '../../helpers';
// hocs
import { withAsyncInitialDataOnDidMount, withComponentDidUpdatePropCallback } from '../../hocs';
// ui
import { Box } from '../../ui';
// component async-documents
import DocumentsCard from './documents-card';
import DocumentsList from './documents-list';
//////////////////////////////////////////////////

const enhance = compose(
  withAsyncInitialDataOnDidMount,
  withHandlers({
    handleGetInitialDataRequest: (props: Object) => () => {
      const { getInitialDataRequest } = props;

      getInitialDataRequest();
    },
  }),
  withComponentDidUpdatePropCallback({ propName: 'recallDocumentsGuid', callbackName: 'handleGetInitialDataRequest' }),
  pure,
);

const AsyncDocuments = enhance(({
  version,
  contentType,
  payrollGuid,
  wrapperStyles,
  documentsType,
  asyncEndpoint,
  tableMaxHeight,
  cancelBtnStyles,
  showSend = true,
  payrollTypeGuid,
  asyncInitialData,
  columnSettingsProps,
  mailIntegrationType,
  getInitialDataRequest,
}: Object) => {
  const { data, loading } = asyncInitialData;

  if (R.equals(contentType, 'card')) {
    if (G.isTrue(loading)) return null;

    return (
      <Box {...G.spreadUiStyles(wrapperStyles)}>
        {
          G.isNotNil(data) &&
          <DocumentsCard
            data={data}
            showSend={showSend}
            payrollGuid={payrollGuid}
            documentsType={documentsType}
            asyncEndpoint={asyncEndpoint}
            tableMaxHeight={tableMaxHeight}
            cancelBtnStyles={cancelBtnStyles}
            payrollTypeGuid={payrollTypeGuid}
            getDocuments={getInitialDataRequest}
            mailIntegrationType={mailIntegrationType}
          />
        }
      </Box>
    );
  }

  return (
    <LocalLoader localLoaderOpen={loading}>
      {
        G.isNotNil(data) &&
        <DocumentsList
          data={data}
          version={version}
          payrollGuid={payrollGuid}
          wrapperStyles={wrapperStyles}
          documentsType={documentsType}
          asyncEndpoint={asyncEndpoint}
          tableMaxHeight={tableMaxHeight}
          cancelBtnStyles={cancelBtnStyles}
          payrollTypeGuid={payrollTypeGuid}
          getDocuments={getInitialDataRequest}
          columnSettingsProps={columnSettingsProps}
          mailIntegrationType={mailIntegrationType}
        />
      }
    </LocalLoader>
  );
});

export default AsyncDocuments;
