import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withProps } from 'react-recompose';
// common
import { sendQuoteRequests } from '../../common/actions';
// components
import { FormFooter2 } from '../form-footer';
import { SelectCarriers } from '../select-carriers';
// forms
import { Fieldset2 } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { MAIL_SENDING_REPLY_TO_OPTIONS } from '../../helpers/options';
// hocs
import { withAsyncConfigs } from '../../hocs';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const inputWrapperStyles = { mb: 25, width: 270 };

const fieldSettings = [
  {
    inputWrapperStyles,
    type: 'customComponent',
    fieldName: GC.FIELD_CARRIER_GUIDS,
    label: ['titles:carriers', 'Carriers'],
    isRequired: ({ values: { carrierEmailGroupGuids } }: Object) => G.isNilOrEmpty(carrierEmailGroupGuids),
    Component: ({ values, hasError, setFieldValue }: Object) => (
      <SelectCarriers
        isMulti={true}
        isClearable={true}
        hasError={hasError}
        setFieldValue={setFieldValue}
        fieldName={GC.FIELD_CARRIER_GUIDS}
        value={R.pathOr([], [GC.FIELD_CARRIER_GUIDS], values)}
      />
    ),
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'emailGroupOptions',
    label: ['titles:email-group', 'Email Group'],
    fieldName: GC.FIELD_CARRIER_EMAIL_GROUP_GUIDS,
    isRequired: ({ values: { carrierGuids } }: Object) => G.isNilOrEmpty(carrierGuids),
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    fieldName: GC.FIELD_MODE,
    options: 'transportationModeOptions',
    label: ['titles:transportation-mode', 'Transportation Mode'],
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    timeSelection: true,
    minDate: G.getCurrentDate(),
    fieldName: GC.FIELD_EXPIRATION_DATE_TIME,
    label: ['titles:expiration-date', 'Expiration Date'],
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:reply-to', 'Reply To'],
    fieldName: GC.FIELD_MAIL_SENDING_REPLY_TO,
    options: G.addEmptyOptionToDropDown(MAIL_SENDING_REPLY_TO_OPTIONS),
  },
];

const defaultFields = {
  [GC.FIELD_MODE]: null,
  [GC.FIELD_CARRIER_GUIDS]: [],
  [GC.FIELD_EXPIRATION_DATE_TIME]: null,
  [GC.FIELD_MAIL_SENDING_REPLY_TO]: null,
  [GC.FIELD_CARRIER_EMAIL_GROUP_GUIDS]: [],
};

const validationSchema = Yup.lazy(({ carrierGuids, carrierEmailGroupGuids }: Object) => {
  let schema = {
    [GC.FIELD_MODE]: G.yupStringRequired,
    [GC.FIELD_EXPIRATION_DATE_TIME]: G.yupStringRequired,
    [GC.FIELD_MAIL_SENDING_REPLY_TO]: G.yupStringRequired,
  };

  if (G.isAllNilOrEmpty([carrierGuids, carrierEmailGroupGuids])) {
    schema = R.mergeRight(
      schema,
      {
        [GC.FIELD_CARRIER_GUIDS]: G.yupArrayRequired,
        [GC.FIELD_CARRIER_EMAIL_GROUP_GUIDS]: G.yupArrayRequired,
      },
    );
  }

  return Yup.object().shape(schema);
});

const enhance = compose(
  connect(null, { sendQuoteRequests }),
  withProps(() => ({ configsNamesArray: [GC.CARRIER_EMAIL_GROUP, GC.GENERAL_MODE_TRANSPORTATION] })),
  withAsyncConfigs,
  withProps(({ asyncConfigs }: Object) => {
    const transportationModeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.GENERAL_MODE_TRANSPORTATION,
    );

    const emailGroupOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.CARRIER_EMAIL_GROUP,
    );

    return {
      optionsForSelect: {
        emailGroupOptions,
        transportationModeOptions,
      },
    };
  }),
  withFormik({
    validationSchema,
    mapPropsToValues: ({ telGuids }: Object) => R.assoc(GC.FIELD_TEL_GUIDS, telGuids, defaultFields),
    handleSubmit: (values: Object, { props: { sendQuoteRequests } }: Object) => sendQuoteRequests(values),
  }),
  pure,
);

const setOverflowY = (values: Object) => {
  const carrierGuids = R.pathOr([], [GC.FIELD_CARRIER_GUIDS], values);
  const carrierEmailGuids = R.pathOr([], [GC.FIELD_CARRIER_EMAIL_GROUP_GUIDS], values);
  const value = R.length(R.concat(carrierGuids, carrierEmailGuids));

  return G.ifElse(R.gt(value, 8), 'auto', 'unset');
};

const SendQuoteRequestForm = (props: Object) => {
  const { values, handleSubmit, optionsForSelect } = props;

  return (
    <Box maxHeight='85vh' overflowY={setOverflowY(values)}>
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...G.getFormikProps(props)}
          {...optionsForSelect}
          fields={fieldSettings}
          fieldsWrapperStyles={{ mt: 15, flexDirection: 'column' }}
        />
        <FormFooter2 />
      </form>
    </Box>
  );
};

export default enhance(SendQuoteRequestForm);
