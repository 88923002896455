import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// utilities
import { sendRequest } from '../../utilities/http';
///////////////////////////////////////////////////////////////////////////////////

export const withAsyncDownload = compose(
  withState('endpoint', 'setEndpoint', null),
  withHandlers({
    handleAsyncDownloadFile: (props: Object) => async (data: Object) => {
      const { endpoint, openLoader, closeLoader } = props;
      const {
        fileName,
        fileType,
        fileFormat,
        asyncMethod } = data;
      openLoader({ showDimmer: true});
      const asyncOptions = {
        params: {
          type: fileType,
          format: fileFormat,
        },
        resType: 'arraybuffer',
      };
      const res = await sendRequest(asyncMethod, endpoint, asyncOptions);
      if (G.isResponseSuccess(res.status)) {
        R.and(
          FileDownload(res.data, fileName),
          G.showToastrMessageSimple(
            'success',
            G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
          ),
        );
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
  pure,
);
