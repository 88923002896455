import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { PopperComponent } from '../popper';
// helpers/constants
import * as G from '../../helpers';
// forms
import { FieldsetComponent } from '../../forms';
// ui
import { Box, Flex, ActionButton } from '../../ui';
// utilities
import endpointsMap from '../../utilities/endpoints';
// component file-download
import { withAsyncDownload } from './with-async-download';
/////////////////////////////////////////////////////////////////////////////////////////

const enhance = compose(
  withAsyncDownload,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      props.defaultFields,
      props.initialValues,
      props.searchedValues,
    ),
    validationSchema: (props: Object) => Yup.object().shape(props.validationSchema),
    handleSubmit: ({ type }: Object, { props, resetForm }: Object) => {
      const { setEndpoint, downloadOptions, handleAsyncDownloadFile } = props;

      setEndpoint(G.getPropFromObject(downloadOptions.endpointName, endpointsMap));
      const data = R.assoc('fileType', type, downloadOptions);

      handleAsyncDownloadFile(data);
      resetForm();
    },
    displayName: 'DOWNLOAD_DOC_FORM',
  }),
  pure,
);

const Content = (props: Object) => (
  <Box p={15}>
    <form onSubmit={props.handleSubmit}>
      <FieldsetComponent {...props} fields={props.fields} fieldsGroupWidth='100%' />
      <ActionButton
        mt={10}
        width='100%'
        type='submit'
        fontSize='16px'
      >
        {props.btnText}
      </ActionButton>
    </form>
  </Box>
);

export const FileDownload = enhance((props: Object) => {
  const {
    icon,
    text,
    fields,
    handleAsyncDownloadFile } = props;
  if (G.isNotNilAndNotEmpty(fields)) {
    return (
      <PopperComponent
        zi={21}
        type='click'
        position='bottom'
        content={<Content {...props} />}
        borderColor={G.getTheme('listActions.borderColor')}
      >
        <Flex>
          {
            G.isNotNilAndNotEmpty(text) &&
            <Box>{text}</Box>
          }
          <Box ml={15}>{icon}</Box>
        </Flex>
      </PopperComponent>
    );
  }
  return (
    <Box onClick={handleAsyncDownloadFile}>
      <Flex>
        {
          G.isNotNilAndNotEmpty(text) &&
          <Box>{text}</Box>
        }
        <Box ml={30}>{icon}</Box>
      </Flex>
    </Box>
  );
});
