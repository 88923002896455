import L from 'leaflet';
import * as R from 'ramda';
import { css } from 'styled-components';
import 'leaflet-draw/dist/leaflet.draw.css';
import React, { useRef, useEffect } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Map, Popup, Marker, TileLayer } from 'react-leaflet';
// helpers/constants
import * as G from '../../helpers';
// component leaflet-map
import { Routing } from './routing';
//////////////////////////////////////////////////

const getMapCenterAndBounds = (locations: Array = []) => {
  if (G.isNilOrEmpty(locations)) return { mapCenter: [38.755157, -98.269035] };

  const bounds = R.map(({ latLng }: Object) => R.values(latLng), locations);
  const polygon = L.polygon(bounds);

  return {
    bounds,
    mapCenter: polygon.getBounds().getCenter(),
  };
};

const getIcon = ({ title, markerTitle, useGreenColor }: Object) => {
  const markerTitleToUse = R.or(markerTitle, title);

  const blueColor = G.getTheme('colors.light.blue');

  return new L.DivIcon({
    className: 'leaflet-marker',
    html: `
      <div class='leaflet-marker' style='width: 0; height: 0; cursor: pointer; overflow: visible;'>
        <div style='transform: translate(-20px, -150%);'>
          <div
            class='leaflet-marker-inner ${useGreenColor && 'greenColor'}'
            style='
              width: 36px;
              height: 36px;
              cursor: pointer;
              position: relative;
              border-radius: 50%;
              transform: scale(1.1);
              transition: 0.2s transform;
              background-color: ${G.getTheme('colors.white')};
              transition: 0.2s transform, 0.2s -webkit-transform;
              border: 5px solid ${G.ifElse(useGreenColor, G.getTheme('colors.light.green'), blueColor)};
            '
          >
            <div
              style='
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 50%;
              '
            >
              <div
                style='
                  width: 100%;
                  height: 100%;
                  display: flex;
                  font-weight: bold;
                  align-items: center;
                  color: ${blueColor};
                  justify-content: center;
                '
              >
                ${markerTitleToUse}
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
  });
};

export const LeafletMap = (props: Object) => {
  const { height, center, routes, locations, popupOffset } = props;

  const mapRef = useRef(null);

  useEffect(() => {
    const map = R.path(['current', 'leafletElement'], mapRef);

    if (G.isAllNotNilOrNotEmpty([map, center])) {
      map.flyTo(center, map.getZoom(), { duration: 2 });
    }
  }, [center]);

  const { bounds, mapCenter } = getMapCenterAndBounds(locations);

  return (
    <Map
      ref={mapRef}
      maxZoom={18}
      bounds={bounds}
      center={mapCenter}
      css={css`height: ${height};`}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <MarkerClusterGroup maxClusterRadius={20}>
        {
          G.isNotNilAndNotEmpty(locations) &&
          locations.map((location: Object, i: number) => {
            const { latLng, infoContent } = location;

            return (
              <Marker key={i} position={latLng} icon={getIcon(location)}>
                <Popup offset={L.point(...R.or(popupOffset, [-7, -40]))}>{infoContent}</Popup>
              </Marker>
            );
          })
        }
      </MarkerClusterGroup>
      {
        G.isNotNilAndNotEmpty(routes) &&
        routes.map(({ color, waypoints }: Object, i: number) => {
          const mapWaypoints = R.map(({ lat, lng }: Object) => L.latLng(lat, lng), waypoints);

          if (R.lte(R.length(waypoints), 1)) return null;

          return <Routing key={i} color={color} waypoints={mapWaypoints} />;
        })
      }
    </Map>
  );
};
