import * as R from 'ramda';
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const customRequired = (value: string) => G.ifElse(value, undefined, ' ');

export const validateCondition = (value: any, params: Object) => (
  G.ifElse(
    R.isNil(params.name),
    'Set Filtered Param',
    undefined,
  )
);
