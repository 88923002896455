import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { TextComponent } from '../text';
import { FormFooter2 } from '../form-footer';
import ZIndexClick from '../z-index-click';
import { PopperWrapper, PopperComponent } from '../popper';
import { OutsideReportExactFilterList } from '../edit-report';
import { withPageReportFilter } from '../edit-report/components/filter-list/hocs';
import { PageFilterItem as FilterItem } from '../edit-report/components/filter-list';
import { defaultFilterFields } from '../edit-report/components/filter-list/settings';
import {
  getAssessorials,
  getAvailableFilterFields,
  transformSearchCriteriaBeforeReportPost,
} from '../edit-report/helpers';
// features
import { makeSelectAvailableReportGeoFencingZoneList } from '../../features/geo-fencing-zone/report/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, StickedFlex, RelativeFlex } from '../../ui';
// component filter
import { withDeleteFilter } from './report-filter-label';
//////////////////////////////////////////////////

const getReportFilterPropertyName = (filterProps: Array, selectedReport: Object) => G.getOrElse(
  R.find((propName: Object) => R.equals(propName.value, selectedReport.propertyName), filterProps),
  GC.FIELD_NAME,
  G.getReferenceTitleName(R.pathOr('', [GC.FIELD_REFERENCE_NAME], selectedReport)),
);

const getValueString = (selectedReport: Object) => {
  const {
    to,
    from,
    last,
    next,
    dataType,
    dateRelative,
    selectMultipleValue,
  } = selectedReport;

  if (G.isNotNilAndNotEmpty(selectMultipleValue)) {
    return R.compose(
      R.join(', '),
      R.map((item: Object) => item.label),
    )(selectMultipleValue);
  }

  if (R.equals(dataType, 'date')) {
    const notEmptyLast = G.ifElse(G.isNotNilAndNotEmpty(last), last, 0);
    const notEmptyNext = G.ifElse(G.isNotNilAndNotEmpty(next), next, 0);

    if (G.isFalse(dateRelative)) {
      if (R.and(R.isNil(from), R.isNil(to))) return '';

      if (R.isNil(from)) return `${G.getWindowLocale('titles:to', 'To')} ${to}`;

      if (R.isNil(to)) return `${G.getWindowLocale('titles:from', 'From')} ${from}`;

      return `${from} - ${to}`;
    }

    return `${notEmptyLast} - ${notEmptyNext}`;
  }

  if (G.isFilterNumberRange(selectedReport)) {
    const to = R.pathOr('', ['numberRange', 'to'], selectedReport);
    const from = R.pathOr('', ['numberRange', 'from'], selectedReport);

    return `${from} - ${to}`;
  }

  if (G.isFilterStringRange(selectedReport)) {
    const to = R.pathOr('', ['stringRange', 'to'], selectedReport);
    const from = R.pathOr('', ['stringRange', 'from'], selectedReport);

    return `${from} - ${to}`;
  }

  if (R.equals(dataType, 'boolean')) {
    if (G.isTrue(selectedReport.booleanValue)) {
      return G.getWindowLocale('titles:yes', 'Yes');
    }

    if (G.isNot(selectedReport.booleanValue)) {
      return G.getWindowLocale('titles:no', 'No');
    }
  }

  return R.pathOr('', [`${dataType}Value`], selectedReport);
};

const getFilterItemDisplayOptions = (filter: Object, filterProps: Array) => ({
  value: getValueString(filter),
  propertyName: getReportFilterPropertyName(filterProps, filter),
  operation: R.toLower(R.or(filter.operation, R.or(filter.timeUnit, 'Date Range'))),
});

const getNeededReportFilterValues = (filterProps: Object, searchCriteria: Object) => {
  const headerReportFilterValues = {
    value: G.getWindowLocale('titles:value', 'Value'),
    propertyName: G.getWindowLocale('titles:filter-name', 'Filter Name'),
    operation: G.getWindowLocale('titles:condition', 'Condition'),
  };

  return R.compose(
    R.prepend(headerReportFilterValues),
    R.map((filter: Object) => getFilterItemDisplayOptions(filter, filterProps)),
  )(R.or(searchCriteria, []));
};

export const PopperContentComponent = (props: Object) => {
  const { version, popperTitle, geoFencingZones, reportFilterValues, handleDeleteFilter } = props;

  const iconColor = G.getThemeByCond(
    G.isThemeSecondVersion(version),
    'colors.light.blue',
    'icons.iconColor',
  );

  return (
    <Box p={10} zIndex={10} width='auto' minWidth={400} maxWidth={1000}>
      <TextComponent
        mb={10}
        ml={10}
        display='block'
        fontWeight='bold'
        width='max-content'
        textTransform='capitalize'
        color={G.getTheme('colors.light.black')}
      >
        {popperTitle}
      </TextComponent>
      <Box
        overflowY='auto'
        overflow='hidden'
        maxHeight='360px'
        borderRadius='5px'
        background={G.getTheme('colors.light.lighterGrey')}
      >
        {reportFilterValues.map((row: Object, rowIndex: number) => (
          <Flex
            p={10}
            top='0px'
            key={rowIndex}
            justifyContent='space-between'
            zIndex={G.ifElse(G.isZero(rowIndex), 11, 10)}
            position={G.ifElse(G.isZero(rowIndex), 'sticky', 'static')}
            background={G.ifElse(
              G.isZero(rowIndex),
              G.getTheme('colors.lightGrey'),
              'unset',
            )}
          >
            {R.values(row).map((cell: Object, cellIndex: number) => {
              if (R.and(R.gt(rowIndex, 0), G.isZero(cellIndex))) {
                const text = R.pathOr(cell, [cell, GC.FIELD_NAME], geoFencingZones);

                return (
                  <Flex key={cellIndex} width='calc(30% + 15px)'>
                    <Box
                      mr='5px'
                      cursor='pointer'
                      onClick={(event: Object) => handleDeleteFilter(R.subtract(rowIndex, 1), event)}
                    >
                      {I.trash(iconColor)}
                    </Box>
                    <TextComponent
                      title={text}
                      withEllipsis={true}
                      textTransform='capitalize'
                    >
                      {text}
                    </TextComponent>
                  </Flex>
                );
              }
              return (
                <TextComponent
                  title={cell}
                  key={cellIndex}
                  withEllipsis={true}
                  textTransform='capitalize'
                  width={G.ifElse(G.isZero(cellIndex), 'calc(30% + 15px)', '30%')}
                >
                  {cell}
                </TextComponent>
              );
            })}
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  geoFencingZones: makeSelectAvailableReportGeoFencingZoneList(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withDeleteFilter,
  pure,
);

const ReportAndQuickFilterComponent = enhance((props: Object) => {
  const {
    version,
    filterProps,
    cleanFilter,
    filteredParams,
    selectedReport,
    geoFencingZones,
    handleDeleteFilter,
  } = props;

  const searchCriteria = R.path(['searchCriteria'], selectedReport);

  const filterPropsToUse = getAvailableFilterFields({
    geoFencingZones,
    usedReport: selectedReport,
    availableFields: filterProps,
  });

  const geoFencingZonesToUse = G.indexByGuid(geoFencingZones);

  return (
    <Box minWidth={600}>
      {
        G.isNotNilAndNotEmpty(searchCriteria) &&
        <PopperContentComponent
          version={version}
          geoFencingZones={geoFencingZonesToUse}
          handleDeleteFilter={handleDeleteFilter}
          popperTitle={G.getWindowLocale('titles:report-filters', 'Report Filters')}
          reportFilterValues={getNeededReportFilterValues(
            filterPropsToUse,
            searchCriteria,
          )}
        />
      }
      {
        G.isNotNilAndNotEmpty(filteredParams) &&
        <PopperContentComponent
          version={version}
          handleDeleteFilter={cleanFilter}
          geoFencingZones={geoFencingZonesToUse}
          popperTitle={G.getWindowLocale('titles:quick-filtered', 'Quick Filtered')}
          reportFilterValues={getNeededReportFilterValues(
            filterPropsToUse,
            [filteredParams],
          )}
        />
      }
    </Box>
  );
});

export const PopupWithReportAndQuickFilters = (props: Object) => (
  R.or(
    G.isNotNilAndNotEmpty(R.path(['filteredParams'], props)),
    G.isNotNilAndNotEmpty(R.path(['selectedReport', 'searchCriteria'], props)),
  )
  && (
    <PopperWrapper
      type='common'
      icon={I.arrowDownSimple()}
      filterCount={props.filterCount}
      popperWithCount={props.popperWithCount}
      additionalStyles={props.additionalStyles}
      text={G.getWindowLocale('titles:filters', 'Filters')}
      popperContent={<ReportAndQuickFilterComponent {...props} />}
    />
  )
);

export const FilterPopup = (props: Object) => {
  const {
    refList,
    usedReport,
    filterProps,
    disableRefFilter,
    withoutOuterClick,
  } = props;

  const availableFields = G.setFilterByParams(
    refList,
    filterProps,
    { disableRefFilter },
  );
  const filterFields = R.pathOr([], ['searchCriteria'], usedReport);

  const filterCount = R.compose(
    R.length,
    R.filter(({ name, groupId }: Object) => R.and(G.notEquals(name, GC.FIELD_GUID), G.isNilOrEmpty(groupId))),
  )(filterFields);

  return (
    <PopperWrapper
      type='common'
      filterCount={filterCount}
      icon={I.arrowDownSimple()}
      withoutOuterClick={withoutOuterClick}
      popperWithCount={props.popperWithCount}
      additionalStyles={props.additionalStyles}
      text={G.getWindowLocale('titles:filters', 'Filters')}
      popperContent={({ closePopper }: Object) => (
        <OutsideReportExactFilterList
          {...props}
          filterFieldWidth={150}
          hideFilterHeader={true}
          closePopper={closePopper}
          filterFields={filterFields}
          availableFields={availableFields}
        />
      )}
    />
  );
};

const getPageFilterSettings = (usePortal: boolean = false) => ({
  usePortal,
  type: 'click',
  overflow: 'unset',
  borderWidth: '1px solid',
  position: 'bottom-start',
  wrapperWidth: 'max-content',
  zi: G.ifElse(usePortal, 1300),
  popperComponentWrapperMargin: '0',
});

const AddReportFilter = withPageReportFilter((props: Object) => {
  const {
    filter,
    isOutside,
    availableFields,
    addFilterStyles,
    handleSaveFilter,
    usePortalForFilters,
  } = props;

  const blueColor = G.getTheme('colors.dark.blue');

  const settings = {
    ...getPageFilterSettings(usePortalForFilters),
    borderColor: blueColor,
  };

  return (
    <ZIndexClick>
      <PopperComponent
        {...settings}
        content={({ closePopper }: Object) => (
          <Box minWidth={770}>
            <FilterItem
              {...props}
              {...filter}
              filterIndex={0}
              withoutOrGroup={true}
              availableFields={availableFields}
            />
            <FormFooter2
              boxStyles={{ p: 15 }}
              cancelAction={closePopper}
              submitBtnStyles={{ height: 25 }}
              cancelBtnStyles={{ height: 25 }}
              submitAction={() => {
                handleSaveFilter();
                closePopper();
              }}
            />
          </Box>
        )}
      >
        {
          isOutside &&
          <Flex
            mx='6px'
            fontSize={13}
            color={blueColor}
            {...G.spreadUiStyles(addFilterStyles)}
          >
            {G.getWindowLocale('titles:add-filter', 'Add Filter')}
          </Flex>
        }
        {
          R.not(isOutside) &&
          <Box mx='6px'>{I.plusRound(blueColor)}</Box>
        }
      </PopperComponent>
    </ZIndexClick>
  );
});

export const AddReportFilterOutside = (props: Object) => {
  const {
    refList,
    filterProps,
    selectedReport,
    geoFencingZones,
    disableRefFilter,
  } = props;

  if (G.isNilOrEmpty(selectedReport)) return null;

  const availableFields = getAvailableFilterFields({
    geoFencingZones,
    usedReport: selectedReport,
    availableFields: G.setFilterByParams(refList, filterProps, { disableRefFilter }),
  });

  return (
    <AddReportFilter {...props} isOutside={true} availableFields={availableFields} />
  );
};

export const PageFilterItem = withPageReportFilter((props: Object) => {
  const {
    name,
    width,
    filter,
    settings,
    titleText,
    handleSaveFilter,
    handleRemoveFilter,
  } = props;

  const darkColor = G.getTheme('colors.black');
  const lightGreyColor = G.getTheme('colors.light.lightGrey');

  return (
    <ZIndexClick>
      <PopperComponent
        {...settings}
        content={({ closePopper }: Object) => (
          <Box minWidth={770}>
            <FilterItem
              {...props}
              {...filter}
              filterIndex={0}
              withoutOrGroup={true}
            />
            <FormFooter2
              boxStyles={{ p: 15 }}
              cancelAction={closePopper}
              submitBtnStyles={{ height: 25 }}
              cancelBtnStyles={{ height: 25 }}
              submitAction={() => {
                handleSaveFilter();
                closePopper();
              }}
            />
          </Box>
        )}
      >
        <Flex
          mx='4px'
          p='4px 6px'
          fontSize={12}
          maxWidth={width}
          color={darkColor}
          title={titleText}
          borderRadius='4px'
          bg={lightGreyColor}
          width='fit-content'
        >
          <TextComponent mr='8px' withEllipsis={true}>
            {name}
          </TextComponent>
          <Box onClick={handleRemoveFilter}>
            {I.closeIcon(darkColor, 8)}
          </Box>
        </Flex>
      </PopperComponent>
    </ZIndexClick>
  );
});

const getFilterName = (filter: Object, indexedFields: Object) => {
  const { dataType } = filter;

  if (R.equals(dataType, 'reference')) {
    return G.getReferenceTitleName(R.pathOr('', [GC.FIELD_REFERENCE_NAME], filter));
  }

  return R.path([G.getPropFromObject(GC.FIELD_PROPERTY_NAME, filter), GC.FIELD_NAME], indexedFields);
};

const PageFilterGroup = (props: Object) => {
  const {
    filterFields,
    setUsedReport,
    isQuickFilter,
    selectedReport,
    availableFields,
    getItemListRequest,
    usePortalForFilters,
    setQuickFilterParams,
    getQuickFilteredListRequest,
  } = props;

  const singleFilter = R.equals(R.length(filterFields), 1);
  const mainBlue = G.getTheme('colors.dark.blue');
  const indexedFields = R.indexBy(R.prop(GC.FIELD_VALUE), availableFields);

  const settings = {
    ...getPageFilterSettings(usePortalForFilters),
    borderColor: mainBlue,
  };

  if (singleFilter) {
    const filter = R.head(filterFields);

    let name = getFilterName(filter, indexedFields);

    const titleOptions = getFilterItemDisplayOptions(filter, availableFields);

    const titleText = `${titleOptions.propertyName} ${titleOptions.operation} ${titleOptions.value}`;

    if (isQuickFilter) name = `${G.getWindowLocale('titles:quick-filter', 'Quick Filter')}: ${name}`;

    return (
      <PageFilterItem
        name={name}
        settings={settings}
        width={filter.width}
        titleText={titleText}
        setUsedReport={setUsedReport}
        isQuickFilter={isQuickFilter}
        selectedReport={selectedReport}
        filterData={R.head(filterFields)}
        availableFields={availableFields}
        getItemListRequest={getItemListRequest}
        setQuickFilterParams={setQuickFilterParams}
        getQuickFilteredListRequest={getQuickFilteredListRequest}
      />
    );
  }

  return (
    <Flex py='2px' borderRadius='5px' border='1px dashed' borderColor='grey'>
      {
        filterFields.map((filter: Object, index: number) => {
          const name = getFilterName(filter, indexedFields);
          const titleOptions = getFilterItemDisplayOptions(filter, availableFields);
          const titleText = `${titleOptions.propertyName} ${titleOptions.operation} ${titleOptions.value}`;

          if (R.equals(index, R.dec(R.length(filterFields)))) {
            return (
              <PageFilterItem
                key={index}
                name={name}
                filterData={filter}
                settings={settings}
                width={filter.width}
                titleText={titleText}
                setUsedReport={setUsedReport}
                selectedReport={selectedReport}
                availableFields={availableFields}
                getItemListRequest={getItemListRequest}
              />
            );
          }

          return (
            <Flex key={index}>
              <PageFilterItem
                name={name}
                filterData={filter}
                settings={settings}
                width={filter.width}
                titleText={titleText}
                setUsedReport={setUsedReport}
                selectedReport={selectedReport}
                availableFields={availableFields}
                getItemListRequest={getItemListRequest}
              />
              <Box fontSize={11}>{G.getWindowLocale('titles:or', 'Or')}</Box>
            </Flex>
          );
        })
      }
    </Flex>
  );
};

export const PageReportFilters = (props: Object) => {
  const {
    zIndex,
    filterProps,
    userLoginId,
    filterParams,
    assessorials,
    geoFencingZones,
    disableRefFilter,
    selectedReport = {},
    updateReportRequest,
    withoutAddingReportFilter,
    allAvailableRefTypesByScopeName,
  } = props;

  const { guid, type, owner, searchCriteria } = selectedReport;

  if (R.and(G.isNilOrEmpty(filterParams), G.isNilOrEmpty(searchCriteria))) return null;

  const whiteColor = G.getTheme('colors.white');

  const availableFields = getAvailableFilterFields({
    geoFencingZones,
    usedReport: selectedReport,
    availableFields: G.setFilterByParams(
      G.getRefListByReportType(allAvailableRefTypesByScopeName, type),
      filterProps,
      {
        reportType: type,
        disableRefFilter,
        assessorialList: getAssessorials({ assessorials, usedReport: selectedReport }),
      },
    ),
  });

  const reportGuid = R.or(guid, 'draft');

  const canUpdate = G.isAllTrue(
    R.equals(owner, userLoginId),
    G.notContain('Default', reportGuid),
    G.notEquals(reportGuid, 'draft'),
  );

  const actionButtonsWidth = G.ifElse(canUpdate, 90, 0); // TODO: change to 170, 90 after "Save As New" implemented

  const filtersCount = R.compose(
    R.add(G.ifElse(G.isNilOrEmpty(filterParams), 0, 1)),
    R.pathOr(0, ['searchCriteria', 'length']),
  )(selectedReport);

  const widthToSubtract = R.compose(
    R.add(8),
    R.divide(R.add(actionButtonsWidth, 90)),
  )(filtersCount);

  const filterWidth = `calc(${R.divide(100, filtersCount)}vw - ${widthToSubtract}px)`;

  const filterGroups = R.compose(
    R.groupBy(R.prop(GC.FIELD_GROUP_ID)),
    G.mapIndexed((item: Object, searchCriteriaIndex: number) => {
      const groupId = G.getPropFromObject(GC.FIELD_GROUP_ID, item);

      if (G.isNilOrEmpty(groupId)) {
        return {
          ...item,
          width: filterWidth,
          searchCriteriaIndex,
          withoutOrGroup: true,
          groupId: G.generateGuid(),
        };
      }

      return {
        ...item,
        width: filterWidth,
        searchCriteriaIndex,
      };
    }),
    R.pathOr([], ['searchCriteria']),
  )(selectedReport);

  return (
    <RelativeFlex
      pt='6px'
      height={34}
      width='100%'
      bg={whiteColor}
      zIndex={R.or(zIndex, '0')}
    >
      {
        R.not(withoutAddingReportFilter) &&
        <AddReportFilter
          {...props}
          isNew={true}
          filterData={defaultFilterFields}
          availableFields={availableFields}
        />
      }
      {
        G.isNotNilAndNotEmpty(searchCriteria) &&
        R.keys(filterGroups).map((groupId: string, index: number) => (
          <PageFilterGroup
            {...props}
            key={index}
            availableFields={availableFields}
            filterFields={G.getPropFromObject(groupId, filterGroups)}
          />
        ))
      }
      {
        G.isNotNilAndNotEmpty(filterParams) &&
        <PageFilterGroup
          {...props}
          isQuickFilter={true}
          availableFields={availableFields}
          filterFields={R.of(Array, filterParams)}
        />
      }
      {/* TODO: add save as new */}
      {
        canUpdate &&
        <StickedFlex right='0' height='100%' bg={whiteColor}>
          <Box
            ml={10}
            width={80}
            fontSize={12}
            cursor='pointer'
            color={G.getTheme('colors.dark.blue')}
            onClick={() => {
              const report = R.assoc(
                'searchCriteria',
                transformSearchCriteriaBeforeReportPost(searchCriteria, type),
                selectedReport,
              );

              updateReportRequest(report);
            }}
          >
            {G.getWindowLocale('actions:update-report', 'Update Report')}
          </Box>
        </StickedFlex>
      }
    </RelativeFlex>
  );
};

export default PopupWithReportAndQuickFilters;
