import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectStore = (state: Object) => state.audio;

const makeSelectAudioPlaying = () => createSelector(
  selectStore,
  ({ playing }: Object) => playing,
);

const makeSelectSrc = () => createSelector(
  selectStore,
  ({ src }: Object) => src,
);

export {
  makeSelectSrc,
  makeSelectAudioPlaying,
};
