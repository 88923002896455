import { $set } from 'plow-js';
import { createReducer } from 'redux-act';
// common idb
import * as A from './actions.js';
//////////////////////////////////////////////////

const idbName = 'Amous-order-report-table-data';
const idbStoreName = 'report-guids';
const idbKeyPath = 'reportGuid';

const initialState = {
  idb: null,
  reportTableOrder: {},
  idbProps: {
    idbName,
    idbKeyPath,
    idbStoreName,
  },
};

const resetReportTableOrder = (state: Object) => (
  $set('reportTableOrder', {}, state)
);

const setReportTableOrder = (state: Object, {reportGuid, orderTable}: Object = {}) => (
  $set(`reportTableOrder.${reportGuid}`, orderTable, state)
);

const initTableOrderIDBSuccess = (state: Object, idb: Object = {}) => (
  $set('idb', idb, state)
);

export default createReducer({
  [A.setReportTableOrder]: setReportTableOrder,
  [A.resetReportTableOrder]: resetReportTableOrder,
  [A.initTableOrderIDBSuccess]: initTableOrderIDBSuccess,
}, initialState);
