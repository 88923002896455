import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../confirm';
import { FormGroupTable } from '../form-group-table';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// insurance
import InsuranceForm from './insurance-form';
import { insuranceSettings } from './settings';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleAddInsurance: (props: Object) => () => {
      const {
        objKey,
        objGuid,
        openModal,
        closeModal,
        createAction } = props;

      const initialValues = { [objKey]: objGuid };
      const component = (
        <InsuranceForm
          editMode={false}
          closeModal={closeModal}
          submitAction={createAction}
          initialValues={initialValues} />
      );
      const modal = {
        component,
        p: 15,
        options: {
          height: 'auto',
          title: G.getWindowLocale('titles:add-insurance', 'Add Insurance'),
        },
      };
      openModal(modal);
    },
    handleEditInsurance: (props: Object) => (insurance: Object) => {
      const {
        objKey,
        objGuid,
        openModal,
        closeModal,
        updateAction } = props;

      const initialValues = R.mergeRight(
        insurance,
        {
          [objKey]: objGuid,
          [GC.FIELD_CARRIER_INSURANCE_TYPE]: R.path(
            [GC.FIELD_CARRIER_INSURANCE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
            insurance,
          ),
        },
      );
      const component = (
        <InsuranceForm
          editMode={true}
          closeModal={closeModal}
          submitAction={updateAction}
          initialValues={initialValues} />
      );
      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          title: G.getWindowLocale('titles:edit-insurance', 'Edit Insurance'),
        },
      };
      openModal(modal);
    },
    handleDeleteInsurance: (props: Object) => ({ guid, insuranceType }: Object) => {
      const { openModal, removeAction } = props;

      const component = (
        <ConfirmComponent
          name={G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, insuranceType)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeAction(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

const Insurances = (props: Object) => (
  <FormGroupTable
    groupName='insurances'
    entities={props.insurances}
    fields={props.collapsedGroup}
    entitiesFields={insuranceSettings}
    handleAddClick={props.handleAddInsurance}
    handleEditRow={props.handleEditInsurance}
    handleDeleteRow={props.handleDeleteInsurance}
    isOpened={R.path(['collapsedGroup', 'insurances'], props)}
    panelTitle={G.getWindowLocale('titles:insurances', 'Insurances')}
    handleToggleFormGroup={() => props.handleToggleFormGroup('insurances')} />
);

export default enhance(Insurances);
