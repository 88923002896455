import React from 'react';
import * as R from 'ramda';
import { times } from 'ramda';
// components loader
import {
  Rectangle,
  DimmerWrapper,
  TitleRectangle,
  TableRectangle } from './ui';
//////////////////////////////////////////////////

const checkIsNilFirstValueAndNotNilSecond = (firstValue: any, secondValue: any) => (
  R.and(R.isNil(firstValue), R.not(R.isNil(secondValue)))
);

const renderDimmerRectangles = (count: number) => (
  times((index: number) => <Rectangle key={index} />, count)
);

export const renderDimmerTable = ({ tableCount, rectangleCount }: Object) => (
  times((index: number) => (
    <TableRectangle key={index}>
      {renderDimmerRectangles(rectangleCount)}
    </TableRectangle>
  ), tableCount)
);

export const DimmerComponent = (props: Object) => (
  <DimmerWrapper zIndex={props.zIndex}>
    <TitleRectangle />
    <Rectangle />
    {
      props.tableCount &&
      renderDimmerTable(props)
    }
    {
      checkIsNilFirstValueAndNotNilSecond(props.tableCount, props.rectangleCount) &&
      renderDimmerRectangles(props.rectangleCount)
    }
  </DimmerWrapper>
);

export default DimmerComponent;
