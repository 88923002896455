import { space, height } from 'styled-system';
import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

const TableWrapper = styled(Box)`
  width: 100%;
  height: 425px;
  overflow: hidden;
  border: 1px solid ${() => G.getTheme('colors.bgGrey')};
`;

const TableScrollWrapper = styled(Flex)`
  height: 425px;
  max-width: 100%;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${() => G.getTheme('colors.bgGrey')};
`;

const TableHeader = styled(Flex)`
  top: 0;
  min-width: 100%;
  z-index: 999999;
  position: sticky;
  flex-direction: row;
  background: ${() => G.getTheme('colors.dark.mainLight')};
  border-bottom: 1px solid ${() => G.getTheme('colors.bgGrey')};
`;

const FlexContainer = styled.div`
  ${space}
  ${height}

  display: flex;
  align-items: ${({ align }: Object) => align};
  justify-content: ${({ justify }: Object) => justify};
  flex-direction: ${({ direction }: Object) => direction};
`;

const StickyElement = styled(FlexContainer)`
  right: 0;
  position: sticky;
  background: ${() => G.getTheme('colors.dark.mainLight')};
`;

export {
  TableHeader,
  TableWrapper,
  FlexContainer,
  StickyElement,
  TableScrollWrapper,
};
