import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// components
import { openLoader, closeLoader } from '../loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import endpointsMap from '../../utilities/endpoints';
// components async-documents
import AsyncDocuments from './';
//////////////////////////////////////////////////

export const withShowDocuments = compose(
  connect(null, { openLoader, closeLoader }),
  withHandlers({
    handleShowDocuments: (props: Object) => (guid: string, type: string, payrollTypeGuid: string) => {
      const { openModal, openLoader, closeLoader, mailIntegrationType } = props;

      openLoader();

      const actionsMap = {
        [GC.DOCUMENTS_TYPE_DRIVER_PAYROLL]: endpointsMap.driverPayrollDocuments,
        [GC.DOCUMENTS_TYPE_VENDOR_PAYROLL]: endpointsMap.vendorPayrollDocuments,
        [GC.DOCUMENTS_TYPE_MASTER_INVOICE]: endpointsMap.customerMasterInvoiceDocuments,
      };

      const options = {
        params: { [GC.FIELD_PRIMARY_OBJECT_GUID]: guid },
      };

      const component = (
        <AsyncDocuments
          payrollGuid={guid}
          documentsType={type}
          asyncOptions={options}
          payrollTypeGuid={payrollTypeGuid}
          mailIntegrationType={mailIntegrationType}
          asyncEndpoint={R.path([type], actionsMap)}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          minWidth: 600,
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          width: 'fit-content',
          title: G.getWindowLocale('titles:documents', 'Documents'),
        },
      };

      openModal(modal);

      closeLoader();
    },
  }),
);
