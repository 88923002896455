import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const enhance = compose(
  withState('value', 'setValue', null),
  withHandlers({
    handleChange: ({ setValue, setOutsideValue }: Object) => (value: string) => {
      setValue(value);

      G.callFunctionWithArgs(setOutsideValue, value);
    },
    handleSelect: (props: Object) => async (value: string) => {
      const { setValue, resultCallback, setOutsideValue } = props;

      const result = await G.geocodeByPlaceAddress(value, 'components -> PlacesAutocompleteInput');

      const { formattedAddress } = result;

      setValue(formattedAddress);

      G.callFunctionWithArgs(resultCallback, result);
      G.callFunctionWithArgs(setOutsideValue, formattedAddress);
    },
  }),
  pure,
);

export const getValue = (props: Object) => {
  const { value, outsideValue, useOutsideValue } = props;

  if (G.isTrue(useOutsideValue)) return outsideValue;

  return value;
};
