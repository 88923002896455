import React from 'react';
import * as R from 'ramda';
import { Field } from 'redux-form';
// components
import { TextComponent } from '../text';
import MultiSelect from '../filter/multi-select';
import { reportBooleanValues } from '../filter/settings';
// helpers/constants
import * as G from '../../helpers';
// forms
import { Error, renderFormGroup } from '../../forms';
// ui
import { Box, ReactSelect } from '../../ui';
// component edit-report
import { Option, FilterDate } from './ui';
import { getValue, setOptions } from './helpers';
//////////////////////////////////////////////////

const renderOptionComponent = (props: Object, input: Object, action: Function, seq: number) => (
  <Option
    onClick={(e: Object) => {
      props.selectOption(props.data, e);
      action(input.name, props.data, seq);
    }}
  >
    {props.data.name}
  </Option>
);

const renderValueComponent = (props: Object) => (
  <TextComponent
    my={6}
    ml='8px'
    position='absolute'
    withEllipsis={true}
    title={props.data.name}
    width='calc(100% - 8px)'
  >
    {props.data.name}
  </TextComponent>
);

export const renderSelect = ({
  zI,
  width,
  input,
  margin,
  options,
  sequence,
  placeholder,
  errorMargin,
  selectAction,
  errorFontSize,
  meta: { error, touched },
}: Object) => (
  <Box
    zIndex={zI}
    touched={touched}
    width={R.or(width, 232)}
    m={R.or(margin, '0 10px')}
  >
    <ReactSelect
      height={30}
      name={input.name}
      isClearable={false}
      onBlurResetsInput={false}
      placeholder={placeholder}
      onSelectResetsInput={false}
      options={setOptions(options)}
      value={getValue(input, options)}
      shouldNotGetValueFromOptions={true}
      hasError={R.and(G.isTrue(touched), G.isNotNilAndNotEmpty(error))}
      components={{
        SingleValue: renderValueComponent,
        Option: (props: Object) => renderOptionComponent(props, input, selectAction, sequence),
      }}
    />
    {
      R.and(touched, error) && <Error fontSize={errorFontSize} m={errorMargin}>{error}</Error>
    }
  </Box>
);

const renderDefault = (
  props: Object,
  field: Object,
  sectionName: string,
  index: number,
  options: Object = {},
) => {
  const { operation } = field;
  const { version } = props;
  if (R.equals(operation, 'range')) {
    return ['from', 'to'].map((prefix: string, i: number) => (
      <Field
        key={i}
        min={0}
        width={106}
        type='text'
        direction='row'
        version={version}
        errorFontSize={14}
        component={renderFormGroup('text')}
        name={`value-${prefix}-${R.inc(index)}`}
        margin={R.or(options.margin, '5px 10px')}
        placeholder={G.getWindowLocale(`titles:${prefix}`, prefix)}
        handler={(value: Object) => (
          props.handleFilterStringValue(
            `${sectionName}value-${prefix}-${R.inc(index)}`,
            value.target.value,
            index,
            prefix,
          )
        )} />
    ));
  }
  return (
    <Field
      type='text'
      width={232}
      margin='0 10px'
      version={version}
      errorFontSize={14}
      name={`value-${R.inc(index)}`}
      component={renderFormGroup('text')}
      placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
      onChange={(event: Object, value: string) => props.handleFilterValue(event.target.name, value, index)} />
  );
};

const renderNumber = (
  props: Object,
  field: Object,
  sectionName: string,
  index: number,
  options: Object = {},
) => {
  const { operation } = field;
  const { version } = props;
  if (R.equals(operation, 'range')) {
    return ['from', 'to'].map((prefix: string, i: number) => (
      <Field
        key={i}
        min={0}
        width={106}
        type='number'
        direction='row'
        version={version}
        errorFontSize={14}
        component={renderFormGroup('text')}
        name={`value-${prefix}-${R.inc(index)}`}
        margin={R.or(options.margin, '5px 10px')}
        placeholder={G.getWindowLocale(`titles:${prefix}`, prefix)}
        normalize={(value: number) => G.ifElse(R.lt(value, 0), 0, value)}
        handler={(value: Object) => (
          props.handleFilterNumberValue(
            `${sectionName}value-${prefix}-${R.inc(index)}`,
            value.target.value,
            index,
            prefix,
          )
        )} />
    ));
  }
  return (
    <Field
      min={0}
      width={232}
      type='number'
      margin='0 10px'
      errorFontSize={14}
      name={`value-${R.inc(index)}`}
      component={renderFormGroup('text')}
      normalize={(value: number) => G.ifElse(R.lt(value, 0), 0, value)}
      placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
      onChange={(event: Object, value: string) => props.handleFilterValue(event.target.name, value, index)} />
  );
};

const renderBoolean = (props: Object, field: Object, index: any, options: Object = {}) => {
  const { version } = props;
  return (
    <Field
      zI={options.zI}
      sequence={index}
      version={version}
      component={renderSelect}
      name={`value-${R.inc(index)}`}
      options={reportBooleanValues()}
      selectAction={props.handleFilterValue} />
  );
};

const renderSelectMultiple = (props: Object, index: number, field: object) => {
  const activeSearchField = R.or(R.path(['formValues', 'field'], props), R.path(['propertyName'], field));
  let searchOptions;
  if (G.isNilOrEmpty(R.path(['filterBy'], props))) {
    searchOptions = R.find(R.propEq(activeSearchField, 'value'))(R.path(['availableFields'], props));
  } else {
    searchOptions = R.find(R.propEq(activeSearchField, 'name'))(R.path(['filterBy'], props));
  }
  const { options, placeholder } = searchOptions;
  const { version } = props;
  return (
    <Field
      zI={11}
      width={232}
      display='flex'
      sequence={index}
      margin='5px 10px'
      version={version}
      inputWidth='100%'
      direction='column'
      type='multiSelect'
      options={options}
      change={props.change}
      errorMargin='0 0 5px 0'
      component={MultiSelect}
      quickFilterInReport={true}
      name={`value-${R.inc(index)}`}
      handleFilterValue={props.handleFilterValue}
      placeholder={G.getWindowLocale(placeholder.key, placeholder.text)} />
  );
};

const renderDate = (
  props: Object,
  field: Object,
  sectionName: string,
  index: number,
  options: Object = {},
) => {
  const { version } = props;
  return (
    <FilterDate zI={options.dateZI}>
      {
        G.ifElse(
          props.filters[index].dateRelative,
          ['last', 'next'].map((prefix: string, i: number) => (
            <Field
              key={i}
              width={106}
              type='number'
              margin='0 10px'
              version={version}
              errorFontSize={14}
              component={renderFormGroup('text')}
              name={`value-${prefix}-${R.inc(index)}`}
              placeholder={G.getWindowLocale(`titles:${prefix}`, prefix)}
              onChange={(event: Object, value: string) => (
                props.handleFilterDateValue(
                  `${sectionName}value-${prefix}-${R.inc(index)}`,
                  value,
                  index,
                  prefix,
                )
              )} />
          )),
          ['from', 'to'].map((prefix: string, i: number) => (
            <Field
              key={i}
              type='text'
              width='160px'
              direction='row'
              timeSelection={true}
              withoutCalendarLabel={true}
              component={renderFormGroup('calendar')}
              name={`value-${prefix}-${R.inc(index)}`}
              margin={R.or(options.margin, '5px 10px')}
              placeholder={
                `${G.getWindowLocale('titles:select-date', 'Select Date')} ${
                  G.getWindowLocale(`titles:${prefix}`, prefix)}`
              }
              handler={(value: Object) => (
                props.handleFilterDateValue(
                  `${sectionName}value-${prefix}-${R.inc(index)}`,
                  value,
                  index,
                  prefix,
                )
              )} />
          )),
        )
      }
    </FilterDate>
  );
};

export const renderField = (
  props: Object,
  field: Object,
  sectionName: string,
  index: number,
  options: Object,
) => {
  const fields = {
    boolean: () => renderBoolean(props, field, index, options),
    selectMultiple: () => renderSelectMultiple(props, index, field),
    date: () => renderDate(props, field, sectionName, index, options),
    number: () => renderNumber(props, field, sectionName, index, options),
    default: () => renderDefault(props, field, sectionName, index, options),
  };
  const param = G.ifElse(R.isNil(fields[field.dataType]), 'default', field.dataType);
  return fields[param]();
};
