import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
// components
import { TextComponent } from '../../../text';
import { reportBooleanValues } from '../../../filter/settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { DatePickerMui } from '../../../datepicker-mui';
import {
  Input,
  FormGroup,
  DatePicker,
  CalendarFormGroup,
} from '../../../../forms';
// ui
import { Box, ReactSelect } from '../../../../ui';
// component edit-report
import { setOptions } from '../../helpers';
import { Option, FilterDate } from '../../ui';
//////////////////////////////////////////////////

const FilterOptionComponent = (props: Object) => {
  const { data, action, selectOption } = props;

  return (
    <Option
      onClick={(e: Object) => {
        selectOption(data, e);
        action(props);
      }}
    >
      {data.name}
    </Option>
  );
};

const ValueComponent = (props: Object) => {
  const value = R.path(['data', 'name'], props);

  return (
    <TextComponent
      my={6}
      ml='8px'
      title={value}
      withEllipsis={true}
      position='absolute'
      width='calc(100% - 8px)'
    >
      {value}
    </TextComponent>
  );
};

export const FilterSelect = (({
  zI,
  id,
  name,
  value,
  margin,
  action,
  options,
  groupId,
  dataType,
  collection,
  filterIndex,
  placeholder,
  withoutOrGroup,
  filterFieldWidth,
  useMenuPortalTarget,
  filterSelectMaxMenuHeight,
}: Object) => (
  <Box
    zI={zI}
    margin={R.or(margin, '0 10px')}
    width={R.or(filterFieldWidth, 232)}
  >
    <ReactSelect
      id={id}
      name={name}
      height={30}
      value={value}
      action={action}
      isClearable={false}
      onBlurResetsInput={false}
      placeholder={placeholder}
      onSelectResetsInput={false}
      options={setOptions(options)}
      maxMenuHeight={filterSelectMaxMenuHeight}
      useMenuPortalTarget={useMenuPortalTarget}
      closeMenuOnScroll={R.pathEq('edit_report_wrapper', ['target', 'id'])}
      components={{
        SingleValue: ValueComponent,
        Option: (props: Object) => (
          <FilterOptionComponent
            {...props}
            action={action}
            groupId={groupId}
            dataType={dataType}
            collection={collection}
            filterIndex={filterIndex}
            withoutOrGroup={withoutOrGroup}
          />
        ),
      }}
    />
  </Box>
));

const BooleanComponent = (props: Object) => {
  const { id, groupId, filterIndex, booleanValue, handleChangeSelect, useMenuPortalTarget } = props;

  return (
    <FilterSelect
      id={id}
      groupId={groupId}
      value={booleanValue}
      filterIndex={filterIndex}
      options={reportBooleanValues()}
      useMenuPortalTarget={useMenuPortalTarget}
      action={(values: Object) => handleChangeSelect({ values, filterProp: 'booleanValue' })}
    />
  );
};

const getInputNumber = (event: Object) => {
  const value = R.path(['target', 'value'], event);

  if (G.isNilOrEmpty(value)) return null;

  return G.toNumber(value);
};

const NumberComponent = (props: Object) => {
  const {
    groupId,
    operation,
    filterIndex,
    numberValue,
    numberRange,
    handleChangeInput,
    handleChangeRange } = props;

  if (R.equals(operation, 'range')) {
    return (
      <FormGroup direction='row'>
        {
          ['from', 'to'].map((prefix: string, i: number) => (
            <Input
              key={i}
              min={0}
              m='0 10px'
              width={106}
              type='number'
              value={R.pathOr('', [prefix], numberRange)}
              placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
              onChange={(event: Object) => handleChangeRange({
                groupId,
                filterIndex,
                rangeType: prefix,
                filterProp: 'numberRange',
                value: getInputNumber(event),
              })}
            />
          ))
        }
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <Input
        min={0}
        m='0 10px'
        width={232}
        type='number'
        value={numberValue}
        placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
        onChange={(event: Object) => (
          handleChangeInput({ groupId, filterIndex, filterProp: 'numberValue', value: getInputNumber(event) })
        )}
      />
    </FormGroup>
  );
};

const StringComponent = (props: Object) => {
  const {
    groupId,
    operation,
    filterIndex,
    stringValue,
    stringRange,
    handleChangeInput,
    handleChangeRange } = props;

  if (R.equals(operation, 'range')) {
    return (
      <FormGroup direction='row'>
        {
          ['from', 'to'].map((prefix: string, i: number) => (
            <Input
              key={i}
              m='0 10px'
              type='text'
              width={106}
              value={R.pathOr('', [prefix], stringRange)}
              placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
              onChange={(event: Object) => handleChangeRange({
                groupId,
                filterIndex,
                rangeType: prefix,
                filterProp: 'stringRange',
                value: event.target.value,
              })}
            />
          ))
        }
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <Input
        min={0}
        m='0 10px'
        type='text'
        width={232}
        value={stringValue}
        placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
        onChange={(event: Object) => (
          handleChangeInput({ groupId, filterIndex, filterProp: 'stringValue', value: event.target.value })
        )}
      />
    </FormGroup>
  );
};

const ReferenceComponent = (props: Object) => {
  const {
    groupId,
    filterIndex,
    referenceValue,
    handleChangeInput } = props;

  return (
    <FormGroup>
      <Input
        min={0}
        m='0 10px'
        type='text'
        width={232}
        value={referenceValue}
        placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
        onChange={(event: Object) => (
          handleChangeInput({ groupId, filterIndex, filterProp: 'referenceValue', value: event.target.value })
        )}
      />
    </FormGroup>
  );
};

export const CalendarGroup = (props: Object) => {
  const {
    value,
    width,
    maxDate,
    minDate,
    handler,
    direction,
    placeholder,
    isClearable,
    timeSelection,
    timeIntervals } = props;

  const timeFormat = G.getDateTimeFormat(timeSelection);
  const selected = G.ifElse(G.isNotNilAndNotEmpty(value), G.makeMomentInstance(value), null);
  const setMaxDate = () => {
    if (G.isNotNilAndNotEmpty(maxDate)) {
      return maxDate;
    }

    return G.momentAddYearsFromCurrent(100);
  };
  const setMinDate = () => {
    if (G.isNotNilAndNotEmpty(minDate)) {
      return minDate;
    }

    return G.momentSubtractYearsFromCurrent(100);
  };

  return (
    <CalendarFormGroup direction={R.or(direction, 'row')}>
      <DatePicker
        pl='3px'
        m='0 10px'
        fontSize={10}
        width={width}
        autoComplete='off'
        onChange={handler}
        selected={selected}
        todayButton='Today'
        maxDate={setMaxDate()}
        minDate={setMinDate()}
        dateFormat={timeFormat}
        showYearDropdown={true}
        showMonthDropdown={true}
        isClearable={R.or(isClearable, false)}
        timeIntervals={R.or(timeIntervals, 15)}
        placeholderText={R.or(placeholder, '')}
        showTimeSelect={R.or(timeSelection, false)}
        onBlur={(e: Object) => handler(e.target.value)}
      />
    </CalendarFormGroup>
  );
};

export const CalendarGroupMui = ({
  m,
  value,
  width,
  maxDate,
  minDate,
  handler,
  placeholder,
}: Object) => {
  const setMaxDate = () => {
    if (G.isNotNilAndNotEmpty(maxDate)) {
      return maxDate;
    }
    return G.momentAddYearsFromCurrent(100);
  };
  const setMinDate = () => {
    if (G.isNotNilAndNotEmpty(minDate)) {
      return minDate;
    }
    return G.momentSubtractYearsFromCurrent(100);
  };

  return (
    <DatePickerMui
      m={m}
      width={width}
      value={value}
      onChange={handler}
      disablePortal={true}
      maxDate={setMaxDate()}
      minDate={setMinDate()}
      withCalendarIcon={true}
      placeholder={placeholder}
      inputSettings={{
        fontSize: 12,
        p: '0 0 0 3px',
        zIndex: 'unset',
      }}
    />
  );
};

const DateComponent = (props: Object) => {
  const {
    groupId,
    filterIndex,
    dateRelative,
    handleChangeDate,
  } = props;

  if (G.isTrue(dateRelative)) {
    const lastNextValues = R.pick(['last', 'next'], props);

    return (
      <FilterDate zI='0'>
        {
          ['last', 'next'].map((prefix: string, i: number) => (
            <Input
              key={i}
              m='0 10px'
              width={106}
              type='number'
              value={R.pathOr('', [prefix], lastNextValues)}
              placeholder={G.getWindowLocale(`titles:${prefix}`, prefix)}
              onChange={(event: Object) => handleChangeDate({
                groupId,
                filterIndex,
                rangeType: prefix,
                filterProp: 'dateRelative',
                value: event.target.value,
              })}
            />
          ))
        }
      </FilterDate>
    );
  }

  const fromToValues = R.pick(['from', 'to'], props);

  const configUseMuiCalendar = G.getAmousConfigByNameFromWindow(GC.UI_USE_MUI_CALENDAR);
  const CalendarGroupToUse = G.ifElse(G.isTrue(configUseMuiCalendar), CalendarGroupMui, CalendarGroup);

  return (
    <FilterDate zI='unset'>
      {
        ['from', 'to'].map((prefix: string, i: number) => {
          let maxDate;
          let minDate;

          const isFrom = R.and(R.equals(prefix, 'from'), G.isNotNilAndNotEmpty(fromToValues.to));

          if (isFrom) maxDate = moment(fromToValues.to).startOf('day');

          if (R.equals(prefix, 'to')) minDate = moment(fromToValues.from).endOf('day');

          return (
            <CalendarGroupToUse
              key={i}
              m='0 10px'
              width={150}
              maxDate={maxDate}
              minDate={minDate}
              value={R.pathOr('', [prefix], fromToValues)}
              placeholder={
                `${G.getWindowLocale('titles:select-date', 'Select Date')} ${
                  G.getWindowLocale(`titles:${prefix}`, prefix)}`
              }
              handler={(date: Object) => {
                const value = G.ifElse(
                  R.equals(prefix, 'from'),
                  G.createLocalDateTimeFromInstanceOrISOString(date, G.getDateTimeFormat(true)),
                  G.toEndOfDayFromDate(date),
                );

                handleChangeDate({
                  value,
                  groupId,
                  filterIndex,
                  rangeType: prefix,
                  filterProp: 'dateRange',
                });
              }}
            />
          );
        })
      }
    </FilterDate>
  );
};

const getMultiSelectValue = (selectMultipleValue: any, stringValue: any) => {
  if (G.isNotNilAndNotEmpty(selectMultipleValue)) {
    return selectMultipleValue;
  } else if (G.isNotNilAndNotEmpty(stringValue)) {
    return R.split(',', stringValue);
  }

  return [];
};

const MultipleSelectComponent = (props: Object) => {
  const {
    id,
    groupId,
    filterIndex,
    stringValue,
    propertyName,
    useMenuPortalTarget,
    selectMultipleValue,
    handleChangeMultiSelect,
    filterSelectMaxMenuHeight,
  } = props;

  // TODO: check cond logic
  let searchOptions;

  if (G.isNilOrEmpty(R.path(['filterBy'], props))) {
    searchOptions = R.find(R.propEq(propertyName, 'value'))(R.path(['availableFields'], props));
  } else {
    searchOptions = R.find(R.propEq(propertyName, 'name'))(R.path(['filterBy'], props));
  }

  const { options, placeholder, handleDisableOptions } = searchOptions;

  let optionsToUse = R.or(options, []);

  if (G.isFunction(options)) optionsToUse = options();

  if (G.isNilOrEmpty(R.path([0, GC.FIELD_VALUE], optionsToUse))) {
    optionsToUse = R.tail(optionsToUse);
  }

  let placeholderValue = '';

  if (G.isNotNilAndNotEmpty(placeholder)) {
    placeholderValue = G.getWindowLocale(placeholder.key, placeholder.text);
  }

  return (
    <FormGroup>
      <Box m='5px 10px' width={232}>
        <ReactSelect
          id={id}
          height={30}
          isMulti={true}
          menuPlacement='auto'
          options={optionsToUse}
          placeholder={placeholderValue}
          menuShouldScrollIntoView={false}
          shouldNotGetValueFromOptions={true}
          isOptionDisabled={handleDisableOptions}
          maxMenuHeight={filterSelectMaxMenuHeight}
          useMenuPortalTarget={useMenuPortalTarget}
          value={getMultiSelectValue(selectMultipleValue, stringValue)}
          closeMenuOnScroll={R.pathEq('edit_report_wrapper', ['target', 'id'])}
          onChange={(options: Array) => handleChangeMultiSelect({ options, groupId, filterIndex })}
        />
      </Box>
    </FormGroup>
  );
};

const SingleSelectComponent = (props: Object) => {
  const {
    id,
    groupId,
    filterIndex,
    stringValue,
    propertyName,
    handleChangeInput,
    useMenuPortalTarget,
  } = props;

  let searchOptions;

  if (G.isNilOrEmpty(R.path(['filterBy'], props))) {
    searchOptions = R.find(R.propEq(propertyName, 'value'))(R.path(['availableFields'], props));
  } else {
    searchOptions = R.find(R.propEq(propertyName, 'name'))(R.path(['filterBy'], props));
  }

  const { options, placeholder } = searchOptions;

  return (
    <Box width={232} m='0 10px'>
      <ReactSelect
        id={id}
        height={30}
        options={options}
        isClearable={false}
        value={stringValue}
        menuPlacement='auto'
        menuShouldScrollIntoView={false}
        useMenuPortalTarget={useMenuPortalTarget}
        placeholder={G.getWindowLocale(placeholder.key, placeholder.text)}
        closeMenuOnScroll={R.pathEq('edit_report_wrapper', ['target', 'id'])}
        onChange={({ value }: Object) => handleChangeInput({ value, groupId, filterIndex, filterProp: 'stringValue' })}
      />
    </Box>
  );
};

export const renderField = (props: Object) => {
  const { dataType } = props;

  const dataTypeCompMap = {
    date: DateComponent,
    number: NumberComponent,
    string: StringComponent,
    boolean: BooleanComponent,
    reference: ReferenceComponent,
    'string:array': StringComponent,
    'string:select': SingleSelectComponent,
    selectMultiple: MultipleSelectComponent,
  };
  const FieldComponent = dataTypeCompMap[dataType];

  if (G.isNilOrEmpty(FieldComponent)) return null;

  return <FieldComponent {...props} />;
};
