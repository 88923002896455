import * as R from 'ramda';
import React from 'react';
import { withFormik, FieldArray } from 'formik';
import { pure, compose, lifecycle, withState, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../form-footer';
import {
  Map,
  StopInfo,
  StopMarker,
  Directions,
  MarkerWithInfo,
} from '../map';
// features
import { Fieldset } from '../../features/new-do/forms/formik/fieldset';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, Span, ActionButton } from '../../ui';
// multiple status message
import {
  validationSchema,
  statusMessageFieldSettings,
  defaultStatusMessageFields,
} from './field-settings';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const locationsWithContent = R.map((oldLocation: Object) => {
  const location = R.clone(oldLocation);
  location.infoContent = <StopInfo {...location} />;
  location.markerContent = <StopMarker {...location} />;

  return location;
});

const renderLocationsWithContent = (oldLocation: Object) => {
  const location = R.clone(oldLocation);
  location.markerContent = <div />;

  return location;
};

const renderStatusMessageMarker = (props: Object) => {
  const { values, events } = props;

  const markerColor = G.getTheme('map.redMarkerColor');
  const locations = R.compose(
    R.map(({ loadEventGuid }: Object) => {
      const location = R.compose(
        R.path([GC.SYSTEM_OBJECT_LOCATION]),
        R.find(R.propEq(loadEventGuid, GC.FIELD_GUID)),
      )(events);
      const latLng = {
        lat: G.getPropFromObject(GC.FIELD_LATITUDE, location),
        lng: G.getPropFromObject(GC.FIELD_LONGITUDE, location),
      };
      const locationWithContent = R.mergeRight(location, { latLng, color: markerColor });

      return renderLocationsWithContent(locationWithContent);
    }),
    R.filter(({ loadEventGuid }: Object) => G.isNotNilAndNotEmpty(loadEventGuid)),
    R.pathOr([], ['statusMessages']),
  )(values);
  const allLocations = R.concat(locations, locationsWithContent(events));

  return (
    <MarkerWithInfo
      color={markerColor}
      locations={G.makeLocationsWithTransform(allLocations)} />
  );
};

const mapEnhance = lifecycle({
  shouldComponentUpdate(nextProps: Object) {
    const mapper = R.compose(
      R.map(R.prop(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID)),
      R.path(['values', 'statusMessages']),
    );
    const condition = R.equals(mapper(this.props), mapper(nextProps));
    if (condition) return false;
  },
});

const MapComponent = mapEnhance((props: Object) => (
  <Box
    width='100%'
    height='100%'
    boxShadow='0 0 10px 0 rgba(205, 205, 205, 0.5)'
  >
    <Map height='250px'>
      <Directions
        locations={props.events.map(({ latLng }: Object) => latLng)} />
      {renderStatusMessageMarker(props)}
    </Map>
  </Box>
));

const getStatusReasonCodeOptions = (props: Object, index: number) => {
  const { values, asyncConfigs, communicationsConfigs, statusMessagesConfigs } = props;

  const statusCode = R.pathOr('', ['statusMessages', index, GC.FIELD_STATUS_MESSAGE_CODE], values);
  const statusMessagesWithStatusReasonCodes = R.compose(
    R.indexBy(R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID)),
    R.filter(R.prop(GC.FIELD_STATUS_MESSAGE_REASON_REQUIRED)),
  )(statusMessagesConfigs);
  const reasonCodes = R.path([statusCode, 'reasonCodes'], statusMessagesWithStatusReasonCodes);
  const hasReasonCodes = G.isNotNilAndNotEmpty(reasonCodes);
  if (G.isFalse(hasReasonCodes)) return [];
  const reasonCodeGuids = JSON.parse(reasonCodes);
  const statusReasonCodeConfigOptions = G.getFullOptionsFromDropdownConfigStore(
    R.or(communicationsConfigs, asyncConfigs),
    GC.COMMUNICATION_REASON_CODE,
  );
  const indexedStatusReasonCodeConfigOptions = G.createIndexedOptions(statusReasonCodeConfigOptions);
  const reasonCodeOptions = R.compose(
    G.addEmptyOptionToDropDown,
    R.map((item: string) => {
      const reasonCode = G.getPropFromObject(item, indexedStatusReasonCodeConfigOptions);

      return {
        [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(reasonCode),
        [GC.FIELD_LABEL]: G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, reasonCode),
      };
    }),
    R.filter((item: Object) => R.includes(item, R.keys(indexedStatusReasonCodeConfigOptions))),
  )(reasonCodeGuids);

  return reasonCodeOptions;
};

const getDateRange = (stops: Array) => {
  const stopEarlyDate = R.compose(
    G.convertDateTimeToConfigFormat,
    R.prop(GC.FIELD_LOAD_EVENT_EARLY_DATE),
    R.head,
    R.values,
  )(stops);

  const stopLateDate = R.compose(
    G.convertDateTimeToConfigFormat,
    R.prop(GC.FIELD_LOAD_EVENT_LATE_DATE),
    R.last,
    R.values,
  )(stops);

  return `${stopEarlyDate} - ${stopLateDate}`;
};

const getEventOptions = (events: Array, loadType: string, eventsDefined: Array) => R.compose(
  G.addEmptyOptionToDropDown,
  R.map((event: Object) => {
    const { guid, status } = event;

    return {
      [GC.FIELD_VALUE]: guid,
      disabled: R.includes(guid, eventsDefined),
      [GC.FIELD_LABEL]: `${G.getStopData(event, G.isLoadTypeTel(loadType))} (${G.toTitleCase(status)})`,
    };
  }),
)(events);

const getStatusCodeOptions = R.compose(
  R.prepend({
    [GC.FIELD_VALUE]: '',
    [GC.FIELD_LABEL]: G.getWindowLocale('titles:select-status-code', 'Select Status Code'),
  }),
  R.map(({ displayedValue, originalConfigGuid }: Object) => ({
    [GC.FIELD_LABEL]: displayedValue,
    [GC.FIELD_VALUE]: originalConfigGuid,
  })),
);

const getFilteredByStatusTypeStatusCodeOptions = (statusMessagesConfigs: Array, statusType: string) => R.compose(
  getStatusCodeOptions,
  R.filter((item: Object) => {
    if (G.isNilOrEmpty(statusType)) return true;

    return R.propEq(statusType, GC.FIELD_STATUS_TYPE, item);
  }),
)(statusMessagesConfigs);

const getStatusCodeByStatusCodeType = (statusType: string, statusMessagesConfigs: Array = []) => R.compose(
  R.pathOr('', [0, GC.FIELD_ORIGINAL_CONFIG_GUID]),
  R.filter(R.pathEq(statusType, [GC.FIELD_STATUS_TYPE])),
)(statusMessagesConfigs);

const getInitialStatusMessageFromEvent = (
  event: Object,
  statusMessagesConfigs: Object,
  useCheckedInStatusCode: boolean,
) => {
  let statusCode = null;
  let statusType = null;

  if (G.isStopDrop(event)) {
    statusType = GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED;
    statusCode = getStatusCodeByStatusCodeType(GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED, statusMessagesConfigs);
  }

  if (G.isStopPickup(event)) {
    statusType = GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED;
    statusCode = getStatusCodeByStatusCodeType(GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED, statusMessagesConfigs);
  }

  if (G.isTrue(useCheckedInStatusCode)) {
    if (G.isStopPickup(event)) {
      statusType = GC.STATUS_CODE_STATUS_TYPE_PICKUP_CHECKED_IN;
      statusCode = getStatusCodeByStatusCodeType(GC.STATUS_CODE_STATUS_TYPE_PICKUP_CHECKED_IN, statusMessagesConfigs);
    } else if (G.isStopDrop(event)) {
      statusType = GC.STATUS_CODE_STATUS_TYPE_DROP_CHECKED_IN;
      statusCode = getStatusCodeByStatusCodeType(GC.STATUS_CODE_STATUS_TYPE_DROP_CHECKED_IN, statusMessagesConfigs);
    } else {
      statusType = GC.STATUS_CODE_STATUS_TYPE_STOP_CHECKED_IN;
      statusCode = getStatusCodeByStatusCodeType(GC.STATUS_CODE_STATUS_TYPE_STOP_CHECKED_IN, statusMessagesConfigs);
    }

    if (G.isNilOrEmpty(statusCode)) {
      statusCode = getStatusCodeByStatusCodeType(GC.STATUS_CODE_STATUS_TYPE_STOP_CHECKED_IN, statusMessagesConfigs);
    }
  }

  return {
    statusCode,
    statusType,
    [GC.FIELD_STATUS_MESSAGE_DATE]: null,
    [GC.FIELD_STATUS_MESSAGE_REASON_CODE]: null,
    [GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID]: G.getGuidFromObject(event),
    [GC.FIELD_TYPE]: G.ifElse(G.isTrue(useCheckedInStatusCode), 'arrived', 'departed'),
  };
};

const StatusMessagesHeader = ({
  events,
  isMapOpened,
  addRowCondition,
  handleToggleMap,
  handleAddStatusMessageRow,
}: Object) => (
  <Flex m='5px' justifyContent='space-between'>
    <Flex
      cursor='pointer'
      width='max-content'
      onClick={handleAddStatusMessageRow}
    >
      <Box fontSize={12} fontWeight='bold' color={G.getTheme('colors.light.blue')}>
        {G.getWindowLocale('titles:add-status-messages', 'Add Status Messages')}
      </Box>
      {
        addRowCondition && <Box ml={10}>{I.plusRound(darkBlueColor)}</Box>
      }
    </Flex>
    <Flex>
      <Box fontSize={12}>
        {G.getWindowLocale('titles:transit-date-range', 'Transit Date Range')}:
        <Span ml='5px' fontWeight='bold'>{getDateRange(events)}</Span>
      </Box>
      <ActionButton
        ml={10}
        height={25}
        fontSize={12}
        type='button'
        borderRadius='4px'
        onClick={handleToggleMap}
      >
        <Flex justifyContent='center'>
          <Box mr='5px'>
            {I.onMap(G.getTheme('colors.white'))}
          </Box>
          {G.ifElse(
            isMapOpened,
            G.getWindowLocale('titles:hide-map', 'Hide Map'),
            G.getWindowLocale('titles:show-map', 'Show Map'),
          )}
        </Flex>
      </ActionButton>
    </Flex>
  </Flex>
);

const StatusMessage = (props: Object) => {
  const {
    form,
    index,
    fields,
    isMapOpened,
    eventOptions,
    statusMessages,
    handleRemoveRow,
    statusCodeOptions,
    handleReRenderMap,
    statusReasonCodeOptions } = props;

  return (
    <Flex>
      {
        R.gt(R.length(statusMessages), 1) &&
        <Box
          width={13}
          height={15}
          cursor='pointer'
          m='30px 15px 0 0'
          onClick={() => handleRemoveRow(index)}
        >
          {I.trash(darkBlueColor)}
        </Box>
      }
      <Fieldset
        {...form}
        fields={fields}
        isMapOpened={isMapOpened}
        eventOptions={eventOptions}
        handleReRenderMap={handleReRenderMap}
        statusCodeOptions={statusCodeOptions}
        statusReasonCodeOptions={statusReasonCodeOptions}
        fieldsWrapperStyles={{ pl: 0, pt: 10, pr: '5px' }}
      />
    </Flex>
  );
};

const ArrivedDepartedSection = (props: Object) => {
  const {
    form,
    remove,
    events,
    loadType,
    isMapOpened,
    handleReRenderMap,
    statusMessagesConfigs } = props;

  const handleRemoveRow = (index: number) => {
    if (isMapOpened) G.callFunction(handleReRenderMap);

    remove(index);
  };
  const textColor = G.getTheme('colors.greyMatterhorn');
  const borderColor = G.getTheme('colors.light.darkGrey');
  const statusMessages = R.pathOr([], ['values', 'statusMessages'], form);
  const titlesMap = {
    arrived: G.getWindowLocale('titles:arrived', 'Arrived'),
    departed: G.getWindowLocale('titles:completed', 'Completed'),
  };
  const arrivedEventsDefined = R.compose(
    R.map(R.prop(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID)),
    R.filter(R.propEq('arrived', GC.FIELD_TYPE)),
  )(statusMessages);
  const departedEventsDefined = R.compose(
    R.map(R.prop(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID)),
    R.filter(R.propEq('departed', GC.FIELD_TYPE)),
  )(statusMessages);
  const arrivedEventOptions = getEventOptions(events, loadType, arrivedEventsDefined);
  const departedEventOptions = getEventOptions(events, loadType, departedEventsDefined);
  const statusCodeOptions = (statusType: string) => getFilteredByStatusTypeStatusCodeOptions(
    statusMessagesConfigs,
    statusType,
  );

  return (
    <div>
      {
        statusMessages.map((item: Object, index: number) => {
          const { type, statusType } = item;

          const eventOptions = G.ifElse(R.equals(type, 'arrived'), arrivedEventOptions, departedEventOptions);

          return (
            <Box
              mt={15}
              key={index}
              p='15px 10px'
              border='1px solid'
              borderRadius='4px'
              borderColor={borderColor}
            >
              <Flex color={textColor} fontWeight='bold'>
                {G.getPropFromObject(type, titlesMap)}
              </Flex>
              <StatusMessage
                form={form}
                index={index}
                isMapOpened={isMapOpened}
                eventOptions={eventOptions}
                statusMessages={statusMessages}
                handleRemoveRow={handleRemoveRow}
                handleReRenderMap={handleReRenderMap}
                statusCodeOptions={statusCodeOptions(statusType)}
                statusReasonCodeOptions={getStatusReasonCodeOptions(props, index)}
                fields={statusMessageFieldSettings(index, { statusMessagesConfigs, values: form.values })} />
            </Box>
          );
        })
      }
    </div>
  );
};


const StatusMessageSection = (props: Object) => {
  const {
    push,
    form,
    remove,
    events,
    loadType,
    isMapOpened,
    handleToggleMap,
    arrivedDeparted,
    handleReRenderMap,
    statusMessagesConfigs } = props;

  const { values } = form;

  const statusMessages = R.pathOr([], ['statusMessages'], values);
  const eventsDefined = R.map(R.prop(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID), statusMessages);
  const eventOptions = getEventOptions(events, loadType, eventsDefined);
  const addRowCondition = R.and(
    G.notEquals(arrivedDeparted, true),
    G.notEquals(R.length(events), R.length(statusMessages)),
  );
  const handleAddStatusMessageRow = () => {
    if (addRowCondition) {
      if (isMapOpened) handleReRenderMap();

      push(defaultStatusMessageFields());
    }
  };
  const handleRemoveRow = (index: number) => {
    if (isMapOpened) handleReRenderMap();

    remove(index);
  };

  const commonStatusMessageProps = {
    form,
    isMapOpened,
    eventOptions,
    handleRemoveRow,
    handleReRenderMap,
    statusCodeOptions: getStatusCodeOptions(statusMessagesConfigs),
  };

  return (
    <Box>
      <StatusMessagesHeader
        events={events}
        isMapOpened={isMapOpened}
        handleToggleMap={handleToggleMap}
        addRowCondition={addRowCondition}
        handleAddStatusMessageRow={handleAddStatusMessageRow} />
      {
        G.notEquals(arrivedDeparted, true) &&
        statusMessages.map((_: any, index: number) => (
          <StatusMessage
            {...commonStatusMessageProps}
            key={index}
            index={index}
            statusMessages={statusMessages}
            statusReasonCodeOptions={getStatusReasonCodeOptions(props, index)}
            fields={statusMessageFieldSettings(index, { values, statusMessagesConfigs })} />
        ))
      }
    </Box>
  );
};

const getOverflowY = ({ values, isMapOpened }: Object) => {
  if (G.isAllTrue(
    G.isFalse(isMapOpened),
    R.lte(R.length(values.statusMessages), 4),
    G.isFalse(G.getAmousConfigByNameFromWindow(GC.UI_USE_MUI_CALENDAR)),
  )) return 'initial';

  return 'auto';
};

const enhance = compose(
  withState('isMapOpened', 'toggleMap', false),
  withState('isSettedUpStatusLocation', 'setStatusLocation', true),
  withHandlers({
    afterSearchCallback: ({ setStatusLocation }: Object) => () => setStatusLocation(true),
    handleToggleMap: ({ toggleMap }: Object) => () => toggleMap((prev: boolean) => R.not(prev)),
    handleReRenderMap: ({ setStatusLocation }: Object) => () => {
      setStatusLocation(false);
      setTimeout(() => setStatusLocation(true), 0);
    },
  }),
  pure,
);

const getMapPropsToValues = (props: Object) => {
  const { events, arrived, statusMessagesConfigs } = props;

  const statusMessages = R.map(
    (event: Object) => getInitialStatusMessageFromEvent(event, statusMessagesConfigs, arrived),
    events,
  );

  return { statusMessages };
};

const formEnhance = withFormik({
  validationSchema,
  mapPropsToValues: getMapPropsToValues,
  handleSubmit: (values: Object, { props, setFieldValue }: Object) => {
    const { events, loadType, createMultipleStatusMessage } = props;

    const callback = (createdStatusMessages: Array) => {
      const createdStatusMessageEventGuids = R.map(
        R.prop(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID),
        createdStatusMessages,
      );
      const notCreatedStatusMessages = R.compose(
        R.values,
        R.omit(createdStatusMessageEventGuids),
        R.indexBy(R.prop(GC.FIELD_STATUS_MESSAGE_LOAD_EVENT_GUID)),
      )(values.statusMessages);

      setFieldValue('statusMessages', notCreatedStatusMessages);
    };
    const statusMessageLocationPropName = G.ifElse(
      G.isLoadTypeTel(loadType),
      'telStatusMessageLocation',
      'cloStatusMessageLocation',
    );
    const statusMessages = R.map((item: Object) => {
      const { statusDate, loadEventGuid } = item;

      const statusMessageLocation = R.compose(
        R.pick(R.keys(GC.locationDefaultFields)),
        R.pathOr({}, [GC.SYSTEM_OBJECT_LOCATION]),
        R.find(R.propEq(loadEventGuid, GC.FIELD_GUID)),
      )(events);

      return R.mergeRight(item, {
        [statusMessageLocationPropName]: statusMessageLocation,
        statusDate: G.createLocalDateTimeFromInstanceOrISOString(statusDate, GC.DEFAULT_DATE_TIME_FORMAT),
      });
    }, values.statusMessages);

    createMultipleStatusMessage({ callback, statusMessages });
  },
});

const completedStatusMessageEnhance = compose(
  enhance,
  formEnhance,
);

const CompletedStatusMessageForm = completedStatusMessageEnhance((props: Object) => (
  <Box height='100%' maxHeight='90vh' overflowY={getOverflowY(props)}>
    <form onSubmit={props.handleSubmit}>
      <FieldArray
        name='statusMessages'
        render={(arrayHelpers: Object) => <StatusMessageSection {...props} {...arrayHelpers} />} />
      <FormFooter2 closeModal={props.closeModal} boxStyles={{ pt: 20, justifyContent: 'space-around' }} />
    </form>
    {
      props.isMapOpened &&
      <Box mt={15} height={250} width='100%'>
        {props.isSettedUpStatusLocation && <MapComponent {...props} />}
      </Box>
    }
  </Box>
));

const getArrivedDepartedMapPropsToValues = (props: Object) => {
  const { events, statusMessagesConfigs } = props;

  const statusMessages = R.compose(
    R.flatten,
    R.map((event: Object) => [
      getInitialStatusMessageFromEvent(event, statusMessagesConfigs, true),
      getInitialStatusMessageFromEvent(event, statusMessagesConfigs),
    ]),
  )(events);

  return { statusMessages };
};

const arrivedDepartedFormikEnhance = withFormik({
  validationSchema,
  mapPropsToValues: getArrivedDepartedMapPropsToValues,
  handleSubmit: (values: Object, { props, setFieldValue }: Object) => {
    const { events, loadType, createMultipleStatusMessage } = props;

    const callback = (createdStatusMessages: Array) => {
      if (G.isNilOrEmpty(createdStatusMessages)) return;

      const notCreatedStatusMessages = R.remove(0, R.length(createdStatusMessages), values.statusMessages);

      setFieldValue('statusMessages', notCreatedStatusMessages);
    };
    const statusMessageLocationPropName = G.ifElse(
      G.isLoadTypeTel(loadType),
      'telStatusMessageLocation',
      'cloStatusMessageLocation',
    );
    const statusMessages = R.map((item: Object) => {
      const { statusDate, loadEventGuid } = item;

      const statusMessageLocation = R.compose(
        R.pick(R.keys(GC.locationDefaultFields)),
        R.pathOr({}, [GC.SYSTEM_OBJECT_LOCATION]),
        R.find(R.propEq(loadEventGuid, GC.FIELD_GUID)),
      )(events);

      return R.mergeRight(item, {
        [statusMessageLocationPropName]: statusMessageLocation,
        statusDate: G.createLocalDateTimeFromInstanceOrISOString(statusDate, GC.DEFAULT_DATE_TIME_FORMAT),
      });
    }, values.statusMessages);

    createMultipleStatusMessage({ callback, statusMessages });
  },
});

const arrivedDepartedEnhance = compose(
  enhance,
  arrivedDepartedFormikEnhance,
);

export const ArrivedDepartedStatusMessageForm = arrivedDepartedEnhance((props: Object) => (
  <Box height='100%' maxHeight='90vh' overflowY={getOverflowY(props)}>
    <form onSubmit={props.handleSubmit}>
      <StatusMessagesHeader
        events={props.events}
        addRowCondition={false}
        isMapOpened={props.isMapOpened}
        handleToggleMap={props.handleToggleMap}
        handleAddStatusMessageRow={props.handleAddStatusMessageRow} />
      <FieldArray
        name='statusMessages'
        render={(arrayHelpers: Object) => <ArrivedDepartedSection {...props} {...arrayHelpers} />} />
      <FormFooter2 closeModal={props.closeModal} boxStyles={{ pt: 20, justifyContent: 'space-around' }} />
    </form>
    {
      props.isMapOpened &&
      <Box height={250} width='100%'>
        {props.isSettedUpStatusLocation && <MapComponent {...props} />}
      </Box>
    }
  </Box>
));

const MultipleStatusMessageForm = (props: Object) => {
  const useArrivedDepartedForm = R.pathOr(false, ['useArrivedDepartedForm'], props);

  if (G.isTrue(useArrivedDepartedForm)) {
    return <ArrivedDepartedStatusMessageForm {...props} />;
  }

  return <CompletedStatusMessageForm {...props} />;
};

export default MultipleStatusMessageForm;
