import * as R from 'ramda';
//////////////////////////////////////////////////

export const mapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      { color: '#e9e9e9' },
      { lightness: 17 },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'landscape',
    stylers: [
      { color: '#f5f5f5' },
      { lightness: 20 },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      { color: '#ffffff' },
      { lightness: 17 },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      { color: '#ffffff' },
      { lightness: 29 },
      { weight: 0.2 },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      { color: '#ffffff' },
      { lightness: 18 },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'road.local',
    stylers: [
      { color: '#ffffff' },
      { lightness: 16 },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      { color: '#f5f5f5' },
      { lightness: 21 },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      { color: '#dedede' },
      { lightness: 21 },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      { visibility: 'on' },
      { color: '#ffffff' },
      { lightness: 16 },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      { saturation: 36 },
      { color: '#333333' },
      { lightness: 40 },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      { color: '#f2f2f2' },
      { lightness: 19 },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'administrative',
    stylers: [
      { color: '#fefefe' },
      { lightness: 20 },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      { color: '#fefefe' },
      { lightness: 17 },
      { weight: 1.2 },
    ],
  },
];

export const mapStylesDark = [
  {
    elementType: 'geometry',
    stylers: [
      { color: '#242f3e' },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      { color: '#242f3e' },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#746855' },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'administrative.locality',
    stylers: [
      { color: '#d59563' },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#d59563' },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      { color: '#263c3f' },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#6b9a76' },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      { color: '#38414e' },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      { color: '#212a37' },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#9ca5b3' },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'road.highway',
    stylers: [{color: '#746855'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      { color: '#1f2835' },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#f3d19c' },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      { color: '#2f3948' },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#d59563' },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      { color: '#17263c' },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#515c6d' },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      { color: '#17263c' },
    ],
  },
];

export const getPolylineOptions = (options: Object) => ({
  strokeWeight: 5,
  strokeOpacity: 0.8,
  strokeColor: R.pathOr('#00b3fd', ['color'], options),
});
