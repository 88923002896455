import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { getInvoiceGlCode, withComponentDidUpdatePropCallback } from '../../../hocs';
// component charge
import { recalculateAllCharges } from '../formik/helpers';
import {
  setGlCodeToCharges,
  withRecalculateChargesOnChangeTotals,
  withRecalculateChargesOnChangeRecalculateId,
} from './';
//////////////////////////////////////////////////

const withRecalculateAllCharges = compose(
  withHandlers({
    handleRecalculateAllCharges: () => (propName: string, props: Object) => {
      const { rateProps } = props;

      const { values, setValues } = rateProps;
      const { charges } = values;

      const newValues = R.assoc(GC.FIELD_CHARGES, recalculateAllCharges(charges, props), values);

      setValues(newValues);
    },
  }),
  withRecalculateChargesOnChangeTotals,
  withRecalculateChargesOnChangeRecalculateId,
  pure,
);

const withGLCodeMappings = compose(
  withHandlers({
    handleSetInitialGLCodes: (props: Object) => () => {
      const { rateProps, isInvoice, isEditMode, glCodeMappings } = props;

      const { values, setValues } = rateProps;
      const { charges } = values;

      if (R.not(isInvoice)) return;

      if (R.and(isInvoice, G.isTrue(isEditMode))) return;

      const invoiceCurrency = G.getCurrencyFromObject2(values);
      const newValues = R.assoc(GC.FIELD_CHARGES, setGlCodeToCharges(charges, glCodeMappings, invoiceCurrency), values);

      setValues(newValues);
    },
    handleChangeCurrency: (props: Object) => (propName: string, newProps: Object) => {
      const { rateProps, isInvoice, glCodeMappings, glCodeMappingList } = props;

      const { values, setValues } = rateProps;
      const { charges } = values;

      if (R.not(isInvoice)) return;

      const { scopeToUse, orderTypeGuid } = glCodeMappings;
      const invoiceCurrency = G.getPropFromObject(propName, newProps);
      const invoiceGlCode = getInvoiceGlCode({
        scopeToUse,
        orderTypeGuid,
        glCodeMappings,
        invoiceCurrency,
        glCodeMappingList,
      });
      const newValues = {
        ...values,
        [GC.FIELD_GL_CODE]: G.fromNullToEmptyString(invoiceGlCode),
        [GC.FIELD_CHARGES]: setGlCodeToCharges(charges, glCodeMappings, invoiceCurrency),
      };


      setValues(newValues);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'glCodeMappings',
    callbackName: 'handleSetInitialGLCodes',
  }),
  withComponentDidUpdatePropCallback({
    propName: GC.FIELD_CURRENCY,
    callbackName: 'handleChangeCurrency',
  }),
  pure,
);

const withDefaultOrderAccessorials = compose(
  withHandlers({
    handleSetDefaultOrderAccessorials: (props: Object) => () => {
      const { rateProps, isInvoice, isEditMode, defaultOrderAccessorials, defaultOrderFuelAccessorial } = props;

      const { values, setValues } = rateProps;

      const { charges } = values;

      if (R.and(R.not(isInvoice), G.isTrue(isEditMode))) return;

      const allAccessorials = R.concat(R.or(charges, []), R.or(defaultOrderAccessorials, []));

      const newValues = R.assoc(
        GC.FIELD_CHARGES,
        recalculateAllCharges(G.addMainFuelChargeToCharges2(allAccessorials, defaultOrderFuelAccessorial), props),
        values,
      );

      setValues(newValues);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'defaultOrderAccessorials',
    callbackName: 'handleSetDefaultOrderAccessorials',
  }),
  pure,
);

const withSharedAccessorialsApplyTo = compose(
  withHandlers({
    handleSetSharedAccessorialsApplyTo: (props: Object) => () => {
      const { rateProps, isInvoice, isEditMode, sharedAccessorialsApplyTo } = props;

      const { values, setValues } = rateProps;

      const { charges } = values;

      if (R.and(R.not(isInvoice), G.isTrue(isEditMode))) return;

      const allAccessorials = R.concat(R.or(charges, []), R.or(sharedAccessorialsApplyTo, []));

      const newValues = R.assoc(
        GC.FIELD_CHARGES,
        recalculateAllCharges(G.addMainFuelChargeToCharges(allAccessorials), props),
        values,
      );

      setValues(newValues);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'sharedAccessorialsApplyTo',
    callbackName: 'handleSetSharedAccessorialsApplyTo',
  }),
  pure,
);

export const withDOCharges = compose(
  withRecalculateAllCharges,
  withRecalculateChargesOnChangeRecalculateId,
  withGLCodeMappings,
);

export const withDOCharges2 = compose(
  withRecalculateAllCharges,
  withRecalculateChargesOnChangeRecalculateId,
  withGLCodeMappings,
  withDefaultOrderAccessorials,
  withSharedAccessorialsApplyTo,
);

